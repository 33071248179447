import { useAppSelector } from '../../hooks/redux';

export const useTurnKeyStepsSelector = () =>
  useAppSelector(
    (state) => state.turnKeySteps,
    (prev, curr) => prev.activeStep === curr.activeStep,
  );

export const useTurnKeyStepsErrorStatus = () =>
  useAppSelector((state) => state.turnKeySteps.errorStatusSteps);
