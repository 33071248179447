export const enum KeysMenuItem {
  account = 'accountKey',
  advert = 'advertKey',
  subscriptions = 'subscriptionsKey',
  subscriptionsFilter = 'subscriptionsFilterKey',
  subscriptionsMonitoring = 'subscriptionMonitoringKey',
  subscriptionsResearches = 'subscriptionsResearchesKey',
  plans = 'plansKey',
  payments = 'paymentsKey',
  settings = 'settingsKey',
  settingsOption1 = 'settingsOption1Key',
  settingsOption2 = 'settingsOption2Key',
  support = 'supportKey',
  logout = 'logoutKey',
}

export const enum LabelsMenuItem {
  account = 'Аккаунт',
  advert = 'Объявления',
  subscriptions = 'Подписки',
  subscriptionsFilter = 'Подписки по фильтру',
  subscriptionsMonitoring = 'Мониторинг объявлений',
  subscriptionsResearches = 'Подписки на отчеты',
  plans = 'Планы',
  payments = 'Платежи',
  settings = 'Настройки',
  settingsOption1 = 'Настройки 1',
  settingsOption2 = 'Настройки 2',
  support = 'Поддержка',
  logout = 'Выйти',
}
