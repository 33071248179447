import { FC, useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { ICarUrlParams } from '../../shared';
import {
  useCompaniesSelector,
  useProfileSelector,
} from '../../store/selectors';
import { Result, Spin, notification } from 'antd';
import { config } from '../../config';
import { StyledContent } from '../../components/styled/StyledContent';
import { useMediaQuery } from 'react-responsive';
import { SCREEN_WIDTH } from '../../screenSettings';
import { russiaCountryValue, russiaRegionId } from '../../data';
import { APP_PATHS } from '../../layout/settingsLayout';
import qs from 'qs';
import { StyledBreadcrumbsWrapper } from './CarPage.styled';
import { useLazyGetCarQuery } from '../../store/car/car.api';
import { CarBreadcrumb } from './Components/CarBreadcrumb';
import { getDefaultDeliveryCompany } from '../../utils/cars';
import { Company } from '../../models/response/companies';
import { useMetadata } from '../../hooks/seo';
import { regionIdsMappingDeclined } from '../../data/mappings';
import { Metadata } from '../../components/Metadata';

export const CarPage: FC = () => {
  const { adId, sourceId } = useParams() as unknown as ICarUrlParams;
  const { currency } = useProfileSelector();
  const [notificationApi, contextHolder] = notification.useNotification();
  const [defaultDeliveryCompany, setDefaultDeliveryCompany] =
    useState<Company>();
  const isMobileWidth = useMediaQuery({
    query: `(max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px)`,
  });
  const { companies } = useCompaniesSelector();
  const [
    fetchCar,
    { isError: isErrorCar, data: car, isFetching: isFetchingCar },
  ] = useLazyGetCarQuery();

  const currentRegion: string | undefined =
    car && regionIdsMappingDeclined[car.regionId];

  const metadata = useMetadata(
    APP_PATHS.CAR_PAGE,
    car?.makeViewName || '',
    car?.modelViewName || '',
    currentRegion || '',
  );

  const commonParams = {
    transmission: car?.transmissionType,
    from: process.env.REACT_APP_SORT_FORM_DEFAULT_FROM_DAYS,
  };

  const hasRussiaRegion = russiaRegionId.some(
    (item) => item.value === car?.regionId,
  );

  const queryStringRegion: string = qs.stringify(
    {
      ...commonParams,
      country: hasRussiaRegion ? russiaCountryValue : car?.regionId,
      regionId: hasRussiaRegion ? car?.regionId : undefined,
    },
    { addQueryPrefix: true, encode: false },
  );

  const similarAdsUrl = `${APP_PATHS.CARS_PAGE}/${car?.makeId.toLowerCase()}/${car?.modelOriginalName.toLowerCase()}/${queryStringRegion}`;

  if (process.env.NODE_ENV === 'development') console.log('[CAR PAGE RENDER]');

  const getData = async () => {
    try {
      if (!adId || !sourceId) throw new Error('adId или sourceId не найдены');

      const carResponse = await fetchCar({ adId, sourceId, currency });

      if (!carResponse.data) {
        throw new Error('Не удалось загрузить данные авто');
      }

      const defaultDeliveryCompany = getDefaultDeliveryCompany(
        companies,
        carResponse.data.regionId,
      );

      if (defaultDeliveryCompany) {
        setDefaultDeliveryCompany(defaultDeliveryCompany);
      }
    } catch (err: any) {
      notificationApi.error({
        message: 'Ошибка загрузки данных',
        description: err?.message,
        duration: config.DEFAULT_NOTIFICATION_DURATION,
      });
    }
  };

  useEffect(() => {
    getData();
  }, [currency, adId, sourceId, companies]);

  return (
    <>
      <Metadata
        title={metadata.title}
        description={metadata.description}
        imageUrl={car?.imageUrl}
      />

      {contextHolder}
      <StyledBreadcrumbsWrapper>
        {car && (
          <CarBreadcrumb
            similarAdsUrl={similarAdsUrl}
            currentRegion={currentRegion}
            car={car}
          />
        )}
      </StyledBreadcrumbsWrapper>

      {isFetchingCar && (
        <StyledContent
          style={{ marginTop: 0, marginBottom: isMobileWidth ? 30 : 40 }}
        >
          {isErrorCar && (
            <Result status='404' subTitle='Извините, произошла ошибка' />
          )}
          <Spin size='large' spinning={isFetchingCar}>
            {contextHolder}
          </Spin>
        </StyledContent>
      )}

      {car && (
        <Outlet
          context={{
            car,
            isErrorCar,
            isFetchingCar,
            contextHolder,
            currentRegion,
            similarAdsUrl,
            defaultDeliveryCompany,
          }}
        />
      )}
    </>
  );
};
