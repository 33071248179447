import styled from 'styled-components';
import { SCREEN_WIDTH } from '../../../../../screenSettings';
import { Form } from 'antd';
import { DeliveryFormFiledsType } from '../types/form';

export const StyledFormDelivery = styled(Form<DeliveryFormFiledsType>)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;
  max-width: 462px;
  margin-bottom: 40px;

  @media screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_2}px) {
    max-width: 100%;
  }
`;
