import { useEffect, forwardRef, useImperativeHandle } from 'react';
import { StyledFormWrapper } from '../../TurnKeyForms.styled';
import { Form, Radio, notification } from 'antd';
import { SearchParamsFormFieldsType } from '../../types/form';
import {
  years,
  engineSizes,
  mileages,
  turnkeyAutoCurrencies,
  transmissions,
} from '../../../../../../data';
import { StyledTitleForm } from '../../shared/styles';
import { Fragment } from 'react';
import { changeOnDefaultIncluded } from '../../../../utils/changeOnDefault';
import { StyledSearchParamsFormMobile } from './SearchParamsFormMobile.styled';
import {
  CustomCheckboxGroup,
  CustomSegmented,
} from '../../../../../../components/FormElements';
import { FormFieldName } from '../../../../../../enums/form';
import { FormItemLabel } from '../../../../../../components/FormItemLabel';
import { FormFiledLabel } from '../../../../../../enums/form';
import { commonValidationRules } from '../../../../../../data/commonValidationRules';
import {
  useCarsSelector,
  useTurnKeySelector,
} from '../../../../../../store/selectors';
import { SelectOptions } from '../../../../../../enums';
import { useGetModelsWhithoutParams } from '../../../../../../hooks/cars';
import {
  getCarBodyColors,
  getCarBodyTypes,
  getCarEngineOptions,
  getCarTypesOfDrive,
} from '../../../../utils/getSelectOptions';
import { useActions } from '../../../../../../hooks/useActions';
import { useTurnKeyFormsSelector } from '../../../../../../store/selectors';
import { StepsValues } from '../../../../enums/stepsValues';
import { useFirstRender } from '../../../../../../hooks/useFirstRender';
import { FormRef } from '../../types/form';
import { FilterCellSingle } from '../../../../../../components/MobileFormElements';
import { PairFilterCell } from '../../../../../../components/MobileFormElements';
import { useGetMakesQuery } from '../../../../../../store/cars/cars.api';
import { CurrencySumSlider } from '../../../../../../components/CurrencySumSlider';
import { NON_IMPORTANT_TYPE_VALUE } from '../../../../../../constants';
import { turnKeySourceIDOptions } from '../../../../../../data/sourceIdMapping';
import { StyledRadioGroup } from '../../SearchParamsForm/SearchParamsForm.styled';
import { hasDamageOptions } from '../../../../../../data/hasDamageOptions';
import { useQueryState } from '../../../../../../hooks/useQueryState';
import { generateFormFields } from '../../SearchParamsForm/SearchParamsForm.utils';
import { useTurnKeyCarContext } from '../../../../../../hooks/turnKeyCar';

export const SearchParamsFormMobile = forwardRef<FormRef>((_, ref) => {
  const { handleSetModels } = useTurnKeyCarContext();
  const { setSearchParamsForm, setActiveStep, setErrorStatus } = useActions();
  const isMount = useFirstRender();
  const { searchParamsForm } = useTurnKeyFormsSelector();
  const [notificationApi, contextHolder] = notification.useNotification();
  const [queryParameters, setQueryParameters] = useQueryState();
  const [form] = Form.useForm<SearchParamsFormFieldsType>();
  const makesFormValue = Form.useWatch(FormFieldName.Make, form);
  const modelsFormValue = Form.useWatch(FormFieldName.Model, form);
  const yearFromCurrent = Form.useWatch(FormFieldName.YearFrom, form);
  const yearToCurrent = Form.useWatch(FormFieldName.YearTo, form);
  const prevCheckedBodyType = Form.useWatch(FormFieldName.BodyTypes, form);
  const prevTypesOfDrive = Form.useWatch(FormFieldName.TypesOfDrive, form);
  const prevBodyColors = Form.useWatch(FormFieldName.BodyColors, form);
  const prevEngineTypes = Form.useWatch(FormFieldName.EngineTypes, form);
  const currency = Form.useWatch(FormFieldName.Currency, form);
  const currentEngineCapacityFrom = Form.useWatch(
    FormFieldName.EngineCapacityFrom,
    form,
  );
  const currentEngineCapacityTo = Form.useWatch(
    FormFieldName.EngineCapacityTo,
    form,
  );
  const mileageCurrent = Form.useWatch(FormFieldName.ToMileage, form);

  const { isFetchingModels, models, getModels } =
    useGetModelsWhithoutParams(notificationApi);

  const { carMakes: makes } = useCarsSelector();

  const { isLoading: isFetchingMakes } = useGetMakesQuery();

  const { carOptions } = useTurnKeySelector();

  const carEngineOptions = getCarEngineOptions(carOptions.engineTypes);
  const carTypesOfDrive = getCarTypesOfDrive(carOptions.typesOfDrive);
  const carBodyTypes = getCarBodyTypes(carOptions.bodyTypes);
  const carBodyColors = getCarBodyColors(carOptions.bodyColors);

  useEffect(() => {
    if (makesFormValue && makesFormValue !== SelectOptions.any) {
      getModels(makesFormValue);
    }
  }, [getModels, makesFormValue]);

  useEffect(() => {
    if (isMount && searchParamsForm.budget) {
      form.setFieldsValue(searchParamsForm);
    }
  }, [form, isMount, searchParamsForm]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    handleSetModels(models);
  }, [models]);

  const finishFormHandler = (values: SearchParamsFormFieldsType) => {
    setSearchParamsForm(values);
    setActiveStep(StepsValues.CarEquipment);
  };

  const handleFieldsChanged = () => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);

    setErrorStatus(hasErrors);
  };

  const submitForm = () => form.submit();

  const resetForm = () => {
    form.resetFields();
    form.setFieldValue(FormFieldName.Currency, turnkeyAutoCurrencies[0].value);
    form.setFieldValue(FormFieldName.EngineTypes, [NON_IMPORTANT_TYPE_VALUE]);
    form.setFieldValue(FormFieldName.TypesOfDrive, [NON_IMPORTANT_TYPE_VALUE]);
    form.setFieldValue(FormFieldName.BodyTypes, [NON_IMPORTANT_TYPE_VALUE]);
    form.setFieldValue(FormFieldName.BodyColors, [
      NON_IMPORTANT_TYPE_VALUE.toString(),
    ]);
    form.setFieldValue(
      FormFieldName.SourceRegionId,
      turnKeySourceIDOptions[0].value,
    );
    form.setFieldValue(FormFieldName.Make, undefined);
    form.setFieldValue(FormFieldName.Model, undefined);
    setQueryParameters({
      [FormFieldName.SourceRegionId]: turnKeySourceIDOptions[0].value,
      [FormFieldName.Make]: undefined,
      [FormFieldName.Model]: undefined,
    });
  };

  useImperativeHandle(ref, () => ({ submitForm, resetForm }));

  return (
    <Fragment key='search-params-form'>
      {contextHolder}
      <StyledTitleForm>Параметры поиска</StyledTitleForm>
      <StyledFormWrapper>
        <StyledSearchParamsFormMobile
          scrollToFirstError
          onFieldsChange={handleFieldsChanged}
          requiredMark={false}
          layout='vertical'
          initialValues={searchParamsForm.make ? searchParamsForm : undefined}
          form={form}
          onFinish={finishFormHandler}
          name='searchParamsForm'
          fields={generateFormFields(queryParameters)}
        >
          <CustomSegmented
            formItemProps={{
              style: { width: '100%' },
              name: FormFieldName.SourceRegionId,
              label: <FormItemLabel>Авто из</FormItemLabel>,
              rules: commonValidationRules.fromRegion,
            }}
            options={turnKeySourceIDOptions}
            onChange={(value) => {
              setQueryParameters({ [FormFieldName.SourceRegionId]: value });
            }}
          />

          <StyledRadioGroup
            name={FormFieldName.HasDamage}
            label={<FormItemLabel>Состояние авто</FormItemLabel>}
          >
            <Radio.Group size='large' options={hasDamageOptions} />
          </StyledRadioGroup>

          <CustomSegmented
            formItemProps={{
              name: FormFieldName.Currency,
              label: <FormItemLabel>{FormFiledLabel.Currency}</FormItemLabel>,
              rules: commonValidationRules.segmentedCurrency,
              initialValue: searchParamsForm.currency
                ? undefined
                : turnkeyAutoCurrencies[0].value,
            }}
            options={turnkeyAutoCurrencies}
          />

          <CurrencySumSlider form={form} currency={currency} />

          <FilterCellSingle
            style={{ marginBottom: 0 }}
            form={form}
            withSearch={true}
            options={makes}
            loading={isFetchingMakes}
            disabled={!carOptions || isFetchingMakes}
            label='Марка'
            name={FormFieldName.Make}
            placeholder='Выберите марку'
            value={makesFormValue || queryParameters.make}
            callback={(value) => {
              form.setFieldValue(FormFieldName.Model, undefined);
              setQueryParameters({
                [FormFieldName.Model]: undefined,
                [FormFieldName.Make]: value.toLowerCase(),
              });
            }}
          />

          <FilterCellSingle
            style={{ marginBottom: 0 }}
            form={form}
            withSearch={true}
            options={models}
            loading={isFetchingModels || isFetchingMakes}
            disabled={!models || isFetchingModels || !makesFormValue}
            label='Модель'
            name={FormFieldName.Model}
            placeholder='Выберите модель'
            value={modelsFormValue || queryParameters.model}
            callback={(value) => {
              setQueryParameters({
                [FormFieldName.Model]: value.toLowerCase(),
              });
            }}
          />

          <PairFilterCell
            style={{ marginBottom: 0 }}
            form={form}
            label='Год'
            nameFrom={FormFieldName.YearFrom}
            nameTo={FormFieldName.YearTo}
            placeholder='Выберите год'
            valueFrom={yearFromCurrent}
            valueTo={yearToCurrent}
            options={years}
          />

          <FilterCellSingle
            style={{ marginBottom: 0 }}
            form={form}
            options={mileages}
            label='Пробег'
            name={FormFieldName.ToMileage}
            placeholder='Выберите пробег'
            value={mileageCurrent}
          />

          <CustomSegmented
            formItemProps={{
              name: FormFieldName.TransmissionType,
              label: (
                <FormItemLabel>{FormFiledLabel.TransmissionType}</FormItemLabel>
              ),
              initialValue: searchParamsForm
                ? undefined
                : transmissions[0].value,
            }}
            options={transmissions}
          />

          <CustomCheckboxGroup
            formItemProps={{
              name: FormFieldName.EngineTypes,
              label: <FormItemLabel>Тип двигателя</FormItemLabel>,
              initialValue: searchParamsForm.engineTypes
                ? undefined
                : [NON_IMPORTANT_TYPE_VALUE],
            }}
            gridCheckboxes={true}
            options={carEngineOptions}
            onChange={(checkedValues) => {
              changeOnDefaultIncluded(
                checkedValues,
                prevEngineTypes,
                form,
                FormFieldName.EngineTypes,
              );
            }}
          />

          <PairFilterCell
            style={{ marginBottom: 0 }}
            form={form}
            label='Объем двигателя, л'
            nameFrom={FormFieldName.EngineCapacityFrom}
            nameTo={FormFieldName.EngineCapacityTo}
            placeholder='Выберите объем двигателя'
            valueFrom={currentEngineCapacityFrom}
            valueTo={currentEngineCapacityTo}
            options={engineSizes}
          />

          <CustomCheckboxGroup
            formItemProps={{
              name: FormFieldName.TypesOfDrive,
              label: <FormItemLabel>Тип привода</FormItemLabel>,
              initialValue: searchParamsForm.typesOfDrive
                ? undefined
                : [NON_IMPORTANT_TYPE_VALUE],
            }}
            gridCheckboxes={true}
            options={carTypesOfDrive}
            onChange={(checkedValues) => {
              changeOnDefaultIncluded(
                checkedValues,
                prevTypesOfDrive,
                form,
                FormFieldName.TypesOfDrive,
              );
            }}
          />
          <CustomCheckboxGroup
            formItemProps={{
              name: FormFieldName.BodyTypes,
              label: <FormItemLabel>Тип кузова</FormItemLabel>,
              initialValue: searchParamsForm.bodyTypes
                ? undefined
                : [NON_IMPORTANT_TYPE_VALUE],
            }}
            options={carBodyTypes}
            gridCheckboxes={true}
            onChange={(checkedValues) => {
              changeOnDefaultIncluded(
                checkedValues,
                prevCheckedBodyType,
                form,
                FormFieldName.BodyTypes,
              );
            }}
          />
          <CustomCheckboxGroup
            formItemProps={{
              name: FormFieldName.BodyColors,
              label: <FormItemLabel>Цвет кузова</FormItemLabel>,
              initialValue: searchParamsForm.bodyColors
                ? undefined
                : [NON_IMPORTANT_TYPE_VALUE.toString()],
            }}
            gridCheckboxes={true}
            options={carBodyColors}
            onChange={(checkedValues) => {
              changeOnDefaultIncluded(
                checkedValues,
                prevBodyColors,
                form,
                FormFieldName.BodyColors,
              );
            }}
          />
        </StyledSearchParamsFormMobile>
      </StyledFormWrapper>
    </Fragment>
  );
});
