import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/dist/query/react';
import { RootState } from '../index';
import { authActions } from '../auth/auth.slice';
import { profileActions } from '../profile/profile.slice';
import { Mutex } from 'async-mutex';
import { ApiEndpoints } from './endpoints';

const { setAuth, logOut } = authActions;
const { resetProfile } = profileActions;

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const {
      auth: { accessToken, userId },
    } = getState() as RootState;

    if (accessToken) headers.set('Authorization', `Bearer ${accessToken}`);
    if (userId) headers.set('x-user-id', userId);

    return headers;
  },
});

const mutex = new Mutex();

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);
  const {
    auth: { refreshToken },
  } = api.getState() as RootState;

  if (result.error && result.error.status === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();

      try {
        const refreshResult: any = await baseQuery(
          {
            url: ApiEndpoints.RefreshToken,
            method: 'POST',
            body: { refreshToken },
          },
          api,
          extraOptions,
        );

        if (refreshResult?.data) {
          api.dispatch(
            setAuth({
              accessToken: refreshResult.data.accessToken,
              refreshToken: refreshResult.data.refreshToken,
              role: refreshResult.data.role,
              username: refreshResult.data.username,
              userId: refreshResult.data.userId,
              email: refreshResult.data.email,
              expires: refreshResult.data.expires,
            }),
          );

          result = await baseQuery(args, api, extraOptions);
        } else {
          api.dispatch(logOut());
          api.dispatch(resetProfile());
        }
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }

  return result;
};

export const api = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
  tagTypes: ['User', 'Subscription', 'SubscriptionFilters'],
});
