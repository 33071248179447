import { FC, useState } from 'react';
import { useLazyCheckAvailabilityQuery } from '../../../../store/car/car.api';
import ReactGA from 'react-ga4';
import { GoogleAnanalyticsEvent } from '../../../../enums/googleAnalyticsAction';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { APP_PATHS } from '../../../../layout/settingsLayout';
import { StyledButton } from '../../../../components/styled/StyledButton';
import { Spin } from 'antd';
import {
  StyledLoadingContainer,
  StyledModalText,
  StyledModal,
  StyledModalHeaderContainer,
  StyledTitle,
  successIconStyles,
  errorIconStyles,
  StyledModalContainer,
  StyledModalContent,
} from './CarRequestActions.styled';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { useCarInfoContext } from '../../../../hooks/car';
import { CarPageOutletContext } from '../../CarPage.interface';
import { AskQuestionAction } from '../AskQuestionAction';
import { SourceId } from '../../../../enums/sourceId';

enum Service {
  CarInspectation = 'carInspectation',
  BuyNow = 'buyNow',
}

export const CarRequestActions: FC = () => {
  const { car, defaultDeliveryCompany } =
    useOutletContext<CarPageOutletContext>();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [isCarAvailabile, setIsCarAvailabile] = useState<boolean>(false);
  const [selectedService, setSelectedService] = useState<Service>();
  const navigate = useNavigate();
  const [
    fetchAvailability,
    { isError: isErrorAvailability, isFetching: isFetchingAvailability },
  ] = useLazyCheckAvailabilityQuery();
  const { similarAdsUrl } = useCarInfoContext();

  const hanldeModalClose = () => setIsOpenModal(false);
  const navigateToCarsPage = () => navigate(similarAdsUrl);
  const navigateToNextStep = () => {
    switch (selectedService) {
      case Service.BuyNow:
        navigate(APP_PATHS.CAR_PAGE_CALCULATE_DELIVERY);
        break;
      case Service.CarInspectation:
        navigate(APP_PATHS.CAR_PAGE_CALCULATE_CAR_INSPECTION);
        break;
    }
  };

  const canProceed =
    !isErrorAvailability &&
    !isFetchingAvailability &&
    typeof isCarAvailabile === 'boolean';

  const handleAutoAvailability = async () => {
    if (!defaultDeliveryCompany) {
      window.open(car.sourceUrl, '_blank');
      return;
    }

    setIsOpenModal(true);

    try {
      const response = await fetchAvailability({
        adId: car.adId,
        sourceId: car.sourceId,
      });

      if (process.env.NODE_ENV === 'production') {
        ReactGA.event({
          category: 'button',
          action: GoogleAnanalyticsEvent.OfferIsActual,
          label: window.location.href,
        });
      }

      if (!response.data) throw new Error('Произошла ошибка проверки авто');

      setIsCarAvailabile(response.data.available);
    } catch (error: any) {
      setErrorMessage(error?.message);
      console.error(error);
    }
  };

  const handleOrderAutoInspectaionClick = async () => {
    setSelectedService(Service.CarInspectation);
    handleAutoAvailability();
  };

  const handleBuyNowClick = async () => {
    setSelectedService(Service.BuyNow);
    handleAutoAvailability();
  };

  return (
    <>
      {car.sourceId !== SourceId.Belarus && (
        <StyledButton
          onClick={handleBuyNowClick}
          type='primary'
          size='large'
          block
        >
          Купить сейчас
        </StyledButton>
      )}

      <StyledButton
        type='primary'
        ghost
        size='large'
        block
        onClick={handleOrderAutoInspectaionClick}
      >
        Заказать осмотр авто
      </StyledButton>

      <AskQuestionAction />

      <StyledModal
        width={424}
        open={isOpenModal}
        centered={true}
        onCancel={hanldeModalClose}
        closeIcon={isErrorAvailability}
        footer={null}
      >
        <StyledModalContainer>
          {isFetchingAvailability && (
            <StyledLoadingContainer>
              <Spin size='large' spinning={true} />
              <StyledModalText>
                Секунду, проверяем наличие авто...
              </StyledModalText>
            </StyledLoadingContainer>
          )}

          {canProceed && (
            <>
              <StyledModalContent>
                <StyledModalHeaderContainer>
                  <CheckCircleFilled
                    style={
                      isCarAvailabile ? successIconStyles : errorIconStyles
                    }
                  />
                  <StyledTitle>
                    {isCarAvailabile ? 'Отличные новости!' : 'Упс...'}
                  </StyledTitle>
                </StyledModalHeaderContainer>
                <StyledModalText>
                  {isCarAvailabile
                    ? 'Авто в продаже, пожалуйста, продолжайте'
                    : 'Объявление о продаже данного авто больше неактуально'}
                </StyledModalText>
              </StyledModalContent>

              <StyledButton
                size='large'
                type='primary'
                onClick={
                  isCarAvailabile ? navigateToNextStep : navigateToCarsPage
                }
              >
                {isCarAvailabile ? 'Продолжить' : 'Посмотреть похожие авто'}
              </StyledButton>
            </>
          )}

          {isErrorAvailability && (
            <>
              <StyledModalHeaderContainer>
                <CloseCircleFilled style={errorIconStyles} />
                <StyledTitle>Извините, что-то пошло не так</StyledTitle>
              </StyledModalHeaderContainer>
              {errorMessage && (
                <StyledModalText>{errorMessage}</StyledModalText>
              )}
            </>
          )}
        </StyledModalContainer>
      </StyledModal>
    </>
  );
};
