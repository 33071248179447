import { Layout, Typography, Result } from 'antd';
import { useNavigate } from 'react-router-dom';
import { APP_PATHS } from '../../layout/settingsLayout';
import { StyledContent } from '../../components/styled/StyledContent';
import { StyledResponsiveButton } from '../../components/Buttons/StyledResponsiveButton';

export const NotFoundPage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Layout>
      <StyledContent>
        <Result
          status='500'
          title={
            <Typography.Title level={1}>
              Извините, страница не найдена!
            </Typography.Title>
          }
          subTitle='Страница, которую вы ищете, не существует или произошла другая ошибка'
          extra={
            <StyledResponsiveButton
              type='primary'
              size='large'
              onClick={() => navigate(APP_PATHS.ROOT)}
            >
              На главную
            </StyledResponsiveButton>
          }
        />
      </StyledContent>
    </Layout>
  );
};
