import { FC, CSSProperties } from 'react';
import { useCarInfoContext } from '../../../../hooks/car';
import { ICar } from '../../../../models/car';
import { Typography, Row, Col } from 'antd';
import {
  StyledDetailsContainer,
  StyledWrapperDetailItem,
  StyledButtonsWrapper,
  StyledFixedToBottomBlock,
  StyledDealerWithData,
  StyledDealerNameWrapper,
} from './StyledCarDetailsSection';
import { countiesMapping } from '../../../../data/countriesMapping';
import { useMediaQuery } from 'react-responsive';
import { SCREEN_WIDTH } from '../../../../screenSettings';
import { StyledSectionTitle } from '../shared/styled';
import { CopyClipboard } from '../../../../components/CopyClipboard';
import { TranslateInGoogle } from '../../../../components/TranslateInGoogle';
import {
  CarDetailsWithIcon,
  CarVin,
  CarDescription,
  CarTags,
  CarMetadata,
} from '../../../../components/Car';
import { CarAdditionalTags } from '../../../../components/CarAdditionalTags';
import { TitleWithActions } from '../../../../components/TitleWithActions';
import { CarRequestActions } from '../CarRequestActions';

interface ICarDetailsSection {
  car: ICar;
  style?: CSSProperties;
}

export const CarDetailsSection: FC<ICarDetailsSection> = ({ car }) => {
  const isMobileWidth = useMediaQuery({
    query: `(max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_4}px`,
  });
  const isTabletWidth = useMediaQuery({
    query: `(max-width: ${SCREEN_WIDTH.TABLET}px`,
  });
  const { carDetails } = useCarInfoContext();

  const isTagsVariatyShowed =
    process.env.REACT_APP_TAG_TYPES_VARIATY === 'true';

  return (
    <StyledDetailsContainer>
      <div className='details__description'>
        <StyledWrapperDetailItem>
          <CarDetailsWithIcon car={car} />
        </StyledWrapperDetailItem>

        <StyledWrapperDetailItem>
          {isTagsVariatyShowed && <CarTags car={car} direction='horizontal' />}
          {car.tags && <CarAdditionalTags tags={car.tags} />}
        </StyledWrapperDetailItem>

        {car.vin && (
          <StyledWrapperDetailItem>
            <CarVin vin={car.vin} />
          </StyledWrapperDetailItem>
        )}

        {process.env.REACT_APP_CAR_DESCRIPTION_FEATURE === 'true' &&
          isTabletWidth &&
          carDetails?.description && (
            <Row
              style={{ width: '100%', marginBottom: isMobileWidth ? 10 : 30 }}
            >
              <Col span={24}>
                <TitleWithActions
                  title={
                    <StyledSectionTitle style={{ marginBottom: 0 }}>
                      Описание
                    </StyledSectionTitle>
                  }
                  actions={[
                    <CopyClipboard copiedText={carDetails?.description} />,
                    <TranslateInGoogle text={carDetails?.description} />,
                  ]}
                  actionsIds={['copyTextAction', 'translateGoogle']}
                />

                <CarDescription
                  carDescription={carDetails ? carDetails.description : ''}
                  isExpanded={true}
                />
              </Col>
            </Row>
          )}

        <StyledButtonsWrapper>
          <CarRequestActions />
        </StyledButtonsWrapper>
      </div>
      <StyledFixedToBottomBlock>
        {car.sourceUrl && (
          <StyledWrapperDetailItem>
            <Typography.Link
              style={{
                fontSize: isMobileWidth ? 14 : 16,
              }}
              href={car.sourceUrl}
              target='_blank'
              rel='noopener noreferrer'
            >
              {'Источник'}
            </Typography.Link>
          </StyledWrapperDetailItem>
        )}
        <StyledDealerWithData>
          {car.dealerUrl && (
            <StyledDealerNameWrapper>
              <Typography.Link
                style={{
                  fontSize: isMobileWidth ? 14 : 16,
                }}
                href={car.dealerUrl}
                target='_blank'
                rel='noopener noreferrer'
              >
                {car.dealerName || 'Дилер'}
              </Typography.Link>
            </StyledDealerNameWrapper>
          )}

          <CarMetadata
            style={{ marginTop: 0 }}
            car={car}
            location={`${countiesMapping[car.regionId]}, ${car.city}`}
          />
        </StyledDealerWithData>
      </StyledFixedToBottomBlock>
    </StyledDetailsContainer>
  );
};
