import { useRef, useCallback } from 'react';
import { useTurnKeyStepsSelector } from '../../../../store/selectors';
import { ActionButtons } from '../ActionButtons';
import { StepsValues } from '../../enums/stepsValues';
import { EquipmentForm } from './EquipmentForm';
import { SearchParamsForm } from './SearchParamsForm';
import { DeliveryForm } from './DeliveryForm';
import { FormRef } from './types/form';
import { useMediaQuery } from 'react-responsive';
import { SearchParamsFormMobile } from './MobileForms/SearchParamsFormMobile';
import { SCREEN_WIDTH } from '../../../../screenSettings';
import { useMetadata } from '../../../../hooks/seo';
import { APP_PATHS } from '../../../../layout/settingsLayout';
import { Metadata } from '../../../../components/Metadata';
import { useTurnKeyCarContext } from '../../../../hooks/turnKeyCar';

export const TurnKeyForms = () => {
  const { titleText, selectedSourceIdViewName } = useTurnKeyCarContext();

  const metadata = useMetadata(
    APP_PATHS.CAR_TURN_KEY,
    titleText || '',
    selectedSourceIdViewName || '',
  );
  const isMobileWidth = useMediaQuery({
    query: `(max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_1}px)`,
  });

  const { activeStep } = useTurnKeyStepsSelector();

  const formSearchParamsRef = useRef<FormRef>(null);
  const formEquipmentRef = useRef<FormRef>(null);
  const formDeliveryRef = useRef<FormRef>(null);
  const formCarStateRef = useRef<FormRef>(null);

  const handleSubmitForms = useCallback(() => {
    switch (activeStep) {
      case StepsValues.SearchParams:
        return formSearchParamsRef.current?.submitForm();
      case StepsValues.CarDelivery:
        return formDeliveryRef.current?.submitForm();
      case StepsValues.CarEquipment:
        return formEquipmentRef.current?.submitForm();
      default:
        break;
    }
  }, [activeStep]);

  const handleResetForms = useCallback(() => {
    formSearchParamsRef.current?.resetForm();
    formDeliveryRef.current?.resetForm();
    formEquipmentRef.current?.resetForm();
    formCarStateRef.current?.resetForm();
  }, []);

  return (
    <>
      <Metadata title={metadata.title} description={metadata.description} />

      {activeStep === StepsValues.CarEquipment && (
        <EquipmentForm ref={formEquipmentRef} />
      )}

      {activeStep === StepsValues.CarDelivery && (
        <DeliveryForm ref={formDeliveryRef} />
      )}
      {activeStep === StepsValues.SearchParams &&
        (isMobileWidth ? (
          <SearchParamsFormMobile ref={formSearchParamsRef} />
        ) : (
          <SearchParamsForm ref={formSearchParamsRef} />
        ))}

      <ActionButtons
        submitCallback={handleSubmitForms}
        resetCallback={handleResetForms}
      />
    </>
  );
};
