import styled from 'styled-components';
import { Typography } from 'antd';
import { SCREEN_WIDTH } from '../../../screenSettings';

export const StyledTitle = styled.p<{
  smallersize?: string;
}>`
  color: var(--Dark-13);
  font-weight: 400;
  margin: 0 3px 0 0;
  line-height: 16px;
  letter-spacing: 0.21px;
  font-size: ${(props) =>
    !!props.smallersize ? '12px !important' : '14px !important'};

  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET}px) {
    font-size: 12px;
  }
`;

export const StyledParagraph = styled(Typography.Paragraph)<{
  smallersize?: string;
}>`
  margin-bottom: 0 !important;
  font-size: ${(props) =>
    !!props.smallersize ? '12px !important' : '14px !important'};
  line-height: 16px;

  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET}px) {
    font-size: 12px !important;
  }
`;
