import { useOutletContext } from 'react-router-dom';
import { CarPageOutletContext } from '../CarPage.interface';
import { useCompaniesSelector } from '../../../store/selectors';
import { useCallback, useEffect, useState } from 'react';
import { SupportedRoute } from '../../../models/response/companies';
import { PaymentSettingsFormNames } from '../CarPage.enum';
import { FormInstance } from 'antd';

export const useHandleCompanyChange = (
  desktopCalculateDeliveryForm: FormInstance,
  companyIdForm: number | undefined,
) => {
  const { car } = useOutletContext<CarPageOutletContext>();
  const { companies: deliveryCompanies } = useCompaniesSelector();
  const [isFirstCall, setIsFirstCall] = useState(false);
  const [deliverySelectValues, setDeliverySelectValues] = useState<
    SupportedRoute[] | undefined
  >();

  const handleCompanyChange = useCallback(
    (companyId: number) => {
      const selectedCompany = deliveryCompanies?.find(
        (deliveryCompany) => deliveryCompany.id === companyId,
      );

      const supportedDeliveryRoutes = selectedCompany?.supportedRoutes.filter(
        (route) => route.originRegionId === car?.regionId,
      );

      setDeliverySelectValues(supportedDeliveryRoutes);

      desktopCalculateDeliveryForm.setFieldsValue({
        [PaymentSettingsFormNames.Delivery]: supportedDeliveryRoutes
          ? supportedDeliveryRoutes[0]?.destinationRegionId
          : undefined,
      });
    },
    [car, deliveryCompanies, desktopCalculateDeliveryForm],
  );

  useEffect(() => {
    if (!isFirstCall && companyIdForm) {
      handleCompanyChange(companyIdForm);
      setIsFirstCall(true);
    }
  }, [companyIdForm]);

  return {
    deliverySelectValues,
    handleCompanyChange,
  };
};
