import { carsApi } from '../../store/cars/cars.api';
import { config } from '../../config';
import { NotificationInstance } from 'antd/es/notification/interface';
import { Params } from 'react-router-dom';
import { checkArrayValidity, checkValue, getDateFrom } from '../../utils/cars';
import { ISortingFormState } from '../../models/cars';
import { initialValuesSortForm } from '../../constants';
import { SortFormFieldNames } from '../../enums/form';
import { useProfileSelector } from '../../store/selectors';

export const useGetCountCars = (
  notificationApi: NotificationInstance,
  formState: any,
  params?: Readonly<Params<string>>,
) => {
  const { currency } = useProfileSelector();
  const [fetchCountCars, { data: countCars, isFetching: isFetchingCountCars }] =
    carsApi.useLazyGetCountCarsQuery();

  const getCountCars = async (
    sortFormValues: ISortingFormState = {
      [SortFormFieldNames.from]: formState.from,
      [SortFormFieldNames.sortBy]: formState.sortBy,
    },
  ): Promise<number | void> => {
    const from =
      sortFormValues.from || initialValuesSortForm[SortFormFieldNames.from];

    return fetchCountCars({
      currency,
      ...formState,
      regionId: checkArrayValidity(formState.regionId),
      engineType: checkArrayValidity(formState.engineType),
      bodyType: checkArrayValidity(formState.bodyType),
      typeOfDrive: checkArrayValidity(formState.typeOfDrive),
      country: checkArrayValidity(formState.country),
      engineCapacityFrom: checkValue(formState.engineCapacityFrom),
      engineCapacityTo: checkValue(formState.engineCapacityTo),
      toMileage: checkValue(formState.toMileage),
      make: checkValue(params?.make || formState.make),
      model: checkValue(params?.model || formState.model),
      transmission: checkValue(formState.transmission),
      yearFrom: checkValue(formState.yearFrom),
      yearTo: checkValue(formState.yearTo),
      vatDeductible: formState.vatDeductible || undefined,
      from: getDateFrom(+from),
      sortBy: undefined,
      page: undefined,
    })
      .unwrap()
      .catch((error: any) => {
        notificationApi.error({
          message: error?.data?.message || 'Ошибка загрузки кол-ва авто',
          duration: config.DEFAULT_NOTIFICATION_DURATION,
        });
      });
  };

  return {
    getCountCars,
    countCars,
    isFetchingCountCars,
  };
};
