import { SearchOutlined } from '@ant-design/icons';
import { ConfigProvider, Form, Input, RadioChangeEvent } from 'antd';
import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { IOption } from '../../models';
import { FormItemDrawer } from '../../pages/CarsPage/components/shared/FormItemDrawer';
import { ButtonFieldMobileForm } from './ButtonFieldMobileForm';
import { RadioGroup } from './RadioGroup';
import { RadioGroupItem } from './RadioGroupItem';
import { FormItemLabel } from '../FormItemLabel';
import { mobileFormItemTheme } from '../../themeSettings';
import { FormInstance, Rule } from 'antd/es/form';
import { SelectOptions } from '../../enums';

interface IFilterCell {
  label: string;
  name: string;
  placeholder: string;
  value: number | string | undefined;
  options: IOption[] | undefined;
  loading?: boolean;
  callback?: (value: any) => void;
  withSearch?: boolean;
  disabled?: boolean;
  required?: boolean;
  form?: FormInstance<any>;
  style?: CSSProperties;
  rules?: Rule[] | undefined;
  isFieldValid?: boolean;
}

export const FilterCellSingle: React.FC<IFilterCell> = ({
  label,
  name,
  placeholder,
  value,
  options,
  loading,
  callback,
  disabled = false,
  withSearch = false,
  required = false,
  form,
  style,
  rules,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [displayOptions, setDisplayOptions] = useState<IOption[] | undefined>(
    options,
  );
  const [search, setSearch] = useState<string>('');

  const handleChange = useCallback(
    (e: RadioChangeEvent) => {
      setOpen(false);
      form?.setFieldsValue({
        [name]: e.target.value,
      });

      if (callback) callback(e.target.value);
    },
    [name, callback],
  );

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const getLabel = useMemo(
    () =>
      (options: IOption[] | undefined, value: string | number | undefined) => {
        if (!options) return undefined;

        const foundOption = options.find((option) =>
          [option.originalName, option.id, option.value].includes(value),
        );

        return foundOption
          ? foundOption.viewName ?? foundOption.label
          : undefined;
      },
    [options],
  );

  useEffect(() => {
    if (options) {
      const result: IOption[] | undefined = options.filter(
        (option: IOption) => {
          if (!option.viewName) {
            return undefined;
          }

          return option.viewName.toLowerCase().includes(search.toLowerCase());
        },
      );

      setDisplayOptions(result);
    }
  }, [search]);

  useEffect(() => {
    setDisplayOptions(options);
  }, [options]);

  //need for case if query params
  useEffect(() => {
    form?.setFieldsValue({
      [name]: value,
    });
  }, [value]);

  return (
    <>
      <Form.Item
        style={style}
        rules={rules ? rules : [{ required, message: 'Обязательное поле' }]}
        label={<FormItemLabel>{label}</FormItemLabel>}
        name={name}
      >
        <ConfigProvider theme={mobileFormItemTheme}>
          <ButtonFieldMobileForm
            event={() => setOpen(!open)}
            loading={loading}
            value={value}
            disabled={disabled}
          >
            {!value || value === SelectOptions.any
              ? placeholder
              : options && getLabel(options, value)}
          </ButtonFieldMobileForm>
        </ConfigProvider>
      </Form.Item>
      <FormItemDrawer title={label} onClose={() => setOpen(!open)} open={open}>
        {withSearch && (
          <Input
            size='large'
            onChange={handleSearch}
            value={search}
            placeholder='Поиск...'
            suffix={
              <SearchOutlined
                rev='true'
                style={{ fontSize: '130%', color: '#97A3B5' }}
              />
            }
            style={{
              paddingLeft: 16,
              paddingRight: 16,
              position: 'sticky',
              top: 0,
              zIndex: 2,
            }}
          />
        )}

        <RadioGroup value={value} onChange={handleChange}>
          {displayOptions &&
            displayOptions.map((option: IOption, index) => (
              <RadioGroupItem
                key={index}
                value={option.originalName || option.id || option.value}
              >
                {option.viewName || option.label}
              </RadioGroupItem>
            ))}
        </RadioGroup>
      </FormItemDrawer>
    </>
  );
};
