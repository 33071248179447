import { useCallback } from 'react';
import { SelectOptions } from '../../enums';
import { NotificationInstance } from 'antd/es/notification/interface';
import { useLazyGetModelsQuery } from '../../store/cars/cars.api';
import { config } from '../../config';

export const useGetModelsWhithoutParams = (
  notificationApi: NotificationInstance,
) => {
  const [fetchModels, { data: models, isFetching: isFetchingModels }] =
    useLazyGetModelsQuery();

  const getModels = useCallback(
    async (make: string) => {
      if (make === SelectOptions.any) return;

      try {
        await fetchModels(make).unwrap();
      } catch (error: any) {
        notificationApi.error({
          message: error?.data?.message || 'Ошибка загрузки марок авто',
          duration: config.DEFAULT_NOTIFICATION_DURATION,
        });
      }
    },
    [fetchModels, notificationApi],
  );

  return {
    models,
    isFetchingModels,
    getModels,
  };
};
