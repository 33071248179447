import styled from 'styled-components';
import { SCREEN_WIDTH } from '../../../../screenSettings';

export const StyledProfileSideSection = styled.aside`
  width: 280px;
  @media only screen and (max-width: ${SCREEN_WIDTH.DESKTOP}px) {
    overflow: hidden;
    width: 250px;
  }
`;
