import styled from 'styled-components';
import { Image } from 'antd';
import { Link } from 'react-router-dom';
import { SCREEN_WIDTH } from '../../screenSettings';

export const StyledCarLink = styled(Link)`
  display: inline-block;
`;

export const CarCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 420px;
  align-items: flex-start;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid var(--card-stroke-18);
  background-color: var(--White);
  width: 100%;

  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_2}px) {
    min-height: 450px;
  }
  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_4}px) {
    min-height: 430px;
    max-width: 295px;
  }

  @media only screen and (max-width: ${SCREEN_WIDTH.MOBILE}px) {
    min-height: 385px;
    max-width: 266px;
  }
`;

export const StyledCardHeader = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  padding-bottom: 12px;
  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_2}px) {
    padding-bottom: 6px;
  }
`;

export const StyledCardTitle = styled.p`
  color: var(--Dark-13);
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.56px;
  padding: 4px;
  white-space: no-wrap;
  margin-bottom: 0;
`;

export const StyledImgContainer = styled.div`
  padding: 0 0 16px;
  width: 100%;
  & .ant-image {
    width: 100%;
  }
`;

export const StyledImg = styled(Image)`
  /* width: 232px !important; */
  height: 226px !important;
  object-fit: cover;
  border-radius: 8px;
  @media only screen and (max-width: ${SCREEN_WIDTH.TO_DESKTOP_BREAK_2}px) {
    /* width: 100% !important; */
    height: 290px !important;
  }
  @media only screen and (max-width: ${SCREEN_WIDTH.DESKTOP}px) {
    height: 260px !important;
  }
  @media only screen and (max-width: ${SCREEN_WIDTH.MOBILE}px) {
    height: 227px !important;
    /* width: 232px !important; */
    object-fit: cover;
  }
`;

export const StyledCarDetailsContainer = styled.div`
  margin-bottom: auto;
`;

export const StyledCarMetaContainer = styled.div`
  margin-top: auto;
  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET}px) {
    & > .ant-row {
      align-items: center;
    }
  }
`;
