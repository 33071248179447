import { Checkbox, Form, Select } from 'antd';
import { FC, useEffect } from 'react';
import { StyledForm } from './DesktopCalculateDeliveryForm.styled';
import { CustomSelect } from '../../../../components/FormElements';
import {
  PaymentSettingsFormLabel,
  PaymentSettingsFormNames,
  PaymentSettingsFormPlaceholder,
} from '../../CarPage.enum';
import { FormItemLabel } from '../../../../components/FormItemLabel';
import { CaretDownOutlined } from '@ant-design/icons';
import { SelectOptionStyle } from '../../../../components/styled/SelectOption';
import { destinationRegionIdMapping } from '../../../../data/mappings';
import { useHandleCompanyChange } from '../../hooks/useHandleCompanyChange';
import {
  useCarPageSelector,
  useCompaniesSelector,
} from '../../../../store/selectors';
import { useControlledFormControls, useSetDefaultCompany } from '../../hooks';
import { CalculateDeliveryForm } from '../../../../models/car/CalculateDelivery';
import { useActions } from '../../../../hooks/useActions';
import { Company } from '../../../../models/response/companies';
import { useOutletContext } from 'react-router-dom';
import { CarPageOutletContext } from '../../CarPage.interface';
import { StyledFormItem } from '../../../../components/styled/StyledFormItem';

interface DesktopCalculateDeliveryFormProps {
  onlyCarInspection: boolean;
}

export const DesktopCalculateDeliveryForm: FC<
  DesktopCalculateDeliveryFormProps
> = ({ onlyCarInspection }) => {
  const { calculateDeliveryForm } = useCarPageSelector();
  const { defaultDeliveryCompany, car } =
    useOutletContext<CarPageOutletContext>();
  const [desktopCalculateDeliveryForm] = Form.useForm<CalculateDeliveryForm>();
  const { handleCarInspectionRequiredChange, handleCustomsClearanceChange } =
    useControlledFormControls();
  const { setSelectedDeliveryValues } = useActions();
  const { companies } = useCompaniesSelector();
  const availableCompanies = companies.filter((company) =>
    company.supportedRoutes.find(
      (route) => route.originRegionId === car.regionId,
    ),
  );

  // Hiding an element in this way is necessary because this element must be in the tree html elements,
  // because this form is used for both calcaulate-delivery and calculate-car-inspection
  const deliverySelectStyles = {
    display: onlyCarInspection ? 'none' : 'block',
  };
  const companyId = Form.useWatch(
    PaymentSettingsFormNames.Company,
    desktopCalculateDeliveryForm,
  );
  const destinationRegionId = Form.useWatch(
    PaymentSettingsFormNames.Delivery,
    desktopCalculateDeliveryForm,
  );
  const { deliverySelectValues, handleCompanyChange } = useHandleCompanyChange(
    desktopCalculateDeliveryForm,
    companyId,
  );
  useSetDefaultCompany(defaultDeliveryCompany, desktopCalculateDeliveryForm);

  useEffect(() => {
    setSelectedDeliveryValues({
      companyId,
      destinationRegionId,
    });
  }, [companyId, destinationRegionId]);

  return (
    <Form
      form={desktopCalculateDeliveryForm}
      requiredMark={false}
      name='desktopCalculateDeliveryForm'
      layout='vertical'
      autoComplete='off'
    >
      <StyledForm>
        <CustomSelect
          style={deliverySelectStyles}
          formItemProps={{
            style: deliverySelectStyles,
            name: PaymentSettingsFormNames.Delivery,
            label: (
              <FormItemLabel>{PaymentSettingsFormLabel.Delivery}</FormItemLabel>
            ),
          }}
          suffixIcon={<CaretDownOutlined rev='true' />}
          placeholder={PaymentSettingsFormPlaceholder.Delivery}
          disabled={deliverySelectValues && deliverySelectValues.length <= 1}
        >
          {deliverySelectValues?.map((deliveryValue) => (
            <Select.Option
              key={deliveryValue.destinationRegionId}
              style={SelectOptionStyle}
              value={deliveryValue.destinationRegionId}
            >
              {destinationRegionIdMapping[deliveryValue.destinationRegionId]}
            </Select.Option>
          ))}
        </CustomSelect>

        <CustomSelect
          formItemProps={{
            name: PaymentSettingsFormNames.Company,
            label: (
              <FormItemLabel>{PaymentSettingsFormLabel.Company}</FormItemLabel>
            ),
          }}
          onChange={handleCompanyChange}
          disabled={availableCompanies.length <= 1}
          suffixIcon={<CaretDownOutlined rev='true' />}
          placeholder={PaymentSettingsFormPlaceholder.Company}
        >
          {availableCompanies?.map((company: Company) => (
            <Select.Option
              key={company.id}
              style={SelectOptionStyle}
              value={company.id}
            >
              {company.name}
            </Select.Option>
          ))}
        </CustomSelect>

        {!onlyCarInspection && (
          <div>
            <StyledFormItem
              name={PaymentSettingsFormNames.CarInspectionRequired}
            >
              <FormItemLabel>
                <Checkbox
                  checked={calculateDeliveryForm.carInspectionRequired}
                  onChange={handleCarInspectionRequiredChange}
                >
                  {PaymentSettingsFormLabel.CarInspectionRequired}
                </Checkbox>
              </FormItemLabel>
            </StyledFormItem>

            <StyledFormItem
              name={PaymentSettingsFormNames.hasCustomsClearanceBenefit}
            >
              <FormItemLabel>
                <Checkbox
                  checked={calculateDeliveryForm.hasCustomsClearanceBenefit}
                  onChange={handleCustomsClearanceChange}
                >
                  {PaymentSettingsFormLabel.hasCustomsClearanceBenefit}
                </Checkbox>
              </FormItemLabel>
            </StyledFormItem>
          </div>
        )}
      </StyledForm>
    </Form>
  );
};
