import { ReactNode } from 'react';
import { PROFILE_SETTINGS_ERROR_DESCRIPTIONS } from '../data/accountMessages';
import { isFetchBaseQueryError } from '../../../utils/isFetchBaseQueryError';

type RequestType = 'getUserData' | 'updateUserData';

export const getDescriptionFromError = (
  error: unknown,
  requestType: RequestType,
): string | ReactNode => {
  if (isFetchBaseQueryError(error) && error.status === 401)
    return PROFILE_SETTINGS_ERROR_DESCRIPTIONS.NON_AUTHORIZED;

  switch (requestType) {
    case 'getUserData':
      return PROFILE_SETTINGS_ERROR_DESCRIPTIONS.GET_INFO;
    case 'updateUserData':
      return PROFILE_SETTINGS_ERROR_DESCRIPTIONS.UPDATE_INFO;
    default:
      return PROFILE_SETTINGS_ERROR_DESCRIPTIONS.GET_INFO;
  }
};
