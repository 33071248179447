import styled from 'styled-components';

export const StyledVinWrapper = styled.div`
  color: var(--Dark-13);
  font-size: var(--desktop-content-font-size);
  font-style: var(--font-style-base);
  font-weight: var(--main-font-weight);
  line-height: var(--content-desktop-line-height);

  &:hover {
    cursor: pointer;
  }
`;
