import { FC } from 'react';
import { useSubscriptionsOfferSelector } from '../../../../store/selectors';
import { StyledSubscriptionsList } from './CarSubscriptionsByFilterList.styles';
import { SwitcherWithDescription } from '../../../../components/SwitcherWithDescription';
import { SubscriptionsListModal } from '../SubscriptionsListModal';

type CarSubscriptionsByFilterListProps = {
  loading?: boolean;
};

export const CarSubscriptionsByFilterList: FC<
  CarSubscriptionsByFilterListProps
> = ({ loading = false }) => {
  const { filterSubscriptions } = useSubscriptionsOfferSelector();

  return (
    <StyledSubscriptionsList>
      {filterSubscriptions.map((car, index) => (
        <SwitcherWithDescription
          description={car.name}
          key={car.hash}
          loading={loading}
          descriptionForDeleteBlock={{ name: car.name, hash: car.hash }}
          defaultChecked={car.isActive}
          isDeleted={true}
          showDivider={
            filterSubscriptions.length > 1 &&
            index !== filterSubscriptions.length - 1
          }
        />
      ))}
      <SubscriptionsListModal forFilterSubscription={true} />
    </StyledSubscriptionsList>
  );
};
