import { ServerResponseCars, ServerResponseCount } from '../../models';
import { ICar } from '../../models/car';
import qs, { ParsedQs } from 'qs';
import { api } from '../api/api';
import { russiaCountryValue, russiaRegionId } from '../../data';
import { ICombinedFormState } from '../../models/cars';
import { modifyParams } from '../../utils/cars';
import { GetCustomCarRequestType } from '../../models/response/cars';
import { ERROR_MESSAGES } from '../../data/errorMessages';
import { carsActions } from './cars.slice';
import { ApiEndpoints } from '../api/endpoints';
import {
  IMake,
  IModel,
  ServerResponseMakes,
  ServerResponseModels,
} from '../../models/response/cars';

export const carsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getMakes: build.query<IMake[], void>({
      query: () => ({
        url: ApiEndpoints.CarsMakes,
      }),

      transformResponse: (response: ServerResponseMakes) => response.makes,
      transformErrorResponse: (response) => ({
        ...response,
        forCustomNotification: {
          status: ERROR_MESSAGES.GET_MAKES.status,
          message: ERROR_MESSAGES.GET_MAKES.message,
        },
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const carMakes = await queryFulfilled;
          if (carMakes.data && !!carMakes.data.length) {
            dispatch(carsActions.setCarMakes(carMakes.data));
          }
        } catch (err) {
          console.error(err, 'INSIDE RTK GET MAKES');
        }
      },
    }),
    getModels: build.query<IModel[], string | ParsedQs | string[] | ParsedQs[]>(
      {
        query: (make: string) => ({
          url: `cars/makes/${make}/models`,
        }),
        transformResponse: (response: ServerResponseModels) => response.models,
      },
    ),
    getCountCars: build.query<number, ICombinedFormState>({
      query: (params: any) => {
        //TODO: remove this logic after createion 'counrty' property on backend
        const modifiedParams = modifyParams(
          params,
          russiaCountryValue,
          russiaRegionId,
        );

        return {
          url: `/cars/count?${qs.stringify(modifiedParams)}`,
        };
      },
      transformResponse: (response: ServerResponseCount) => response.count,
      keepUnusedDataFor: 1,
    }),
    getCars: build.query<ICar[], ICombinedFormState>({
      query: (params: ICombinedFormState) => {
        //TODO: remove this logic after creation 'country' property on backend
        const modifiedParams = modifyParams(
          params,
          russiaCountryValue,
          russiaRegionId,
        );

        return {
          url: `/cars?${qs.stringify(modifiedParams)}`,
        };
      },
      transformResponse: (response: ServerResponseCars) => response.cars,
      keepUnusedDataFor: 1,
    }),
    sendCustomCarOffer: build.mutation<unknown, GetCustomCarRequestType>({
      query: (body) => {
        return {
          url: ApiEndpoints.CustomCar,
          method: 'POST',
          body,
        };
      },
      transformResponse: () => ({
        forCustomNotification: {
          status: 'success',
          message: 'Заявка успешно отправлена!',
          description: 'Ожидайте обратной связи.',
        },
      }),
      transformErrorResponse: (response) => ({
        ...response,
        forCustomNotification: {
          status: ERROR_MESSAGES.REQUEST_BUY_CAR.status,
          message: ERROR_MESSAGES.REQUEST_BUY_CAR.message,
          description: ERROR_MESSAGES.REQUEST_BUY_CAR.description,
        },
      }),
    }),
  }),
});

export const {
  useLazyGetMakesQuery,
  useGetMakesQuery,
  useLazyGetModelsQuery,
  useLazyGetCarsQuery,
  useGetCarsQuery,
  useLazyGetCountCarsQuery,
  useSendCustomCarOfferMutation,
} = carsApi;
