import { FC } from 'react';
import { DetailWithIconType } from '../CarDetailsWithIcon/CarDetailsWithIcon.utils';
import {
  StyledDetailWrapper,
  StyledDescriptionBlock,
  StyledIconBlock,
} from './CarDetailsItemWithIcon.styled';

type CarDetailsItemWithIconPropsType = Omit<DetailWithIconType, 'id'>;

export const CarDetailsItemWithIcon: FC<CarDetailsItemWithIconPropsType> = ({
  icon,
  description,
}) => {
  return (
    <StyledDetailWrapper>
      <StyledIconBlock>{icon}</StyledIconBlock>
      <StyledDescriptionBlock>{description}</StyledDescriptionBlock>
    </StyledDetailWrapper>
  );
};
