import styled from 'styled-components';

export const StyledTurnKeyContent = styled.div`
  width: 100%;
  min-height: 640px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-grow: 1;
`;
