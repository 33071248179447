import { FC } from 'react';
import { useAppContext } from '../../../hooks/app';
import { Form, Input, Select } from 'antd';
import { ISignUpCredentials } from '../../../models/auth/ISignUpCredentials';
import { useSignUpMutation } from '../../../store/auth/auth.api';
import { useNavigate } from 'react-router-dom';
import { SelectOptionStyle } from '../../../components/styled/SelectOption';
import { CaretDownOutlined } from '@ant-design/icons';
import { countries } from '../../../data/countries';
import { StyledFormItem } from '../styled/StyledFormItem';
import { APP_PATHS, NAVIGATE_PATHS } from '../../../layout/settingsLayout';
import { currencies } from '../../../data';
import { StyledSegmented } from '../../../components/styled/StyledSegmented';
import { FormItemLabel } from '../../../components/FormItemLabel';
import { StyledButtonWrapper } from '../styled/StyledButtonWrapper';
import { StyledSubmitFormItem } from '../styled/StyledSubmitFormItem';
import { StyledLink } from '../styled/StyledLink';
import { AUTH_ERROR_MESSAGES, AUTH_WARNING_MESSAGES } from '../data';
import { formSettings } from '../../../config';
import { useAuthErrorNotification } from '../hooks';
import { StyledResponsiveButton } from '../../../components/Buttons/StyledResponsiveButton';
import { validationRules } from './SignUp.data';
import { commonValidationRules } from '../../../data/commonValidationRules';
import {
  FormFiledLabel,
  FormFieldName,
  FormPlaceholder,
} from '../../../enums/form';
import { config } from '../../../config';
import { useMediaQuery } from 'react-responsive';
import { SCREEN_WIDTH } from '../../../screenSettings';
import { FilterCellSingle } from '../../../components/MobileFormElements';
import { StyledMobileFormItemWrapper } from './SignUp.styled';
import { commonMetadata } from '../../../seo';
import { Metadata } from '../../../components/Metadata';

export const SignUp: FC = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm<ISignUpCredentials>();
  const country = Form.useWatch(FormFieldName.CountryCode, form);
  const { apiNotification } = useAppContext();
  const isTablet = useMediaQuery({
    query: `(max-width: ${SCREEN_WIDTH.TABLET}px)`,
  });
  const metaData = commonMetadata[APP_PATHS.SIGN_UP];
  const isCountryCodeValid = form.getFieldError(FormFieldName.CountryCode);
  const authErrorNotification = useAuthErrorNotification();
  const [signUp, { isLoading: isLoadingSignUp }] = useSignUpMutation();

  const handleCountryChangeMobile = (value: string) => {
    form.setFieldsValue({
      [FormFieldName.CountryCode]: value,
    });
  };

  const handleSubmit = async (values: ISignUpCredentials): Promise<void> => {
    try {
      await signUp(values).unwrap();

      apiNotification.warning({
        message: AUTH_WARNING_MESSAGES.EMAIL_CONFIRMATION_LINK_SENT,
        duration: config.DEFAULT_NOTIFICATION_DURATION,
      });

      navigate(NAVIGATE_PATHS.SIGN_IN);
    } catch (error: any) {
      authErrorNotification(AUTH_ERROR_MESSAGES.SIGN_UP, error);
    }
  };

  return (
    <>
      <Metadata
        title={metaData.title}
        description={metaData.description}
      />

      <Form
        form={form}
        name='signinForm'
        layout='vertical'
        initialValues={{ [FormFieldName.Currency]: 'USD' }}
        onFinish={handleSubmit}
        autoComplete='off'
      >
        <StyledFormItem
          name={FormFieldName.Email}
          label={<FormItemLabel>{FormFiledLabel.Email}</FormItemLabel>}
          rules={commonValidationRules.email}
        >
          <Input
            placeholder={FormPlaceholder.Email}
            size={formSettings.controlSize}
          />
        </StyledFormItem>

        <StyledFormItem
          name={FormFieldName.Username}
          label={<FormItemLabel>{FormFiledLabel.Username}</FormItemLabel>}
          rules={commonValidationRules.username}
        >
          <Input
            placeholder={FormPlaceholder.Username}
            size={formSettings.controlSize}
          />
        </StyledFormItem>

        <StyledFormItem
          name={FormFieldName.Password}
          label={<FormItemLabel>{FormFiledLabel.Password}</FormItemLabel>}
          rules={validationRules.password}
          hasFeedback
        >
          <Input.Password
            placeholder={FormPlaceholder.Password}
            size={formSettings.controlSize}
          />
        </StyledFormItem>

        <StyledFormItem
          name={FormFieldName.RepeatPassword}
          label={<FormItemLabel>{FormFiledLabel.RepeatPassword}</FormItemLabel>}
          dependencies={[FormFieldName.Password]}
          hasFeedback
          rules={commonValidationRules.repeatPassword}
        >
          <Input.Password
            placeholder={FormPlaceholder.RepeatPassword}
            size={formSettings.controlSize}
          />
        </StyledFormItem>

        {!isTablet && (
          <StyledFormItem
            name={FormFieldName.CountryCode}
            label={<FormItemLabel>{FormFiledLabel.Country}</FormItemLabel>}
            rules={commonValidationRules.countryCode}
          >
            <Select
              showSearch
              suffixIcon={<CaretDownOutlined rev='true' />}
              size={formSettings.controlSize}
              placeholder={FormPlaceholder.Country}
            >
              {countries.map((country) => (
                <Select.Option
                  key={country.code}
                  style={SelectOptionStyle}
                  value={country.code}
                >
                  {country.name}
                </Select.Option>
              ))}
            </Select>
          </StyledFormItem>
        )}

        {isTablet && (
          <StyledMobileFormItemWrapper>
            <FilterCellSingle
              style={{ marginBottom: 0 }}
              form={form}
              name={FormFieldName.CountryCode}
              placeholder={FormPlaceholder.Country}
              value={country}
              options={countries.map((elem) => ({
                value: elem.code,
                label: elem.name,
              }))}
              label={FormFiledLabel.Country}
              callback={handleCountryChangeMobile}
              rules={commonValidationRules.countryCode}
              isFieldValid={!!isCountryCodeValid.length}
            />
          </StyledMobileFormItemWrapper>
        )}

        <StyledFormItem
          name={FormFieldName.Currency}
          label={<FormItemLabel>{FormFiledLabel.Currency}</FormItemLabel>}
          required={true}
        >
          <StyledSegmented
            block
            required={true}
            size={formSettings.controlSize}
            options={currencies}
          />
        </StyledFormItem>

        <StyledFormItem
          label={<FormItemLabel>{FormFiledLabel.CompanyName}</FormItemLabel>}
          name={FormFieldName.CompanyName}
        >
          <Input
            placeholder={FormPlaceholder.CompanyName}
            size={formSettings.controlSize}
          />
        </StyledFormItem>

        <StyledFormItem
          label={<FormItemLabel>{FormFiledLabel.CompanyUrl}</FormItemLabel>}
          name={FormFieldName.CompanyUrl}
        >
          <Input
            placeholder={FormPlaceholder.CompanyUrl}
            size={formSettings.controlSize}
          />
        </StyledFormItem>

        <StyledButtonWrapper>
          <StyledSubmitFormItem>
            <StyledResponsiveButton
              size={formSettings.controlSize}
              type='primary'
              htmlType='submit'
              loading={isLoadingSignUp}
            >
              Зарегистрироваться
            </StyledResponsiveButton>
          </StyledSubmitFormItem>

          <StyledLink to={NAVIGATE_PATHS.SIGN_IN}>Уже есть аккаунт?</StyledLink>
        </StyledButtonWrapper>
      </Form>
    </>
  );
};
