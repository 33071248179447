export enum CarDetailsKeys {
  engineCapacity = 'engineCapacity',
  yearOfIssue = 'yearOfIssue',
  transmissionType = 'transmissionType',
  engineType = 'engineType',
  enginePowerInHp = 'enginePowerInHp',
  mileage = 'mileage',
  bodyColor = 'bodyColor',
  bodyType = 'bodyType',
  previousOwnersNumber = 'previousOwnersNumber',
}
