import React, { FC, CSSProperties, useRef, useState } from 'react';
import { ICarDetails } from '../../../../models/car';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import { useMediaQuery } from 'react-responsive';
import { ReactComponent as ArrowRight } from '../../../../img/icons/ArrowRight.svg';
import { ReactComponent as ArrowLeft } from '../../../../img/icons/ArrowLeft.svg';
import {
  StyledContainerImageGallery,
  StyledModalFullScreen,
  StyledLeftButton,
  StyledRightButton,
} from './StyledCarImageGalley';
import { FullScreenGalleryModalContent } from './components/FullScreenGalleryModalContent';
import { SCREEN_WIDTH } from '../../../../screenSettings';

interface ICarImageGallery {
  car: ICarDetails;
  style?: CSSProperties;
}

const transformImages = (
  bigImages: string[],
  smallImages: string[],
  alt: string,
): ReactImageGalleryItem[] =>
  bigImages.map((bigImage, index) => ({
    original: bigImage,
    thumbnail: smallImages[index],
    originalWidth: 520,
    originalHeight: 360,
    thumbnailWidth: 100,
    thumbnailHeight: 800,
    originalAlt: alt,
    thumbnailAlt: alt,
  }));

export const CarImageGallery: FC<ICarImageGallery> = ({ car, style }) => {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const imageGalleryRef = useRef<ImageGallery | null>(null);
  const [fullScreenOpen, setFullScreenOpen] = useState(false);

  const isTabletWidth = useMediaQuery({
    query: `(max-width: ${SCREEN_WIDTH.TABLET}px)`,
  });

  const isDesktopWidth = useMediaQuery({
    query: `(max-width: ${SCREEN_WIDTH.DESKTOP}px)`,
  });

  const isHiddenThumbnails = useMediaQuery({
    query: `(max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_1}px)`,
  });
  const isMobileWidth = useMediaQuery({
    query: `(max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px)`,
  });

  const bigImagesArr = car.bigImages ?? [];

  const smallImagesArr = car.smallImages ?? [];

  const changeCurrentActiveIndex = (index: number) => {
    setActiveSlideIndex(index);
  };

  return (
    <>
      <StyledContainerImageGallery imgHeight={!isMobileWidth ? 360 : 250}>
        <ImageGallery
          additionalClass='selected-car__gallery'
          renderLeftNav={(onClick, disabled) => (
            <StyledLeftButton
              icon={
                <ArrowLeft
                  style={!isTabletWidth ? { fontSize: 25 } : { fontSize: 30 }}
                />
              }
              onClick={onClick}
              disabled={disabled}
            />
          )}
          renderRightNav={(onClick, disabled) => (
            <StyledRightButton
              icon={
                <ArrowRight
                  style={!isTabletWidth ? { fontSize: 25 } : { fontSize: 30 }}
                />
              }
              onClick={onClick}
              disabled={disabled}
            />
          )}
          onSlide={changeCurrentActiveIndex}
          showNav={!isMobileWidth}
          showThumbnails={!isHiddenThumbnails}
          items={transformImages(
            bigImagesArr,
            smallImagesArr,
            `${car.makeViewName} ${car.modelViewName}`,
          )}
          showPlayButton={false}
          showFullscreenButton={false}
          ref={imageGalleryRef}
          slideDuration={200}
          onClick={() => setFullScreenOpen(true)}
        />
      </StyledContainerImageGallery>

      <StyledModalFullScreen
        width={(isMobileWidth && '100%') || (isDesktopWidth && '80%') || '100%'}
        centered={true}
        title={null}
        open={fullScreenOpen}
        onOk={() => setFullScreenOpen(false)}
        onCancel={() => setFullScreenOpen(false)}
        footer={null}
        maskClosable={true}
      >
        <FullScreenGalleryModalContent
          currentImageIndex={activeSlideIndex}
          images={bigImagesArr}
        />
      </StyledModalFullScreen>
    </>
  );
};
