import { ICarFeature } from '../models';
import { FeatureCategoriesIds } from '../enums/featuresCategories';
import { getFilteredFeaturesById } from './getFilteredFeaturesById';

type GrouperFeatures = {
  categoryId: number;
  features: ICarFeature[];
};

type GetGroupedFeatures = (features: ICarFeature[]) => GrouperFeatures[];

export const getGroupedFeatures: GetGroupedFeatures = (features) => {
  const idsFeatures = features.map((elem) => elem.categoryId);
  const uniqueIds = Array.from(new Set(idsFeatures));

  const groupedFeatures: GrouperFeatures[] = [];

  uniqueIds.forEach((id) => {
    switch (id) {
      case FeatureCategoriesIds.Safety: {
        groupedFeatures.push({
          categoryId: FeatureCategoriesIds.Safety,
          features: getFilteredFeaturesById(
            features,
            FeatureCategoriesIds.Safety,
          ),
        });
        break;
      }

      case FeatureCategoriesIds.Comfort: {
        groupedFeatures.push({
          categoryId: FeatureCategoriesIds.Comfort,
          features: getFilteredFeaturesById(
            features,
            FeatureCategoriesIds.Comfort,
          ),
        });
        break;
      }

      case FeatureCategoriesIds.Additional: {
        groupedFeatures.push({
          categoryId: FeatureCategoriesIds.Additional,
          features: getFilteredFeaturesById(
            features,
            FeatureCategoriesIds.Additional,
          ),
        });
        break;
      }

      case FeatureCategoriesIds.Exterior: {
        groupedFeatures.push({
          categoryId: FeatureCategoriesIds.Exterior,
          features: getFilteredFeaturesById(
            features,
            FeatureCategoriesIds.Exterior,
          ),
        });
        break;
      }

      case FeatureCategoriesIds.Interior: {
        groupedFeatures.push({
          categoryId: FeatureCategoriesIds.Interior,
          features: getFilteredFeaturesById(
            features,
            FeatureCategoriesIds.Interior,
          ),
        });
        break;
      }

      case FeatureCategoriesIds.Multimedia: {
        groupedFeatures.push({
          categoryId: FeatureCategoriesIds.Multimedia,
          features: getFilteredFeaturesById(
            features,
            FeatureCategoriesIds.Multimedia,
          ),
        });
        break;
      }

      default:
        break;
    }
  });

  return groupedFeatures;
};
