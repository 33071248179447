import { ParsedQs } from 'qs';
import { useEffect, useMemo } from 'react';
import { SelectOptions } from '../../enums';
import { useParams } from 'react-router-dom';
import { NotificationInstance } from 'antd/es/notification/interface';
import { carsApi } from '../../store/cars/cars.api';
import { config } from '../../config';

export const useGetModels = (notificationApi: NotificationInstance) => {
  const params = useParams();
  const [fetchModels, { data: models, isFetching: isFetchingModels }] =
    carsApi.useLazyGetModelsQuery();

  const getModels = async (
    make: string | ParsedQs | string[] | ParsedQs[],
  ): Promise<void> => {
    if (make === SelectOptions.any) return;

    try {
      await fetchModels(make).unwrap();
    } catch (error: any) {
      notificationApi.error({
        message: error?.data?.message || 'Ошибка загрузки марок авто',
        duration: config.DEFAULT_NOTIFICATION_DURATION,
      });
    }
  };

  useEffect(() => {
    if (params.make) getModels(params.make);
  }, []);

  const memoizedModels = useMemo(() => {
    return models
      ? [
          { id: '', originalName: SelectOptions.any, viewName: 'Любая' },
          ...models,
        ]
      : undefined;
  }, [models]);

  return {
    models: memoizedModels,
    isFetchingModels,
    getModels,
  };
};
