export const BellSvg = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='17'
    height='21'
    viewBox='0 0 17 21'
    fill='none'
  >
    <path
      d='M3.01719 17.1499V8.5999C3.01719 7.08817 3.61772 5.63835 4.68668 4.56939C5.75564 3.50044 7.20545 2.8999 8.71719 2.8999C10.2289 2.8999 11.6787 3.50044 12.7477 4.56939C13.8167 5.63835 14.4172 7.08817 14.4172 8.5999V17.1499M3.01719 17.1499H14.4172M3.01719 17.1499H1.11719M14.4172 17.1499H16.3172M7.76719 19.9999H9.66719'
      stroke='currentColor'
      strokeWidth='1.2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8.7166 2.9C9.24127 2.9 9.6666 2.47467 9.6666 1.95C9.6666 1.42533 9.24127 1 8.7166 1C8.19193 1 7.7666 1.42533 7.7666 1.95C7.7666 2.47467 8.19193 2.9 8.7166 2.9Z'
      stroke='currentColor'
      strokeWidth='1.2'
    />
  </svg>
);
