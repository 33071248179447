import { FC, PropsWithChildren } from 'react';
import { LinkProps } from 'react-router-dom';
import { StyledLinkButton } from './LinkButton.styled';

interface LinkButtonProps extends LinkProps {}

export const LinkButton: FC<PropsWithChildren<LinkButtonProps>> = ({
  children,
  ...linkProps
}) => {
  return (
    <StyledLinkButton {...linkProps}>
      <span>{children}</span>
    </StyledLinkButton>
  );
};
