import { FC } from 'react';
import { StyledAntMenu } from './ProfileSideMenu.styled';
import { menuItems } from '../../../../data/ProfileHeaderMenuItems';
import { KeysMenuItem } from '../../../../enums/menu';
import type { MenuProps } from 'antd';
import { ConfigProvider } from 'antd';
import { useProfileSelector } from '../../../../store/selectors';

import { useHandleMenuClick } from '../../../../hooks/profile';

export const ProfileSideMenu: FC = () => {
  const { activeSubMenuKey } = useProfileSelector();
  const { onClickHandler } = useHandleMenuClick();

  const onMenuClickHandler: MenuProps['onClick'] = ({ key }) => {
    onClickHandler(key);
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Menu: {
            activeBarHeight: 0,
            activeBarBorderWidth: 0,
            itemActiveBg: '#E1E7F0',
            itemBorderRadius: 6,
            itemColor: '#192A4D',
            itemSelectedColor: '#192A4D',
            itemSelectedBg: '#E1E7F0',
            subMenuItemBg: 'transparent',
            subMenuItemBorderRadius: 6,
            itemMarginInline: 0,
            itemPaddingInline: 0,
            itemMarginBlock: 0,
          },
        },
      }}
    >
      <StyledAntMenu
        inlineIndent={4}
        selectedKeys={[activeSubMenuKey]}
        style={{ backgroundColor: 'transparent', width: '100%' }}
        items={menuItems}
        mode='inline'
        onClick={onMenuClickHandler}
        defaultOpenKeys={[KeysMenuItem.subscriptions]}
      />
    </ConfigProvider>
  );
};
