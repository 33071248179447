import getSymbolFromCurrency from 'currency-symbol-map';
import { Currency } from '../enums/currency';

export const getCurrencySymbol = (currency: String) => {
  switch (currency) {
    case Currency.EURO:
      return getSymbolFromCurrency(Currency.EURO);
    case Currency.USD:
      return getSymbolFromCurrency(Currency.USD);
    case Currency.RUB:
      return getSymbolFromCurrency(Currency.RUB);
    case Currency.BYN:
      return Currency.BYN;
    default:
      return getSymbolFromCurrency(Currency.USD);
  }
};
