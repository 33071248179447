import { FC, memo } from 'react';
import {
  StyledDrawer,
  StyledDrawerContent,
} from './CheckboxGroupDrawer.styled';
import { CloseCustomIcon } from '../../img/icons/components';
import { FormInstance } from 'antd';
import { CustomCheckboxGroup } from '../FormElements';
import { useTurnKeyFormsSelector } from '../../store/selectors';
import { EquipmentFormFieldNames } from '../../enums/form';
import { OptionType } from '../../data/types';
import { useActions } from '../../hooks/useActions';
import { getTitleWithIconByCategory } from '../../utils/getTitleWithIconByCategory';

type CheckboxGroupDrawerPropsType = {
  isOpen: boolean;
  closeDrawer: () => void;
  options: OptionType[];
  form: FormInstance<any>;
  formFieldName: `${EquipmentFormFieldNames}`;
};

export const CheckboxGroupDrawer: FC<CheckboxGroupDrawerPropsType> = memo(
  ({ isOpen, closeDrawer, form, formFieldName, options }) => {
    const { setMobileInitialCategories, setEquipmentForm } = useActions();
    const { mobileInitialCategories } = useTurnKeyFormsSelector();

    const handleCloseDrawer = () => {
      setMobileInitialCategories({
        ...mobileInitialCategories,
        [formFieldName]: form.getFieldValue(formFieldName),
      });
      setEquipmentForm({
        ...mobileInitialCategories,
        [formFieldName]: form.getFieldValue(formFieldName),
      });

      closeDrawer();
    };

    const { description: title } = getTitleWithIconByCategory(+formFieldName);

    const handleCheckedChange = (checkedValues: number[]) => {
      form.setFieldValue(formFieldName, checkedValues);
    };

    return (
      <StyledDrawer
        open={isOpen}
        style={{ borderTopLeftRadius: 10, borderTopRightRadius: 10 }}
        closeIcon={<CloseCustomIcon style={{ color: '#97A3B5' }} />}
        zIndex={20}
        height={'calc(100vh - 90px)'}
        onClose={handleCloseDrawer}
        title={title}
        closable={true}
        placement='bottom'
        destroyOnClose={true}
      >
        <StyledDrawerContent>
          <CustomCheckboxGroup
            formItemProps={{
              name: formFieldName,
            }}
            options={options}
            onChange={handleCheckedChange}
          />
        </StyledDrawerContent>
      </StyledDrawer>
    );
  },
);
