import { FC } from 'react';
import { StyledProfilePageWrapper } from './ProfilePage.styled';
import { ProfileSideSection } from './components/ProfileSideSection/ProfileSideSection';
import { useMediaQuery } from 'react-responsive';
import { SCREEN_WIDTH } from '../../screenSettings';
import { commonMetadata } from '../../seo';
import { APP_PATHS } from '../../layout/settingsLayout';
import { Metadata } from '../../components/Metadata';
import { ProfilePageLayout } from './profilePageLayout';

export const ProfilePage: FC = () => {
  const metaData = commonMetadata[APP_PATHS.PROFILE_PAGE];
  const isDesktopWidth = useMediaQuery({
    query: `(max-width: ${SCREEN_WIDTH.DESKTOP}px)`,
  });

  return (
    <>
      <Metadata
        title={metaData.title}
        description={metaData.description}
      />
      <StyledProfilePageWrapper>
        {!isDesktopWidth && <ProfileSideSection />}
        <ProfilePageLayout />
      </StyledProfilePageWrapper>
    </>
  );
};
