import React, { FC } from 'react';
import { StyledArticleChart } from './Prices.styled';
import { options, chartColors, pluginLegendSpacing } from './PriceChart.data';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { IPriceChart, IChartData } from './PriceChart.types';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import { startCase } from 'lodash';
import { getCurrentLocationById } from '../../../../../utils/getCurrentLocationById';
import { sourceIds, currencySymbols } from '../../../../../data';

dayjs.locale('ru');

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const PriceChart: FC<IPriceChart> = ({ carModelMedianPricesByRegions }) => {
  const currentCurrency = currencySymbols[carModelMedianPricesByRegions.medianPricesByRegions[0].currency] || '$';

  const groupedData = carModelMedianPricesByRegions.medianPricesByRegions.reduce((result: any, item) => {
    const key: string = dayjs(`${item.year}-${item.month}-01`).format();

    if (!result[key]) {
      result[key] = {};
    }

    const currentRegion: string | undefined = getCurrentLocationById(item.sourceId, sourceIds);

    if (currentRegion) {
      result[key][currentRegion] = item.medianPrice;
    }
    return result;
  }, {});

  const preparedSortedDates: string[] = Object.keys(groupedData).sort((a, b) => dayjs(a).diff(dayjs(b)));
  const sortedDates = preparedSortedDates.length > 3 ? preparedSortedDates.slice(-3) : preparedSortedDates;
  //showed only last three datasets

  const chartData: IChartData = {
    labels: sortedDates.map((date) => startCase(dayjs(date).format('MMMM'))),
    datasets: Object.keys(groupedData[sortedDates[0]]).map((sourceLabel, index) => ({
      label: sourceLabel,
      data: sortedDates.map((date) => groupedData[date][sourceLabel]),
      backgroundColor: chartColors[index],
    })),
  };

  return (
    <StyledArticleChart>
      <Bar plugins={[pluginLegendSpacing]} options={options(5, currentCurrency)} data={chartData} />
    </StyledArticleChart>
  );
};
