import { APP_PATHS, NAVIGATE_PATHS } from '../../layout/settingsLayout';
import { engineTypesValues } from '../../data';
import { StyledNavLink } from './HeaderNavBar.styled';
import { KeysMenuItem } from '../../enums/menu';

const NAVBAR_LINKS = {
  SEARCH: APP_PATHS.CARS_PAGE,
  ELECTRO: `${APP_PATHS.CARS_PAGE}?engineType=${engineTypesValues.Electro}`,
  TURN_KEY: APP_PATHS.CAR_TURN_KEY,
  SUBS_BY_FILTER: APP_PATHS.PROFILE_PAGE_SUBSCRIPTIONS_FILTER,
  //todo: use subs active key
  ADS_MONITORING: APP_PATHS.PROFILE_PAGE_SUBSCRIPTIONS_OFFER,
};

export const getMenuItems = (isAuth: boolean) => {
  return [
    {
      key: 'search link',
      label: (
        <StyledNavLink to={NAVBAR_LINKS.SEARCH} rel='noopener noreferrer'>
          Поиск
        </StyledNavLink>
      ),
    },
    {
      key: 'electro link',
      label: (
        <StyledNavLink to={NAVBAR_LINKS.ELECTRO} rel='noopener noreferrer'>
          Электро
        </StyledNavLink>
      ),
    },
    {
      key: 'turnkey-car',
      label: (
        <StyledNavLink to={NAVBAR_LINKS.TURN_KEY} rel='noopener noreferrer'>
          Авто &ldquo;под ключ&rdquo;
        </StyledNavLink>
      ),
    },
    {
      key: KeysMenuItem.subscriptionsFilter,
      label: (
        <StyledNavLink
          to={isAuth ? NAVBAR_LINKS.SUBS_BY_FILTER : NAVIGATE_PATHS.SIGN_IN}
          rel='noopener noreferrer'
        >
          Подписки по фильтру
        </StyledNavLink>
      ),
    },
    {
      key: KeysMenuItem.subscriptionsMonitoring,
      label: (
        <StyledNavLink
          to={isAuth ? NAVBAR_LINKS.ADS_MONITORING : NAVIGATE_PATHS.SIGN_IN}
          rel='noopener noreferrer'
        >
          Мониторинг объявлений
        </StyledNavLink>
      ),
    },
  ];
};
