import { createContext } from 'react';
import { ICarDetails } from '../../models/car';
import { IMedianPricesByRegionResponse } from '../../models/response/IMedianPricesByRegionsResponse';
import { ICarRecommendationsResponse } from '../../models/response/ICarRecommendationsResponse';
import { ICarUrlParams } from '../../shared';
import { ICarClonesResponse } from '../../models/response/ICarClonesResponse';

interface ICarInfoContext {
  carDetails: ICarDetails | undefined;
  currentRegion: string | undefined;
  carModelMedianPricesByRegions: IMedianPricesByRegionResponse | undefined;
  price: number | undefined;
  isFetchingCarRecommendations: boolean;
  isErrorCarRecommendations: boolean;
  carRecommendations: ICarRecommendationsResponse | undefined;
  resultRecommendations: any[] | undefined;
  params: ICarUrlParams;
  isErrorCarClones: boolean;
  carClones: ICarClonesResponse | undefined;
  similarAdsUrl: string;
}

export const CarInfoContext = createContext<ICarInfoContext | undefined>(
  undefined,
);
