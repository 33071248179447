import { FC, useMemo } from 'react';
import { useCarInfoContext } from '../../../../hooks/car';
import { StyledPopularFeatures } from './PopularFeatures.styled';
import { TitleWithIcon } from '../../../../components/TitleWithIcon';
import { CarFeaturesBlock } from '../../../../components/CarFeaturesBlock';
import { FeatureCategoriesIds } from '../../../../enums/featuresCategories';
import { config } from '../../../../config';

export const PopularFeatures: FC = () => {
  const { carDetails } = useCarInfoContext();

  const popularFeatures = useMemo(
    () =>
      carDetails?.features.length
        ? carDetails.features
            .filter(
              (feature) =>
                feature.categoryId === FeatureCategoriesIds.Multimedia ||
                feature.categoryId === FeatureCategoriesIds.Comfort,
            )
            .sort(
              (feature1, feature2) =>
                feature1.featureOrder - feature2.featureOrder,
            )
            .slice(0, config.POPULAR_FEATURES_LIMIT)
        : [],
    [carDetails?.features],
  );

  return (
    <StyledPopularFeatures>
      <TitleWithIcon categoryID={FeatureCategoriesIds.Popular} />
      <CarFeaturesBlock
        needTransform={false}
        features={popularFeatures}
        style={{ columnGap: 12 }}
      />
    </StyledPopularFeatures>
  );
};
