import { createContext } from 'react';
import { ICarData } from '../../models';

export type SelectedCarByFilters = {
  name: string;
  hash: string;
};

export type ModalSubscriptionsContextType = {
  isOpenModal: boolean;
  openModal: () => void;
  closeModal: () => void;
  selectedCar: ICarData | null;
  updateSelectedCar: (car: ICarData | null) => void;
  selectedCarByFilters: SelectedCarByFilters;
  updateSelectedCarByFilter: (carName: SelectedCarByFilters) => void;
};

const initialContext: ModalSubscriptionsContextType = {
  isOpenModal: false,
  selectedCar: null,
  selectedCarByFilters: { name: '', hash: '' },
  updateSelectedCarByFilter: (carName) => {},
  updateSelectedCar: (car) => {},
  openModal: () => {},

  closeModal: () => {},
};

export const ModalSubscriptionsContext =
  createContext<ModalSubscriptionsContextType>(initialContext);
