import { ICarFeature } from '../models';

type GetGroupedFeatures = (
  features: ICarFeature[],
  id: number,
) => ICarFeature[];

export const getFilteredFeaturesById: GetGroupedFeatures = (features, id) => {
  return features.filter((elem) => elem.categoryId === id);
};
