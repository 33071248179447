import styled from 'styled-components';
import { Form } from 'antd';
import { SCREEN_WIDTH } from '../../../screenSettings';

export const StyledFormItem = styled(Form.Item)`
  margin-bottom: 24px;

  .ant-form-item-control-input-content {
    display: flex;
    justify-content: space-between;
  }

  @media screen and (max-width: ${SCREEN_WIDTH.TABLET}px) {
    margin-bottom: 16px;
  }
`;
