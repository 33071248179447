export const SafetySvg = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='25'
    height='25'
    viewBox='0 0 25 25'
    fill='none'
  >
    <path
      d='M12.5 1.9978L3.5 4.9978V13.9978C3.5 18.9689 7.52891 22.9978 12.5 22.9978C17.4711 22.9978 21.5 18.9689 21.5 13.9978V4.9978L12.5 1.9978ZM19.8125 13.9978C19.8125 18.0361 16.5383 21.3103 12.5 21.3103C8.46172 21.3103 5.1875 18.0361 5.1875 13.9978V6.26343L12.5 3.6853L19.8125 6.26343V13.9978Z'
      fill='currentColor'
    />
    <path
      d='M9.37052 11.6339C9.29232 11.5554 9.19937 11.493 9.09701 11.4505C8.99465 11.408 8.88489 11.3861 8.77404 11.3861C8.66319 11.3861 8.55343 11.408 8.45107 11.4505C8.34871 11.493 8.25576 11.5554 8.17756 11.6339C8.099 11.7121 8.03666 11.8051 7.99412 11.9075C7.95158 12.0098 7.92969 12.1196 7.92969 12.2304C7.92969 12.3413 7.95158 12.451 7.99412 12.5534C8.03666 12.6558 8.099 12.7487 8.17756 12.8269L11.2104 15.8597L11.2596 15.9089C11.3336 15.983 11.4214 16.0418 11.5181 16.082C11.6149 16.1221 11.7185 16.1427 11.8233 16.1427C11.928 16.1427 12.0317 16.1221 12.1284 16.082C12.2251 16.0418 12.313 15.983 12.3869 15.9089L17.6252 10.6707C17.6993 10.5967 17.7581 10.5088 17.7982 10.4121C17.8384 10.3154 17.859 10.2117 17.859 10.107C17.859 10.0023 17.8384 9.89859 17.7982 9.80187C17.7581 9.70515 17.6993 9.61728 17.6252 9.54331L17.5596 9.47769C17.4856 9.40358 17.3978 9.34478 17.301 9.30466C17.2043 9.26455 17.1006 9.2439 16.9959 9.2439C16.8912 9.2439 16.7875 9.26455 16.6908 9.30466C16.5941 9.34478 16.5062 9.40358 16.4322 9.47769L11.8221 14.0855L9.37052 11.6339Z'
      fill='currentColor'
    />
  </svg>
);
