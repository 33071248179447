export const YearOfIssueSvg = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
  >
    <path
      d='M14.3002 6.6H1.7002M10.8002 1V3.8M5.2002 1V3.8M5.0602 15H10.9402C12.1163 15 12.7044 15 13.1536 14.7711C13.5487 14.5698 13.87 14.2485 14.0713 13.8534C14.3002 13.4042 14.3002 12.8161 14.3002 11.64V5.76C14.3002 4.58389 14.3002 3.99583 14.0713 3.54662C13.87 3.15148 13.5487 2.83022 13.1536 2.62889C12.7044 2.4 12.1163 2.4 10.9402 2.4H5.0602C3.88409 2.4 3.29603 2.4 2.84682 2.62889C2.45167 2.83022 2.13042 3.15148 1.92908 3.54662C1.7002 3.99583 1.7002 4.58389 1.7002 5.76V11.64C1.7002 12.8161 1.7002 13.4042 1.92908 13.8534C2.13042 14.2485 2.45167 14.5698 2.84682 14.7711C3.29603 15 3.88409 15 5.0602 15Z'
      stroke='currentColor'
      strokeWidth='0.8'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
