import { FormInstance } from 'antd';
import { FormState } from '../components/FilterForms';
import { SelectOptions } from '../../../enums';
import { FilterFormFieldNames } from '../../../enums/form';

export const useMakeChange = (
  form: FormInstance<FormState>,
  getModels: (make: string) => void,
) => {
  const handleMakeChange = (make: string) => {
    form.setFieldsValue({
      [FilterFormFieldNames.model]: undefined,
    });

    if (make !== SelectOptions.any) getModels(make);
  };

  return handleMakeChange;
};
