import { FC, useState, useEffect, CSSProperties } from 'react';
import { getMenuItems } from './HeaderNavBar.data';
import { MenuProps, Button, ConfigProvider } from 'antd';
import { KeysMenuItem } from '../../enums/menu';
import { useActions } from '../../hooks/useActions';
import {
  StyledNavBar,
  StyledAntMenu,
  StyledAntDrawer,
  StyledWrapperNavigation,
  StyledSegmentedContent,
  StyledSegmented,
} from './HeaderNavBar.styled';
import { MenuOutlined } from '@ant-design/icons';
import { useMediaQuery } from 'react-responsive';
import { SCREEN_WIDTH } from '../../screenSettings';
import { useIsUserAuth } from '../../hooks/auth/useIsUserAuth';
import { FormItemLabel } from '../FormItemLabel';
import { currencies } from '../../data';
import { useProfileSelector } from '../../store/selectors';
import { useUpdateUserInfo } from '../../hooks/profile';

type HeaderNavBarPropsType = {
  styles?: CSSProperties;
};

export const HeaderNavBar: FC<HeaderNavBarPropsType> = ({ styles }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { setSubmenuKey } = useActions();
  const { updateUserCurrency } = useActions();
  const { currency, countryCode, username, companyName, companyUrl } =
    useProfileSelector();
  const { updateUserHandler, contextHolder, isLoading } =
    useUpdateUserInfo(true);

  const isBeforeTabletWidth = useMediaQuery({
    query: `(max-width: ${SCREEN_WIDTH.TO_DESKTOP_BREAK_1}px`,
  });

  const isTabletWidth = useMediaQuery({
    query: `(max-width: ${SCREEN_WIDTH.TABLET}px`,
  });

  const isBurgerShowed = useMediaQuery({
    query: `(max-width: ${SCREEN_WIDTH.TO_DESKTOP_BREAK_2}px`,
  });

  const isAuth = useIsUserAuth();
  const menuItems = getMenuItems(isAuth);

  const handleSegmentedChange = (segmentedValue: unknown) => {
    if (currency === segmentedValue) return;

    if (!isAuth) {
      updateUserCurrency({ currency: segmentedValue as string });
      return;
    }

    updateUserHandler({
      countryCode,
      currency: segmentedValue as string,
      username,
      companyName,
      companyUrl,
    });
  };

  const toggleDrawerOpenState = () => setIsDrawerOpen((prev) => !prev);

  const closeDrawerHandler = () => setIsDrawerOpen(false);

  const onMenuClickHandler: MenuProps['onClick'] = ({ key }) => {
    switch (key) {
      case KeysMenuItem.subscriptionsFilter:
        setSubmenuKey({ key });
        break;
      case KeysMenuItem.subscriptionsMonitoring:
        setSubmenuKey({ key });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (!isBurgerShowed) {
      setIsDrawerOpen(false);
    }
  }, [isBurgerShowed]);

  return (
    <ConfigProvider
      theme={{
        components: {
          Menu: {
            activeBarHeight: 1,
            horizontalItemSelectedColor: '#1B79DE',
            horizontalItemHoverColor: '#3f8bf3',
            itemActiveBg: 'transparent',
            itemBorderRadius: 0,
            itemHoverColor: '#3F8BF3',
            itemHoverBg: 'transparent',
            horizontalLineHeight: '28px',
            itemMarginBlock: 0,
            itemMarginInline: 0,
            itemSelectedColor: '#717C8C',
            itemSelectedBg: 'transparent',
            itemPaddingInline: isBeforeTabletWidth && !isTabletWidth ? 8 : 12,
            itemColor: '#717C8C',
            itemHeight: 28,
          },
          Button: {
            colorBgTextActive: 'transparent',
            colorBgTextHover: 'transparent',
          },
        },
      }}
    >
      <StyledWrapperNavigation style={{ ...styles }}>
        {isBurgerShowed && (
          <StyledAntDrawer
            placement='left'
            width={320}
            rootClassName='header__navigation_drawer'
            open={isDrawerOpen}
            onClose={closeDrawerHandler}
            title='Меню'
          >
            <StyledAntMenu
              onClick={closeDrawerHandler}
              className='header__menu_vertical'
              mode='vertical'
              items={menuItems}
              selectable={false}
            />
            <StyledSegmentedContent>
              <FormItemLabel>Валюта</FormItemLabel>
              <StyledSegmented
                size='large'
                disabled={isLoading}
                defaultValue={currency}
                options={currencies}
                onChange={handleSegmentedChange}
              />
            </StyledSegmentedContent>
            {contextHolder}
          </StyledAntDrawer>
        )}

        <StyledNavBar>
          {isBurgerShowed && (
            <Button
              className='burger__button'
              icon={<MenuOutlined style={{ color: '#2B3E64', fontSize: 20 }} />}
              type='text'
              onClick={toggleDrawerOpenState}
            />
          )}
          {!isBurgerShowed && (
            <StyledAntMenu
              style={{ maxHeight: 28 }}
              className='header__menu_horizontal'
              mode='horizontal'
              items={menuItems}
              selectable={false}
              onClick={onMenuClickHandler}
            />
          )}
        </StyledNavBar>
      </StyledWrapperNavigation>
    </ConfigProvider>
  );
};
