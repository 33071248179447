import { FC } from 'react';
import {
  StyledPriceWrapper,
  StyledPriceDescription,
  StyledAntdDivider,
  StyledPrice,
  StyledWrapper,
} from './CarPriceBlock.styled';
import { formatNumber } from '../../../utils/formatNumber';
import { currencySymbols } from '../../../data';

export type CarPriceBlockPropsType = {
  description: string;
  price: number;
  currency: string;
  isSmallSize?: boolean;
  showDivider?: boolean;
};

export const CarPriceBlock: FC<CarPriceBlockPropsType> = ({
  description,
  price,
  currency,
  isSmallSize,
  showDivider = false,
}) => {
  return (
    <StyledWrapper>
      <StyledPriceWrapper>
        <StyledPriceDescription>{description}</StyledPriceDescription>
        <StyledPrice
          smallsize={isSmallSize}
        >{`${formatNumber(price)} ${currencySymbols[currency]}`}</StyledPrice>
      </StyledPriceWrapper>
      {showDivider && <StyledAntdDivider type='vertical' />}
    </StyledWrapper>
  );
};
