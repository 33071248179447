import { FC } from 'react';
import { Dropdown, theme, Typography } from 'antd';
import { ParsedQs } from 'qs';
import { Tags } from '../../../enums';
import { formatNumber } from '../../../utils/formatNumber';
import { currencySymbols } from '../../../data';
import { Price } from '../../../models/car';
import {
  StyledList,
  StyledTagWrapper,
  StyledTag,
} from './CarsListDropdown.styled';

interface ICarsListDropdown {
  prices: any;
  currency: string | ParsedQs | string[] | ParsedQs[] | undefined;
  price: number;
  tag?: any;
  fontSizeCustom?: number;
}

const { useToken } = theme;

export const CarsListDropdown: FC<ICarsListDropdown> = ({
  fontSizeCustom,
  prices,
  currency,
  tag,
  price,
}) => {
  const { token } = useToken();

  const getColorByPriceType = (tag: string): string => {
    if (tag === Tags.lowPrice) {
      return 'success';
    } else if (tag === Tags.highPrice) {
      return 'error';
    } else {
      return 'default';
    }
  };

  return (
    <Dropdown
      placement='topRight'
      trigger={['hover']}
      dropdownRender={(menu) => (
        <StyledList>
          {prices.map((price: Price, index: number) => {
            if (price.currency.toLowerCase() === currency) {
              return null;
            }
            return (
              <li key={`${price.amount}-${index}`}>
                <Typography.Text style={{ fontSize: 16 }}>
                  {`≈ ${formatNumber(price.amount)} ${currencySymbols[price.currency]}`}
                </Typography.Text>
              </li>
            );
          })}
        </StyledList>
      )}
    >
      <StyledTagWrapper
        colorText={token.colorTextHeading}
        backgroundColor='#F5F7FA'
      >
        <StyledTag
          icon={null}
          fontsizecustom={fontSizeCustom}
          color={getColorByPriceType(tag)}
        >{`${formatNumber(price)} ${currency}`}</StyledTag>
      </StyledTagWrapper>
    </Dropdown>
  );
};
