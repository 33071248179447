import { List, Row, Divider } from 'antd';
import styled from 'styled-components';
import { SCREEN_WIDTH } from '../../screenSettings';

export const StyledListItem = styled.div`
  background-color: #ffffff;
  padding: 40px 0px;
  border-bottom: 1px solid var(--Gray-3);
  &:last-child {
    border-bottom: none;
  }

  &:first-of-type {
    padding: 48px 0 40px 0px;
  }

  &:last-of-type {
    padding: 40px 0px 16px 0px;
    border-block-end: none;
  }
`;

export const StyledDetailsImageWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;

  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_2}px) {
    flex-direction: column-reverse;
  }
`;

export const StyledListItemMeta = styled(List.Item.Meta)`
  .ant-list-item-meta-title {
    margin-block-end: 0 !important;
  }
`;

export const StyledDescriptionWrapper = styled.div`
  position: relative;
  width: calc(70% - 16px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  gap: 16px;
  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_2}px) {
    width: 100%;
  }
`;

export const StyledCarTagsWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

export const StyledDealerLocationWrapper = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  flex: 1;
  margin-top: 16px;
  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
  }
`;

export const StyledDivider = styled(Divider)`
  height: 24px;
  margin: 0 16px;
  align-self: center;
  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
    display: none;
  }
`;

export const StyledDealerNameWrapper = styled(Row)`
  flex-shrink: 1;
  max-width: 55%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const StyledCarMetaData = styled.div`
  //
`;
