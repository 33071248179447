import styled from 'styled-components';
import { Slider } from 'antd';
import { SCREEN_WIDTH } from '../../../screenSettings';

export const StyledSlider = styled(Slider)`
  &.ant-slider-horizontal {
    margin-bottom: 0;
    .ant-slider-dot {
      visibility: hidden;
    }
    .ant-slider-mark {
      top: -24px;
      @media screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_2}px) {
        top: -30px;
      }

      .ant-slider-mark-text:nth-child(1) {
        left: 2% !important;

        @media screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_2}px) {
          left: 3% !important;
        }
      }
      .ant-slider-mark-text:nth-child(2) {
        left: 90% !important;

        @media screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_2}px) {
          left: 92% !important;
        }
      }
    }
  }
`;
