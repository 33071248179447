import { useCallback } from 'react';
import { SubscriptionTelegramLink } from '../../components/SubscriptionTelegramLink/SubscriptionTelegramLink';
import { CleanLink } from '../../components/Links/CleanLink';
import { NAVIGATE_PATHS } from '../../layout/settingsLayout';
import { SubscriptionModelWindow } from '../../components/SubscriptionModelWindow/SubscriptionModelWindow';
import { SubscriptionErrorCodes } from '../../enums/subscriptionErrorCodes';
import { config } from '../../config';
import { SubscriptionType } from '../../enums/subscription';

interface ISubscriptionError {
  status: number;
  data?: string;
}

export function useSubscriptionError(userId: string | null) {
  return useCallback(
    (error: ISubscriptionError, subscribtionType: SubscriptionType) => {
      if (error.status === 401)
        return {
          type: 'error',
          message: 'Ваша сессия истекла',
          description: (
            <>
              Чтобы добавить новые подписки, пожалуйста,{' '}
              <CleanLink to={NAVIGATE_PATHS.SIGN_IN}>
                войдите в аккаунт
              </CleanLink>
              .
            </>
          ),
          duration: config.DEFAULT_NOTIFICATION_DURATION,
        };

      switch (error.data) {
        case SubscriptionErrorCodes.TelegramNotLinked:
          return {
            type: 'warning',
            message: 'Ой, кажется, ваш Телеграм аккаунт не привязан',
            description: (
              <>
                Пожалуйста, перейдите по{' '}
                <SubscriptionTelegramLink>'ссылке'</SubscriptionTelegramLink> и
                попробуйте подписаться снова. Спасибо!
              </>
            ),
            duration: config.DEFAULT_NOTIFICATION_DURATION,
          };

        case SubscriptionErrorCodes.SubscriptionAlreadyExists:
          return {
            type: 'warning',
            message: 'Такая подписка уже существует.',
            description: (
              <SubscriptionModelWindow subscribtionType={subscribtionType} />
            ),
            duration: config.DEFAULT_NOTIFICATION_DURATION,
          };

        case SubscriptionErrorCodes.SubscriptionsLimitReached:
          return {
            type: 'error',
            message: 'Вы достигли лимита подписок.',
            description: (
              <SubscriptionModelWindow subscribtionType={subscribtionType} />
            ),
            duration: config.DEFAULT_NOTIFICATION_DURATION,
          };

        default:
          return {
            type: 'error',
            message: 'Ошибка подписки на данное авто',
            description: `Status: ${error.status}. Title: ${error}`,
            duration: config.DEFAULT_NOTIFICATION_DURATION,
          };
      }
    },
    [userId],
  );
}
