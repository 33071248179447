export const formatTemplateString = (
  stringToFormat: string,
  ...params: Array<string | number>
): string => {
  if (params.length === 0) return stringToFormat;

  let stringToReturn = stringToFormat;
  for (let i = 0; i < params.length; i++) {
    stringToReturn = stringToReturn.replace(
      new RegExp('\\{' + i + '\\}', 'gi'),
      params[i].toString(),
    );
  }

  return stringToReturn;
};
