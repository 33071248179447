import styled from 'styled-components';
import { ArrowRightCustomIcon } from '../../img/icons/components';

export const StyledFeaturesWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:hover,
  &:target,
  &:focus {
    background-color: rgba(63, 139, 243, 0.3);
    border-radius: 4px;
  }
`;

export const StyledGroupedWrapper = styled.div`
  padding: 0 0 0 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledCount = styled.span`
  color: var(--Gray-7);
  margin-right: 3px;
  font-size: 12px;
  line-height: 116%;
  letter-spacing: 0.048px;
`;

export const StyledArrowRightIcon = styled(ArrowRightCustomIcon)`
  color: #717c8c;
`;
