import { FC, memo, useState } from 'react';
import { FormInstance } from 'antd';
import { StyledFeaturesList } from './MobileFeaturesCheckboxes.styled';
import { CategoriesType } from '../../models/turn-key/GetCarFeatures';
import { StyledContent } from '../styled/StyledContent';
import { CarFeatureClickableTitle } from '../CarFeatureClickableTitle';
import { getGrouppedCategories } from '../../utils/getGrouppedCategories';
import { CheckboxGroupDrawer } from '../CheckboxGroupDrawer';
import { OptionType } from '../../data/types';
import { useTurnKeyFormsSelector } from '../../store/selectors';
import { EquipmentFormFieldNames } from '../../enums/form';
import { SCREEN_WIDTH } from '../../screenSettings';
import { useMediaQuery } from 'react-responsive';

type EquipmentFormNamesStringType = `${EquipmentFormFieldNames}`;

type MobileFeaturesCheckboxesPropsType = {
  categories: CategoriesType[];
  form: FormInstance<unknown>;
  fieldNames: string[];
};

export const MobileFeaturesCheckboxes: FC<MobileFeaturesCheckboxesPropsType> =
  memo(({ categories, form, fieldNames }) => {
    const { mobileInitialCategories } = useTurnKeyFormsSelector();

    const isMobileWidth = useMediaQuery({
      query: `(max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px`,
    });

    const grouppedCategories = getGrouppedCategories(
      categories,
      fieldNames.map((elem) => +elem),
    );

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [drawerOptions, setDrawerOptions] = useState<OptionType[]>([]);
    const [currentFieldName, setCurrentFieldName] = useState('');

    const closeDrawer = () => setIsDrawerOpen(false);

    const openDrawer = (fieldName: string, options: OptionType[]) => {
      setCurrentFieldName(fieldName);
      setDrawerOptions(options);
    };

    return (
      <>
        <StyledContent style={isMobileWidth ? { padding: 0 } : undefined}>
          <StyledFeaturesList>
            {grouppedCategories.map((item, index) => {
              const categoryIdStringified =
                item.categoryId.toString() as EquipmentFormNamesStringType;

              return (
                <CarFeatureClickableTitle
                  key={item.categoryId}
                  categoryId={item.categoryId}
                  customCount={
                    mobileInitialCategories[categoryIdStringified]?.length
                  }
                  customAction={() => {
                    openDrawer(fieldNames[index], item.options);
                    setTimeout(() => setIsDrawerOpen(true));
                  }}
                />
              );
            })}
          </StyledFeaturesList>
        </StyledContent>
        <CheckboxGroupDrawer
          form={form}
          closeDrawer={closeDrawer}
          isOpen={isDrawerOpen}
          options={drawerOptions}
          formFieldName={currentFieldName as EquipmentFormNamesStringType}
        />
      </>
    );
  });
