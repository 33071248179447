import styled from 'styled-components';
import { SCREEN_WIDTH } from '../../screenSettings';
import { Alert } from 'antd';

export const StyledAlertContent = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  padding: 16px 12px;

  @media screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
    padding: 8px 4px;
  }
`;

export const StyledAlert = styled(Alert)`
  border-radius: 8px;
`;

export const IconWrapper = styled.div`
  padding: 3px;
`;
