import styled from 'styled-components';
import { SCREEN_WIDTH } from '../../../../screenSettings';

export const StyledTitleWrapper = styled.div`
  width: 100%;
  padding: var(--padding-title-content-desktop);
  box-shadow: var(--account-content-shadow);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--gap-desktop-row-small);

  @media screen and (max-width: ${SCREEN_WIDTH.DESKTOP}px) {
    padding: var(--padding-title-content-mobile);
  }

  @media screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_1}px) {
    flex-direction: column;
    padding: var(--padding-title-content-mobile) 0 0 0;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0;
  }
`;

export const StyledTitle = styled.h1`
  margin-bottom: 0;
  color: var(--Dark-13);
  font-size: var(--big-title-size);
  font-style: var(--font-style-base);
  font-weight: var(--bold-font-weight);
  line-height: var(--title-account-line-height);

  @media screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
    font-size: var(--mobile-title-size);
  }
`;

export const StyledCount = styled.span`
  display: inline-block;
  color: var(--Gray-6);
  font-size: var(--desktop-content-font-size);
  font-style: var(--font-style-base);
  font-weight: var(--bold-font-weight);
`;

export const StyledSwitcherWrapper = styled.div<{
  activeswitcher: boolean | undefined;
}>`
  margin-left: auto;

  @media screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_1}px) {
    margin-left: 0;
    width: 100%;
    padding: 16px;
    background: ${({ activeswitcher }) =>
      activeswitcher ? 'var(--Gray-1)' : 'var(--Red-1)'};
    box-shadow: 0px -1px 0px 0px #e1e7f0 inset;
  }
`;

export const StyledTitleWithIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-desktop-row-small);

  @media screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_1}px) {
    padding: 24px 16px;
    box-shadow: 0px -1px 0px 0px #e1e7f0 inset;
    width: 100%;
  }
`;
