import { FC, ReactNode } from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import {
  StyledTitleWrapper,
  StyledTitle,
  StyledCount,
  StyledSwitcherWrapper,
  StyledTitleWithIcon,
} from './BlockTitleWithIcon.styles';
import { IconWithTooltip } from '../../../../components/IconWithTooltip';
import { useMediaQuery } from 'react-responsive';
import { SCREEN_WIDTH } from '../../../../screenSettings';
import { NotificationTGSwitcher } from '../../../../components/NotificationTGSwitcher';
import { useSubscriptionsOfferSelector } from '../../../../store/selectors';

type BlockTitleWithIconProps = {
  title: string | ReactNode;
  tooltipDescription: ReactNode;
  count: null | number;
  withSwitcher?: boolean;
  loading?: boolean;
};

export const BlockTitleWithIcon: FC<BlockTitleWithIconProps> = ({
  title,
  count,
  tooltipDescription,
  withSwitcher = false,
  loading = false,
}) => {
  const isMobileWidth = useMediaQuery({
    query: `(max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px)`,
  });
  const { notificationsEnabled } = useSubscriptionsOfferSelector();

  return (
    <StyledTitleWrapper>
      <StyledTitleWithIcon>
        <StyledTitle>{title}</StyledTitle>
        <IconWithTooltip
          description={tooltipDescription}
          customTrigger={isMobileWidth ? ['click'] : ['hover']}
          customPopoverWidth={'310px'}
        />
        <Spin
          spinning={loading}
          indicator={<LoadingOutlined spin />}
          size='small'
        >
          <StyledCount>{!loading ? count : ''}</StyledCount>
        </Spin>
      </StyledTitleWithIcon>
      {withSwitcher && (
        <StyledSwitcherWrapper activeswitcher={notificationsEnabled}>
          <NotificationTGSwitcher
            loading={loading}
            defaultChecked={notificationsEnabled}
            description={'Уведомления в Telegram'}
          />
        </StyledSwitcherWrapper>
      )}
    </StyledTitleWrapper>
  );
};
