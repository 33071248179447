import { FC, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import logo from '../img/logo/logo.svg';
import { Row } from 'antd';
import { PrimaryButton } from '../components/Buttons/PrimaryButton';
import { UserOptionsPopover } from '../components/UserOptionsPopover';
import { UserOptionsButton } from '../components/Buttons/UserOptionsButton';
import { UserOptionsDrawer } from '../components/UserOptionsDrawer';
import { HeaderCurrencyBlock } from '../components/HeaderCurrencyBlock';
import { HeaderNavBar } from '../components/HeaderNavBar';
import { StyledHeaderContainer, StyledDivider } from './Header.styled';
import { APP_PATHS, NAVIGATE_PATHS } from './settingsLayout';
import { useIsUserAuth } from '../hooks/auth/useIsUserAuth';
import { useMediaQuery } from 'react-responsive';
import { SCREEN_WIDTH } from '../screenSettings';

export const MyHeader: FC = () => {
  const navigate = useNavigate();
  const isAuth = useIsUserAuth();

  const isTabletWidth = useMediaQuery({
    query: `(max-width: ${SCREEN_WIDTH.TABLET}px`,
  });
  const isBurgerShowed = useMediaQuery({
    query: `(max-width: ${SCREEN_WIDTH.TO_DESKTOP_BREAK_2}px`,
  });

  const [isDrawerOpened, setIsDrawerOpened] = useState(false);

  const isPopoverShowed = !isTabletWidth && isAuth;
  const isDrawerShowed = isTabletWidth && isAuth;

  const hideDrawer = () => setIsDrawerOpened(false);

  const openDrawer = () => setIsDrawerOpened(true);

  const redirectToSignIn = () => navigate(NAVIGATE_PATHS.SIGN_IN);

  return (
    <Row>
      <StyledHeaderContainer span={24}>
        <HeaderNavBar styles={{ order: isBurgerShowed ? 1 : 2 }} />
        <Link to={APP_PATHS.ROOT} style={{ order: isBurgerShowed ? 2 : 1 }}>
          <img
            src={logo}
            width='100px'
            height='30px'
            alt='AutoAggregator'
            title='AutoAggregator'
          />
        </Link>
        {!isAuth && (
          <PrimaryButton style={{ order: 5 }} onClick={redirectToSignIn}>
            Войти
          </PrimaryButton>
        )}
        {!isBurgerShowed && <HeaderCurrencyBlock style={{ order: 3 }} />}
        {!isBurgerShowed && <StyledDivider type='vertical' />}
        {isPopoverShowed && <UserOptionsPopover layoutStyles={{ order: 5 }} />}
        {isDrawerShowed && (
          <UserOptionsButton
            style={{ order: 5 }}
            isArrowTransformed={isDrawerOpened}
            onClick={openDrawer}
          />
        )}
        {isDrawerShowed && isDrawerOpened && (
          <UserOptionsDrawer
            onCloseAction={hideDrawer}
            isDrawerOpened={isDrawerOpened}
          />
        )}
      </StyledHeaderContainer>
    </Row>
  );
};
