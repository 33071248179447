import { FC } from 'react';
import { Spin } from 'antd';
import { BlockTitleWithIcon } from '../BlockTitleWithIcon';
import { CarSubscriptionsList } from '../CarSubscriptionsList';
import { ModalSubscriptionsContextProvider } from '../../../../contexts';
import { StyledMonitoringWrapper } from './ProfileAdvertsMonitoring.styles';
import { CarsNotFound } from '../CarsNotFound';
import { useSubscriptionsOfferSelector } from '../../../../store/selectors';
import { useGetSubscriptionsOffers } from '../../../../hooks/subscriptions';
import { isFetchBaseQueryError } from '../../../../utils/isFetchBaseQueryError';

export const ProfileAdvertsMonitoring: FC = () => {
  const { offerSubscriptions } = useSubscriptionsOfferSelector();
  const { contextHolder, isLoading, error, isError } =
    useGetSubscriptionsOffers();

  const has404Error =
    isError && isFetchBaseQueryError(error) && error.status === 404;

  return (
    <ModalSubscriptionsContextProvider>
      {contextHolder}
      <Spin size='large' spinning={isLoading}>
        <StyledMonitoringWrapper>
          <BlockTitleWithIcon
            tooltipDescription={
              <>
                <b>"Мониторинг объвлений"</b> позволяет вам ослеживать изменения
                в интересующих вас объявлениях. Telegram уведомит вас при
                изменении цены, описания, или др.
              </>
            }
            loading={isLoading}
            title='Мониторинг объявлений'
            count={offerSubscriptions.length}
            withSwitcher={true}
          />
          {!!offerSubscriptions.length && <CarSubscriptionsList />}
          {!offerSubscriptions.length && !isLoading && (
            <CarsNotFound needLinkMessenger={has404Error} />
          )}
        </StyledMonitoringWrapper>
      </Spin>
    </ModalSubscriptionsContextProvider>
  );
};
