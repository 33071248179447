import { FC, PropsWithChildren } from 'react';
import {
  StyledFilterFormsContainer,
  StyledFilterFormsHeader,
  StyledFilterFormsMain,
} from './FilterForms.styled';

export const FilterFormsContainer: FC<PropsWithChildren> = ({ children }) => {
  return (
    <StyledFilterFormsContainer>
      <StyledFilterFormsHeader>Поиск авто</StyledFilterFormsHeader>
      <StyledFilterFormsMain>{children}</StyledFilterFormsMain>
    </StyledFilterFormsContainer>
  );
};
