import { FC, CSSProperties } from 'react';
import { Select } from 'antd';
import {
  StyledCurrencyWrapper,
  StyledAntSelect,
} from './HeaderCurrencyBlock.styled';
import { ArrowDownIcon } from '../../img/icons/components';
import { useProfileSelector } from '../../store/selectors';
import { currencies } from '../../data';
import { useActions } from '../../hooks/useActions';
import { useUpdateUserInfo } from '../../hooks/profile';
import { SelectOptionStyle } from '../styled/SelectOption';
import { useIsUserAuth } from '../../hooks/auth/useIsUserAuth';

type HeaderCurrencyProps = {
  style?: CSSProperties;
};

export const HeaderCurrencyBlock: FC<HeaderCurrencyProps> = ({ style }) => {
  const { currency, username, countryCode, companyUrl, companyName } =
    useProfileSelector();

  const isAuth = useIsUserAuth();
  const { updateUserHandler, contextHolder, isLoading } =
    useUpdateUserInfo(true);

  const { updateUserCurrency } = useActions();

  const handleChange = (selectValue: unknown) => {
    if (currency === selectValue) return;

    if (!isAuth) {
      updateUserCurrency({ currency: selectValue as string });
      return;
    }

    updateUserHandler({
      countryCode,
      currency: selectValue as string,
      username,
      companyName,
      companyUrl,
    });
  };

  return (
    <StyledCurrencyWrapper style={style}>
      {contextHolder}
      <StyledAntSelect
        disabled={isLoading}
        loading={isLoading}
        defaultValue={currency}
        onChange={handleChange}
        suffixIcon={<ArrowDownIcon style={{ color: '' }} />}
      >
        {currencies.map((currency) => (
          <Select.Option key={currency.label} style={SelectOptionStyle}>
            {currency.label}
          </Select.Option>
        ))}
      </StyledAntSelect>
    </StyledCurrencyWrapper>
  );
};
