import styled from 'styled-components';
import { Alert } from 'antd';

export const StyledIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  height: fit-content;
  position: relative;
`;

export const StyledAlert = styled(Alert)`
  position: absolute;
  top: 100%;
  left: 100%;
  transform: translate(-50%, 0);
  &.ant-alert,
  &.ant-alert-success,
  &.ant-alert-error {
    border: none;
    padding: 2px;
  }
`;
