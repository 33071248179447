import { ISignInCredentials } from '../../models/auth/ISignInCredentials';
import { ISignUpCredentials } from '../../models/auth/ISignUpCredentials';
import { IAuthResponse } from '../../models/response/IAuthResponse';
import { api } from '../api/api';
import { IConfirmEmailParams, IResetPasswordParams } from '../../models/auth';
import { ParsedQs } from 'qs';
import { ApiEndpoints } from '../api/endpoints';

export const authApi = api.injectEndpoints({
  endpoints: (build) => ({
    signIn: build.mutation<IAuthResponse, ISignInCredentials>({
      query: (credentials: ISignInCredentials) => ({
        url: `${process.env.REACT_APP_BASE_URL}${ApiEndpoints.SignIn}`,
        method: 'POST',
        body: credentials,
      }),
    }),
    signUp: build.mutation<any, ISignUpCredentials>({
      query: (credentials: ISignUpCredentials) => ({
        url: `${process.env.REACT_APP_BASE_URL}${ApiEndpoints.SignUp}`,
        method: 'POST',
        body: credentials,
      }),
    }),
    confirmEmail: build.mutation<any, IConfirmEmailParams>({
      query: ({ userId, code }) => ({
        url: `${process.env.REACT_APP_BASE_URL}${ApiEndpoints.ConfirmEmail}`,
        method: 'POST',
        body: {
          userId,
          code,
        },
      }),
    }),
    resendConfirmEmail: build.mutation<
      any,
      string | ParsedQs | string[] | ParsedQs[]
    >({
      query: (userId: string) => ({
        url: `${process.env.REACT_APP_BASE_URL}${ApiEndpoints.ResendConfirmEmail}`,
        method: 'POST',
        body: { userId },
      }),
    }),
    forgotPassword: build.mutation<any, string>({
      query: (email: string) => ({
        url: `${process.env.REACT_APP_BASE_URL}${ApiEndpoints.ForgotPassword}`,
        method: 'POST',
        body: { email },
      }),
    }),
    resetPassword: build.mutation<any, IResetPasswordParams>({
      query: (resetPasswordParams: IResetPasswordParams) => ({
        url: `${process.env.REACT_APP_BASE_URL}${ApiEndpoints.ResetPassword}`,
        method: 'POST',
        body: { ...resetPasswordParams },
      }),
    }),
  }),
});

export const {
  useSignInMutation,
  useSignUpMutation,
  useConfirmEmailMutation,
  useResendConfirmEmailMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
} = authApi;
