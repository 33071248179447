import { FC } from 'react';
import { UserAvatarBlock } from '../../../../components/UserAvatarBlock';
import { ProfileSideMenu } from '../ProfileSideMenu/ProfileSideMenu';
import { StyledProfileSideSection } from './ProfileSideSection.style';

export const ProfileSideSection: FC = () => {
  return (
    <StyledProfileSideSection>
      <UserAvatarBlock />
      <ProfileSideMenu />
    </StyledProfileSideSection>
  );
};
