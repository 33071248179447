import styled from 'styled-components';
import { Image, Pagination } from 'antd';
import { SCREEN_WIDTH } from '../../../../../screenSettings';

export const StyledPagination = styled(Pagination)`
  &.clones__list_pagination {
    margin-top: 40px;
    padding: 16px;
    background: var(--Gray-2);
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .ant-pagination-item-active {
      border-radius: 2px;
      background-color: transparent;
      border: 1px solid var(--Blue-7);
    }

    @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
      justify-content: space-between;

      .ant-pagination-item,
      .ant-pagination-prev,
      .ant-pagination-next {
        background: var(--White);
        border-radius: 2px;
        border: 1px solid var(--Gray-4);
      }

      .ant-pagination-item-active {
        background: var(--White);
      }
      .ant-pagination-disabled {
        border-radius: 2px;
        border: 1px solid var(--Gray-4);
        background: var(--White);
        color: var(--Gray-4);
      }
    }
  }
`;

export const StyledListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
    gap: 0;
  }
`;

export const StyledListItem = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 40px;
  width: 100%;

  @media only screen and (max-width: ${SCREEN_WIDTH.TO_DESKTOP_BREAK_1}px) {
    gap: 10px;
  }

  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
    min-height: 180px;
  }

  & .clones__details {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 40px;
    width: 100%;

    @media only screen and (max-width: ${SCREEN_WIDTH.TO_DESKTOP_BREAK_1}px) {
      gap: 10px;
    }

    @media only screen and (max-width: ${SCREEN_WIDTH.DESKTOP}px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      margin-left: 20px;
      flex-grow: 1;
    }

    @media only screen and (max-width: ${SCREEN_WIDTH.TABLET}px) {
      gap: 4px;
      margin-left: 10px;
      flex-grow: 1;
    }
    @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
      gap: 2px;
      margin-left: 0;
    }
  }

  & > div.clones__details_description {
    width: 100%;
    text-align: end;
    align-self: center;
  }

  & div.clones__details_link {
    align-self: flex-end;
    margin-left: auto;
    margin-bottom: 10px;
    @media only screen and (max-width: ${SCREEN_WIDTH.DESKTOP}px) {
      margin-top: auto;
      align-self: flex-start;
      margin-bottom: 15px;
    }
    @media only screen and (max-width: ${SCREEN_WIDTH.TABLET}px) {
      margin-bottom: 5px;
      margin-left: 0;
    }
  }
`;

export const StyledImageContainer = styled.div`
  width: 74px;

  @media only screen and (max-width: ${SCREEN_WIDTH.DESKTOP}px) {
    width: 240px;
    flex-shrink: 1;
  }
  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET}px) {
    width: 150px;
  }

  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
    width: 100px;
  }
  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_4}px) {
    width: 60px;
  }
`;

export const StyledImage = styled(Image)`
  border-radius: 4px;
  object-fit: cover;
  width: 74px !important;
  height: 64px !important;

  @media only screen and (max-width: ${SCREEN_WIDTH.DESKTOP}px) {
    width: 100% !important;
    height: 100% !important;
    max-height: 280px;
  }

  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET}px) {
    width: 148px !important;
    max-height: 160px;
  }

  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
    max-height: 90px;
    width: 90px !important;
  }
  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_4}px) {
    max-height: 80px;
    width: 74px !important;
  }
  @media only screen and (max-width: ${SCREEN_WIDTH.MOBILE}px) {
    max-height: 58px;
    width: 60px !important;
  }
`;

export const StyledDateContainer = styled.div`
  max-width: 105px;
  width: 100%;

  @media only screen and (max-width: ${SCREEN_WIDTH.DESKTOP}px) {
    max-width: 320px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
  }
`;

export const StyledMileageContainer = styled.div`
  max-width: 80px;
  width: 100%;

  @media only screen and (max-width: ${SCREEN_WIDTH.DESKTOP}px) {
    max-width: 320px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
  }
`;

export const StyledPriceContainer = styled.div`
  max-width: 80px;
  width: 100%;

  @media only screen and (max-width: ${SCREEN_WIDTH.DESKTOP}px) {
    max-width: 320px;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
  }
`;
