export const findLabelByValue = (
  values: { value: number | string | undefined; label: string }[],
  value: number,
): string | null => {
  const filtered = values.filter((v) => v.value === value);

  if (filtered.length && filtered[0].value === '0') return null;

  if (filtered.length) return filtered[0].label;

  return null;
};
