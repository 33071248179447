import { FC } from 'react';
import { Cost } from '../../../../models/car/CalculateDelivery';
import {
  StyledPricesBlockRow,
  StyledVerticalDivider,
  TextKey,
  TextValueBold,
} from '../CalculateDelivery';
import { formatNumber } from '../../../../utils/formatNumber';
import { getCurrencySymbol } from '../../../../utils/getCurrencySymbol';

interface CarCalculationReportRow {
  costOriginal: Cost;
  costConverted: Cost;
  title: string;
}

export const CarCalculationReportRow: FC<CarCalculationReportRow> = ({
  costOriginal,
  costConverted,
  title,
}) => {
  return (
    <StyledPricesBlockRow>
      <TextKey>{title}</TextKey>
      <span>
        <TextKey>
          {formatNumber(costOriginal.fromAmount)} -{' '}
          {formatNumber(costOriginal.toAmount)}{' '}
          {getCurrencySymbol(costOriginal.currency)}
        </TextKey>
        <StyledVerticalDivider type='vertical' />
        <TextValueBold>
          ~ {formatNumber(costConverted.fromAmount)} -{' '}
          {formatNumber(costConverted.toAmount)}{' '}
          {getCurrencySymbol(costConverted.currency)}
        </TextValueBold>
      </span>
    </StyledPricesBlockRow>
  );
};
