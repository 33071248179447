import { FC } from 'react';
import { ProvidedTagsType } from '../../models/car';
import { StyledTagWithIcon, StyledTag } from './TagWithTooltip.styled';
import { IconWithTooltip } from '../IconWithTooltip';
import { useMediaQuery } from 'react-responsive';
import { SCREEN_WIDTH } from '../../screenSettings';

type TagWithTooltipPropsType = {
  tag: ProvidedTagsType;
};

export const TagWithTooltip: FC<TagWithTooltipPropsType> = ({ tag }) => {
  const isTabletWidth = useMediaQuery({
    query: `(max-width: ${SCREEN_WIDTH.TABLET}px)`,
  });

  const isMobileWidth = useMediaQuery({
    query: `(max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px)`,
  });

  return (
    <StyledTagWithIcon>
      {tag.description ? (
        <IconWithTooltip
          icon={
            <StyledTag
              icon={null}
              bordered={false}
              color={tag.positive ? 'success' : undefined}
            >
              {tag.label}
            </StyledTag>
          }
          description={tag.description}
          customTrigger={isTabletWidth ? ['click'] : ['hover']}
          customPopoverWidth={(isMobileWidth && 200) || 240}
          placement='topRight'
          align={isMobileWidth ? undefined : { offset: [4, -13] }}
        />
      ) : (
        <StyledTag
          icon={null}
          bordered={false}
          color={tag.positive ? 'success' : undefined}
        >
          {tag.label}
        </StyledTag>
      )}
    </StyledTagWithIcon>
  );
};
