import {FC, PropsWithChildren, CSSProperties} from "react";
import {StyledParagraph} from "./StyledParagraph";
import {BaseType} from "antd/es/typography/Base";

interface ParagraphProps {
  style?: CSSProperties;
  type?: BaseType | undefined;
}

export const MyParagraph: FC<PropsWithChildren<ParagraphProps>> = ({children, style, type, ...props}) => {
  return <StyledParagraph type={type} style={{margin: 0, ...style}} {...props}>{children}</StyledParagraph>;
};