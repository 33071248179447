import styled from 'styled-components';
import background from '../../img/background.jpg';
import { SCREEN_WIDTH } from '../../screenSettings';

export const StyledHomePage = styled.div`
  position: relative;
  min-height: calc(100vh - 80px);
  background-size: cover;
  background-position: center;

  background-image: linear-gradient(
      16deg,
      rgba(0, 0, 0, 0) 74.12%,
      rgba(0, 0, 0, 0.6) 108.12%
    ),
    linear-gradient(
      4deg,
      rgba(204, 234, 255, 0.5) -46.64%,
      rgba(90, 157, 255, 0.5) -46.63%,
      rgba(0, 88, 183, 0.45) -6.23%,
      rgba(0, 42, 126, 0.45) 36.51%,
      rgba(0, 0, 0, 0.5) 103.98%
    ),
    url(${background});
  background-color: lightgray;
  background-repeat: no-repeat;

  @media only screen and (max-width: ${SCREEN_WIDTH.DESKTOP}px) {
    background-position: 76% -190px;
    padding-bottom: 48px;
  }

  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET}px) {
    background-position: 65% -240px;
  }

  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
    padding-bottom: 25px;
    background-size: 873px;
    background-position: 57% -35%;
  }
`;

export const StyledAdditionalRectangle = styled.div`
  position: absolute;
  bottom: 0;
  z-index: 0;
  width: 100%;
  height: 0;
  background: linear-gradient(202deg, #0c1f48 32.85%, #1d4bae 139.85%);
  backdrop-filter: blur(2px);

  @media only screen and (max-width: ${SCREEN_WIDTH.DESKTOP}px) {
    height: 321px;
  }

  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
    height: 536px;
  }
`;

export const StyledHomeContainer = styled.div`
  width: 100%;
  padding: 0 16px;
`;

export const StyledHomeContent = styled.div`
  max-width: ${SCREEN_WIDTH.MAX_WIDTH_APP}px;
  width: 100%;
  margin: 0 auto;
`;

export const StyledHomeContentWrapper = styled.div`
  padding-top: 147px;
  display: flex;
  justify-content: space-between;
  gap: 16px;

  @media only screen and (max-width: ${SCREEN_WIDTH.DESKTOP}px) {
    flex-direction: column;
    gap: 238px;
  }

  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
    padding-top: 69px;
    gap: 69px;
  }
`;

export const StyledFormContainer = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 530px;

  @media only screen and (max-width: ${SCREEN_WIDTH.DESKTOP}px) {
    max-width: 100%;
  }
`;

export const StyledTextContainer = styled.div`
  color: var(--White);
  max-width: 607px;
`;

export const StyledTitle = styled.h1`
  font-size: 64px;
  font-weight: 800;
  line-height: 80px;
  letter-spacing: -0.64px;
  text-transform: uppercase;
  margin-bottom: 32;

  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
    font-size: 34px;
    font-weight: 800;
    line-height: 38px;
    letter-spacing: -0.34px;
  }
`;

export const StyledSubTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const StyledSubTitle = styled.h2`
  position: relative;
  font-size: 18px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -0.18px;
  margin-bottom: 0;
  padding-left: 16px;

  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.12px;
    padding-left: 15px;
  }

  ::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background-color: var(--White);
  }
`;
