import styled from 'styled-components';
import { StyledTitle } from '../../../../components/styled/StyledTitle';
import { FC, PropsWithChildren } from 'react';

interface CarsListHeaderProps {
  style?: React.CSSProperties;
}

const StyledCarsListHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: nowrap;
`;

const CarsListHeader: FC<PropsWithChildren<CarsListHeaderProps>> = ({
  style,
  children,
}) => {
  return (
    <StyledCarsListHeader style={{ ...style }}>
      <StyledTitle level={1}>{children}</StyledTitle>
    </StyledCarsListHeader>
  );
};

export default CarsListHeader;
