import styled from 'styled-components';
import { SCREEN_WIDTH } from '../../screenSettings';

export const StyledSubscriptionIcon = styled.div<{
  correctposition: boolean;
}>`
  position: absolute;
  z-index: 10;
  top: ${({ correctposition }) => (correctposition ? '50%' : '0')};
  ${({ correctposition }) => correctposition && 'transform: translateY(-50%);'}
  right: 2%;

  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET}px) {
    right: 1%;
  }
  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_1}px) {
    right: ${({ correctposition }) => (correctposition ? '1%' : '3%')};
    top: ${({ correctposition }) => (correctposition ? '50%' : '1%')};
  }
  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
    right: ${({ correctposition }) => (correctposition ? '2%' : '4%')};
    top: ${({ correctposition }) => (correctposition ? '80%' : '0')};
  }
`;
