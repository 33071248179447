export const ArrowDownSvg = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4.78876 6.05527C4.30345 5.66474 3.61497 5.5856 3.25099 5.87849C2.88702 6.17138 2.98537 6.7254 3.47068 7.11593L6.98556 9.94435C7.28835 10.188 7.67023 10.3104 8.00304 10.297C8.33597 10.3106 8.71808 10.1882 9.02102 9.94437L12.5359 7.11595C13.0212 6.72542 13.1196 6.1714 12.7556 5.87851C12.3916 5.58562 11.7031 5.66476 11.2178 6.05529L8.0033 8.64201L4.78876 6.05527Z'
      fill='currentColor'
    />
  </svg>
);
