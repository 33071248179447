import { FC } from 'react';
import { StyledHeaderAntMenu } from './HeaderOptionMenu.styled';
import { menuItems } from '../../data/ProfileHeaderMenuItems';
import type { MenuProps } from 'antd';
import { ConfigProvider } from 'antd';
import { useProfileSelector } from '../../store/selectors';
import { useHandleMenuClick } from '../../hooks/profile';

type HeaderOptionMenuPropsType = {
  additionalActionClick?: () => void;
  additionalMarginTopDivider?: number;
};

export const HeaderOptionMenu: FC<HeaderOptionMenuPropsType> = ({
  additionalActionClick,
  additionalMarginTopDivider,
}) => {
  const { activeSubMenuKey } = useProfileSelector();
  const { onClickHandler } = useHandleMenuClick();

  const onMenuClickHandler: MenuProps['onClick'] = ({ key }) => {
    if (additionalActionClick) {
      additionalActionClick();
    }
    onClickHandler(key);
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Menu: {
            activeBarHeight: 0,
            activeBarBorderWidth: 0,
            itemActiveBg: '#E1E7F0',
            itemBorderRadius: 6,
            itemColor: '#192A4D',
            itemSelectedColor: '#192A4D',
            itemSelectedBg: '#E1E7F0',
            subMenuItemBg: 'transparent',
            subMenuItemBorderRadius: 6,
            itemMarginInline: 0,
            itemPaddingInline: 0,
            itemMarginBlock: 0,
          },
        },
      }}
    >
      <StyledHeaderAntMenu
        selectedKeys={[activeSubMenuKey]}
        divedermargintop={additionalMarginTopDivider}
        inlineIndent={8}
        items={menuItems}
        mode='inline'
        onClick={onMenuClickHandler}
      />
    </ConfigProvider>
  );
};
