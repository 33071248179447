import { FC } from 'react';
import { useAppContext } from '../../../hooks/app';
import { Form, Input } from 'antd';
import { useResetPasswordMutation } from '../../../store/auth/auth.api';
import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'qs';
import { StyledTitle } from '../styled/StyledTitle';
import { StyledFormItem } from '../styled/StyledFormItem';
import { APP_PATHS, NAVIGATE_PATHS } from '../../../layout/settingsLayout';
import { StyledButtonWrapper } from '../styled/StyledButtonWrapper';
import { StyledSubmitFormItem } from '../styled/StyledSubmitFormItem';
import { ReserPasswordFormField } from './ResetPassword.types';
import { FormItemLabel } from '../../../components/FormItemLabel';
import { AuthLayout } from '../components/AuthLayout/AuthLayout';
import { AUTH_ERROR_MESSAGES, AUTH_SUCCESS_MESSAGES } from '../data';
import { formSettings } from '../../../config';
import { useAuthErrorNotification } from '../hooks';
import { StyledResponsiveButton } from '../../../components/Buttons/StyledResponsiveButton';
import { commonValidationRules } from '../../../data/commonValidationRules';
import {
  FormFiledLabel,
  FormFieldName,
  FormPlaceholder,
} from '../../../enums/form';
import { config } from '../../../config';
import { commonMetadata } from '../../../seo';
import { Metadata } from '../../../components/Metadata';

export const ResetPassword: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const metaData = commonMetadata[APP_PATHS.RESET_PASSWORD];
  const { apiNotification } = useAppContext();
  const authErrorNotification = useAuthErrorNotification();
  const { userId, code } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const [resetPassword, { isLoading: isLoadingResetPassword }] =
    useResetPasswordMutation();

  const handleSubmit = async (values: ReserPasswordFormField) => {
    try {
      if (!userId || !code) throw new Error('Неверный userId и code');

      await resetPassword({
        userId,
        token: code,
        password: values.password,
        repeatPassword: values.repeatPassword,
      }).unwrap();

      apiNotification.success({
        message: AUTH_SUCCESS_MESSAGES.NEW_PASSWORD_CREATED,
        description: `Пожалуйста, войдите в аккаунт`,
        duration: config.DEFAULT_NOTIFICATION_DURATION,
      });

      navigate(NAVIGATE_PATHS.SIGN_IN);
    } catch (error: any) {
      authErrorNotification(AUTH_ERROR_MESSAGES.PASSWORD_RESET, error);
    }
  };

  return (
    <>
      <Metadata
        title={metaData.title}
        description={metaData.description}
      />

      <AuthLayout>
        <StyledTitle>Cброс пароля</StyledTitle>
        <Form
          layout='vertical'
          name='forgotPasswordForm'
          initialValues={{ remember: true }}
          onFinish={handleSubmit}
          autoComplete='off'
          requiredMark={false}
        >
          <StyledFormItem
            name={FormFieldName.Password}
            label={<FormItemLabel>{FormFiledLabel.NewPassword}</FormItemLabel>}
            rules={commonValidationRules.password}
            hasFeedback
          >
            <Input.Password
              placeholder={FormPlaceholder.Password}
              size={formSettings.controlSize}
            />
          </StyledFormItem>

          <StyledFormItem
            name={FormFieldName.RepeatPassword}
            label={
              <FormItemLabel>{FormFiledLabel.RepeatPassword}</FormItemLabel>
            }
            dependencies={[FormFieldName.Password]}
            hasFeedback
            rules={commonValidationRules.repeatPassword}
          >
            <Input.Password
              placeholder={FormPlaceholder.RepeatPassword}
              size={formSettings.controlSize}
            />
          </StyledFormItem>

          <StyledButtonWrapper>
            <StyledSubmitFormItem>
              <StyledResponsiveButton
                size={formSettings.controlSize}
                type='primary'
                htmlType='submit'
                loading={isLoadingResetPassword}
              >
                Сохранить
              </StyledResponsiveButton>
            </StyledSubmitFormItem>
          </StyledButtonWrapper>
        </Form>
      </AuthLayout>
    </>
  );
};
