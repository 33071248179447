import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { StyledWrapper } from './CarsNotFound.styles';
import { StyledButton } from '../../../../components/styled/StyledButton';
import { APP_PATHS } from '../../../../layout/settingsLayout';
import { CustomAntResult } from '../../../../components/CustomAntResult';
import { getTelegramLink } from '../../../../utils/getTelegramLink';
import {
  useDomainSelector,
  useAuthSelector,
  useProfileSelector,
} from '../../../../store/selectors';
import { DomainExtension } from '../../../../enums/domain';

type CarsNotFoundPropsType = {
  needLinkMessenger?: boolean;
};

export const CarsNotFound: FC<CarsNotFoundPropsType> = ({
  needLinkMessenger = false,
}) => {
  const navigate = useNavigate();
  const { userId } = useAuthSelector();
  const { domainExtension } = useDomainSelector();
  const { currency } = useProfileSelector();

  const TGLink = getTelegramLink(
    userId as string,
    domainExtension as DomainExtension,
    currency,
  );

  const handleFollowLink = (url: string) => {
    window.open(url, '_blank');
  };

  const handleNavigateToSearch = () => {
    if (needLinkMessenger) {
      handleFollowLink(TGLink);
    } else {
      navigate(APP_PATHS.CARS_PAGE);
    }
  };

  const title = needLinkMessenger
    ? 'Ой!\u00A0Кажется, ваш\u00A0Телеграм аккаунт не\u00A0привязан!'
    : 'Объявления не\u00A0добавлены!';
  const subtitle = needLinkMessenger
    ? 'После привязки аккаунта, не\u00A0забудьте обновить\u00A0страницу.'
    : `У\u00A0вас\u00A0нет ни\u00A0одной активной подписки.`;

  return (
    <StyledWrapper>
      <CustomAntResult status='404' title={title} subTitle={subtitle} />

      <StyledButton
        onClick={handleNavigateToSearch}
        type='primary'
        size='large'
      >
        {needLinkMessenger ? 'Привязать аккаунт' : 'Вернуться к объявлениям'}
      </StyledButton>
    </StyledWrapper>
  );
};
