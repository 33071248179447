import { createContext } from 'react';
import { ICarFeature } from '../../models';

export type FeaturesDrawerContextType = {
  isOpenDrawer: boolean;
  header: string;
  setHeader: (header: string) => void;
  features: ICarFeature[];
  setFeatures: (features: ICarFeature[]) => void;
  openDrawer: () => void;
  closeDrawer: () => void;
};

const initialContext: FeaturesDrawerContextType = {
  isOpenDrawer: false,
  header: '',
  setHeader: (header) => {},
  features: [],
  setFeatures: (features) => {},
  openDrawer: () => {},

  closeDrawer: () => {},
};

export const FeaturesDrawerContext =
  createContext<FeaturesDrawerContextType>(initialContext);
