import { FC } from 'react';
import { Switch, Spin, Divider } from 'antd';
import { DeleteSubscriptionBlock } from '../DeleteSubscriptionBlock';
import { SelectedCarByFilters } from '../../contexts';
import {
  CheckOutlined,
  CloseOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import {
  StyledSwitcherWrapper,
  StyledDescription,
} from './SwitcherWithDescription.styles';
import { useChangeStatusFiltersNotification } from '../../hooks/subscriptions';

type SwitcherWithDescriptionProps = {
  description: string;
  defaultChecked?: boolean;
  loading?: boolean;
  isDeleted?: boolean;
  descriptionForDeleteBlock?: SelectedCarByFilters;
  showDivider?: boolean;
};

export const SwitcherWithDescription: FC<SwitcherWithDescriptionProps> = ({
  description,
  defaultChecked,
  loading,
  isDeleted,
  showDivider,
  descriptionForDeleteBlock,
}) => {
  const { contextHolder, isChangingStatus, changeStatusNotificationByFilter } =
    useChangeStatusFiltersNotification();

  const handleSwitcherChange = (checked: boolean) => {
    if (descriptionForDeleteBlock) {
      changeStatusNotificationByFilter({
        hash: descriptionForDeleteBlock.hash,
        isActive: checked,
      });
    }
  };

  return (
    <>
      <StyledSwitcherWrapper>
        {contextHolder}
        <Spin
          spinning={loading}
          indicator={<LoadingOutlined spin />}
          size='small'
        >
          {!loading && (
            <Switch
              disabled={isChangingStatus}
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              defaultChecked={defaultChecked}
              onChange={handleSwitcherChange}
            />
          )}
        </Spin>
        <StyledDescription>{description}</StyledDescription>
        {isDeleted && (
          <DeleteSubscriptionBlock
            filtersItem={descriptionForDeleteBlock}
            changeColorOnHover={true}
            forSubscriptionsByFilter={true}
          />
        )}
      </StyledSwitcherWrapper>
      {showDivider && <Divider type='horizontal' />}
    </>
  );
};
