import { bindActionCreators } from '@reduxjs/toolkit';
import { useAppDispatch } from './redux';
import { carsActions } from '../store/cars/cars.slice';
import { authActions } from '../store/auth/auth.slice';
import { profileActions } from '../store/profile/profile.slice';
import { domainActions } from '../store/domain/domain.slice';
import { subscriptionActions } from '../store/subscription/subscription.slice';
import { companiesActions } from '../store/companies/companies.slice';
import { carPageActions } from '../store/car/car.page.slice';
import { turnKeyActions } from '../store/turn-key/turn-key.slice';
import { turnKeyFormsActions } from '../store/turn-key/turn-key-form.slice';
import { appGeneralActions } from '../store/app-general/app.slice';
import { turnKeyStepsActions } from '../store/turn-key/turn-key-steps.slice';

const actions = {
  ...carsActions,
  ...authActions,
  ...profileActions,
  ...domainActions,
  ...subscriptionActions,
  ...companiesActions,
  ...carPageActions,
  ...turnKeyActions,
  ...turnKeyFormsActions,
  ...appGeneralActions,
  ...turnKeyStepsActions,
};

export const useActions = () => {
  const dispatch = useAppDispatch();
  return bindActionCreators(actions, dispatch);
};
