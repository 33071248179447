import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  SearchParamsFormFieldsType,
  EquipmentFormFieldsType,
  DeliveryFormFiledsType,
} from '../../pages/CarTurnKeyPage/components/TurnKeyForms/types/form';
import { EquipmentFormFieldNames } from '../../enums/form';

type OptionsEquipmentType = Record<`${EquipmentFormFieldNames}`, number[]>;

type InitialState = {
  searchParamsForm: SearchParamsFormFieldsType;
  equipmentForm: EquipmentFormFieldsType;
  deliveryForm: DeliveryFormFiledsType;
  mobileInitialCategories: OptionsEquipmentType;
};

const initialState: InitialState = {
  searchParamsForm: {} as SearchParamsFormFieldsType,
  equipmentForm: {} as EquipmentFormFieldsType,
  deliveryForm: {} as DeliveryFormFiledsType,
  mobileInitialCategories: {} as OptionsEquipmentType,
};

export const turnKeyFormsSlice = createSlice({
  name: 'turnKeyForms',
  initialState,
  reducers: {
    setSearchParamsForm: (
      state,
      { payload }: PayloadAction<SearchParamsFormFieldsType>,
    ) => {
      state.searchParamsForm = payload;
    },
    setEquipmentForm: (
      state,
      { payload }: PayloadAction<EquipmentFormFieldsType>,
    ) => {
      state.equipmentForm = payload;
    },
    setMobileInitialCategories: (
      state,
      { payload }: PayloadAction<OptionsEquipmentType>,
    ) => {
      state.mobileInitialCategories = payload;
    },
    setDeliveryForm: (
      state,
      { payload }: PayloadAction<DeliveryFormFiledsType>,
    ) => {
      state.deliveryForm = payload;
    },
    resetTurnKeyFormsState: () => {
      return initialState;
    },
  },
});

export const turnKeyFormsActions = turnKeyFormsSlice.actions;
export const turnKeyFormsReducer = turnKeyFormsSlice.reducer;
