import { FC, PropsWithChildren } from 'react';
import { Button, ConfigProvider, ButtonProps } from 'antd';
import styled from 'styled-components';

interface IPrimaryButton extends ButtonProps {
  backgroundColorDefault?: string;
  colorDefault?: string;
}

const StyledPrimaryButton = styled(Button)`
  text-transform: uppercase;
  font-weight: 600;
  border: 0;
  box-shadow: none;
  &:disabled {
  }
`;

export const PrimaryButton: FC<PropsWithChildren<IPrimaryButton>> = ({
  backgroundColorDefault,
  colorDefault,
  children,
  ...antStandardProps
}) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          fontSizeLG: 14,
        },
        components: {
          Button: {
            borderRadius: 8,
            fontSize: 14,
            colorBgContainer: backgroundColorDefault || '#F5FCFF',
            colorText: colorDefault,
          },
        },
      }}
    >
      <StyledPrimaryButton size='large' {...antStandardProps}>
        {children}
      </StyledPrimaryButton>
    </ConfigProvider>
  );
};
