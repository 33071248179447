import styled from 'styled-components';
import { Swiper } from 'swiper/react';

export const StyledSwiper = styled(Swiper)`
  position: relative;
  overflow-y: visible;

  .swiper-wrapper {
    padding: 0 3px;
  }
`;

export const StyledSwiperControlsContainer = styled.div`
  display: flex;
  gap: 16px;
  padding: 0 10px;
  position: absolute;
  top: -35px;
  right: 0px;
  z-index: 1;

  button:first-child {
    transform: rotate(180deg);
  }
`;

export const StyledSimilarAdsContainer = styled.div`
  margin-bottom: 56px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const StyledTitleSimilarAdsContainer = styled.div`
  margin: 8px 0 24px 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;
