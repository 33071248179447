import { AlertProps } from 'antd';
import { FC, ReactNode } from 'react';
import {
  IconWrapper,
  StyledAlertContent,
  StyledAlert,
} from './AlertInfo.styled';
import { StyledAntdInfoCircleOutlined } from '../IconWithTooltip/IconWithTooltip.styled';

interface AlertInfoProps extends AlertProps {
  content: ReactNode;
}

export const AlertInfo: FC<AlertInfoProps> = ({ content, ...props }) => {
  return (
    <StyledAlert
      message={
        <StyledAlertContent>
          <IconWrapper>
            <StyledAntdInfoCircleOutlined />
          </IconWrapper>
          <span>{content}</span>
        </StyledAlertContent>
      }
      type='info'
      {...props}
    />
  );
};
