import { Checkbox } from 'antd';
import { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';

const StyledCheckboxGroupItem = styled(Checkbox)`
  height: 40px;
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 0 12px;
  margin: 0 0 2px 0 !important;

  span {
    top: 0; // reset default style
  }
`;

export const CheckboxGroupItem: FC<PropsWithChildren<{ value: any }>> = ({
  children,
  value,
}) => {
  return (
    <StyledCheckboxGroupItem value={value}>{children}</StyledCheckboxGroupItem>
  );
};
