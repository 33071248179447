import styled from 'styled-components';

export const StyledTitle = styled.p`
  margin-bottom: 16px;
  display: inline-block;
  color: var(--Dark-13);
  font-style: var(--font-style-base);
  font-weight: var(--bold-font-weight);
  line-height: var(--desktop-content-line-height);
  font-size: var(--mobile-title-size);
  letter-spacing: 0.08px;
`;

export const StyledFeaturesList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 18px;
`;
