import { FC } from 'react';
import {
  StyledFeaturesWrapper,
  StyledGroupedWrapper,
  StyledCount,
  StyledArrowRightIcon,
} from './CarFeatureClickableTitle.styled';
import { ICarFeature } from '../../models';
import { useFeaturesDrawerContext } from '../../hooks/car';
import { TitleWithIcon } from '../TitleWithIcon';
import { getTitleWithIconByCategory } from '../../utils/getTitleWithIconByCategory';

type CarFeatureClickableTitleProps = {
  categoryId: number;
  groupedFeatures?: ICarFeature[];
  customAction?: () => void;
  customCount?: number;
};

export const CarFeatureClickableTitle: FC<CarFeatureClickableTitleProps> = ({
  categoryId,
  groupedFeatures = [],
  customAction,
  customCount,
}) => {
  const { openDrawer, setFeatures, setHeader } = useFeaturesDrawerContext();
  const { description } = getTitleWithIconByCategory(categoryId);

  const handleClick = () => {
    if (customAction) {
      customAction();
      return;
    }

    setFeatures(groupedFeatures);
    setHeader(description);
    openDrawer();
  };

  return (
    <StyledFeaturesWrapper key={categoryId} onClick={handleClick}>
      <TitleWithIcon categoryID={categoryId} smallerDescription={true} />
      <StyledGroupedWrapper>
        <StyledCount>{customCount || groupedFeatures.length}</StyledCount>
        <StyledArrowRightIcon />
      </StyledGroupedWrapper>
    </StyledFeaturesWrapper>
  );
};
