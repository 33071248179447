import styled from 'styled-components';
import { SCREEN_WIDTH } from '../../../../screenSettings';

export const StyledWrapperCard = styled.div`
  min-height: 800px;
  border-radius: 8px;
  background: var(--White);
  width: 100%;
  max-width: calc(100% - 280px - 16px);

  @media screen and (max-width: ${SCREEN_WIDTH.DESKTOP}px) {
    max-width: 100%;
  }

  @media screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
    border-radius: 0;
  }
`;
