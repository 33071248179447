import { FC, ReactNode } from 'react';
import { Switch, Spin } from 'antd';
import {
  CheckOutlined,
  CloseOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { ToggleSubscriptionState } from '../../enums/carSubscriptions';
import {
  StyledSwitcherWrapper,
  StyledDescription,
} from './NotificationTGSwitcher.styles';
import { useToggleTGNotification } from '../../hooks/subscriptions';

type SwitcherWithDescriptionProps = {
  description: string | ReactNode;
  loading?: boolean;
  defaultChecked?: boolean;
};

export const NotificationTGSwitcher: FC<SwitcherWithDescriptionProps> = ({
  description,
  loading,
  defaultChecked,
}) => {
  const {
    isLoading: isTGLoading,
    contextHolder,
    changeTGNotification,
  } = useToggleTGNotification();

  const handleSwitcherChange = (checked: boolean) => {
    changeTGNotification({
      action: checked
        ? ToggleSubscriptionState.Enable
        : ToggleSubscriptionState.Disable,
    });
  };

  return (
    <StyledSwitcherWrapper>
      {contextHolder}
      <Spin
        spinning={loading}
        indicator={<LoadingOutlined spin />}
        size='small'
      >
        {!loading && (
          <Switch
            disabled={isTGLoading}
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            defaultChecked={defaultChecked}
            onChange={handleSwitcherChange}
          />
        )}
      </Spin>
      <StyledDescription>{description}</StyledDescription>
    </StyledSwitcherWrapper>
  );
};
