import { SelectOptions } from './enums';
import { SizeType } from 'antd/es/config-provider/SizeContext';

export const config = {
  FORM: {
    INVALID_VALUES: [undefined, null, SelectOptions.any, 0, '0', ''],
    DEBOUNCE: 500,
  },
  CARS_LIST_PAGE: {
    ITEMS_PER_PAGE: 20,
  },
  DEFAULT_NOTIFICATION_DURATION: 3,
  PROFILE_NOTIFICATION_DURATION: 30,
  SUBSCRIPTION_DELETE_DURATION: 10,
  MONITORING_SUBSCRIPTION_DURATION: 10,
  SUBSCRIPTION_TG_NOTIFICATION_DURATION: 10,
  APP_LEVEL_NOTIFICATION_DURATION: 10,
  DEFAULT_NOTIFICATION_PLACEMENT: 'topRight' as const,
  ALERT_DURATION: 2000,
  GOOGLE_TRANSLATE_MAX_LENGTH: 5000,
  POPULAR_FEATURES_LIMIT: 10,
  TRANSLATE_GOOGLE_URL: 'https://translate.google.com/?sl=auto&tl=ru&',
};

export const formSettings = {
  controlSize: 'large' as SizeType,
};
