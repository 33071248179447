import { useCallback } from 'react';
import { ToggleTelegramNotificationsRequest } from '../../models/response/subscriptionsCars';
import { useToggleTelegramNotificationMutation } from '../../store/subscription/subscription.api';
import { notification } from 'antd';
import { config } from '../../config';

export const useToggleTGNotification = () => {
  const [toggleNotification, { isLoading }] =
    useToggleTelegramNotificationMutation();

  const [notificationApi, contextHolder] = notification.useNotification();

  const changeTGNotification = useCallback(
    async (body: ToggleTelegramNotificationsRequest) => {
      try {
        await toggleNotification(body).unwrap();
      } catch (err: unknown) {
        console.error(err);

        notificationApi.error({
          message: 'Ошибка!',
          description:
            'Возникла непредвиденная ошибка при изменении статуса телеграм уведомлений.',
          duration: config.SUBSCRIPTION_TG_NOTIFICATION_DURATION,
        });
      }
    },
    [notificationApi, toggleNotification],
  );

  return { isLoading, contextHolder, changeTGNotification };
};
