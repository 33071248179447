import { Checkbox, Form } from 'antd';
import { FC, useEffect } from 'react';
import { FilterCellSingle } from '../../../../components/MobileFormElements';
import {
  PaymentSettingsFormLabel,
  PaymentSettingsFormNames,
  PaymentSettingsFormPlaceholder,
} from '../../CarPage.enum';
import {
  StyledForm,
  StyledMobileFormItem,
} from './MobileCalculateDeliveryForm.styled';
import {
  useCarPageSelector,
  useCompaniesSelector,
} from '../../../../store/selectors';
import {
  useControlledFormControls,
  useHandleCompanyChange,
  useSetDefaultCompany,
} from '../../hooks';
import { destinationRegionIdMapping } from '../../../../data/mappings';
import { CalculateDeliveryForm } from '../../../../models/car/CalculateDelivery';
import { useActions } from '../../../../hooks/useActions';
import { useOutletContext } from 'react-router-dom';
import { CarPageOutletContext } from '../../CarPage.interface';
import { FormItemLabel } from '../../../../components/FormItemLabel';
import { StyledFormItem } from '../../../../components/styled/StyledFormItem';

interface MobileCalculateDeliveryFormProps {
  onlyCarInspection: boolean;
}

export const MobileCalculateDeliveryForm: FC<
  MobileCalculateDeliveryFormProps
> = ({ onlyCarInspection }) => {
  const { handleCarInspectionRequiredChange, handleCustomsClearanceChange } =
    useControlledFormControls();
  const { calculateDeliveryForm } = useCarPageSelector();
  const { defaultDeliveryCompany, car } =
    useOutletContext<CarPageOutletContext>();
  const [mobileCalculateDeliveryForm] = Form.useForm<CalculateDeliveryForm>();
  const { setSelectedDeliveryValues } = useActions();
  const { companies } = useCompaniesSelector();
  const availableCompanies = companies.filter((company) =>
    company.supportedRoutes.find(
      (route) => route.originRegionId === car.regionId,
    ),
  );
  const companyId = Form.useWatch(
    PaymentSettingsFormNames.Company,
    mobileCalculateDeliveryForm,
  );
  const destinationRegionId = Form.useWatch(
    PaymentSettingsFormNames.Delivery,
    mobileCalculateDeliveryForm,
  );
  const { deliverySelectValues, handleCompanyChange } = useHandleCompanyChange(
    mobileCalculateDeliveryForm,
    companyId,
  );

  useSetDefaultCompany(defaultDeliveryCompany, mobileCalculateDeliveryForm);

  useEffect(() => {
    setSelectedDeliveryValues({
      companyId,
      destinationRegionId,
    });
  }, [companyId, destinationRegionId]);

  return (
    <StyledForm
      form={mobileCalculateDeliveryForm}
      name='mobileCalculateDeliveryForm'
      autoComplete='off'
    >
      <StyledMobileFormItem
        style={{ display: onlyCarInspection ? 'none' : 'block' }}
      >
        <FilterCellSingle
          style={{ marginBottom: 0 }}
          form={mobileCalculateDeliveryForm}
          name={PaymentSettingsFormNames.Delivery}
          placeholder={PaymentSettingsFormPlaceholder.Delivery}
          value={destinationRegionId}
          disabled={deliverySelectValues && deliverySelectValues.length <= 1}
          options={deliverySelectValues?.map((deliveryValue) => ({
            value: deliveryValue.destinationRegionId,
            label:
              destinationRegionIdMapping[deliveryValue.destinationRegionId],
          }))}
          label={PaymentSettingsFormLabel.Delivery}
        />
      </StyledMobileFormItem>
      <StyledMobileFormItem>
        <FilterCellSingle
          style={{ marginBottom: 0 }}
          form={mobileCalculateDeliveryForm}
          name={PaymentSettingsFormNames.Company}
          placeholder={PaymentSettingsFormPlaceholder.Company}
          value={companyId}
          disabled={availableCompanies.length <= 1}
          options={availableCompanies?.map((company) => ({
            value: company.id,
            label: company.name,
          }))}
          label={PaymentSettingsFormLabel.Company}
          callback={handleCompanyChange}
        />
      </StyledMobileFormItem>

      {!onlyCarInspection && (
        <div>
          <StyledFormItem name={PaymentSettingsFormNames.CarInspectionRequired}>
            <FormItemLabel>
              <Checkbox
                checked={calculateDeliveryForm.carInspectionRequired}
                onChange={handleCarInspectionRequiredChange}
              >
                {PaymentSettingsFormLabel.CarInspectionRequired}
              </Checkbox>
            </FormItemLabel>
          </StyledFormItem>

          <StyledFormItem
            name={PaymentSettingsFormNames.hasCustomsClearanceBenefit}
          >
            <FormItemLabel>
              <Checkbox
                checked={calculateDeliveryForm.hasCustomsClearanceBenefit}
                onChange={handleCustomsClearanceChange}
              >
                {PaymentSettingsFormLabel.hasCustomsClearanceBenefit}
              </Checkbox>
            </FormItemLabel>
          </StyledFormItem>
        </div>
      )}
    </StyledForm>
  );
};
