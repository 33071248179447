import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAuthState } from '../../models/auth';

const initialState: IAuthState = {
  accessToken: null,
  refreshToken: null,
  role: null,
  username: null,
  userId: null,
  email: null,
  expires: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth: (state, action: PayloadAction<IAuthState>) => {
      Object.assign(state, action.payload);
    },
    logOut: (state) => {
      Object.keys(state).forEach((key) => {
        state[key as keyof IAuthState] = null;
      });
    },
    updateUserName: (state, { payload }: PayloadAction<string>) => {
      state.username = payload;
    },
    getRefreshToken: () => {},
  },
});

export const authActions = authSlice.actions;
export const authReducer = authSlice.reducer;
