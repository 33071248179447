import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const StyledLinkButton = styled(Link)`
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border: none;
  border-radius: 4px;
  color: var(--Blue-12);
  background-color: #fff;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
  text-transform: uppercase;
  transition: all ease-in 0.3s;
  position: relative;

  &:hover,
  &:target,
  &:focus {
    cursor: pointer;
    box-shadow: 0 0.5em 0.5em -0.4em var(--Blue-12);
    transform: translateY(-0.2em);
  }
  &:active {
    box-shadow: 0 0 0 2em transparent;
    border-color: var(--Blue-12);
    animation: pulse 1s;
  }

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 var(--Blue-12);
    }
  }
`;
