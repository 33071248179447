import { useCallback } from 'react';
import { NotificationInstance } from 'antd/es/notification/interface';
import { subscriptionApi } from '../../store/subscription/subscription.api';
import { useNavigate } from 'react-router-dom';
import { useIsUserAuth } from '../auth/useIsUserAuth';
import { NAVIGATE_PATHS } from '../../layout/settingsLayout';
import { useAppSelector } from '../redux';
import { SubscriptionModelWindow } from '../../components/SubscriptionModelWindow/SubscriptionModelWindow';
import { config } from '../../config';
import { useSubscriptionError } from './useSubscriptionError';
import { ICarDetailsBase } from '../../models';
import { useAppContext } from '../app';
import { SubscriptionType } from '../../enums/subscription';
import ReactGA from 'react-ga4';
import { GoogleAnanalyticsEvent } from '../../enums/googleAnalyticsAction';

export const useOfferSubscription = () => {
  const navigate = useNavigate();
  const { apiNotification } = useAppContext();
  const isAuth = useIsUserAuth();
  const { userId } = useAppSelector((state) => state.auth);
  const handleSubscriptionError = useSubscriptionError(userId);
  const [subscriptionOffer, { isLoading }] =
    subscriptionApi.useSubscriptionOfferMutation();

  const handleSubscription = useCallback(
    async (details: ICarDetailsBase) => {
      if (process.env.NODE_ENV === 'production') {
        ReactGA.event({
          category: 'button',
          action: GoogleAnanalyticsEvent.AddOfferSubscription,
          label: window.location.href,
        });
      }

      if (!isAuth) {
        navigate(NAVIGATE_PATHS.SIGN_IN);
        return;
      }

      try {
        await subscriptionOffer(details).unwrap();

        apiNotification.success({
          message: 'Подписка успешно добавлена!',
          description: (
            <SubscriptionModelWindow
              subscribtionType={SubscriptionType.Offer}
              content='Telegram уведомит Вас в случае изменений по объявлению.'
            />
          ),
          duration: config.DEFAULT_NOTIFICATION_DURATION,
        });
      } catch (error: any) {
        const props = handleSubscriptionError(error, SubscriptionType.Offer);
        apiNotification[props.type as keyof NotificationInstance]({
          ...props,
        } as any);
      }
    },
    [
      apiNotification,
      handleSubscriptionError,
      isAuth,
      navigate,
      subscriptionOffer,
    ],
  );

  return { isLoading, handleSubscription };
};
