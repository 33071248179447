import { Col } from 'antd';
import { FC, memo } from 'react';
import CarsListHeader from './CarsListHeader';
import { DesktopSortForm } from '../DesktopSortForm/DesktopSortForm';
import { MobileSortForm } from '../MobileSortForm/MobileSortForm';
import { StyledCarList } from './CarsList.styled';
import styled from 'styled-components';
import { StyledTitle } from '../../../../components/styled/StyledTitle';
import { formatNumber } from '../../../../utils/formatNumber';
import { SCREEN_WIDTH } from '../../../../screenSettings';
import { useMediaQuery } from 'react-responsive';

const StyledCount = styled.span`
  line-height: 22px;
  color: var(--Gray-6);
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.024px;
  margin: 2px 0 0 0;
  white-space: nowrap;
`;

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: nowrap;
`;

const StyledTitleCount = styled(StyledTitle)`
  font-size: 14px !important;
`;

interface CarsListHeaderWrapperProps {
  headerListTitle: string;
  currentCount?: number;
}

export const CarsListHeaderWrapper: FC<CarsListHeaderWrapperProps> = memo(
  ({ headerListTitle, currentCount }) => {
    const isTablet = useMediaQuery({
      query: `(max-width: ${SCREEN_WIDTH.TABLET}px)`,
    });

    return (
      <StyledCarList.StyledCarsListHeader>
        {isTablet && (
          <Col style={{ width: '100%' }}>
            <StyledCarList.StyledCarsListMobileHeaderContainer>
              <CarsListHeader>{headerListTitle}</CarsListHeader>
              <MobileSortForm />
              <StyledContainer>
                <StyledTitleCount level={3}>
                  Найдено предложений{' '}
                </StyledTitleCount>
                <StyledCount>{formatNumber(currentCount)}</StyledCount>
              </StyledContainer>
            </StyledCarList.StyledCarsListMobileHeaderContainer>
          </Col>
        )}

        {!isTablet && (
          <Col style={{ width: '100%' }}>
            <StyledCarList.StyledCarsListHeaderContainer>
              <CarsListHeader>{headerListTitle}</CarsListHeader>
              <DesktopSortForm />
            </StyledCarList.StyledCarsListHeaderContainer>
          </Col>
        )}
      </StyledCarList.StyledCarsListHeader>
    );
  },
);
