import { StyledTurnKeyContent } from './CarTurnKeyPage.styled';
import { useEffect } from 'react';
import { TurnKeyTitle } from './components/TurnKeyTitle';
import { TurnKeySteps } from './components/TurnKeySteps';
import { TurnKeyForms } from './components/TurnKeyForms';
import {
  useGetCarOptionsQuery,
  useGetCategoriesQuery,
} from '../../store/turn-key/turn-key.api';
import { Section } from '../../components/Section';
import { useActions } from '../../hooks/useActions';
import { StepsValues } from './enums/stepsValues';
import { TurnKeyCarContextProvider } from '../../contexts/turnKeyCarContext';

export const CarTurnKeyPage = () => {
  const { isLoading: isFetchingCarOptions } = useGetCarOptionsQuery();
  const { isLoading: isFetchingCarCategories } = useGetCategoriesQuery();
  const { resetTurnKeyFormsState, setActiveStep, setErrorStatus } =
    useActions();

  useEffect(() => {
    return () => {
      resetTurnKeyFormsState();
      setActiveStep(StepsValues.SearchParams);
      setErrorStatus(false);
    };
  }, []);

  return (
    <TurnKeyCarContextProvider>
      {/* metadata at TurnKeyForms component */}
      <Section
        style={{ marginTop: 32 }}
        bodyStyle={{ padding: 0 }}
        isFetching={isFetchingCarOptions || isFetchingCarCategories}
        headerContent={<TurnKeyTitle />}
        headerStyle={{ padding: 0 }}
      >
        <StyledTurnKeyContent>
          <TurnKeySteps />
          <TurnKeyForms />
        </StyledTurnKeyContent>
      </Section>
    </TurnKeyCarContextProvider>
  );
};
