import { FC, PropsWithChildren } from 'react';
import { FormItemProps, SelectProps } from 'antd';
import { CheckIcon } from '../../../img/icons/components';
import { CaretDownOutlined } from '@ant-design/icons';
import { SelectOptions } from '../../../enums';
import { FormFieldName } from '../../../enums/form';
import { FormItemLabel } from '../../FormItemLabel';
import { CustomSelect } from '../CustomSelect';

interface MakesModelSelectProps extends SelectProps {
  formItemProps?: FormItemProps;
  modeProps: FormFieldName.Make | FormFieldName.Model;
}

export const MakesModelSelect: FC<PropsWithChildren<MakesModelSelectProps>> = ({
  children,
  formItemProps,
  modeProps,
  ...selectProps
}) => {
  const isMakeMode = modeProps === FormFieldName.Make;

  return (
    <CustomSelect
      formItemProps={{
        shouldUpdate: true,
        name: isMakeMode ? FormFieldName.Make : FormFieldName.Model,
        label: <FormItemLabel>{isMakeMode ? 'Марка' : 'Модель'}</FormItemLabel>,
        rules: [
          { required: true, message: 'Обязательное поле' },
          {
            message: isMakeMode
              ? 'Выберите марку авто'
              : 'Выберите модель авто',
            validator: (_: any, value: string) => {
              if (value === SelectOptions.any) {
                return Promise.reject();
              }

              return Promise.resolve();
            },
            validateTrigger: 'onSubmit',
          },
        ],
        ...formItemProps,
      }}
      size='large'
      menuItemSelectedIcon={<CheckIcon />}
      suffixIcon={<CaretDownOutlined rev='true' />}
      showSearch={true}
      {...selectProps}
    >
      {children}
    </CustomSelect>
  );
};
