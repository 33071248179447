import { api } from '../api/api';
import {
  ServerResponseCarDetails,
  ICarPriceDistributionQueryParams,
  ICarMileageDistributionQueryParams,
  IMedianPricesByRegionRequest,
  ICarDetailsBase,
  CalculateDeliveryPayload,
} from '../../models';
import { CarAvailabilityResponse, ICarDetails } from '../../models/car';
import { transformStringToUppercase } from '../../utils/transformToLowerUpperCase';
import { IMedianPricesByRegionResponse } from '../../models/response/IMedianPricesByRegionsResponse';
import { IMileageDistributionResponse } from '../../models/response/IMileageDistributionResponse';
import { ICarClonesResponse } from '../../models/response/ICarClonesResponse';
import { ICarRecommendationsResponse } from '../../models/response/ICarRecommendationsResponse';
import { IModelPriceDistributionResponse } from '../../models/response/IModelPriceDistributionResponse';
import { CalculateDeliveryResponse } from '../../models/car/CalculateDelivery';
import {
  TurnKeyDeliveryRequest,
  TurnKeyDeliveryResponse,
} from '../../models/car/CarTurnKey';
import {
  AskQuestionPayload,
  BuyRequestPayload,
  CarInspectionRequestPayload,
} from '../../models/car/CarRequests';
import { ApiEndpoints } from '../api/endpoints';

export const carApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCar: build.query<ICarDetails, ICarDetailsBase>({
      query: (carDetailsParams) => {
        const { adId, sourceId, currency } = carDetailsParams;

        return {
          url: ApiEndpoints.Cars,
          params: {
            adId,
            sourceId,
            currency: transformStringToUppercase(currency),
          },
        };
      },
      transformResponse: (response: ServerResponseCarDetails) =>
        response.details,
    }),
    getCarClones: build.query<ICarClonesResponse, ICarDetailsBase>({
      query: (carDetailsParams) => {
        const { adId, sourceId, currency } = carDetailsParams;

        return {
          url: ApiEndpoints.CarClones,
          params: {
            adId,
            sourceId,
            currency: transformStringToUppercase(currency),
          },
        };
      },
    }),
    getCarRecommendations: build.query<
      ICarRecommendationsResponse,
      ICarDetailsBase
    >({
      query: (carDetailsParams) => {
        const { adId, sourceId, currency } = carDetailsParams;

        return {
          url: ApiEndpoints.CarRecommendations,
          params: {
            adId,
            sourceId,
            currency: transformStringToUppercase(currency),
          },
        };
      },
    }),
    getCarModelMedianPricesByRegions: build.query<
      IMedianPricesByRegionResponse,
      IMedianPricesByRegionRequest
    >({
      query: (carDetailsParams) => {
        const { makeId, modelId, currency, yearOfIssue, engineCapacity } =
          carDetailsParams;

        return {
          url: ApiEndpoints.CarMedianPricesByRegion,
          params: engineCapacity
            ? {
                makeId: transformStringToUppercase(makeId),
                modelId: transformStringToUppercase(modelId),
                currency: transformStringToUppercase(currency),
                yearOfIssue,
                engineCapacity,
              }
            : { makeId, modelId, currency, yearOfIssue },
        };
      },
    }),
    getModelPriceDistribution: build.query<
      IModelPriceDistributionResponse,
      ICarPriceDistributionQueryParams
    >({
      query: (modelDetailsQueryParam) => {
        const { makeId, modelId, yearOfIssue, engineCapacity, sourceId } =
          modelDetailsQueryParam;

        return {
          url: ApiEndpoints.CarModelPriceDistributions,
          params: {
            makeId: transformStringToUppercase(makeId),
            modelId: transformStringToUppercase(modelId),
            yearOfIssue,
            sourceId,
            engineCapacity,
          },
        };
      },
    }),
    getMileageDistribution: build.query<
      IMileageDistributionResponse,
      ICarMileageDistributionQueryParams
    >({
      query: (modelDetailsQueryParam) => {
        const { makeId, modelId, yearOfIssue, engineType } =
          modelDetailsQueryParam;

        return {
          url: ApiEndpoints.CarMileageDistributions,
          params: {
            makeId: transformStringToUppercase(makeId),
            modelId: transformStringToUppercase(modelId),
            yearOfIssue,
            engineType,
          },
        };
      },
    }),
    checkAvailability: build.query<
      CarAvailabilityResponse,
      { adId: string; sourceId: number }
    >({
      query: (params) => {
        const { adId, sourceId } = params;

        return {
          url: ApiEndpoints.CheckAvailability,
          params: { adId, sourceId },
        };
      },
    }),
    calculateDelivery: build.query<
      CalculateDeliveryResponse,
      CalculateDeliveryPayload
    >({
      query: (params) => {
        return {
          url: ApiEndpoints.CalculateDelivery,
          params,
        };
      },
    }),
    calculateTurnKeyDelivery: build.query<
      TurnKeyDeliveryResponse,
      TurnKeyDeliveryRequest
    >({
      query: (params) => {
        return {
          url: ApiEndpoints.CalculateTurnKeyDelivery,
          params,
        };
      },
    }),
    buyRequest: build.mutation<void, BuyRequestPayload>({
      query: (body: BuyRequestPayload) => ({
        url: ApiEndpoints.ByuCarRequest,
        method: 'POST',
        body,
      }),
    }),
    carInspectionRequest: build.mutation<void, CarInspectionRequestPayload>({
      query: (body: CarInspectionRequestPayload) => ({
        url: ApiEndpoints.CarInspection,
        method: 'POST',
        body,
      }),
    }),
    askQuestion: build.mutation<void, AskQuestionPayload>({
      query: (body: AskQuestionPayload) => ({
        url: ApiEndpoints.AskQuestionsCars,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useLazyGetCarQuery,
  useLazyGetCarClonesQuery,
  useLazyGetCarRecommendationsQuery,
  useLazyGetCarModelMedianPricesByRegionsQuery,
  useLazyGetModelPriceDistributionQuery,
  useLazyGetMileageDistributionQuery,
  useLazyCheckAvailabilityQuery,
  useLazyCalculateDeliveryQuery,
  useBuyRequestMutation,
  useCalculateTurnKeyDeliveryQuery,
  useLazyCalculateTurnKeyDeliveryQuery,
  useCarInspectionRequestMutation,
  useAskQuestionMutation,
} = carApi;
