import { FC, useRef, useState, memo } from 'react';
import { CustomSlider } from '../FormElements';
import { FormFieldName } from '../../enums/form';
import { commonValidationRules } from '../../data/commonValidationRules';
import { TitleRow } from '../../pages/CarTurnKeyPage/components/TurnKeyForms/shared/styles';
import { TitleRowDescription } from '../../pages/CarTurnKeyPage/components/TurnKeyForms/shared/styles';
import { IconWithTooltip } from '../IconWithTooltip';
import { useMediaQuery } from 'react-responsive';
import { SCREEN_WIDTH } from '../../screenSettings';
import { PRICE_LIMIT_BUDGET } from '../../constants';
import { getMarksSlider } from '../../pages/CarTurnKeyPage/components/TurnKeyForms/SearchParamsForm/SearchParamsForm.utils';
import { getCurrencySymbol } from '../../utils/getCurrencySymbol';
import { StyledSliderWrapper } from './CurrencySumSlider.styled';
import { Form, FormInstance } from 'antd';

type CurrencySumSliderPropsType = {
  currency: string;
  form: FormInstance<unknown>;
};

const DEFAULT_TOOLTIP_OFFSET = [0, -12];
const MOBILE_LEFT_CORRECT_OFFSET_X = 10;
const MOBILE_RIGHT_CORRECT_OFFSET_X = -25;

export const CurrencySumSlider: FC<CurrencySumSliderPropsType> = memo(
  ({ currency, form }) => {
    const [tooltipOffset, setTooltipOffset] = useState(DEFAULT_TOOLTIP_OFFSET);
    const isTabletWidth = useMediaQuery({
      query: `(max-width: ${SCREEN_WIDTH.TABLET}px)`,
    });

    const isMobileWidth = useMediaQuery({
      query: `(max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_1}px)`,
    });

    const wrapperRef = useRef<HTMLDivElement>(null);

    const correctPositionMobileDevice = (value: number) => {
      if (value < PRICE_LIMIT_BUDGET * 0.15) {
        setTooltipOffset((prev) =>
          prev[0] !== MOBILE_LEFT_CORRECT_OFFSET_X
            ? [MOBILE_LEFT_CORRECT_OFFSET_X, DEFAULT_TOOLTIP_OFFSET[1]]
            : [...prev],
        );
      } else if (value > PRICE_LIMIT_BUDGET - PRICE_LIMIT_BUDGET * 0.15) {
        setTooltipOffset((prev) =>
          prev[0] !== MOBILE_RIGHT_CORRECT_OFFSET_X
            ? [MOBILE_RIGHT_CORRECT_OFFSET_X, DEFAULT_TOOLTIP_OFFSET[1]]
            : [...prev],
        );
      }
    };

    const budget = Form.useWatch(FormFieldName.Budget, form);

    return (
      <StyledSliderWrapper ref={wrapperRef}>
        <CustomSlider
          refElement={wrapperRef.current}
          formItemProps={{
            name: FormFieldName.Budget,
            rules: commonValidationRules.budget,
            validateDebounce: 100,
            label: (
              <TitleRow style={{ marginBottom: 7 }}>
                <TitleRowDescription>Бюджет</TitleRowDescription>
                <IconWithTooltip
                  description='some clue'
                  placement='topLeft'
                  customPopoverWidth={230}
                  customTrigger={isTabletWidth ? ['click'] : ['hover']}
                />
              </TitleRow>
            ),
          }}
          onChange={(value: number) => {
            if (isMobileWidth) {
              correctPositionMobileDevice(value);
            }
          }}
          marks={getMarksSlider(currency)}
          tooltip={{
            autoAdjustOverflow: !isMobileWidth,
            placement: 'top',
            open: !!budget && budget !== PRICE_LIMIT_BUDGET,
            align: isMobileWidth ? { offset: tooltipOffset } : undefined,
            formatter: (value?: number) =>
              value ? (
                <span>{`${value.toLocaleString('en-US')}\u00A0${getCurrencySymbol(currency)}`}</span>
              ) : (
                ''
              ),
          }}
        />
      </StyledSliderWrapper>
    );
  },
);
