import styled from 'styled-components';
import { SCREEN_WIDTH } from '../../../../../screenSettings';
import { Form } from 'antd';

export const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
  margin-bottom: 40px;
`;

export const StyledRowTwoColumn = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;

  @media screen and (max-width: ${SCREEN_WIDTH.DESKTOP}px) {
    align-items: flex-end;
  }
`;

export const StyledRadioGroup = styled(Form.Item)`
  width: 100%;
  margin-bottom: 0;

  & .ant-radio-group {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
`;
