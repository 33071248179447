import { FC, CSSProperties } from 'react';
import { ICarFeature } from '../../models';
import { StyledContent } from '../styled/StyledContent';
import { TitleWithIcon } from '../TitleWithIcon';
import { CarFeatureList } from '../CarFeatureList';

type CarFeaturesInfoProps = {
  sortedByCategoryFeatures: ICarFeature[];
  styles?: CSSProperties;
};

export const CarFeaturesInfo: FC<CarFeaturesInfoProps> = ({
  sortedByCategoryFeatures,
  styles,
}) => {
  const id = sortedByCategoryFeatures[0].categoryId;

  return (
    <StyledContent style={{ marginTop: 0, ...styles }}>
      <TitleWithIcon styles={{ marginBottom: 24 }} categoryID={id} />
      <CarFeatureList sortedByCategoryFeatures={sortedByCategoryFeatures} />
    </StyledContent>
  );
};
