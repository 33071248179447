import { CSSProperties, FC, SyntheticEvent } from 'react';
import notFoundCar from '../../../../img/notFoundCar.svg';
import { Image } from 'antd';
import { ICarData } from '../../../../models';
import styled from 'styled-components';

interface ICarsListImage {
  item: ICarData;
  width?: number | string;
  style?: CSSProperties;
  handleImageLoad?: (event: SyntheticEvent<HTMLImageElement, Event>) => void;
}

const StyledCarImage = styled(Image)`
  object-fit: cover;
  border-radius: 8px;
`;

export const CarsListImage: FC<ICarsListImage> = ({
  item,
  width,
  style,
  handleImageLoad,
}) => {
  // const [imageLoaded, setImageLoaded] = useState(true);

  return (
    <StyledCarImage
      loading='lazy'
      style={style}
      alt={item.title}
      width={width ? width : undefined}
      height='auto'
      preview={false}
      src={item.imageUrl}
      fallback={notFoundCar}
      // onError={() => setImageLoaded(false)}
      onLoad={handleImageLoad}
    />
  );
};
