import { FC, memo } from 'react';
import {
  DeliveryBlock,
  StyledPricesBlockRow,
  StyledVerticalDivider,
  TextKey,
  TextKeyBold,
  TextValueBold,
  TitleRow,
} from './CalculateDeliverySection.styled';
import { IconWithTooltip } from '../../../../components/IconWithTooltip';
import { StyledTitle } from '../../../../components/styled/StyledTitle';
import { regionIdsMapping } from '../../../../data/mappings';
import { formatNumber } from '../../../../utils/formatNumber';
import { getCurrencySymbol } from '../../../../utils/getCurrencySymbol';
import { useOutletContext } from 'react-router-dom';
import { CarPageOutletContext } from '../../CarPage.interface';
import { AmountCurrency } from '../../../../models/car/CalculateDelivery';

interface PriceAfterTaxConvertedBlockProps {
  carPriceOriginal: AmountCurrency;
  carPriceConverted: AmountCurrency;
  taxRate: number;
  carPriceAfterTaxConverted: AmountCurrency;
}

export const PriceAfterTaxConvertedBlock: FC<PriceAfterTaxConvertedBlockProps> =
  memo(
    ({
      carPriceOriginal,
      carPriceConverted,
      taxRate,
      carPriceAfterTaxConverted,
    }) => {
      const { car } = useOutletContext<CarPageOutletContext>();

      return (
        <DeliveryBlock>
          <TitleRow>
            <StyledTitle level={3}>Цена авто после вычета НДС</StyledTitle>
            <IconWithTooltip
              description={
                <>
                  Стоимость автомобиля с вычетом налога на добавленную стоимость
                  (НДС), действующего в стране покупки
                </>
              }
            />
          </TitleRow>
          <StyledPricesBlockRow>
            <TextKey>Цена авто в {regionIdsMapping[car.regionId]}</TextKey>
            <TextValueBold>
              <div>
                <TextValueBold>
                  {formatNumber(carPriceOriginal.amount)}{' '}
                  {getCurrencySymbol(carPriceOriginal.currency)}
                </TextValueBold>
                <StyledVerticalDivider type='vertical' />
                <TextValueBold>
                  {formatNumber(carPriceConverted.amount)}{' '}
                  {getCurrencySymbol(carPriceConverted.currency)}
                </TextValueBold>
              </div>
            </TextValueBold>
          </StyledPricesBlockRow>
          <StyledPricesBlockRow>
            <TextKey>Ставка НДС</TextKey>
            <TextValueBold>{taxRate} %</TextValueBold>
          </StyledPricesBlockRow>
          <StyledPricesBlockRow>
            <TextKeyBold>ИТОГО</TextKeyBold>
            <TextValueBold>
              {formatNumber(carPriceAfterTaxConverted.amount)}{' '}
              {getCurrencySymbol(carPriceAfterTaxConverted.currency)}
            </TextValueBold>
          </StyledPricesBlockRow>
        </DeliveryBlock>
      );
    },
  );
