import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { APP_PATHS } from '../../layout/settingsLayout';
import { useActions } from '../useActions';
import { MenuProfileKeysType } from '../../models/profile';
import { KeysMenuItem } from '../../enums/menu';

export const useHandleMenuClick = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isProfileAccount = location.pathname.includes(
    APP_PATHS.PROFILE_PAGE_ACCOUNT,
  );
  const isSubscriptionsMonitoring = location.pathname.includes(
    APP_PATHS.PROFILE_PAGE_SUBSCRIPTIONS_OFFER,
  );
  const isSubscriptionsFilter = location.pathname.includes(
    APP_PATHS.PROFILE_PAGE_SUBSCRIPTIONS_FILTER,
  );
  const { logOut, setSubmenuKey, resetProfile } = useActions();

  const onClickHandler = (key: string) => {
    if (key === KeysMenuItem.logout) {
      logOut();
      resetProfile();
      navigate(APP_PATHS.CARS_PAGE);
      setSubmenuKey({ key: '' });
      return;
    }

    setSubmenuKey({ key: key as MenuProfileKeysType });

    switch (key) {
      case KeysMenuItem.subscriptionsMonitoring: {
        if (isSubscriptionsMonitoring) {
          break;
        }
        navigate(APP_PATHS.PROFILE_PAGE_SUBSCRIPTIONS_OFFER);
        break;
      }
      case KeysMenuItem.subscriptionsFilter: {
        if (isSubscriptionsFilter) {
          break;
        }
        navigate(APP_PATHS.PROFILE_PAGE_SUBSCRIPTIONS_FILTER);
        break;
      }
      case KeysMenuItem.account: {
        if (isProfileAccount) {
          break;
        }
        navigate(APP_PATHS.PROFILE_PAGE_ACCOUNT);
        break;
      }

      default: {
        if (!isProfileAccount) navigate(APP_PATHS.PROFILE_PAGE_ACCOUNT);
      }
    }
  };
  const memoizedHandler = useCallback(onClickHandler, [
    isProfileAccount,
    isSubscriptionsFilter,
    isSubscriptionsMonitoring,
    logOut,
    navigate,
    resetProfile,
    setSubmenuKey,
  ]);

  return { onClickHandler: memoizedHandler };
};
