import { FC, ReactNode } from 'react';
import { StyledWrapperCard } from './ProfileContentCard.styled';

type ProfileContentCardPropsType = {
  children: ReactNode;
};

export const ProfileContentCard: FC<ProfileContentCardPropsType> = ({
  children,
}) => {
  return <StyledWrapperCard>{children}</StyledWrapperCard>;
};
