export const InteriorSvg = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='25'
    height='25'
    viewBox='0 0 25 25'
    fill='none'
  >
    <path
      d='M5 22.498V17.498M5 7.49805V2.49805M2.5 4.99805H7.5M2.5 19.998H7.5M13.5 3.49805L11.7658 8.00691C11.4838 8.74014 11.3428 9.10675 11.1235 9.41513C10.9292 9.68844 10.6904 9.92724 10.4171 10.1216C10.1087 10.3409 9.74209 10.4819 9.00886 10.7639L4.5 12.498L9.00886 14.2322C9.74209 14.5142 10.1087 14.6552 10.4171 14.8745C10.6904 15.0689 10.9292 15.3076 11.1235 15.581C11.3428 15.8893 11.4838 16.256 11.7658 16.9892L13.5 21.498L15.2342 16.9892C15.5162 16.256 15.6572 15.8893 15.8765 15.581C16.0708 15.3076 16.3096 15.0689 16.5829 14.8745C16.8913 14.6552 17.2579 14.5142 17.9911 14.2322L22.5 12.498L17.9911 10.7639C17.2579 10.4819 16.8913 10.3409 16.5829 10.1216C16.3096 9.92724 16.0708 9.68844 15.8765 9.41513C15.6572 9.10675 15.5162 8.74014 15.2342 8.00691L13.5 3.49805Z'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
