import { Modal, ModalProps } from 'antd';
import { FC } from 'react';
import './CustomModal.css';

export const CustomModal: FC<ModalProps> = ({ children, ...props }) => {
  let scrollPosition = 0;
  const body = document.querySelector('body');
  const DISABLE_SCROLLING_CLASS = 'scroll-disabled';

  // custom logic for IOS scroll disabling
  const afterOpen = (open: boolean) => {
    if (!body) return;

    if (open) {
      scrollPosition = window.pageYOffset;
      body.classList.add(DISABLE_SCROLLING_CLASS);
      body.style.top = `-${scrollPosition}px`;
      return;
    }

    body.classList.remove(DISABLE_SCROLLING_CLASS);
    body.style.removeProperty('top');
    window.scrollTo(0, scrollPosition);
  };

  return <Modal {...props}>{children}</Modal>;
};
