import styled from 'styled-components';
import { Form } from 'antd';

export const StyledFormItem = styled(Form.Item)`
  margin-bottom: 0;

  .ant-form-item-control {
    position: relative;
    .ant-form-item-explain,
    .ant-form-item-explain-error {
      position: absolute;
    }
    .ant-form-item-explain-error:not(:first-child) {
      display: none;
    }
  }
`;
