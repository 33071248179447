export enum PaymentSettingsFormNames {
  Delivery = 'destinationRegionId',
  Company = 'companyId',
  CarInspectionRequired = 'carInspectionRequired',
  hasCustomsClearanceBenefit = 'hasCustomsClearanceBenefit',
}

export enum PaymentSettingsFormLabel {
  Delivery = 'Доставка в',
  Company = 'Компания',
  CarInspectionRequired = 'Необходим осмотр авто',
  hasCustomsClearanceBenefit = 'Льготная растаможка',
}

export enum PaymentSettingsFormPlaceholder {
  Delivery = 'Выберите доставку',
  Company = 'Выберите компанию',
}
