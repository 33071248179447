import { FC, useState, PropsWithChildren, useMemo } from 'react';
import {
  ModalSubscriptionsContext,
  SelectedCarByFilters,
} from './ModalSubscriptionsContext';
import { ICarData } from '../../models';

export const ModalSubscriptionsContextProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [car, setCar] = useState<ICarData | null>(null);
  const [carByFilterName, setCarByFilterName] = useState<SelectedCarByFilters>({
    hash: '',
    name: '',
  });

  const openModal = () => setIsOpen(true);

  const closeModal = () => setIsOpen(false);

  const updateSelectedCar = (newCar: ICarData | null) => setCar(newCar);

  const updateSelectedCarByFilter = (carByFilter: SelectedCarByFilters) =>
    setCarByFilterName(carByFilter);

  const value = useMemo(
    () => ({
      isOpenModal: isOpen,
      selectedCar: car,
      updateSelectedCar,
      openModal,
      closeModal,
      selectedCarByFilters: carByFilterName,
      updateSelectedCarByFilter,
    }),
    [car, carByFilterName, isOpen],
  );

  return (
    <ModalSubscriptionsContext.Provider value={value}>
      {children}
    </ModalSubscriptionsContext.Provider>
  );
};
