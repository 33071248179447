import { useCallback, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import qs from "qs";

export const useQueryState = (replace = true) => {
  const location = useLocation();
  const navigate = useNavigate();

  const setQuery: any = useCallback(
    (updatesObject: any) => {
      const existingQueries = qs.parse(location.search, {
        ignoreQueryPrefix: true,
      });

      const updatedQueries = { ...existingQueries, ...updatesObject };

      const queryString = qs.stringify(updatedQueries, {
        skipNulls: true,
        encode: false,
        arrayFormat: "repeat",
      });

      navigate(`${location.pathname}?${queryString}`, { replace });
    },
    [navigate, location, replace]
  );

  const queryParameters = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        decoder(value) {
          // Commentd because we have a lot of string values in forms
          // if (/^(\d+|\d*\.\d+)$/.test(value)) {
          //   return parseFloat(value);
          // }

          let keywords: { [key: string]: any } = {
            true: true,
            false: false,
            null: null,
            undefined: undefined,
          };

          if (value in keywords) return keywords[value];

          return value;
        },
      }),
    [location.search]
  );

  return [queryParameters, setQuery];
};
