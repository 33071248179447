import { FC, Fragment } from 'react';
import { StyledButton } from '../../../../components/styled/StyledButton';
import { useMediaQuery } from 'react-responsive';
import { SCREEN_WIDTH } from '../../../../screenSettings';
import { useModalSubscriptionContext } from '../../../../hooks/subscriptions';
import {
  useDeleteCarFromSubscriptions,
  useDeleteCarFromFilters,
} from '../../../../hooks/subscriptions';
import { StyledBody } from './SubscriptionsListModal.styles';
import {
  ModalConfirm,
  ModalFooterControls,
} from '../../../../components/ModalConfirm';

type SubscriptionsListModalProps = {
  forFilterSubscription?: boolean;
};

export const SubscriptionsListModal: FC<SubscriptionsListModalProps> = ({
  forFilterSubscription,
}) => {
  const isTabletToMobileBreak1 = useMediaQuery({
    query: `(max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_1}px)`,
  });

  const isTabletToMobileBreak3 = useMediaQuery({
    query: `(max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px)`,
  });

  const isMobileWidth = useMediaQuery({
    query: `(max-width: ${SCREEN_WIDTH.MOBILE}px)`,
  });

  const {
    isOpenModal,
    closeModal,
    selectedCar,
    selectedCarByFilters,
    updateSelectedCar,
    updateSelectedCarByFilter,
  } = useModalSubscriptionContext();

  const { contextHolder, isDeleting, deleteSubscription } =
    useDeleteCarFromSubscriptions();

  const {
    contextHolder: deleteFiltersStatusHolder,
    deleteSubscriptionFromFilters,
    isDeletingFromFilters,
  } = useDeleteCarFromFilters();

  const handleDeleteCarFromSubscription = async () => {
    if (selectedCar && selectedCar.adId) {
      await deleteSubscription({
        adId: selectedCar.adId,
        sourceId: selectedCar.sourceId,
      });
    }
    if (selectedCarByFilters.hash) {
      await deleteSubscriptionFromFilters(selectedCarByFilters.hash);
    }
    closeModal();
    updateSelectedCar(null);
    updateSelectedCarByFilter({ name: '', hash: '' });
  };

  return (
    <>
      {contextHolder}
      <ModalConfirm
        width={
          (isMobileWidth && 320) ||
          (isTabletToMobileBreak3 && '100%') ||
          (isTabletToMobileBreak1 && 380) ||
          424
        }
        open={isOpenModal}
        zIndex={40}
        title='Удаление объявления'
        maskClosable={true}
        centered
        onCancel={closeModal}
        footer={
          <ModalFooterControls>
            <StyledButton
              size='large'
              type='primary'
              ghost
              block
              onClick={() => closeModal()}
            >
              Отменить
            </StyledButton>
            <StyledButton
              loading={isDeleting || isDeletingFromFilters}
              onClick={handleDeleteCarFromSubscription}
              type='primary'
              size='large'
              block
            >
              Удалить
            </StyledButton>
          </ModalFooterControls>
        }
      >
        <StyledBody>
          {forFilterSubscription
            ? 'Вы действительно хотите удалить подписку по фильтру\u00A0\u00A0'
            : 'Вы действительно хотите удалить объявление\u00A0\u00A0'}
          <span className='modal__highlight'>
            {selectedCar?.car.fullName || selectedCarByFilters.name}
          </span>{' '}
          {forFilterSubscription ? '?' : 'из подписок?'}
        </StyledBody>
      </ModalConfirm>
      <Fragment key='deleteFiltersStatusHolder'>
        {deleteFiltersStatusHolder}
      </Fragment>
    </>
  );
};
