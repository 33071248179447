import styled from 'styled-components';

export const StyledTooltipDescription = styled.span`
  color: var(--Gray-7);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0.21px;
  & b {
    font-weight: 600;
    font-size: 16px;
  }
`;
