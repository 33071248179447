import { useCallback } from 'react';
import { ToggleFiltersTelegramNotificationsRequest } from '../../models/response/subscriptionsCars';
import { useToggleFiltersTelegramNotificationMutation } from '../../store/subscription/subscription.api';
import { notification } from 'antd';
import { config } from '../../config';

export const useChangeStatusFiltersNotification = () => {
  const [toggleACtiveStateTGNotification, { isLoading }] =
    useToggleFiltersTelegramNotificationMutation();

  const [notificationApi, contextHolder] = notification.useNotification();

  const changeStatusNotificationByFilter = useCallback(
    async (body: ToggleFiltersTelegramNotificationsRequest) => {
      try {
        await toggleACtiveStateTGNotification(body).unwrap();
      } catch (err: unknown) {
        console.error(err);

        notificationApi.error({
          message: 'Ошибка!',
          description:
            'Возникла непредвиденная ошибка при изменении статуса телеграм уведомлений.',
          duration: config.SUBSCRIPTION_TG_NOTIFICATION_DURATION,
        });
      }
    },
    [notificationApi, toggleACtiveStateTGNotification],
  );

  return {
    isChangingStatus: isLoading,
    contextHolder,
    changeStatusNotificationByFilter,
  };
};
