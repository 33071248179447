import { combineReducers, configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import { carsApi } from './cars/cars.api';
import { carPageReducer, carPageSlice } from './car/car.page.slice';
import { carApi } from './car/car.api';
import { profileApi } from './profile/profile.api';
import { authApi } from './auth/auth.api';
import { authReducer, authSlice } from './auth/auth.slice';
import { appGeneralReducer, appGeneralSlice } from './app-general/app.slice';
import { profileReducer, profileSlice } from './profile/profile.slice';
import { api } from './api/api';
import { subscriptionApi } from './subscription/subscription.api';
import {
  subscriptionReducer,
  subscriptionSlice,
} from './subscription/subscription.slice';
import { carsReducer, carsSlice } from './cars/cars.slice';
import { domainReducer, domainSlice } from './domain/domain.slice';
import { companiesReducer, companiesSlice } from './companies/companies.slice';
import { turnKeyReducer, turnKeySlice } from './turn-key/turn-key.slice';
import {
  turnKeyFormsReducer,
  turnKeyFormsSlice,
} from './turn-key/turn-key-form.slice';
import {
  turnKeyStepsReducer,
  turnKeyStepsSlice,
} from './turn-key/turn-key-steps.slice';
import { appNotificationMiddleware } from './middleware';

const persistConfig = {
  key: 'root',
  storage: storage,
  blacklist: [
    'carsListForm',
    'carsListPage',
    'carsApi/api',
    'authApi/api',
    'api',
    'subscription/api',
    'domain',
    subscriptionApi.reducerPath,
    'companies',
    'carPage',
    'turnKey',
    'turnKeyForms',
    'turnKeySteps',
  ],
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    [authApi.reducerPath]: authApi.reducer,
    [authSlice.name]: authReducer,
    [appGeneralSlice.name]: appGeneralReducer,
    [carsApi.reducerPath]: carsApi.reducer,
    [profileApi.reducerPath]: profileApi.reducer,
    [carsSlice.name]: carsReducer,
    [carApi.reducerPath]: carApi.reducer,
    [carPageSlice.name]: carPageReducer,
    [subscriptionApi.reducerPath]: subscriptionApi.reducer,
    [subscriptionSlice.name]: subscriptionReducer,
    [profileSlice.name]: profileReducer,
    [domainSlice.name]: domainReducer,
    [companiesSlice.name]: companiesReducer,
    [turnKeySlice.name]: turnKeyReducer,
    [turnKeyFormsSlice.name]: turnKeyFormsReducer,
    [turnKeyStepsSlice.name]: turnKeyStepsReducer,
  }),
);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).concat(api.middleware, appNotificationMiddleware),
  devTools: process.env.NODE_ENV === 'development',
});

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
