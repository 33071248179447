import { Col, theme } from 'antd';
import { FC } from 'react';
import { CarsListDropdown } from '../Car';
import { ICarData } from '../../models';
import {
  StyledRow,
  StyledCarTitleLink,
  StyledCol,
} from './CarItemHeader.styled';
import { useMediaQuery } from 'react-responsive';
import { SCREEN_WIDTH } from '../../screenSettings';

interface ICarsListTitle {
  item: ICarData;
  carUrl: string;
  isPriceShowed?: boolean;
}

const { useToken } = theme;

export const CarItemHeader: FC<ICarsListTitle> = ({
  item,
  carUrl,
  isPriceShowed = true,
}) => {
  const isTabletWidth = useMediaQuery({
    query: `(max-width: ${SCREEN_WIDTH.TABLET - 1}px)`,
  });

  const { token } = useToken();

  return (
    <StyledRow md={isTabletWidth ? 0 : 1}>
      <StyledCol>
        <StyledCarTitleLink
          color={token.colorTextHeading}
          to={carUrl}
          rel='noreferrer'
        >
          {item.car.fullName}
        </StyledCarTitleLink>
        {isPriceShowed && (
          <Col>
            <CarsListDropdown
              price={item.price}
              prices={item.prices}
              currency={item.currency.toUpperCase()}
              tag={item.tag}
            />
          </Col>
        )}
      </StyledCol>
    </StyledRow>
  );
};
