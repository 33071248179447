import { api } from '../api/api';
import { GetCarOptionsResponse } from '../../models/turn-key/GetOptionsResponse';
import { GetCarFeaturesResponse } from '../../models/turn-key/GetCarFeatures';
import { turnKeyActions } from './turn-key.slice';
import { ERROR_MESSAGES } from '../../data/errorMessages';
import { ApiEndpoints } from '../api/endpoints';

export const turnKeyApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCarOptions: build.query<GetCarOptionsResponse, void>({
      query: () => {
        return {
          url: ApiEndpoints.CarOptions,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const carOptions = await queryFulfilled;
          if (carOptions.data) {
            dispatch(turnKeyActions.setCarOptions(carOptions.data));
          }
        } catch (err) {
          console.error(err, 'INSIDE RTK GET OPTIONS');
        }
      },
      transformErrorResponse: (response) => ({
        ...response,
        forCustomNotification: {
          status: ERROR_MESSAGES.GET_CAR_OPTIONS.status,
          message: ERROR_MESSAGES.GET_CAR_OPTIONS.message,
          description: ERROR_MESSAGES.GET_CAR_OPTIONS.description,
        },
      }),
    }),
    getCategories: build.query<GetCarFeaturesResponse, void>({
      query: () => {
        return {
          url: ApiEndpoints.CarFeaturesAvailable,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const response = await queryFulfilled;
          if (response.data && Array.isArray(response.data.categories)) {
            dispatch(turnKeyActions.setCategories(response.data.categories));
          }
        } catch (err) {
          console.error(err, 'INSIDE RTK GETTING FEATURES');
        }
      },
      transformErrorResponse: (response) => ({
        ...response,
        forCustomNotification: {
          status: ERROR_MESSAGES.GET_CAR_FEATURES.status,
          message: ERROR_MESSAGES.GET_CAR_FEATURES.message,
          description: ERROR_MESSAGES.GET_CAR_FEATURES.description,
        },
      }),
    }),
  }),
});

export const { useGetCarOptionsQuery, useGetCategoriesQuery } = turnKeyApi;
