export const ExteriorSvg = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='25'
    height='25'
    viewBox='0 0 25 25'
    fill='none'
  >
    <path
      d='M12.5 20.9703C13.5615 21.9204 14.9633 22.4981 16.5 22.4981C19.8137 22.4981 22.5 19.8118 22.5 16.4981C22.5 13.7311 20.6271 11.4016 18.0798 10.7082M6.92018 10.7082C4.37293 11.4016 2.5 13.7311 2.5 16.498C2.5 19.8118 5.18629 22.498 8.5 22.498C11.8137 22.498 14.5 19.8118 14.5 16.498C14.5 15.7176 14.351 14.9719 14.0798 14.2879M18.5 8.49805C18.5 11.8118 15.8137 14.498 12.5 14.498C9.18629 14.498 6.5 11.8118 6.5 8.49805C6.5 5.18434 9.18629 2.49805 12.5 2.49805C15.8137 2.49805 18.5 5.18434 18.5 8.49805Z'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
