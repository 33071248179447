import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Company } from '../../models/response/companies';

interface InitialState {
  companies: Company[];
}

const initialState: InitialState = {
  companies: [],
};

export const companiesSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    setCompanies: (state, { payload }: PayloadAction<Company[]>) => {
      state.companies = payload;
    },
  },
});

export const companiesActions = companiesSlice.actions;
export const companiesReducer = companiesSlice.reducer;
