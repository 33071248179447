import { useLazyGetCarRecommendationsQuery } from '../../store/car/car.api';
import { notification } from 'antd';
import { config } from '../../config';
import { useProfileSelector } from '../../store/selectors';
import { ICarDetailsBase } from '../../models';

export const useGetCarsRecommendations = () => {
  const { currency } = useProfileSelector();
  const [notificationApi, contextHolder] = notification.useNotification();
  const [
    fetchCarRecommendations,
    {
      isError: isErrorCarRecommendations,
      data: carRecommendations,
      isFetching: isFetchingCarRecommendations,
    },
  ] = useLazyGetCarRecommendationsQuery();

  const getCarRecommendations = async (
    params: ICarDetailsBase,
  ): Promise<void> => {
    try {
      await fetchCarRecommendations({ ...params, currency });
    } catch (err: any) {
      if (process.env.NODE_ENV === 'development') {
        console.log(err);
      }
      notificationApi.error({
        message: 'Ошибка загрузки рекомендаций авто',
        description:
          err?.data?.message || 'Не удалось загрузить рекомендации авто',
        duration: config.DEFAULT_NOTIFICATION_DURATION,
      });
    }
  };

  return {
    getCarRecommendations,
    carRecommendations,
    isErrorCarRecommendations,
    isFetchingCarRecommendations,
    contextHolder,
  };
};
