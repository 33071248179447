import { PropsWithChildren, FC } from 'react';
import { ModalProps } from 'antd';
import { StyledAntModal } from './ModalConfrim.styled';

interface ConfirmModalProps extends ModalProps {
  withSeparateHeader?: boolean;
}

export const ModalConfirm: FC<PropsWithChildren<ConfirmModalProps>> = ({
  withSeparateHeader,
  children,
  ...props
}) => {
  return (
    <StyledAntModal withSeparateHeader={withSeparateHeader} {...props}>
      {children}
    </StyledAntModal>
  );
};
