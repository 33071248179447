import { CaretDownOutlined } from '@ant-design/icons';
import { Button, ButtonProps, ConfigProvider, theme } from 'antd';
import { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';

const StyledButtonFieldMobileForm = styled(Button)`
  overflow: hidden;
  max-width: 100%;
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: row-reverse;
  padding: 10px 12px !important;

  span:not([role='img']) {
    display: inline-block;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .anticon + span {
    margin-inline-start: 0;
  }
`;

const { useToken } = theme;

interface ButtonFieldMobileFormProps extends ButtonProps {
  event: () => void;
  loading?: boolean;
  value?: string | number | undefined;
}

export const ButtonFieldMobileForm: FC<
  PropsWithChildren<ButtonFieldMobileFormProps>
> = ({ children, event, disabled = false, loading = false, value }) => {
  const { token } = useToken();

  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            colorText: '#192A4D',
            colorBorder: '#CAD3E0',
            colorTextDisabled: '#717C8C',
            borderRadiusLG: 4,
          },
        },
      }}
    >
      <StyledButtonFieldMobileForm
        size='large'
        block
        icon={
          <CaretDownOutlined
            rev='true'
            style={{ color: token.colorTextSecondary, fontSize: 12 }}
          />
        }
        onClick={event}
        disabled={disabled}
        loading={loading}
        value={value}
        style={{ width: '100%' }}
      >
        {children}
      </StyledButtonFieldMobileForm>
    </ConfigProvider>
  );
};
