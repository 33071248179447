import styled from 'styled-components';
import { SCREEN_WIDTH } from '../../screenSettings';

export const StyledProfilePageWrapper = styled.section`
  padding: 16px 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  @media screen and (max-width: ${SCREEN_WIDTH.TABLET}px) {
    padding: 16px;
  }
  @media screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
    padding: 16px 0;
  }
`;
