import { CSSProperties } from 'react';

export const changeGridOrderToBottomStyles = (elements: number, col = 3) => {
  const rows = Math.ceil(elements / col);
  const styles: CSSProperties = {
    gridAutoFlow: 'column',
    gridTemplateRows: `repeat(${rows}, 1fr)`,
  };
  return styles;
};
