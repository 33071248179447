import { FC } from 'react';
import { StyledTitle, StyledFeaturesList } from './MobileCarFeatures.styled';
import { ICarFeature } from '../../models';
import { getGroupedFeatures } from '../../utils/getGroupedFeatures';
import { StyledContent } from '../styled/StyledContent';
import { CarFeatureClickableTitle } from '../CarFeatureClickableTitle';
import { FeaturesDrawer } from '../FeaturesDrawer';

type CarFeaturesDescriptionProps = {
  features: ICarFeature[];
};

export const MobileCarFeatures: FC<CarFeaturesDescriptionProps> = ({
  features,
}) => {
  const groupedFeaturesByCategory = getGroupedFeatures(features);

  return (
    <>
      <StyledContent>
        <StyledTitle>Комплектация</StyledTitle>
        <StyledFeaturesList>
          {groupedFeaturesByCategory.map((item) => (
            <CarFeatureClickableTitle
              key={item.categoryId}
              categoryId={item.categoryId}
              groupedFeatures={item.features}
            />
          ))}
        </StyledFeaturesList>
      </StyledContent>
      <FeaturesDrawer />
    </>
  );
};
