export const theme = (isMobileWidth = false, isTabletWidth = false) => {
  return {
    token: {
      colorTextHeading: '#192A4D',
      colorPrimary: '#3F8BF3',
      colorPrimaryText: '#3F8BF3',
      fontFamily: 'Open Sans',
      borderRadius: 4,
      colorTextBase: '#192A4D',
      colorText: '#192A4D',
      colorTextSecondary: '#717C8C',
      fontSizeLG: 14,
      fontSizeSM: 14,
      fontSizeXL: 20,
      controlHeightLG: 36,
      controlItemBgHover: '#F5FCFF',
      colorBgContainerDisabled: '#F5F7FA',
      colorTextDisabled: '#97A3B5',
      colorBorder: '#CAD3E0',
      colorPrimaryBorderHover: '#3F8BF3',
      colorInfoBorderHover: '#3F8BF3',
      colorTextPlaceholder: '#97A3B5',
      colorBgLayout: '#E9EFF6',
      colorLink: '#3F8BF3',
      colorLinkHover: '#69b1ff',
      colorLinkActive: '#0958d9',
      primaryShadow: 'none',
    },
    components: {
      Button: {
        controlHeightLG: 38,
        borderRadius: 8,
        fontSizeLG: 12,
        colorText: '#3F8BF3',
        colorError: '#FF2929',
        colorPrimaryHover: '#1B79DE',
        colorBgContainerDisabled: '#F5F7FA',
        colorTextDisabled: '#97A3B5',
        borderRadiusLG: 8,
        colorLink: '#3F8BF3',
        borderColorDisabled: '#97A3B5',
        onlyIconSize: 16,
        lineWidthFocus: 1,
      },
      Typography: {
        colorTextHeading: '#192A4D',
        fontSizeHeading1: 24,
        fontSizeHeading2: 20,
        fontSizeHeading3: 16,
        fontSizeHeading4: 14,
        fontSizeHeading5: 12,
        lineHeightHeading2: 1.2,
        lineHeightHeading3: 2.0,
        lineHeightHeading4: 1.6,
        lineHeightHeading5: 1.4,
        colorLink: '#3F8BF3',
        titleMarginBottom: 0,
        titleMarginTop: 0,
      },
      Segmented: {
        colorBgLayout: '#E7F1F9',
        colorText: '#192A4D',
        colorTextLabel: '#192A4D',
        fontSizeLG: 14,
        boxShadowTertiary:
          '0px 1px 2px 0px rgba(113, 124, 140, 0.10), 0px 0.1px 0.3px 0px rgba(113, 124, 140, 0.10)',
      },
      Select: {
        colorFillSecondary: '#5A9DFF',
        colorIcon: '#FFFFFF',
        controlHeight: 36,
      },
      Checkbox: {
        controlInteractiveSize: isTabletWidth ? 20 : 16,
      },
      Tag: {
        fontSize: 14,
        colorSuccess: '#22BF3C',
        colorSuccessBg: '#E3FDE7',
        colorError: '#F5222D',
        colorErrorBg: '#FFF1F0',
        colorWarning: '#FA8C16',
        colorWarningBg: '#FFF7E6',
      },
      Breadcrumb: {
        linkColor: '#717C8C',
        lastItemColor: '#192A4D',
      },
      Notification: {
        width: 434,
        fontSize: 14,
        fontSizeLG: 14,
        paddingContentHorizontalLG: 16,
        paddingLG: 16,
        paddingMD: 12,
      },
      Steps: {
        colorPrimary: '#CAD3E0',
      },
      Divider: {
        colorSplit: '#E1E7F0',
      },
      Popover: {
        sizePopupArrow: 14,
      },
      Collapse: {
        motionDurationMid: '0.1s',
        motionDurationSlow: '0.2s',
      },
      Drawer: {
        motionDurationMid: '0.05s',
        motionDurationSlow: '0.1s',
      },
      Form: {
        colorError: '#DF1616',
      },
      Result: {
        paddingLG: 16,
        paddingXL: 0,
        iconFontSize: 60,
      },
      Alert: {
        colorInfoBg: '#F5F7FA',
        colorInfoBorder: '#FFFFFF',
        colorTextHeading: '#192A4D',
        colorText: '#192A4D',
      },
    },
  };
};
