import { Segmented } from 'antd';
import styled from 'styled-components';
import { SCREEN_WIDTH } from '../../screenSettings';

export const StyledSegmented = styled(Segmented)`
  width: 100%;
  font-weight: 600;
  @media screen and (max-width: ${SCREEN_WIDTH.TABLET}px) {
    &.ant-segmented {
      .ant-segmented-item-label {
        padding: 0 8px;
      }
    }
  }
`;
