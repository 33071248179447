import { FC } from 'react';
import { StyledFeaturesDescription } from './CarFeaturesDescription.styled';
import { ICarFeature } from '../../../../models';
import { getGroupedFeatures } from '../../../../utils/getGroupedFeatures';
import { CarFeaturesInfo } from '../../../../components/CarFeaturesInfo';

type CarFeaturesDescriptionProps = {
  features: ICarFeature[];
};

export const CarFeaturesDescription: FC<CarFeaturesDescriptionProps> = ({
  features,
}) => {
  const groupedFeaturesByCategory = getGroupedFeatures(features);

  return (
    <StyledFeaturesDescription>
      {groupedFeaturesByCategory
        .sort((item1, item2) => item1.categoryId - item2.categoryId)
        .map((feature) => (
          <CarFeaturesInfo
            key={feature.categoryId}
            sortedByCategoryFeatures={feature.features}
          />
        ))}
    </StyledFeaturesDescription>
  );
};
