import { ConfigProvider, Layout, notification, Space, Spin } from 'antd';
import { FC, useEffect } from 'react';
import { AppContext } from './contexts';
import { MyFooter } from './layout/Footer';
import { MyHeader } from './layout/Header';
import { Main } from './layout/Main';
import { theme } from './themeSettings/themeConfig';
import { SCREEN_WIDTH } from './screenSettings';
import {
  footerStyle,
  footerMobileStyle,
  StyledWrapper,
  headerStyle,
} from './App.styled';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import dayjs from 'dayjs';
import ru from 'dayjs/locale/ru';
import relativeTime from 'dayjs/plugin/relativeTime';
import { APP_PATHS } from './layout/settingsLayout';
import { useActions } from './hooks/useActions';
import { DomainExtension } from './enums/domain';
import { AppLoader } from './components/AppLoader';
import { useDomainSelector } from './store/selectors';
import ReactGA from 'react-ga4';

dayjs.locale('ru');
dayjs.extend(relativeTime);
dayjs.locale(ru);

export const App: FC = () => {
  const [api, contextHolder] = notification.useNotification();
  const location = useLocation();
  const { domainExtension } = useDomainSelector();
  const { setDomainExtension } = useActions();

  const isTabletWidth = useMediaQuery({
    query: `(max-width: ${SCREEN_WIDTH.TABLET}px)`,
  });
  const isMobileWidth = useMediaQuery({
    query: `(max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px)`,
  });

  let padding = '0 16px';
  if (isMobileWidth) padding = '0';

  let contentMarginBotton = 40;
  if (isTabletWidth && !isMobileWidth) contentMarginBotton = 56;
  if (isMobileWidth) contentMarginBotton = 16;

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      setDomainExtension(DomainExtension.By);
      return;
    }

    const domainExtension = window.location.hostname.split('.').pop();

    switch (domainExtension) {
      case DomainExtension.By:
        ReactGA.initialize(process.env.REACT_APP_GOOGLE_STREAM_ID_BY as string);
        break;
      case DomainExtension.Ru:
        ReactGA.initialize(process.env.REACT_APP_GOOGLE_STREAM_ID_RU as string);
        break;
      case DomainExtension.Net:
        ReactGA.initialize(
          process.env.REACT_APP_GOOGLE_STREAM_ID_NET as string,
        );
        break;
      default:
        break;
    }

    if (
      domainExtension &&
      Object.values(DomainExtension).includes(
        domainExtension as DomainExtension,
      )
    ) {
      setDomainExtension(domainExtension as DomainExtension);
    } else {
      setDomainExtension(DomainExtension.By);
      console.error('Unknown or missing domain extension, defaulting to BY');
    }
  }, []);

  if (!domainExtension) return <Spin />;

  return (
    <AppContext.Provider value={{ apiNotification: api }}>
      <ConfigProvider theme={theme(isMobileWidth, isTabletWidth)}>
        <AppLoader />
        <Space direction='vertical' style={{ width: '100%' }}>
          <Layout style={{ minHeight: '100vh' }}>
            {contextHolder}
            <Layout.Header style={{ ...headerStyle, padding }}>
              <StyledWrapper>
                <MyHeader />
              </StyledWrapper>
            </Layout.Header>
            <Layout.Content
              style={
                location.pathname === APP_PATHS.ROOT
                  ? undefined
                  : { padding, marginBottom: contentMarginBotton }
              }
            >
              <StyledWrapper pageLocation={location.pathname}>
                <Main />
              </StyledWrapper>
            </Layout.Content>
            <Layout.Footer
              style={
                isMobileWidth
                  ? {
                      ...footerStyle,
                      ...footerMobileStyle,
                    }
                  : { ...footerStyle }
              }
            >
              <StyledWrapper>
                <MyFooter />
              </StyledWrapper>
            </Layout.Footer>
          </Layout>
        </Space>
      </ConfigProvider>
    </AppContext.Provider>
  );
};
