import { Affix, ConfigProvider, Form, FormInstance, Typography } from 'antd';
import { FC, memo, useEffect, useState } from 'react';
import { IOption } from '../../models';
import { FormItemDrawer } from '../../pages/CarsPage/components/shared/FormItemDrawer';
import { ButtonFieldMobileForm } from './ButtonFieldMobileForm';
import { CheckboxGroup } from './CheckboxGroup';
import { CheckboxGroupItem } from './CheckboxGroupItem';
import { StyledButton } from '../styled/StyledButton';
import { mobileFormItemTheme } from '../../themeSettings';
import { FormItemLabel } from '../FormItemLabel';

interface IFilterCellMulti {
  form: FormInstance<any>;
  label: string;
  name: string;
  placeholder: string;
  value: number[] | undefined;
  //TODO after add country property to backed, make callback props optional
  callback: (value: number[] | undefined) => void;
  options: IOption[];
  disabled?: boolean;
}

export const FilterCellMulti: FC<IFilterCellMulti> = memo(
  ({ form, label, name, placeholder, value, callback, options, disabled }) => {
    const [open, setOpen] = useState<boolean>(false);
    const [labelsButton, setLabelButton] = useState<any>([]);
    const [checkedValue, setCheckedValue] = useState(value);

    const handleChange = (value: any) => {
      setCheckedValue(value);
    };

    const handleSubmit = () => {
      setOpen(!open);
      callback(checkedValue);
      setLabelsByCheckedValues();
      //TODO after add country property to backed uncomment it and remove callback props for FilterCellMulti
      // form.setFieldsValue({
      //   [name]: checkedValue,
      // });
    };

    const setLabelsByCheckedValues = () => {
      const checkedRegions =
        value &&
        Array.isArray(value) &&
        value.map((checkedValue: number) => {
          const matchedLabels = options.find(
            (option) => option.value === checkedValue,
          );
          return matchedLabels;
        });

      const labels =
        checkedRegions &&
        checkedRegions.filter(Boolean).map((el: any) => el.label);

      setLabelButton(labels);
    };

    useEffect(() => {
      setLabelsByCheckedValues();
      setCheckedValue(value);
    }, [value]);

    useEffect(() => {
      if (!open) {
        setCheckedValue(value);
      }
    }, [open]);

    return (
      <>
        <Form.Item label={<FormItemLabel>{label}</FormItemLabel>} name={name}>
          <ConfigProvider theme={mobileFormItemTheme}>
            <ButtonFieldMobileForm
              disabled={disabled}
              event={() => setOpen(true)}
            >
              {labelsButton?.length ? labelsButton.join(', ') : placeholder}
            </ButtonFieldMobileForm>
          </ConfigProvider>
        </Form.Item>
        <FormItemDrawer
          title={label}
          onClose={() => setOpen(false)}
          open={open}
        >
          <CheckboxGroup value={checkedValue} onChange={handleChange}>
            {options.map((option: IOption) => (
              <CheckboxGroupItem key={option.value} value={option.value}>
                {option.label}
              </CheckboxGroupItem>
            ))}
          </CheckboxGroup>
          <Affix style={{ margin: '0 8px' }} offsetBottom={30}>
            <StyledButton
              type='primary'
              onClick={handleSubmit}
              size='large'
              block
            >
              Применить
            </StyledButton>
          </Affix>
        </FormItemDrawer>
      </>
    );
  },
);
