import { russiaCountryValue, russiaRegionId } from '../../data';
import { ICarDetailsBase } from '../../models';
import { IFilterFormState } from '../../models/cars';
import { subscriptionActions } from './subscription.slice';
import {
  SubscriptionFilterResponse,
  SubscriptionOfferResponse,
} from '../../models/response/ISubscription';
import {
  CarBySubscriptionsResponse,
  CarBySubscriptionsModifiedResponse,
  DeleteCarFromSubscriptionsRequest,
  ToggleTelegramNotificationsRequest,
  CarsBySubscriptionsFilterResponse,
  ToggleFiltersTelegramNotificationsRequest,
} from '../../models/response/subscriptionsCars';
import { modifyParams } from '../../utils/cars';
import { transformStringToUppercase } from '../../utils/transformToLowerUpperCase';
import { api } from '../api/api';

export const subscriptionApi = api.injectEndpoints({
  endpoints: (build) => ({
    subscriptionFilter: build.mutation<
      SubscriptionFilterResponse,
      IFilterFormState
    >({
      query: (formState: IFilterFormState) => {
        const modifiedParams = modifyParams(
          formState,
          russiaCountryValue,
          russiaRegionId,
        );

        return {
          url: '/subscriptions/filters',
          method: 'POST',
          body: modifiedParams,
        };
      },
      invalidatesTags: ['SubscriptionFilters'],
    }),
    subscriptionOffer: build.mutation<
      SubscriptionOfferResponse,
      ICarDetailsBase
    >({
      query: (params: ICarDetailsBase) => {
        return {
          url: '/subscriptions/offers',
          method: 'POST',
          body: {
            adId: params.adId,
            sourceId: params.sourceId,
            currency: transformStringToUppercase(params.currency),
          },
        };
      },
      invalidatesTags: ['Subscription'],
    }),
    getOffersBySubscription: build.query<
      CarBySubscriptionsModifiedResponse,
      void
    >({
      query: () => {
        return {
          url: `/subscriptions/offers`,
        };
      },
      providesTags: ['Subscription'],
      transformResponse: (response: CarBySubscriptionsResponse) => ({
        notificationsEnabled: response.notificationsEnabled,
        offerSubscriptions: response.offerSubscriptions.map(
          (elem) => elem.details,
        ),
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const subscriptionsInfo = await queryFulfilled;

          if (subscriptionsInfo.data) {
            dispatch(
              subscriptionActions.setCarsBySubscription({
                notificationsEnabled:
                  subscriptionsInfo.data.notificationsEnabled,
                offerSubscriptions: subscriptionsInfo.data.offerSubscriptions,
              }),
            );
          }
        } catch (err) {
          console.error(err, 'INSIDE RTK GET SUBSCRIPTIONS CARS');
        }
      },
    }),
    getFiltersBySubscription: build.query<
      CarsBySubscriptionsFilterResponse,
      void
    >({
      query: () => {
        return {
          url: `/subscriptions/filters`,
        };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.filterSubscriptions.map(({ hash }) => ({
                type: 'SubscriptionFilters' as const,
                id: hash,
              })),
              'SubscriptionFilters',
            ]
          : ['SubscriptionFilters'],

      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const subscriptionsInfo = await queryFulfilled;

          if (
            subscriptionsInfo.data &&
            Array.isArray(subscriptionsInfo.data.filterSubscriptions)
          ) {
            dispatch(
              subscriptionActions.setCarsByFilters({
                notificationsEnabled:
                  subscriptionsInfo.data.notificationsEnabled,
                filterSubscriptions: subscriptionsInfo.data.filterSubscriptions,
              }),
            );
          }
        } catch (err) {
          console.error(err, 'INSIDE RTK GET CARS BY SUBSCRIPTIONS FILTERS');
        }
      },
    }),
    deleteSubscription: build.mutation<void, DeleteCarFromSubscriptionsRequest>(
      {
        query: (data: DeleteCarFromSubscriptionsRequest) => {
          return {
            url: `/subscriptions/offers?adId=${data.adId}&sourceId=${data.sourceId}`,
            method: 'DELETE',
          };
        },
      },
    ),
    deleteFilterSubscription: build.mutation<void, string>({
      query: (hash) => {
        return {
          url: `/subscriptions/filters/${hash}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['SubscriptionFilters'],
    }),
    toggleFiltersTelegramNotification: build.mutation<
      void,
      ToggleFiltersTelegramNotificationsRequest
    >({
      query: ({ isActive, hash }) => {
        return {
          url: `/subscriptions/filters/${hash}`,
          method: 'PATCH',
          body: { isActive },
        };
      },
      async onQueryStarted(requestBody, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            subscriptionActions.toggleACtiveSubscriptionByFilter(requestBody),
          );
        } catch (err) {
          console.error(
            err,
            'INSIDE RTK TOGGLE TELEGRAM NOTIFICATION BY FILTER',
          );
        }
      },
    }),
    toggleTelegramNotification: build.mutation<
      void,
      ToggleTelegramNotificationsRequest
    >({
      query: (body: ToggleTelegramNotificationsRequest) => {
        return {
          url: '/subscribers/notifications/telegram',
          method: 'PATCH',
          body,
        };
      },
      async onQueryStarted(requestBody, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(subscriptionActions.toggleNotification(requestBody.action));
        } catch (err) {
          console.error(err, 'INSIDE RTK TOGGLE TELEGRAM NOTIFICATION');
        }
      },
    }),
  }),
});

export const {
  useSubscriptionFilterMutation,
  useSubscriptionOfferMutation,
  useGetOffersBySubscriptionQuery,
  useGetFiltersBySubscriptionQuery,
  useLazyGetOffersBySubscriptionQuery,
  useDeleteSubscriptionMutation,
  useToggleTelegramNotificationMutation,
  useDeleteFilterSubscriptionMutation,
  useToggleFiltersTelegramNotificationMutation,
} = subscriptionApi;
