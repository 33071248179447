import { Result } from 'antd';
import styled from 'styled-components';
import { SCREEN_WIDTH } from '../../screenSettings';

export const StyledAntResult = styled(Result)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_2}px) {
    gap: 20px;
  }

  & .ant-result-title {
    font-size: 16px;
    font-weight: 600;
  }

  & .ant-result-subtitle {
    text-align: center;
    width: 100%;
    max-width: 100%;
    color: var(--Dark-13);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.21px;

    @media only screen and (max-width: ${SCREEN_WIDTH.TABLET}px) {
      max-width: 80%;
    }

    @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_2}px) {
      max-width: 100%;
    }
  }
  .ant-result-image {
    @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_2}px) {
      width: 240px;
      height: 270px;
    }
  }
`;
