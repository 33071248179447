import styled from 'styled-components';
import { SCREEN_WIDTH } from '../../../../screenSettings';

export const ButtonsBlock = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  max-width: var(--max-width-form-turnkey-desktop);
  margin-bottom: 40px;

  @media screen and (max-width: ${SCREEN_WIDTH.DESKTOP}px) {
    max-width: var(--max-width-form-turnkey-tablet);
  }

  @media screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_1}px) {
    width: 100%;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    gap: 16px;
  }

  @media screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
    max-width: var(--max-width-form-turnkey-mobile);
  }
`;

export const GroupButtons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /* @media screen and (max-width: ${SCREEN_WIDTH.TABLET}px) {
    width: 70%;
  } */

  @media screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_1}px) {
    flex-direction: column-reverse;
    align-items: stretch;
    justify-content: flex-start;
    gap: 16px;
  }
`;
