import dayjs from 'dayjs';
import { ParsedQs } from 'qs';
import { config } from '../../config';
import { SelectOptions } from '../../enums';
import { transformStringToUppercase } from '../transformToLowerUpperCase';
import { ICombinedFormState } from '../../models/cars';
import { formatNumber } from '../formatNumber';
import { Company } from '../../models/response/companies';

export const getDateFrom = (days: number): string =>
  dayjs().subtract(days, 'day').toISOString();

export const checkValue = (
  value: number | string | string[] | ParsedQs | ParsedQs[] | undefined,
) => {
  if (config.FORM.INVALID_VALUES.includes(value as SelectOptions))
    return undefined;

  return value;
};

export const checkArrayValidity = (
  value: [] | (string | number)[] | undefined,
): number[] | undefined => {
  if (value === undefined) return undefined;

  if (Array.isArray(value)) return value.map((item) => +item);

  return [+value];
};

//TODO: remove this logic after createion 'counrty' property on backend
export const modifyParams = (
  params: ICombinedFormState,
  russiaCountryValue: number,
  russiaRegionId: Array<{ value: number; label: string }>,
) => {
  const modifiedParams = { ...params };
  const { regionId, country } = params;

  if (country && !regionId) {
    modifiedParams.regionId = country.includes(russiaCountryValue)
      ? [...country, ...russiaRegionId.map((r) => r.value)]
      : [...country];
  } else if (country && regionId) {
    modifiedParams.regionId = [...country, ...regionId];
  } else if (!country && regionId) {
    modifiedParams.regionId = [...regionId];
  }

  if (modifiedParams.regionId) {
    const index = modifiedParams.regionId.indexOf(russiaCountryValue);

    if (index !== -1) {
      modifiedParams.regionId.splice(index, 1);
    }
  }

  if (modifiedParams.make) {
    modifiedParams.make = transformStringToUppercase(modifiedParams.make);
  }

  if (modifiedParams.model) {
    modifiedParams.model = transformStringToUppercase(modifiedParams.model);
  }

  if (country) delete modifiedParams.country;

  return modifiedParams;
};

export const getCountText = (
  isFetchingCountCars: boolean,
  countCars: number | undefined,
): string => {
  if (isFetchingCountCars) return 'Загрузка...';

  return countCars
    ? `Показать ${formatNumber(countCars)} объявлений`
    : 'Нет объявлений';
};

export const getDefaultDeliveryCompany = (
  companies: Company[] | undefined,
  regionId: number,
): Company | undefined => {
  const deliveryCompany = companies?.find((company) =>
    company.supportedRoutes.find((route) => route.originRegionId === regionId),
  );

  return deliveryCompany || undefined;
};
