import React, { FC, ReactNode } from 'react';
import { StyledLink } from '../styled/StyledLink';
import { theme } from 'antd';

interface IMyLink {
  children?: ReactNode;
  onClick?: () => void;
  href?: string;
  target?: string;
  classNameProp?: string;
}

const { useToken } = theme;

export const MyLink: FC<IMyLink> = ({ children, onClick, href, target, classNameProp }) => {
  const { token } = useToken();

  return (
    <StyledLink
      className={classNameProp}
      colorlink={token.colorLink}
      coloractive={token.colorLinkActive}
      colorhover={token.colorLinkHover}
      href={href}
      target={target}
      onClick={onClick}
    >
      {children}
    </StyledLink>
  );
};
