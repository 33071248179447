import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { CarsPage } from '../pages/CarsPage/CarsPage';
import { CarPage } from '../pages/CarPage/CarPage';
import { NotFoundPage } from '../pages/NotFoundPage/NotFoundPage';
import { SignIn } from '../pages/Auth/SignIn';
import { SignUp } from '../pages/Auth/SignUp/SignUp';
import { ConfirmEmail } from '../pages/Auth/ConfirmEmail/ConfirmEmail';
import { ForgotPassword } from '../pages/Auth/ForgotPassword';
import { ResetPassword } from '../pages/Auth/ResetPassword/ResetPassword';
import { AuthPage } from '../pages/Auth/AuthPage/AuthPage';
import { APP_PATHS } from './settingsLayout';
import { UserAlreadyAuth } from '../components/UserAlreadyAuth';
import { AuthRequired } from '../components/AuthRequired';
import { ProfilePage } from '../pages/ProfilePage/ProfilePage';
import { ProfileSettingsAccount } from '../pages/ProfilePage/components/ProfileSettingsAccount';
import { ProfileAdvertsMonitoring } from '../pages/ProfilePage/components/ProfileAdvertsMonitoring';
import { ProfileFilterSubscription } from '../pages/ProfilePage/components/ProfileFilterSubscription';
import { CarTurnKeyPage } from '../pages/CarTurnKeyPage';
import { Home } from '../pages/HomePage';
import { TrackPageViews } from '../components/TrackPageViews';
import { CalculateDeliveryPage } from '../pages/CarPage/CalculateDelivery';
import { CarInfo } from '../pages/CarPage/CarInfo';

export const Main: FC = () => (
  <TrackPageViews>
    <Routes>
      <Route path={APP_PATHS.ROOT} element={<Home />} />
      <Route element={<CarsPage />} path={APP_PATHS.CARS_PAGE}>
        <Route element={<CarsPage />} path={APP_PATHS.CARS_MAKE_PAGE}>
          <Route element={<CarsPage />} path={APP_PATHS.CARS_MAKE_MODEL_PAGE} />
        </Route>
      </Route>

      <Route path={APP_PATHS.CAR_PAGE} element={<CarPage />}>
        <Route index element={<CarInfo />} />
        <Route element={<CarInfo />} path={APP_PATHS.CAR_PAGE} />
        <Route
          element={<CalculateDeliveryPage onlyCarInspection={false} />}
          path={APP_PATHS.CAR_PAGE_CALCULATE_DELIVERY}
        />
        <Route
          element={<CalculateDeliveryPage onlyCarInspection={true} />}
          path={APP_PATHS.CAR_PAGE_CALCULATE_CAR_INSPECTION}
        />
      </Route>
      <Route path={APP_PATHS.CAR_TURN_KEY} element={<CarTurnKeyPage />} />

      <Route element={<AuthRequired />}>
        <Route path={APP_PATHS.PROFILE_PAGE} element={<ProfilePage />}>
          <Route
            path={APP_PATHS.PROFILE_PAGE_ACCOUNT}
            element={<ProfileSettingsAccount />}
          />
          <Route
            path={APP_PATHS.PROFILE_PAGE_SUBSCRIPTIONS_OFFER}
            element={<ProfileAdvertsMonitoring />}
          />
          <Route
            path={APP_PATHS.PROFILE_PAGE_SUBSCRIPTIONS_FILTER}
            element={<ProfileFilterSubscription />}
          />
        </Route>
      </Route>
      <Route path={APP_PATHS.API}>
        <Route path={APP_PATHS.AUTH}>
          <Route element={<AuthPage />} path={APP_PATHS.AUTH_PAGE}>
            <Route
              path={APP_PATHS.SIGN_IN}
              element={<UserAlreadyAuth destination={<SignIn />} />}
            />
            <Route
              path={APP_PATHS.SIGN_UP}
              element={<UserAlreadyAuth destination={<SignUp />} />}
            />
          </Route>
          <Route element={<ConfirmEmail />} path={APP_PATHS.CONFIRM_EMAIL} />
          <Route
            element={<ForgotPassword />}
            path={APP_PATHS.FORGOT_PASSWORD}
          />
          <Route element={<ResetPassword />} path={APP_PATHS.RESET_PASSWORD} />
        </Route>
      </Route>
      <Route path={APP_PATHS.NOT_FOUND_PAGE} element={<NotFoundPage />} />
    </Routes>
  </TrackPageViews>
);
