import { CSSProperties } from 'react';

import { sortingOfDate, sortingOfPeriod } from '../../../../data';
import { ConfigProvider, Form, Select, theme } from 'antd';
import { useCarsListPageContext } from '../../../../hooks/carsList';
import { CaretDownOutlined } from '@ant-design/icons';
import { useQueryState } from '../../../../hooks/useQueryState';
import { StyledDesktopSortForm } from './StyledDesktopSortForm';
import { initialValuesSortForm } from '../../../../constants';
import { SortFormFieldNames } from '../../../../enums/form';

const OptionStyle: CSSProperties = {
  height: 36,
  fontWeight: 100,
  display: 'flex',
  alignItems: 'center',
  marginBottom: 2,
};

export const DesktopSortForm = () => {
  const { token } = theme.useToken();
  const {
    sortForm,
    getCars,
    getCountCars,
    setPage,
    setCurrentCount,
    handleSetMetadata,
  } = useCarsListPageContext();
  const [queryParameters, setQueryParameters] = useQueryState();

  const handleFromChange = async (from: any): Promise<void> => {
    setPage(1);
    setQueryParameters({
      [SortFormFieldNames.from]: from,
    });

    const [countCars] = await Promise.all([
      getCountCars({
        [SortFormFieldNames.from]: from,
        [SortFormFieldNames.sortBy]: queryParameters.sortBy,
      }),
      queryParameters.page === '1'
        ? getCars({
            [SortFormFieldNames.from]: from,
            [SortFormFieldNames.sortBy]: queryParameters.sortBy,
          })
        : null,
    ]);

    if (!!countCars) {
      handleSetMetadata(countCars);
      setCurrentCount(countCars);
    }
  };

  const handleSortByChange = async (sortBy: any): Promise<void> => {
    setPage(1);
    setQueryParameters({
      [SortFormFieldNames.sortBy]: sortBy,
    });

    if (queryParameters.page === '1') {
      await getCars({
        [SortFormFieldNames.from]: queryParameters.from,
        [SortFormFieldNames.sortBy]: sortBy,
      });
    }
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimaryBorderHover: 'transparent',
          colorInfoBorderHover: 'transparent',
          colorTextPlaceholder: token.colorTextHeading,
        },
        components: {
          Select: {
            colorBorder: 'transparent',
          },
        },
      }}
    >
      <Form
        name='sortFormDesktop'
        layout='inline'
        autoComplete='off'
        form={sortForm}
        onFinish={() => console.log()}
        style={{ display: 'flex', justifyContent: 'end' }}
        fields={[
          {
            name: [SortFormFieldNames.from],
            value:
              queryParameters.from ||
              initialValuesSortForm[SortFormFieldNames.from],
          },
          {
            name: [SortFormFieldNames.sortBy],
            value: queryParameters.sortBy,
          },
        ]}
      >
        <StyledDesktopSortForm.StyledFormItem name={SortFormFieldNames.from}>
          <StyledDesktopSortForm.StyledCleanSelect
            onChange={handleFromChange}
            color={token.colorTextHeading}
            colordisabled={token.colorTextDisabled}
            size='large'
            placeholder='Период'
            suffixIcon={<CaretDownOutlined rev='true' />}
            listHeight={264}
            popupMatchSelectWidth={false}
            placement='bottomRight'
          >
            {sortingOfPeriod.map((option, index: number) => (
              <Select.Option
                style={OptionStyle}
                key={`${index}-${option.value}`}
                value={String(option.value)}
                label={option.label}
                id={option.value}
              >
                {option.label}
              </Select.Option>
            ))}
          </StyledDesktopSortForm.StyledCleanSelect>
        </StyledDesktopSortForm.StyledFormItem>

        <StyledDesktopSortForm.StyledFormItem name={SortFormFieldNames.sortBy}>
          <StyledDesktopSortForm.StyledCleanSelect
            onChange={handleSortByChange}
            color={token.colorTextHeading}
            colordisabled={token.colorTextDisabled}
            size='large'
            placeholder='Сортировка'
            suffixIcon={<CaretDownOutlined rev='true' />}
            listHeight={264}
            popupMatchSelectWidth={208}
            placement='bottomRight'
          >
            {sortingOfDate.map((option, index: number) => (
              <Select.Option
                style={OptionStyle}
                key={`${index}-${option.value}`}
                value={String(option.value)}
                label={option.label}
                id={option.value}
              >
                {option.label}
              </Select.Option>
            ))}
          </StyledDesktopSortForm.StyledCleanSelect>
        </StyledDesktopSortForm.StyledFormItem>
      </Form>
    </ConfigProvider>
  );
};
