import { Checkbox, ConfigProvider } from 'antd';
import { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';

const StyledCheckboxGroup = styled(Checkbox.Group)`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 24px;

  .ant-checkbox-wrapper-checked {
    background-color: #f5fcff;
  }
`;

export const CheckboxGroup: FC<
  PropsWithChildren<{
    value: [] | number[] | undefined;
    onChange: (value: unknown[]) => void;
  }>
> = ({ children, value, onChange }) => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Checkbox: {
            fontSize: 16,
          },
        },
      }}
    >
      <StyledCheckboxGroup value={value} onChange={onChange}>
        {children}
      </StyledCheckboxGroup>
    </ConfigProvider>
  );
};
