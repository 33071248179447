import { Form, FormInstance } from 'antd';
import { FormState } from '../components/FilterForms';
import { FilterFormFieldNames } from '../../../enums/form';

export const useWatchFilterForm = (form: FormInstance<FormState>) => {
  const country = Form.useWatch(FilterFormFieldNames.country, form);
  const regionId = Form.useWatch(FilterFormFieldNames.regionId, form);
  const make = Form.useWatch(FilterFormFieldNames.make, form);
  const model = Form.useWatch(FilterFormFieldNames.model, form);
  const yearFrom = Form.useWatch(FilterFormFieldNames.yearFrom, form);
  const yearTo = Form.useWatch(FilterFormFieldNames.yearTo, form);

  return { country, regionId, make, model, yearFrom, yearTo };
};
