import styled from 'styled-components';
import { CSSProperties } from 'react';
import { SCREEN_WIDTH } from '../../../../../screenSettings';

export const TwoColumnFormLayoutStyles: CSSProperties = {
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  columnGap: 40,
};

export const ThreeColumnTwoRowsFormLayoutStyles: CSSProperties = {
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gridTemplateRows: 'auto 1fr',
  columnGap: 40,
  rowGap: 32,
};

export const ThreeColumnFormLayoutStyles: CSSProperties = {
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  columnGap: 40,
};

export const StyledTitleForm = styled.h3`
  color: var(--Dark-13);
  width: 100%;
  margin: 40px auto 32px;
  font-size: 18px;
  font-weight: 600;
  max-width: var(--max-width-form-turnkey-desktop);

  @media screen and (max-width: ${SCREEN_WIDTH.DESKTOP}px) {
    max-width: var(--max-width-form-turnkey-tablet);
  }

  @media screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_2}px) {
    margin: 24px 16px;
    font-size: 16px;
  }
`;

export const TitleRow = styled.div`
  margin-bottom: 0;
  display: flex;
  height: 32px;
  align-items: center;
  gap: 8px;
`;

export const TitleRowDescription = styled.div`
  color: var(--Dark);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.21px;
`;
