import { FC } from 'react';
import { CarPriceBlockPropsType, CarPriceBlock } from '../Car';
import { StyledCarPriceItems } from './CarItemPrices.styled';

type CatItemPricesPropsType = {
  pricesProps: CarPriceBlockPropsType[];
};

export const CatItemPrices: FC<CatItemPricesPropsType> = ({ pricesProps }) => {
  return (
    <StyledCarPriceItems>
      {pricesProps.map((item, index) => (
        <CarPriceBlock
          {...item}
          key={`${item.description}`}
          showDivider={index !== pricesProps.length - 1}
        />
      ))}
    </StyledCarPriceItems>
  );
};
