import { NON_IMPORTANT_TYPE_VALUE } from '../../../constants';
import {
  EngineTypes,
  TypeofDrive,
  BodyColorsType,
  BodyType,
} from '../../../models/turn-key/GetOptionsResponse';
import { Nullable } from '../../../models';

export const getCarEngineOptions = (source: Nullable<EngineTypes[]>) => {
  const temp = Array.isArray(source)
    ? source
        .map((type) => ({
          label: type.name,
          value: type.engineType,
        }))
        .sort((item1, item2) => item2.label.length - item1.label.length)
    : [];
  return [
    {
      label: 'Не важно',
      value: NON_IMPORTANT_TYPE_VALUE,
    },
    ...temp,
  ];
};

export const getCarTypesOfDrive = (source: Nullable<TypeofDrive[]>) => {
  const temp = Array.isArray(source)
    ? source.map((type) => ({
        label: type.name.split(' ')[0],
        value: type.typeOfDrive,
      }))
    : [];
  return [
    {
      label: 'Не важно',
      value: NON_IMPORTANT_TYPE_VALUE,
    },
    ...temp,
  ];
};

export const getCarBodyColors = (source: Nullable<BodyColorsType[]>) => {
  const temp = Array.isArray(source)
    ? source
        .map((type) => ({
          label: type.name,
          value: type.colorKey,
        }))
        .sort((item1, item2) => item2.label.length - item1.label.length)
    : [];
  return [
    {
      label: 'Не важно',
      value: NON_IMPORTANT_TYPE_VALUE.toString(),
    },
    ...temp,
  ];
};

export const getCarBodyTypes = (source: Nullable<BodyType[]>) => {
  const temp = Array.isArray(source)
    ? source
        .map((type) => ({
          label: type.name,
          value: type.bodyType,
        }))
        .sort((item1, item2) => item2.label.length - item1.label.length)
    : [];
  return [
    {
      label: 'Не важно',
      value: NON_IMPORTANT_TYPE_VALUE,
    },
    ...temp,
  ];
};
