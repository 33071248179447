import styled from 'styled-components';

export const StyledFeatureItem = styled.li`
  color: var(--Dark-13);
  font-style: var(--font-style-base);
  font-weight: var(--main-font-weight);
  font-size: var(--main-font-size);
  line-height: var(--desktop-content-line-height);
  letter-spacing: 0.21px;
  text-align: left;
`;
