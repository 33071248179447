import styled from 'styled-components';
import { SCREEN_WIDTH } from '../screenSettings';
import { Col, Row, Typography } from 'antd';
import { activeColor, hoverColor } from './Footer.data';

export const StyledFooterContent = styled(Col)`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: 24px;
`;

export const StyledSocialCol = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
    align-items: flex-start;
    width: 40%;
    justify-items: flex-start;
  }
`;

export const StyledSocialRow = styled(Row)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 24px;
  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
    flex-wrap: wrap;
    justify-content: flex-start;
    padding-left: 16px;
  }
`;

export const StyledSocialTitle = styled(Typography.Text)`
  margin-bottom: 16px;
  letter-spacing: 0.21px;
`;

export const StyledCopyrightText = styled(Typography.Text)`
  line-height: 14px;
  letter-spacing: 0.048px;
  opacity: 0.7;
  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
    padding-left: 16px;
    line-height: 140%;
  }
`;

export const StyleSocialIconLink = styled.a`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: stroke 0.3s ease;

  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
    justify-content: flex-start;
  }

  &:hover {
    svg:not(.InstagramSVG) {
      path {
        stroke: ${hoverColor};
        transition: stroke 0.3s ease;
      }
    }

    .InstagramSVG {
      path {
        fill: ${hoverColor};
        transition: fill 0.3s ease;
      }
    }
  }

  &:active {
    svg:not(.InstagramSVG) {
      path {
        stroke: ${activeColor};
      }
    }

    .InstagramSVG {
      path {
        fill: ${activeColor};
        transition: fill 0.3s ease;
      }
    }
  }
`;
