import { FC, ReactNode, CSSProperties } from 'react';
import {
  StyledBlockRow,
  StyledKeyBlock,
  StyledTextKey,
  TextValueBold,
  StyledVerticalDivider,
} from './PriceRowWithDiscount.styled';
import { StyledHighlitedText } from '../styled/StyledHighlitedText';
import { formatNumber } from '../../utils/formatNumber';
import { getCurrencySymbol } from '../../utils/getCurrencySymbol';

type PriceRowWithDiscountPropsType = {
  title: string | ReactNode;
  priceOriginal: number;
  currencyOriginal: string;
  priceOriginalDiscount: number;
  currencyOriginalDiscount: string;
  priceServices: number;
  currencyConverted: string;
  style?: CSSProperties;
};

export const PriceRowWithDiscount: FC<PriceRowWithDiscountPropsType> = ({
  title,
  currencyConverted,
  currencyOriginal,
  currencyOriginalDiscount,
  priceOriginal,
  priceOriginalDiscount,
  priceServices,
  style,
}) => {
  return (
    <StyledBlockRow style={style}>
      <StyledKeyBlock>
        <StyledTextKey>{title}</StyledTextKey>
      </StyledKeyBlock>
      <TextValueBold>
        <span
          style={{
            display: 'inline-block',
            margin: '0 8px 0 0',
            textDecoration: 'line-through',
          }}
        >
          {formatNumber(priceOriginal)}&nbsp;
          {getCurrencySymbol(currencyOriginal)}
        </span>

        <StyledHighlitedText>
          {formatNumber(priceOriginalDiscount)}&nbsp;
          {getCurrencySymbol(currencyOriginalDiscount)}
        </StyledHighlitedText>
        <StyledVerticalDivider type='vertical' />
        <span>
          ~&nbsp;{formatNumber(priceServices)}&nbsp;
          {getCurrencySymbol(currencyConverted)}
        </span>
      </TextValueBold>
    </StyledBlockRow>
  );
};
