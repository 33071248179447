import { FC } from 'react';

import { HeaderOptionMenu } from '../HeaderOptionMenu';
import { UserAvatarBlock } from '../UserAvatarBlock';

type DrawerPopoverContentWithMenuPropsType = {
  additionalClickOptionAction?: () => void;
  additionalMarginTopDivider?: number;
};

export const DrawerPopoverContentWithMenu: FC<
  DrawerPopoverContentWithMenuPropsType
> = ({ additionalClickOptionAction, additionalMarginTopDivider }) => (
  <nav>
    <UserAvatarBlock />
    <HeaderOptionMenu
      additionalActionClick={additionalClickOptionAction}
      additionalMarginTopDivider={additionalMarginTopDivider}
    />
  </nav>
);
