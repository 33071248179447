import { FC, useState } from 'react';
import { StyledButton } from '../../../../components/styled/StyledButton';
import { QuestionCircleOutlined } from '@ant-design/icons';
import {
  SendRequestForm,
  SendRequestModalForm,
} from '../../../../components/SendRequestModalForm';
import { useAskQuestionMutation } from '../../../../store/car/car.api';
import ReactGA from 'react-ga4';
import { GoogleAnanalyticsEvent } from '../../../../enums/googleAnalyticsAction';
import { useCarPageSelector } from '../../../../store/selectors';
import { Form, notification } from 'antd';
import { config } from '../../../../config';
import { CarPageOutletContext } from '../../CarPage.interface';
import { useOutletContext } from 'react-router-dom';
import { AskQuestionPayload } from '../../../../models/car/CarRequests';

export const AskQuestionAction: FC = () => {
  const [askQuestionForm] = Form.useForm<SendRequestForm>();
  const [notificationApi, contextHolder] = notification.useNotification();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { car } = useOutletContext<CarPageOutletContext>();
  const { calculateDeliveryForm } = useCarPageSelector();
  const [askQuestion, { isLoading: isAskQustionLoading }] =
    useAskQuestionMutation();

  const showModal = () => {
    if (process.env.NODE_ENV === 'production') {
      ReactGA.event({
        category: 'button',
        action: GoogleAnanalyticsEvent.OpenAskQuestionForm,
        label: window.location.href,
      });
    }

    setIsOpenModal(true);
  };

  const sendRequest = async (values: SendRequestForm) => {
    if (process.env.NODE_ENV === 'production') {
      ReactGA.event({
        category: 'button',
        action: GoogleAnanalyticsEvent.SubmitAskQuestionForm,
        label: window.location.href,
      });
    }

    const { companyId, destinationRegionId } = calculateDeliveryForm;

    if (!companyId || !destinationRegionId) {
      notificationApi.error({
        message: 'Произошла ошибка при отправке заявки.',
        description: 'companyId или destinationRegionId неизвестны',
        duration: config.DEFAULT_NOTIFICATION_DURATION,
      });
      return;
    }

    const { personalDataProcessingConsent, ...formDataWithoutAgreement } =
      values;

    const body: AskQuestionPayload = {
      ...formDataWithoutAgreement,
      adId: car.adId,
      phone: formDataWithoutAgreement.phone.toString(),
      sourceId: car.sourceId,
    };

    try {
      await askQuestion(body).unwrap();
      setIsOpenModal(false);
      askQuestionForm.resetFields();
      notificationApi.success({
        message: 'Заявка успешно отправлена!',
        description: 'Ожидайте обратной связи.',
        duration: config.DEFAULT_NOTIFICATION_DURATION,
      });
    } catch (error: any) {
      console.error(error);
      notificationApi.error({
        message: 'Произошла ошибка при отправке заявки.',
        description: `${error?.status} ${error?.data?.title || ''}`,
        duration: config.DEFAULT_NOTIFICATION_DURATION,
      });
    }
  };

  return (
    <>
      {contextHolder}
      <StyledButton
        type='primary'
        ghost
        size='large'
        block
        icon={<QuestionCircleOutlined style={{ fontSize: 16 }} />}
        onClick={showModal}
      >
        Задать вопрос
      </StyledButton>

      <SendRequestModalForm
        title='Задать вопрос'
        isOpenModal={isOpenModal}
        setIsOpenModal={setIsOpenModal}
        sendRequest={sendRequest}
        form={askQuestionForm}
        isLoading={isAskQustionLoading}
        withMessageField={true}
      />
    </>
  );
};
