import styled from 'styled-components';
import { Form, Select } from 'antd';

const StyledCleanSelect = styled(Select)<{
  color: string;
  colordisabled: string;
}>`
  .ant-select-selector {
    font-size: 12px !important;
    border-color: transparent !important;
    box-shadow: none !important;
    color: ${(props) => props.color} !important;
    text-transform: uppercase;
    font-weight: 600;

    .ant-select-selection-item {
      font-weight: 600;
      text-transform: uppercase;
    }
  }

  .ant-select-arrow svg {
    color: ${(props) => props.color} !important;
    transition: 0.2s;
  }

  .ant-select-open {
    .ant-select-arrow svg {
      color: ${(props) => props.color} !important;
      color: ${(props) => props.colordisabled} !important;
    }
  }
`;

const StyledFormItem = styled(Form.Item)`
  margin-inline-end: 0 !important;
`;

export const StyledDesktopSortForm = {
  StyledCleanSelect,
  StyledFormItem,
};
