import { FC } from 'react';
import {
  StyledAdditionalRectangle,
  StyledFormContainer,
  StyledHomeContainer,
  StyledHomeContent,
  StyledHomeContentWrapper,
  StyledHomePage,
  StyledSubTitle,
  StyledSubTitleContainer,
  StyledTextContainer,
  StyledTitle,
} from './HomePage.styled';
import { FilterForms } from './components/FilterForms';
import { useSpring, animated } from '@react-spring/web';
import { APP_PATHS } from '../../layout/settingsLayout';
import { useMetadata } from '../../hooks/seo';
import { Metadata } from '../../components/Metadata';

const subTitleData = [
  'Тысячи объявлений о продаже автомобилей',
  'Авто под ключ (поиск, осмотр, доставка)',
  'Аналитика стоимости и пробега',
  'Калькулятор расходов каждого объявления',
  'Уведомления об авто в телеграм',
];

export const Home: FC = () => {
  const metadata = useMetadata(APP_PATHS.ROOT);
  const animateFormStyles = useSpring({
    from: { opacity: 0, transform: 'translate3d(+100%,0,0)' },
    to: { opacity: 1, transform: 'translate3d(0%,0,0)' },
  });

  const animateMainTextStyles = useSpring({
    from: { opacity: 0, transform: 'translate3d(-100%,0,0)' },
    to: { opacity: 1, transform: 'translate3d(0%,0,0)' },
  });

  return (
    <>
      <Metadata title={metadata.title} description={metadata.description} />

      <StyledHomePage>
        <StyledHomeContainer>
          <StyledHomeContent>
            <StyledHomeContentWrapper>
              <StyledTextContainer>
                <animated.div style={animateMainTextStyles}>
                  <StyledTitle>АВТО ИЗ Европы, США и Китая</StyledTitle>
                  <StyledSubTitleContainer>
                    {subTitleData.map((subTitle) => (
                      <StyledSubTitle key={subTitle}>{subTitle}</StyledSubTitle>
                    ))}
                  </StyledSubTitleContainer>
                </animated.div>
              </StyledTextContainer>

              <StyledFormContainer>
                <animated.div style={animateFormStyles}>
                  <FilterForms />
                </animated.div>
              </StyledFormContainer>
            </StyledHomeContentWrapper>
          </StyledHomeContent>
        </StyledHomeContainer>
        <StyledAdditionalRectangle />
      </StyledHomePage>
    </>
  );
};
