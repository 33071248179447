import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { InitialStateDomain } from './domain.types';
import { DomainExtension } from '../../enums/domain';

const initialState: InitialStateDomain = {
    domainExtension: undefined,
};

export const domainSlice = createSlice({
  name: 'domain',
  initialState,
  reducers: {
    setDomainExtension: (
      state,
      { payload }: PayloadAction<DomainExtension>,
    ) => {
      state.domainExtension = payload;
    },
  },
});

export const domainActions = domainSlice.actions;
export const domainReducer = domainSlice.reducer;
