import { useLazyGetCarClonesQuery } from '../../store/car/car.api';
import { notification } from 'antd';
import { config } from '../../config';
import { ICarDetailsBase } from '../../models';

export const useGetCarClones = () => {
  const [
    fetchCarClones,
    {
      isError: isErrorCarClones,
      data: carClones,
      isFetching: isFetchingCarClones,
    },
  ] = useLazyGetCarClonesQuery();
  const [notificationApi, contextHolder] = notification.useNotification();

  const getCarClones = async (params: ICarDetailsBase): Promise<void> => {
    try {
      await fetchCarClones(params);
    } catch (err: any) {
      if (process.env.NODE_ENV === 'development') {
        console.log(err);
      }
      notificationApi.error({
        message: 'Ошибка загрузки клонов авто',
        description: err?.data?.message || 'Не удалось загрузить колны авто',
        duration: config.DEFAULT_NOTIFICATION_DURATION,
      });
    }
  };

  return {
    getCarClones,
    carClones,
    isErrorCarClones,
    isFetchingCarClones,
    contextHolder,
  };
};
