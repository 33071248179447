import { Spin } from 'antd';
import { useGetMakesQuery } from '../../store/cars/cars.api';
import { useGetCompaniesQuery } from '../../store/companies/companies.api';
import { useGetUserInfo } from '../../hooks/app';

export const AppLoader = () => {
  const { isLoading: isFetchingMakes } = useGetMakesQuery();
  const { isLoading: isFetchingCompanies } = useGetCompaniesQuery();
  const { contextHolder: contextHolderUser } = useGetUserInfo();

  return (
    <>
      <Spin
        spinning={isFetchingMakes || isFetchingCompanies}
        style={{ display: 'none' }}
      />
      {contextHolderUser}
    </>
  );
};
