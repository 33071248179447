import { FC, PropsWithChildren } from 'react';
import { StyledFormItem } from '../../styled/StyledFormItem';
import { FormItemProps } from 'antd/es/form';
import { SelectProps, Select } from 'antd';

interface CustomSelectPropsType extends SelectProps {
  formItemProps: FormItemProps;
}

export const CustomSelect: FC<PropsWithChildren<CustomSelectPropsType>> = ({
  formItemProps,
  children,
  ...selectProps
}) => {
  return (
    <StyledFormItem {...formItemProps}>
      <Select style={{ color: '#FFFFFF' }} {...selectProps}>
        {children}
      </Select>
    </StyledFormItem>
  );
};
