import { FC, ReactNode, useRef, CSSProperties } from 'react';
import { PopoverProps } from 'antd';
import {
  StyledIconWrapper,
  StyledPopoverOnIcon,
  StyledAntdInfoCircleOutlined,
} from './IconWithTooltip.styled';
import { PopoverActionType } from '../../models';
import { useMediaQuery } from 'react-responsive';
import { SCREEN_WIDTH } from '../../screenSettings';

interface IconWithTooltipPropsType extends PopoverProps {
  description: string | ReactNode;
  customPopoverWidth?: string | number;
  icon?: ReactNode;
  customTrigger?: PopoverActionType | PopoverActionType[];
  styles?: CSSProperties;
}

export const IconWithTooltip: FC<IconWithTooltipPropsType> = ({
  description,
  icon,
  customPopoverWidth,
  customTrigger,
  styles,
  ...popoverProps
}) => {
  const isMobileWidth = useMediaQuery({
    query: `(max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px)`,
  });
  const refContainerPopover = useRef<HTMLSpanElement>(null);

  return (
    <StyledIconWrapper ref={refContainerPopover} style={styles}>
      <StyledPopoverOnIcon
        zIndex={20}
        getPopupContainer={() => refContainerPopover.current as HTMLElement}
        destroyTooltipOnHide={true}
        overlayClassName='popover__icon'
        align={{ offset: [-8, -13] }}
        overlayStyle={{
          boxShadow: '0px 2px 8px 0px rgba(0, 42, 126, 0.1)',
          width:
            !isMobileWidth && customPopoverWidth ? customPopoverWidth : 230,
        }}
        overlayInnerStyle={{
          padding: '12px 16px',
          width: '100%',
          textAlign: 'center',
        }}
        placement={isMobileWidth ? 'top' : 'topLeft'}
        content={description}
        trigger={customTrigger ? customTrigger : ['hover', 'click']}
        {...popoverProps}
      >
        {icon ? icon : <StyledAntdInfoCircleOutlined />}
      </StyledPopoverOnIcon>
    </StyledIconWrapper>
  );
};
