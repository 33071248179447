import styled from 'styled-components';
import { Typography } from 'antd';

export const StyledTitle = styled(Typography.Text)`
  margin-bottom: 32px;
  display: block;
  font-size: 22px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0.033px;
`;
