import styled from 'styled-components';
import { Divider, Typography } from 'antd';
import { SCREEN_WIDTH } from '../../../../screenSettings';

const { Paragraph } = Typography;

export const StyledDivider = styled(Divider)`
  margin: 40px 0;
`;

export const StyledAdRatingSliderDescriptionContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex: 1;
  align-items: flex-end;
  @media screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_4}px) {
    align-items: flex-start;
  }
`;

export const StyledAdRatingSliderDescriptionTitle = styled(Paragraph)`
  margin-bottom: 8px !important;
  font-size: var(--desktop-content-font-size);
  font-style: var(--font-style-base);
  font-weight: var(--main-font-weight);
  line-height: var(--desktop-content-line-height);
  letter-spacing: 0.08px;

  @media screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_1}px) {
    font-weight: 400;
    font-size: 10px;
    font-style: normal;
    line-height: 14px;
    letter-spacing: 0.04px;
    margin-bottom: 0 !important;
  }
`;

export const StyledAdRatingSliderDescriptionSubTitle = styled(Paragraph)`
  margin-bottom: 0 !important;
`;

export const StyledDescriptionRatingContainer = styled.div`
  margin-top: 6px;
  margin-bottom: 144px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-desktop-row-small);

  @media screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_2}px) {
    margin-bottom: 120px;
    margin-top: 0;
  }
  @media screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_4}px) {
    margin-bottom: 96px;
    align-items: flex-start;
    & .analytics__section_title {
      font-size: 13px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      gap: 3px;
    }
  }
`;

export const StyledAdRatingSliderTextContainer = styled.div<{ ranges: number }>`
  text-align: center;
  width: ${(props) =>
    props.ranges === 4 ? `calc(25% - 10px)` : `calc(30% - 10px)`};

  &:first-child {
    text-align: start;
  }

  &:last-child {
    text-align: end;
  }
  @media screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_1}px) {
    font-size: 10px;
    font-style: normal;
    line-height: 14px;
    letter-spacing: 0.04px;
    width: ${(props) =>
      props.ranges === 4 ? `calc(25% - 1px)` : `calc(30% - 1px)`};
  }
`;
