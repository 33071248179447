export const HorsePowerSvg = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
  >
    <g clipPath='url(#clip0_4756_1393)'>
      <path
        d='M8 16C3.58667 16 0 12.4133 0 8C0 3.58667 3.58667 0 8 0C12.4133 0 16 3.58667 16 8C16 12.4133 12.4133 16 8 16ZM8 0.666667C3.95333 0.666667 0.666667 3.95333 0.666667 8C0.666667 12.0467 3.95333 15.3333 8 15.3333C12.0467 15.3333 15.3333 12.0467 15.3333 8C15.3333 3.95333 12.0467 0.666667 8 0.666667ZM10.54 8.80667C10.6933 8.58 10.7067 8.29333 10.5733 8.05333C10.4467 7.81333 10.1933 7.66667 9.92667 7.66667H6.07333C6.07333 7.66667 6.02 7.64667 6.01333 7.63333C6 7.62 5.99333 7.59333 5.98 7.62L8.29333 3.11333L7.7 2.80667L5.36 7.36667C5.29333 7.59333 5.33333 7.83333 5.47333 8.02667C5.61333 8.22 5.83333 8.32667 6.07333 8.32667H9.92667C9.92667 8.32667 9.97333 8.34 9.98667 8.36C10 8.38 10 8.40667 9.96667 8.46L7.7 12.8067L8.29333 13.1133L10.54 8.79333V8.80667Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_4756_1393'>
        <rect width='16' height='16' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
