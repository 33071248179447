import {FC, ReactNode, CSSProperties} from 'react';
import styled from "styled-components";

const StyledFullHeightPage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 80px - 80px);
`;

interface IContainerSpin {
  children: ReactNode;
  style?: CSSProperties;
}

export const FullHeightContainer: FC<IContainerSpin> = ({ children, style }) => {
  return (
    <StyledFullHeightPage style={style}>
      {children}
    </StyledFullHeightPage>
  );
};