import { StepProps } from 'antd';
import styled from 'styled-components';
import { SCREEN_WIDTH } from '../../../../screenSettings';

const HiddenLessThanDesktopPart = styled.span`
  @media screen and (max-width: ${SCREEN_WIDTH.DESKTOP}px) {
    display: none;
  }
`;

export const stepsItems: StepProps[] = [
  {
    title: (
      <>
        Параметры <HiddenLessThanDesktopPart>поиска</HiddenLessThanDesktopPart>
      </>
    ),
    description: null,
  },
  {
    title: 'Комплектация',
    description: null,
  },
  {
    title: 'Доставка и стоимость ',
    description: null,
  },
];
