import { FC } from 'react';
import { ProvidedTagsType } from '../../models/car';
import { StyledAdditionalTagsWrapper } from './CarAdditionalTags.styled';
import { TagWithTooltip } from '../TagWithTooltip';

type CarAdditionalTagsPropsType = {
  tags: ProvidedTagsType[];
};

export const CarAdditionalTags: FC<CarAdditionalTagsPropsType> = ({ tags }) => {
  return (
    <StyledAdditionalTagsWrapper>
      {tags.map((tag, index) => (
        <TagWithTooltip key={tag.label + index} tag={tag} />
      ))}
    </StyledAdditionalTagsWrapper>
  );
};
