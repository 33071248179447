import { Row, ConfigProvider } from 'antd';
import {
  StyledFooterContent,
  StyledSocialRow,
  StyledSocialCol,
  StyleSocialIconLink,
  StyledSocialTitle,
  StyledCopyrightText,
} from './Footer.styled';
import { FC } from 'react';
import Icon from '@ant-design/icons';
import { TelegramSVG } from '../img/icons/TelegramSVG';
import { MailSVG } from '../img/icons/MailSVG';
import { InstagramSVG } from '../img/icons/InstagramSVG';

export const MyFooter: FC = () => {
  return (
    <ConfigProvider
      theme={{
        token: {},
        components: {
          Typography: {
            colorText: '#FFFFFF',
          },
        },
      }}
    >
      <StyledFooterContent>
        <StyledSocialRow>
          <StyledSocialCol>
            <StyledSocialTitle>Telegram-bot:</StyledSocialTitle>
            <StyleSocialIconLink href={process.env.REACT_APP_TELEGRAM_URL} target='_blank'>
              <Icon rev='true' component={TelegramSVG} />
            </StyleSocialIconLink>
          </StyledSocialCol>

          <StyledSocialCol>
            <StyledSocialTitle>Write us:</StyledSocialTitle>
            <StyleSocialIconLink href={`mailto:${process.env.REACT_APP_EMAIL}`}>
              <Icon rev='true' component={MailSVG} />
            </StyleSocialIconLink>
          </StyledSocialCol>

          <StyledSocialCol>
            <StyledSocialTitle>Find us here:</StyledSocialTitle>
            <StyleSocialIconLink href={process.env.REACT_APP_INSTAGRAM_LINK} target='_blank'>
              <Icon rev='true' component={InstagramSVG} />
            </StyleSocialIconLink>
          </StyledSocialCol>
        </StyledSocialRow>
        <Row>
          <StyledCopyrightText>All content and images Copyright&nbsp;©2023&nbsp;AutoAggregator</StyledCopyrightText>
        </Row>
      </StyledFooterContent>
    </ConfigProvider>
  );
};
