import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StepsValues } from '../../pages/CarTurnKeyPage/enums/stepsValues';

type InitialState = {
  activeStep: StepsValues;
  errorStatusSteps: 'error' | undefined;
};

const initialState: InitialState = {
  errorStatusSteps: undefined,
  activeStep: StepsValues.SearchParams,
};

export const turnKeyStepsSlice = createSlice({
  name: 'turnKeySteps',
  initialState,
  reducers: {
    setActiveStep: (state, { payload }: PayloadAction<StepsValues>) => {
      state.activeStep = payload;
    },
    setErrorStatus: (state, { payload }: PayloadAction<boolean>) => {
      payload
        ? (state.errorStatusSteps = 'error')
        : (state.errorStatusSteps = undefined);
    },
  },
});

export const turnKeyStepsActions = turnKeyStepsSlice.actions;
export const turnKeyStepsReducer = turnKeyStepsSlice.reducer;
