import { api } from '../api/api';
import { profileActions } from './profile.slice';

import {
  GetAccountSettingsResponseType,
  UpdateAccountSettingsRequestType,
} from '../../models/profile';
import { ApiEndpoints } from '../api/endpoints';

export const profileApi = api.injectEndpoints({
  endpoints: (build) => ({
    getProfileInfo: build.query<GetAccountSettingsResponseType, void>({
      query: () => {
        return {
          url: ApiEndpoints.AccountSettings,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const userSettings = await queryFulfilled;
          if (userSettings.data) {
            dispatch(profileActions.setUserInfo(userSettings.data));
          }
        } catch (err) {
          console.error(err, 'INSIDE RTK GET USER INFO');
        }
      },
      providesTags: ['User'],
    }),
    updateProfileInfo: build.mutation<void, UpdateAccountSettingsRequestType>({
      query: (body) => {
        return {
          url: ApiEndpoints.AccountSettings,
          method: 'PATCH',
          body,
        };
      },
      invalidatesTags: ['User'],
    }),
  }),
});

export const {
  useLazyGetProfileInfoQuery,
  useGetProfileInfoQuery,
  useUpdateProfileInfoMutation,
} = profileApi;
