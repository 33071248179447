import styled from 'styled-components';

const StyledFormWrapper = styled.div`
  padding: 32px 16px 24px 16px;

  & .ant-form {
    .ant-form-item-control {
      position: relative;
    }
    .ant-form-item-explain {
      position: absolute;
    }
    .ant-form-item {
      margin-bottom: 0;
    }

    display: flex;
    flex-direction: column;
    gap: 24px;
  }
`;

export const StyledDesktopFilterForm = {
  StyledFormWrapper,
};
