import { ErrorMachineName } from '../enums';
import { useAppContext } from '../../../hooks/app';
import { errors } from '../mappings';
import { AUTH_ERROR_MESSAGES } from '../data';
import { config } from '../../../config';

export const useAuthErrorNotification = () => {
  const { apiNotification } = useAppContext();

  return (message: string, error: any) => {
    apiNotification.error({
      message,
      description:
        errors[error?.data?.code as ErrorMachineName] ||
        error?.message ||
        AUTH_ERROR_MESSAGES.SUPPORT, //TODO: add link to message after design
      duration: config.DEFAULT_NOTIFICATION_DURATION,
    });
  };
};
