import styled from 'styled-components';
import { SCREEN_WIDTH } from '../../screenSettings';

export const StyledSliderWrapper = styled.div`
  & .ant-tooltip-inner {
    color: var(--Dark-13);
    background-color: #93c2e87d;
    box-shadow: var(--tooltip-box-shadow);
  }
  & .ant-tooltip-arrow::before {
    background-color: #93c2e87d;
  }
  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_2}px) {
    & .ant-slider {
      margin: auto;
      margin-top: 18px;
      max-width: 98%;
    }
  }
`;
