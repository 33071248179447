import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useIsUserAuth } from '../../hooks/auth/useIsUserAuth';
import { NAVIGATE_PATHS } from '../../layout/settingsLayout';

export const AuthRequired = () => {
  const isUserAuth = useIsUserAuth();
  const location = useLocation();

  return isUserAuth ? (
    <Outlet />
  ) : (
    <Navigate
      to={NAVIGATE_PATHS.SIGN_IN}
      state={{ from: location }}
      replace={true}
    />
  );
};
