import { useEffect, useRef } from 'react';
import { useProfileNotification } from '../../pages/ProfilePage/hooks';
import { useLazyGetProfileInfoQuery } from '../../store/profile/profile.api';
import { PROFILE_SETTINGS_ERROR_MESSAGES } from '../../pages/ProfilePage/data/accountMessages';
import { getDescriptionFromError } from '../../pages/ProfilePage/utils';
import { useIsUserAuth } from '../auth/useIsUserAuth';

export const useGetUserInfo = () => {
  const isAuth = useIsUserAuth();
  const firstRender = useRef(isAuth);
  const { contextHolder, openNotificationWithIcon } = useProfileNotification();

  const [fetchUserInfo, { isLoading }] = useLazyGetProfileInfoQuery();

  useEffect(() => {
    const setUser = async () => {
      try {
        await fetchUserInfo().unwrap();
      } catch (error) {
        const description = getDescriptionFromError(error, 'getUserData');
        openNotificationWithIcon(
          'topRight',
          'error',
          PROFILE_SETTINGS_ERROR_MESSAGES.GET_INFO,
          description,
        );
      }
    };

    if (isAuth) {
      if (firstRender.current) {
        setUser();
        firstRender.current = false;
      }
    } else {
      firstRender.current = true;
    }
  }, [fetchUserInfo, isAuth, openNotificationWithIcon]);

  return { isLoading, contextHolder };
};
