import { CSSProperties, FC } from 'react';
import { StyledFeatureItem } from './CarFeatureListItem.styled';

type CarFeatureListItemProps = {
  featureName: string;
  styles?: CSSProperties;
};

export const CarFeatureListItem: FC<CarFeatureListItemProps> = ({
  featureName,
  styles,
}) => (
  <StyledFeatureItem style={{ ...styles }}>{featureName}</StyledFeatureItem>
);
