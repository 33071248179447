import styled from 'styled-components';
import { SCREEN_WIDTH } from '../../screenSettings';
import { Divider } from 'antd';

export const StyledBlockRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledKeyBlock = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;

  @media screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
    gap: 12px;
  }
`;

export const StyledTextKey = styled.span`
  font-size: var(--desktop-content-font-size);
  font-weight: var(--main-font-weight);
  line-height: var(--desktop-content-line-height);

  @media screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
    font-size: var(--content-mobile-size);
    line-height: var(--mobile-line-height);
    letter-spacing: var(--mobile-letter-spacing);
  }
`;

export const TextValueBold = styled.div`
  display: inline-block;
  font-size: var(--bold-font-size);
  font-weight: var(--bold-font-weight);
  line-height: var(--desktop-content-line-height);
  letter-spacing: -0.333px;

  @media screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
    display: inline-flex;
    align-items: center;
    margin-right: 4px;
    font-size: var(--mobile-description-font-size);
    line-height: var(--mobile-line-height);
    letter-spacing: var(--mobile-letter-spacing);
  }
`;

export const StyledVerticalDivider = styled(Divider)`
  height: 24px;
  margin: 0 16px;

  @media screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
    margin: 0 8px;
  }
`;
