import { List, Row } from 'antd';
import styled from 'styled-components';
import { SCREEN_WIDTH } from '../../../../screenSettings';

const StyledList = styled(List)<{
  backgroundcolor: string;
  paginationitemswidth: number;
}>`
  .ant-list-items {
    margin: 0 16px;
    background-color: ${(props) => props.backgroundcolor};
  }

  .ant-list-pagination {
    margin-block-start: 0;
    padding: 16px;
    background: #f5f7fa;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    @media only screen and (max-width: ${SCREEN_WIDTH.TABLET}px) {
      padding: 16px;
    }
    @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_4}px) {
      padding: 16px 12px;
    }

    .ant-pagination-jump-next {
      height: ${(props) => props.paginationitemswidth}px;
      min-width: ${(props) => props.paginationitemswidth}px;
      line-height: ${(props) => props.paginationitemswidth}px;
      margin-inline-end: 0;
    }

    .ant-pagination-item {
      min-width: ${(props) => props.paginationitemswidth}px;
      height: ${(props) => props.paginationitemswidth}px;
      font-size: 16px;
      line-height: ${(props) => props.paginationitemswidth - 2}px;
      font-weight: 400;
      /* margin-inline-end: 0; */ // gap between controls
      letter-spacing: 0.08px;
      background: transparent;
      margin: 0 8px;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
      & a {
        padding: 0;
      }
      @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_4}px) {
        margin: 0 4px;
        font-size: 15px;
      }
    }

    .ant-pagination-next,
    .ant-pagination-prev {
      min-width: ${(props) => props.paginationitemswidth}px;
      height: ${(props) => props.paginationitemswidth}px;
      line-height: ${(props) => props.paginationitemswidth}px;
    }
    @media only screen and (max-width: ${SCREEN_WIDTH.TABLET}px) {
      .ant-pagination-item,
      .ant-pagination-prev,
      .ant-pagination-next {
        background: transparent;
        border-radius: 2px;
        border: 1px solid transparent;
      }

      .ant-pagination-item-active {
        border: 1px solid var(--Blue-7);
        border-radius: 2px;
      }
      .ant-pagination-disabled {
        border-radius: 2px;
        color: var(--Gray-4);
      }
    }
  }
`;

const StyledCarsListHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledCarsListHeader = styled(Row)`
  padding: 18px 16px !important;
`;

const StyledCarsListMobileHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledCarList = {
  StyledList,
  StyledCarsListHeaderContainer,
  StyledCarsListHeader,
  StyledCarsListMobileHeaderContainer,
};
