import { Typography } from 'antd';
import styled from 'styled-components';
import { SCREEN_WIDTH } from '../../screenSettings';

const { Paragraph } = Typography;
export const StyledParagraph = styled(Paragraph)`
  margin: 0;

  @media screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
    font-size: 14px;
    line-height: 20px;
  }
`;
