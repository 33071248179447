import styled from 'styled-components';
import { SCREEN_WIDTH } from '../../screenSettings';

export const StyledSection = styled.section`
  background-color: #ffffff;
  border-radius: 8px;
  width: 100%;

  @media screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
    border-radius: 0;
  }
`;

export const StyledHeader = styled.div`
  padding: 24px;
  box-shadow: 0px -1px 0px 0px #e1e7f0 inset;

  @media screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
    padding: 24px 16px;
  }
`;

export const StyledBody = styled.div`
  padding: 24px;

  @media screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
    padding: 16px;
  }
`;
