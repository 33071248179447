export const LogoutCustomSvg = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    viewBox='0 0 16 17'
    fill='none'
  >
    <path
      d='M3.21125 15C2.86625 15 2.57825 14.8845 2.34725 14.6535C2.11575 14.422 2 14.1337 2 13.7887V4.21125C2 3.86625 2.11575 3.57825 2.34725 3.34725C2.57825 3.11575 2.86625 3 3.21125 3H8.01425V3.75H3.21125C3.09625 3.75 2.9905 3.798 2.894 3.894C2.798 3.9905 2.75 4.09625 2.75 4.21125V13.7887C2.75 13.9038 2.798 14.0095 2.894 14.106C2.9905 14.202 3.09625 14.25 3.21125 14.25H8.01425V15H3.21125ZM11.3465 11.6535L10.82 11.1143L12.5592 9.375H5.894V8.625H12.5592L10.8193 6.885L11.3465 6.3465L14 9L11.3465 11.6535Z'
      fill='currentColor'
    />
  </svg>
);
