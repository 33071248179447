import styled from 'styled-components';
import { Col, Divider } from 'antd';
import { SCREEN_WIDTH } from '../screenSettings';

export const StyledHeaderContainer = styled(Col)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_2}px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`;

export const StyledDivider = styled(Divider)`
  order: 4;
  margin-left: 4px;
  margin-right: 16px;
  height: 32px;
`;
