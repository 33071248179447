import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InitialProfileStateType } from '../../models/profile';
import { MenuProfileKeysType } from '../../models/profile';
import { GetAccountSettingsResponseType } from '../../models/profile';

const initialState: InitialProfileStateType = {
  activeSubMenuKey: '',
  email: '',
  username: '',
  currency: 'USD',
  emailConfirmed: false,
  companyUrl: '',
  companyName: '',
  countryCode: '',
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setSubmenuKey: (
      state,
      { payload }: PayloadAction<{ key: MenuProfileKeysType }>,
    ) => {
      state.activeSubMenuKey = payload.key;
    },
    setUserInfo: (
      state,
      { payload }: PayloadAction<GetAccountSettingsResponseType>,
    ) => {
      Object.assign(state, payload);
    },
    updateUserCurrency: (
      state,
      { payload }: PayloadAction<{ currency: string }>,
    ) => {
      state.currency = payload.currency;
    },
    resetProfile: (state) => {
      const { currency } = state;
      return { ...initialState, currency };
    },
  },
});

export const profileActions = profileSlice.actions;
export const profileReducer = profileSlice.reducer;
