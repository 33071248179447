import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { SCREEN_WIDTH } from '../../screenSettings';
import { Row, Col } from 'antd';

export const StyledCarTitleLink = styled(Link)`
  font-size: 22px;
  font-weight: 600;
  line-height: 28px;
  color: ${(props) => props.color};
  margin-bottom: 2px;

  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_4}px) {
    font-size: 16px;
  }
`;

export const StyledCol = styled(Col)`
  display: flex;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
`;

export const StyledRow = styled(Row)<{ md: number }>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: ${(props) => (props.md ? 'row' : 'column')};
`;

export const StyledMobileCol = styled(Col)`
  width: 100%;
  margin-bottom: 16px;
`;
