import { ReactElement, CSSProperties } from 'react';
import { FeatureCategoriesIds } from '../enums/featuresCategories';
import {
  PopularCustomIcon,
  SafetyCustomIcon,
  AdditionalCustomIcon,
  ComfortCustomIcon,
  MultimediaCustomIcon,
  InteriorCustomIcon,
  ExteriorCustomIcon,
} from '../img/icons/components';

type TitleWithIconType = {
  description: string;
  icon: ReactElement;
};

type GetTitleWithIconType = (categoryId: number) => TitleWithIconType;

const baseIconColorStyle: CSSProperties = { color: '#3F8BF3' };

export const getTitleWithIconByCategory: GetTitleWithIconType = (
  categoryId,
) => {
  switch (categoryId) {
    case FeatureCategoriesIds.Additional:
      return {
        description: 'Дополнительно',
        icon: <AdditionalCustomIcon style={baseIconColorStyle} />,
      };

    case FeatureCategoriesIds.Safety:
      return {
        description: 'Безопасность',
        icon: <SafetyCustomIcon style={baseIconColorStyle} />,
      };

    case FeatureCategoriesIds.Comfort:
      return {
        description: 'Комфорт',
        icon: <ComfortCustomIcon style={baseIconColorStyle} />,
      };

    case FeatureCategoriesIds.Exterior:
      return {
        description: 'Экстерьер',
        icon: <ExteriorCustomIcon style={baseIconColorStyle} />,
      };

    case FeatureCategoriesIds.Interior:
      return {
        description: 'Интерьер',
        icon: <InteriorCustomIcon style={baseIconColorStyle} />,
      };

    case FeatureCategoriesIds.Multimedia:
      return {
        description: 'Мультимедиа',
        icon: <MultimediaCustomIcon style={baseIconColorStyle} />,
      };

    case FeatureCategoriesIds.Popular:
      return {
        description: 'Популярные',
        icon: <PopularCustomIcon style={baseIconColorStyle} />,
      };

    default:
      return {
        description: 'Популярные',
        icon: <PopularCustomIcon style={baseIconColorStyle} />,
      };
  }
};
