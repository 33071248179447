import styled from 'styled-components';
import { Tag } from 'antd';
import { SCREEN_WIDTH } from '../../../screenSettings';

export const StyledTagWrapper = styled.div<{
  colorText: string;
  backgroundColor: string;
}>`
  .ant-tag {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-tag-default {
    background-color: ${(props) => props.backgroundColor};
    color: ${(props) => props.colorText};
  }
`;

export const StyledTag = styled(Tag)<{ fontsizecustom?: number }>`
  padding: 0 8px;
  font-size: ${(props) =>
    props.fontsizecustom ? `${props.fontsizecustom}px` : '20px'};
  cursor: pointer;
  border: none;
  font-weight: var(--bold-font-weight);
  display: flex;
  flex-direction: row-reverse;
  gap: 10px;
  height: 32px;

  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET}px) {
    height: 24px;
  }

  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
    font-size: 14px;
    height: 28px;
  }
`;

export const StyledList = styled.ul`
  margin-bottom: 2px;
  list-style-type: none;
  text-align: end;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 12px 16px;
  box-shadow:
    0 2px 4px 0 rgba(113, 124, 140, 0.2),
    0 0.30000001192092896px 0.5px 0 rgba(113, 124, 140, 0.1);

  li {
    letter-spacing: 0.21px;
    margin-bottom: 4px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;
