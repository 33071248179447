export const ERROR_MESSAGES = {
  GET_MAKES: {
    status: 'error',
    message: 'Ошибка загрузки марок авто.',
  },
  GET_COMPANIES: {
    status: 'error',
    message: 'Не удалось загрузить данные компаний по доставке авто.',
  },
  GET_CAR_OPTIONS: {
    status: 'error',
    message: 'Ошибка получения характеристик авто!',
    description: 'Не удалось получить возможные характеристики автомобилей.',
  },
  GET_CAR_FEATURES: {
    status: 'error',
    message: 'Ошибка при получении данных!',
    description:
      'Не удалось получить возможные варианты комплектации автомобилей.',
  },
  REQUEST_BUY_CAR: {
    status: 'error',
    message: 'Ошибка при отправке заявки!',
    description: 'Попробуйте сформировать запрос позже!',
  },
};
