import { Form } from 'antd';
import styled from 'styled-components';
import { SCREEN_WIDTH } from '../../../../screenSettings';

export const StyledForm = styled(Form)`
  display: flex;
  gap: 24px;
  justify-content: space-between;

  @media screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
    gap: 16px;
    flex-basis: 100%;
    flex-direction: column;
  }
`;

export const StyledMobileFormItem = styled.div`
  flex: 1;
  flex-basis: 50%;

  @media screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
    flex-basis: 100%;
  }
`;
