import styled, { CSSProperties } from 'styled-components';
import { SCREEN_WIDTH } from '../../../../screenSettings';
import { CustomModal } from '../../../../components/CustomModal';

export const successIconStyles: CSSProperties = {
  color: '#22BF3C',
  fontSize: 24,
};
export const errorIconStyles: CSSProperties = {
  color: '#FF2929',
  fontSize: 24,
};

export const StyledModal = styled(CustomModal)`
  .ant-modal-content {
    padding: 40px 16px;
  }
`;

export const StyledModalHeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  gap: 8px;
`;

export const StyledTitle = styled.p`
  margin-bottom: 0;
  color: var(--Dark);
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.08px;

  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_2}px) {
    font-size: 14px;
  }
`;

export const StyledModalContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const StyledLoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const StyledModalText = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.015em;
  text-align: center;
  margin-bottom: 0;
  color: var(--Gray-9);
`;

export const StyledModalContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  gap: 8px;
`;
