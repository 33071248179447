import styled from 'styled-components';

export const StyledFeatureList = styled.ul`
  list-style-type: disc;
  margin-left: 24px;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
`;
