export const convertToArray = (data: any): number[] => {
  if (!data) return [];

  if (typeof data === 'string' || typeof data === 'number') {
    return [+data];
  }

  if (Array.isArray(data)) {
    return data.map((item) => +item);
  }

  return [];
};
