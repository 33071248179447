import { ReactNode } from 'react';
import { config } from '../../../config';
import { notification } from 'antd';
import type { NotificationPlacement } from 'antd/es/notification/interface';

type NotificationType = 'success' | 'info' | 'warning' | 'error';

export const useProfileNotification = () => {
  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIcon = (
    placement: NotificationPlacement = 'topRight',
    type: NotificationType = 'success',
    message?: ReactNode,
    description?: ReactNode,
  ) => {
    api[type]({
      message,
      description,
      duration: config.PROFILE_NOTIFICATION_DURATION,
      placement,
    });
  };

  return { contextHolder, openNotificationWithIcon };
};
