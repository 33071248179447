import styled from 'styled-components';
import { Form, Checkbox } from 'antd';
import { SCREEN_WIDTH } from '../../../screenSettings';

export const StyledFormItem = styled(Form.Item)`
  margin-bottom: 0;
  width: 100%;
`;

export const StyledCheckboxGroup = styled(Checkbox.Group)<{
  needgrid?: number;
}>`
  &.ant-checkbox-group {
    width: 100%;
    display: ${({ needgrid }) => (!!needgrid ? 'grid' : 'inline-flex')};
    grid-template-columns: repeat(4, 1fr);

    @media screen and (max-width: ${SCREEN_WIDTH.TO_DESKTOP_BREAK_3}px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_1}px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  gap: 4px;
  .ant-checkbox + span::first-letter {
    text-transform: uppercase;
  }

  @media screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_1}px) {
    gap: 8px;
    .ant-checkbox-wrapper .ant-checkbox {
      align-self: flex-start;
    }
  }
`;
