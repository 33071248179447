import styled from 'styled-components';
import { Form } from 'antd';
import { SearchParamsFormFieldsType } from '../../types/form';

export const StyledSearchParamsFormMobile = styled(
  Form<SearchParamsFormFieldsType>,
)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 40px;
`;
