import { FC } from 'react';
import { StyledDetailsWrapper } from './CarDetailsWithIcon.styled';
import { ICar } from '../../../models/car';
import { getDetailsWithIconForRender } from './CarDetailsWithIcon.utils';
import { CarDetailsItemWithIcon } from '../CarDetailsItemWithIcon/CarDetailsItemWithIcon';

type CarDetailsWithIconPropsType = {
  car: ICar;
};

export const CarDetailsWithIcon: FC<CarDetailsWithIconPropsType> = ({
  car,
}) => {
  const descriptionsElementsWithIcon = getDetailsWithIconForRender(car);
  return (
    <StyledDetailsWrapper>
      {descriptionsElementsWithIcon.map((elem) => {
        const { id, ...props } = elem;
        return (
          !!elem.description && <CarDetailsItemWithIcon key={id} {...props} />
        );
      })}
    </StyledDetailsWrapper>
  );
};
