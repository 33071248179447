import { FC } from 'react';
import { DesktopFilterForm } from '../DesktopFilterForm';
import { MobileFilterForm } from '../MobileFilterForm';
import { useMediaQuery } from 'react-responsive';
import { SCREEN_WIDTH } from '../../../../screenSettings';
import { FilterFormsContainer } from './FilterFormsContainer';
import { FormState } from './FilterForms.types';
import { useNavigate } from 'react-router-dom';
import { APP_PATHS } from '../../../../layout/settingsLayout';
import { SelectOptions } from '../../../../enums';
import qs from 'qs';

export const FilterForms: FC = () => {
  const navigate = useNavigate();
  const isTabletOrMobile = useMediaQuery({
    query: `(max-width: ${SCREEN_WIDTH.TABLET}px)`,
  });

  const navigateToCars = (formState: FormState) => {
    const { make, model, regionId, country, yearFrom, yearTo } = formState;

    const queryString = qs.stringify(
      {
        regionId,
        country,
        yearFrom,
        yearTo,
        page: '1',
      },
      { addQueryPrefix: true, encode: false, arrayFormat: 'repeat' },
    );

    const getCarPagePath = () => {
      let path = `${APP_PATHS.CARS_PAGE}`;

      if (make && make !== SelectOptions.any) {
        path += `/${make}`;
      }

      if (model && model !== SelectOptions.any) {
        path += `/${model}`;
      }

      if (queryString) {
        path += `${queryString}`;
      }

      return path;
    };

    navigate(getCarPagePath());
  };

  const props = { navigateToCars };

  return (
    <>
      <FilterFormsContainer>
        {isTabletOrMobile ? (
          <MobileFilterForm {...props} />
        ) : (
          <DesktopFilterForm {...props} />
        )}
      </FilterFormsContainer>
    </>
  );
};
