export const PROFILE_SETTINGS_ERROR_MESSAGES = {
  GET_INFO: 'Ошибка получения данных пользователя',
  UPDATE_INFO: 'Ошибка обновления данных пользователя',
  UPDATE_CURRENCY: 'Ошибка изменения валюты',
};

export const PROFILE_SETTINGS_ERROR_DESCRIPTIONS = {
  GET_INFO: 'При получении данных пользователя произошла ошибка',
  UPDATE_INFO:
    'При обновлении данных пользователя произошла ошибка. Попробуйте повторить запрос',
  NON_AUTHORIZED: 'Пожалуйста, авторизуйтесь для изменения данных пользователя',
  UPDATE_CURRENCY: 'При изменинии валютного приоритета произошла ошибка',
};

export const PROFILE_SETTINGS_SUCCESS_MESSAGES = {
  UPDATE_INFO: 'Завершено успешно!',
  UPDATE_CURRENCY: 'Завершено успешно!',
};

export const PROFILE_SETTINGS_SUCCESS_DESCRIPTIONS = {
  UPDATE_INFO: 'Данные пользователя успешно обновлены',
  UPDATE_CURRENCY: 'Валютные приоритеты изменены',
};
