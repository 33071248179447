import styled from 'styled-components';
import { StyledButton } from '../../../../components/styled/StyledButton';
import { SCREEN_WIDTH } from '../../../../screenSettings';

export const StyledFilterFormsContainer = styled.div`
  background: var(--White);
  border-radius: 8px;
`;

export const StyledFilterFormsHeader = styled.div`
  border-radius: 8px 8px 0px 0px;
  background: var(--Gray-1);
  box-shadow: 0px -1px 0px 0px #e1e7f0 inset;
  padding: 24px;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.03px;

  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
    padding: 16px;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.08px;
  }
`;

export const StyledFilterFormsMain = styled.div`
  padding: 24px;

  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
    padding: 16px 16px 24px 16px;
  }
`;

export const StyledDesktopFilterControlsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
`;

export const StyledSubmitButton = styled(StyledButton)`
  && {
    width: calc(50% - 8px);
  }

  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
    && {
      width: 100%;
    }
  }
`;

export const StyledFormElementsWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;

  .ant-form-item {
    margin: 0;
  }

  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
    flex-direction: column;
    gap: 0;
  }
`;

export const StyledFilterFormColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 50%;

  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
    width: 100%;
  }
`;
