import { FC, memo } from 'react';
import {
  DeliveryBlock,
  StyledBr,
  StyledPricesBlockRow,
  StyledVerticalDivider,
  TextKey,
  TextKeyBold,
  TextValueBold,
  TitleRow,
} from './CalculateDeliverySection.styled';
import { IconWithTooltip } from '../../../../components/IconWithTooltip';
import { formatNumber } from '../../../../utils/formatNumber';
import { getCurrencySymbol } from '../../../../utils/getCurrencySymbol';
import { StyledTitle } from '../../../../components/styled/StyledTitle';
import { CalculateDeliveryResponse } from '../../../../models/car/CalculateDelivery';
import { useCarPageSelector } from '../../../../store/selectors';

interface ClearenceProps {
  calculatedDelivery: CalculateDeliveryResponse;
}

export const Clearence: FC<ClearenceProps> = memo(({ calculatedDelivery }) => {
  const { calculateDeliveryForm } = useCarPageSelector();

  return (
    <DeliveryBlock>
      <TitleRow>
        <StyledTitle level={3}>Расстаможка и оформление</StyledTitle>
        <IconWithTooltip
          description={
            <>
              Расходы на официальное оформление автомобиля при ввозе в страну,
              включая пошлины, сборы и утилизацию
            </>
          }
        />
      </TitleRow>
      <StyledPricesBlockRow>
        <TextKey>
          Таможенная пошлина <StyledBr />{' '}
          {calculateDeliveryForm.hasCustomsClearanceBenefit ? '(льготная)' : '(полная)'}
        </TextKey>
        <TextValueBold>
          <div>
            {calculateDeliveryForm.hasCustomsClearanceBenefit ? (
              <TextKey>
                {formatNumber(
                  calculatedDelivery.customsClearanceCostDetails
                    .customsDutyCostOriginal.fromAmount,
                )}{' '}
                {getCurrencySymbol(
                  calculatedDelivery.customsClearanceCostDetails
                    .customsDutyCostOriginal.currency,
                )}
              </TextKey>
            ) : (
              <TextKey>
                {formatNumber(
                  calculatedDelivery.customsClearanceCostDetails
                    .customsDutyCostOriginal.toAmount,
                )}{' '}
                {getCurrencySymbol(
                  calculatedDelivery.customsClearanceCostDetails
                    .customsDutyCostOriginal.currency,
                )}
              </TextKey>
            )}

            <StyledVerticalDivider type='vertical' />

            {calculateDeliveryForm.hasCustomsClearanceBenefit ? (
              <TextValueBold>
                {formatNumber(
                  calculatedDelivery.customsClearanceCostDetails
                    .customsDutyCostConverted.fromAmount,
                )}{' '}
                {getCurrencySymbol(
                  calculatedDelivery.customsClearanceCostDetails
                    .customsDutyCostConverted.currency,
                )}
              </TextValueBold>
            ) : (
              <TextValueBold>
                {formatNumber(
                  calculatedDelivery.customsClearanceCostDetails
                    .customsDutyCostConverted.toAmount,
                )}{' '}
                {getCurrencySymbol(
                  calculatedDelivery.customsClearanceCostDetails
                    .customsDutyCostConverted.currency,
                )}
              </TextValueBold>
            )}
          </div>
        </TextValueBold>
      </StyledPricesBlockRow>
      <StyledPricesBlockRow>
        <TextKey>Таможенный сбор</TextKey>
        <TextValueBold>
          <span>
            <TextKey>
              {formatNumber(
                calculatedDelivery.customsClearanceCostDetails
                  .customsFeeCostOriginal.amount,
              )}{' '}
              {getCurrencySymbol(
                calculatedDelivery.customsClearanceCostDetails
                  .customsFeeCostOriginal.currency,
              )}
            </TextKey>
            <StyledVerticalDivider type='vertical' />
            <TextValueBold>
              {formatNumber(
                calculatedDelivery.customsClearanceCostDetails
                  .customsFeeCostConverted.amount,
              )}{' '}
              {getCurrencySymbol(
                calculatedDelivery.customsClearanceCostDetails
                  .customsFeeCostConverted.currency,
              )}
            </TextValueBold>
          </span>
        </TextValueBold>
      </StyledPricesBlockRow>
      <StyledPricesBlockRow>
        <TextKey>Утилизационный сбор</TextKey>
        <TextValueBold>
          <span>
            <TextKey>
              {formatNumber(
                calculatedDelivery.customsClearanceCostDetails
                  .recyclingCollectionCostOriginal.amount,
              )}{' '}
              {getCurrencySymbol(
                calculatedDelivery.customsClearanceCostDetails
                  .recyclingCollectionCostOriginal.currency,
              )}
            </TextKey>
            <StyledVerticalDivider type='vertical' />
            <TextValueBold>
              {formatNumber(
                calculatedDelivery.customsClearanceCostDetails
                  .recyclingCollectionCostConverted.amount,
              )}{' '}
              {getCurrencySymbol(
                calculatedDelivery.customsClearanceCostDetails
                  .recyclingCollectionCostConverted.currency,
              )}
            </TextValueBold>
          </span>
        </TextValueBold>
      </StyledPricesBlockRow>
      <StyledPricesBlockRow>
        <TextKey>Расходы на СВХ</TextKey>
        <TextValueBold>
          <span>
            <TextKey>
              {formatNumber(
                calculatedDelivery.customsClearanceCostDetails
                  .paperworkCostOriginal.amount,
              )}{' '}
              {getCurrencySymbol(
                calculatedDelivery.customsClearanceCostDetails
                  .paperworkCostOriginal.currency,
              )}
            </TextKey>
            <StyledVerticalDivider type='vertical' />
            <TextValueBold>
              {formatNumber(
                calculatedDelivery.customsClearanceCostDetails
                  .paperworkCostConverted.amount,
              )}{' '}
              {getCurrencySymbol(
                calculatedDelivery.customsClearanceCostDetails
                  .paperworkCostConverted.currency,
              )}
            </TextValueBold>
          </span>
        </TextValueBold>
      </StyledPricesBlockRow>
      <StyledPricesBlockRow>
        <TextKeyBold>ИТОГО</TextKeyBold>
        <TextValueBold>
          <>
            {calculateDeliveryForm.hasCustomsClearanceBenefit ? (
              <TextValueBold>
                {formatNumber(
                  calculatedDelivery.customsClearanceCostDetails
                    .totalCostWithBenefitConverted.amount,
                )}{' '}
                {getCurrencySymbol(
                  calculatedDelivery.customsClearanceCostDetails
                    .totalCostWithBenefitConverted.currency,
                )}
              </TextValueBold>
            ) : (
              <TextValueBold>
                {formatNumber(
                  calculatedDelivery.customsClearanceCostDetails
                    .totalCostConverted.amount,
                )}{' '}
                {getCurrencySymbol(
                  calculatedDelivery.customsClearanceCostDetails
                    .totalCostConverted.currency,
                )}
              </TextValueBold>
            )}
          </>
        </TextValueBold>
      </StyledPricesBlockRow>
    </DeliveryBlock>
  );
});
