import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMake } from '../../models/response/cars';

type CarInitialStateType = {
  carMakes: IMake[];
};
const initialState: CarInitialStateType = {
  carMakes: [],
};

export const carsSlice = createSlice({
  name: 'cars',
  initialState,
  reducers: {
    setCarMakes: (state, { payload }: PayloadAction<IMake[]>) => {
      state.carMakes = payload;
    },
  },
});

export const carsActions = carsSlice.actions;
export const carsReducer = carsSlice.reducer;
