import styled from 'styled-components';
import { SCREEN_WIDTH } from '../../../../screenSettings';

export const StyledFormWrapper = styled.section`
  width: 100%;
  align-self: center;
  max-width: var(--max-width-form-turnkey-desktop);
  & .ant-form {
    .ant-form-item-control {
      position: relative;
    }
    .ant-form-item-explain {
      position: absolute;
    }
  }

  @media screen and (max-width: ${SCREEN_WIDTH.DESKTOP}px) {
    max-width: var(--max-width-form-turnkey-tablet);
  }

  @media screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_2}px) {
    max-width: var(--max-width-form-turnkey-mobile);
  }
`;
