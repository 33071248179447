import { FC } from 'react';
import { Form, Input } from 'antd';
import { useForgotPasswordMutation } from '../../../store/auth/auth.api';
import { StyledTitle } from '../styled/StyledTitle';
import { useAppContext } from '../../../hooks/app';
import { StyledFormItem } from '../styled/StyledFormItem';
import { FormItemLabel } from '../../../components/FormItemLabel';
import { StyledButtonWrapper } from '../styled/StyledButtonWrapper';
import { StyledSubmitFormItem } from '../styled/StyledSubmitFormItem';
import { AuthLayout } from '../components/AuthLayout/AuthLayout';
import { AUTH_ERROR_MESSAGES, AUTH_WARNING_MESSAGES } from '../data';
import { formSettings } from '../../../config';
import { useAuthErrorNotification } from '../hooks';
import { StyledResponsiveButton } from '../../../components/Buttons/StyledResponsiveButton';
import { commonValidationRules } from '../../../data/commonValidationRules';
import {
  FormFiledLabel,
  FormFieldName,
  FormPlaceholder,
} from '../../../enums/form';
import { config } from '../../../config';
import { commonMetadata } from '../../../seo';
import { APP_PATHS } from '../../../layout/settingsLayout';
import { Metadata } from '../../../components/Metadata';

export const ForgotPassword: FC = () => {
  const { apiNotification } = useAppContext();
  const authErrorNotification = useAuthErrorNotification();
  const metaData = commonMetadata[APP_PATHS.FORGOT_PASSWORD];
  const [forgotPassword, { isLoading: isLoadingForgotPassword }] =
    useForgotPasswordMutation();

  const onFinish = async (values: { email: string }) => {
    try {
      await forgotPassword(values.email).unwrap();

      apiNotification.warning({
        message: AUTH_WARNING_MESSAGES.PASSWORD_RECOVERY_LINK_SENT,
        duration: config.DEFAULT_NOTIFICATION_DURATION,
      });
    } catch (error: any) {
      authErrorNotification(AUTH_ERROR_MESSAGES.PASSWORD_RECOVERY, error);
    }
  };

  return (
    <>
      <Metadata
        title={metaData.title}
        description={metaData.description}
      />

      <AuthLayout>
        <StyledTitle>Восстановление пароля</StyledTitle>
        <Form
          layout='vertical'
          name='forgotPasswordForm'
          style={{ maxWidth: 600 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete='off'
          requiredMark={false}
        >
          <StyledFormItem
            name={FormFieldName.Email}
            label={<FormItemLabel>{FormFiledLabel.Email}</FormItemLabel>}
            rules={commonValidationRules.email}
          >
            <Input
              placeholder={FormPlaceholder.Email}
              size={formSettings.controlSize}
            />
          </StyledFormItem>

          <StyledButtonWrapper>
            <StyledSubmitFormItem>
              <StyledResponsiveButton
                size={formSettings.controlSize}
                type='primary'
                htmlType='submit'
                loading={isLoadingForgotPassword}
              >
                Восстановить пароль
              </StyledResponsiveButton>
            </StyledSubmitFormItem>
          </StyledButtonWrapper>
        </Form>
      </AuthLayout>
    </>
  );
};
