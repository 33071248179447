import { FC, useState, HTMLAttributes, memo } from 'react';
import { ICarFeature } from '../../models';
import { FeatureTag } from '../Car/FeatureTag';
import { StyledFeaturesTags } from './CarFeaturesBlock.styled';
import { featuresByDefault } from '../../enums/featuresByDefault';
import { FeaturesCollapseButton } from '../Buttons/FeaturesCollapseButton';
import { useMediaQuery } from 'react-responsive';
import { SCREEN_WIDTH } from '../../screenSettings';

interface CarFeaturesBlockProps extends HTMLAttributes<HTMLDivElement> {
  features: ICarFeature[];
  isCollapsed?: boolean;
  needTransform?: boolean;
  url?: string;
}

export const CarFeaturesBlock: FC<CarFeaturesBlockProps> = memo(
  ({ features, isCollapsed = false, needTransform = true, url, ...props }) => {
    const isTabletWidth = useMediaQuery({
      query: `(max-width: ${SCREEN_WIDTH.TABLET}px)`,
    });
    const isMobileWidth = useMediaQuery({
      query: `(max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px)`,
    });

    const [showMore, setShowMore] = useState(false);

    const toggleMoreFeatures = () => setShowMore((prev) => !prev);

    const buttonClickHandler = () => {
      if (!isMobileWidth) {
        toggleMoreFeatures();
      }
    };

    const featuresQuantity =
      (isTabletWidth && featuresByDefault.TABLET) || featuresByDefault.DESKTOP;

    const preparedFeatures = needTransform
      ? features
          .filter((_, index) => (showMore ? true : index < featuresQuantity))
          .sort(
            (feature1, feature2) =>
              feature1.featureOrder - feature2.featureOrder,
          )
      : features;

    return (
      <StyledFeaturesTags {...props}>
        {preparedFeatures.map((feature, index) => (
          <FeatureTag
            feature={feature.featureName}
            key={`${feature.featureName}-${index}`}
          />
        ))}
        {features.length > featuresQuantity && isCollapsed && (
          <FeaturesCollapseButton
            featuresLength={features.length}
            isCollapsed={showMore}
            action={buttonClickHandler}
            url={url}
          />
        )}
      </StyledFeaturesTags>
    );
  },
);
