import { FC, PropsWithChildren, ReactNode } from 'react';
import { StyledBody, StyledHeader, StyledSection } from './Section.styled';
import { Result, Spin } from 'antd';
import { CSSProperties } from 'styled-components';

interface SectionProps {
  headerContent?: ReactNode;
  isError?: boolean;
  isFetching?: boolean;
  style?: CSSProperties;
  headerStyle?: CSSProperties;
  bodyStyle?: CSSProperties;
}

export const Section: FC<PropsWithChildren<SectionProps>> = ({
  headerContent,
  children,
  isError,
  isFetching,
  style,
  headerStyle,
  bodyStyle,
}) => {
  return (
    <StyledSection style={style}>
      <Spin size='large' spinning={isFetching || false}>
        {headerContent && (
          <StyledHeader style={headerStyle}>{headerContent}</StyledHeader>
        )}
        <StyledBody style={bodyStyle}>
          {isError ? (
            <Result status='404' subTitle='Извините, произошла ошибка' />
          ) : (
            children
          )}
        </StyledBody>
      </Spin>
    </StyledSection>
  );
};
