export const GasolineSvg = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='14'
    height='16'
    viewBox='0 0 14 16'
    fill='none'
  >
    <path
      d='M0.86084 6.91162L4.34436 2.79942'
      stroke='currentColor'
      strokeWidth='0.8'
      strokeLinecap='round'
    />
    <path
      d='M0.86084 15L0.860839 6.91318'
      stroke='currentColor'
      strokeWidth='0.8'
      strokeLinecap='round'
    />
    <path
      d='M4.34619 2.7998L13.1392 2.7998'
      stroke='currentColor'
      strokeWidth='0.8'
      strokeLinecap='round'
    />
    <path
      d='M0.86084 15L13.1374 15'
      stroke='currentColor'
      strokeWidth='0.8'
      strokeLinecap='round'
    />
    <path
      d='M13.1382 2.7998L13.1382 14.9988'
      stroke='currentColor'
      strokeWidth='0.8'
      strokeLinecap='round'
    />
    <line
      x1='3.00352'
      y1='7.41025'
      x2='3.00352'
      y2='12.6793'
      stroke='currentColor'
      strokeWidth='0.8'
      strokeLinecap='round'
    />
    <path
      d='M6.36094 1.04193C6.36094 0.82102 6.18185 0.641934 5.96094 0.641934C5.74002 0.641934 5.56094 0.82102 5.56094 1.04193L6.36094 1.04193ZM6.36094 3.19043L6.36094 1.04193L5.56094 1.04193L5.56094 3.19043L6.36094 3.19043Z'
      fill='currentColor'
    />
    <path
      d='M5.96094 1L11.4057 1'
      stroke='currentColor'
      strokeWidth='0.8'
      strokeLinecap='round'
    />
    <line
      x1='11.4135'
      y1='3.14893'
      x2='11.4135'
      y2='1.00043'
      stroke='currentColor'
      strokeWidth='0.8'
    />
  </svg>
);
