import { FC } from 'react';
import {
  CarCardContainer,
  StyledCarDetailsContainer,
  StyledCardHeader,
  StyledCardTitle,
  StyledCarLink,
  StyledCarMetaContainer,
  StyledImg,
  StyledImgContainer,
} from './StyledCarCard';
import { IRecommendation } from '../../models';
import { ICar } from '../../models/car';
import { CarDetails, CarMetadata, CarsListDropdown } from '../Car';
import { getPriceByCurrency } from '../../utils/getPriceByCurrency';
import notFoundCar from '../../img/notFoundCar.svg';
import { getCarUrl } from '../../utils/getUrl';
import { sourceIdMapping } from '../../data/sourceIdMapping';
import { APP_PATHS } from '../../layout/settingsLayout';

interface ICarCard {
  car: ICar | IRecommendation;
  currency: string;
  makeId: string;
  modelId: string;
  tagType?: any;
  showSmallerSize?: boolean;
}

export const CarCard: FC<ICarCard> = ({
  car,
  currency,
  makeId,
  modelId,
  tagType,
  showSmallerSize,
}) => {
  return (
    <StyledCarLink
      to={`${getCarUrl({
        adId: car.adId,
        sourceId: car.sourceId,
        makeId,
        modelId,
      })}`}
      rel='noreferrer'
    >
      <CarCardContainer>
        <StyledImgContainer>
          <StyledImg
            src={car.imageUrl}
            preview={false}
            fallback={notFoundCar}
            width='100%'
            height='auto'
            alt={`${car.makeViewName} ${car.modelViewName}`}
            title={`${car.makeViewName} ${car.modelViewName}`}
          />
        </StyledImgContainer>

        <StyledCardHeader>
          <StyledCardTitle
            style={{ fontSize: 14, lineHeight: 1.2 }}
          >{`${car.makeViewName} ${car.modelViewName}`}</StyledCardTitle>
          <CarsListDropdown
            fontSizeCustom={14}
            tag={tagType}
            price={getPriceByCurrency(car.prices, currency)}
            prices={car.prices}
            currency={currency}
          />
        </StyledCardHeader>

        <StyledCarDetailsContainer>
          <CarDetails car={car} style={{ fontSize: 12, lineHeight: '16px' }} />
        </StyledCarDetailsContainer>

        <StyledCarMetaContainer>
          <CarMetadata
            lessFontSize={showSmallerSize ? true : undefined}
            car={car}
            location={`${sourceIdMapping[car.sourceId]}, ${car.city}`}
          />
        </StyledCarMetaContainer>
      </CarCardContainer>
    </StyledCarLink>
  );
};
