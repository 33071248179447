import { useRef, useCallback } from 'react';

type TimeOutType = ReturnType<typeof setTimeout>;

export const useDebounceCb = (cb: (...args: any[]) => void, delay: number) => {
  const timeout = useRef<TimeOutType>();

  return useCallback(
    (...args: any[]) => {
      const laterFunc = () => {
        clearTimeout(timeout.current);
        cb(...args);
      };
      clearTimeout(timeout.current);
      timeout.current = setTimeout(laterFunc, delay);
    },
    [cb, delay],
  );
};
