import { FC } from 'react';
import { Form, Input } from 'antd';
import { ISignInCredentials } from '../../../models/auth/ISignInCredentials';
import { useSignInMutation } from '../../../store/auth/auth.api';
import { useNavigate } from 'react-router-dom';
import { useActions } from '../../../hooks/useActions';
import { IAuthResponse } from '../../../models/response/IAuthResponse';
import { StyledFormItem } from '../styled/StyledFormItem';
import { APP_PATHS, NAVIGATE_PATHS } from '../../../layout/settingsLayout';
import { FormItemLabel } from '../../../components/FormItemLabel';
import { StyledButtonWrapper } from '../styled/StyledButtonWrapper';
import { StyledSubmitFormItem } from '../styled/StyledSubmitFormItem';
import { StyledLink } from '../styled/StyledLink';
import { AUTH_ERROR_MESSAGES } from '../data';
import { formSettings } from '../../../config';
import { useAuthErrorNotification } from '../hooks';
import { StyledResponsiveButton } from '../../../components/Buttons/StyledResponsiveButton';
import { commonValidationRules } from '../../../data/commonValidationRules';
import {
  FormFiledLabel,
  FormFieldName,
  FormPlaceholder,
} from '../../../enums/form';
import { Metadata } from '../../../components/Metadata';
import { commonMetadata } from '../../../seo';

export const SignIn: FC = () => {
  const navigate = useNavigate();
  const { setAuth } = useActions();
  const metaData = commonMetadata[APP_PATHS.SIGN_IN];
  const authErrorNotification = useAuthErrorNotification();
  const [signIn, { isLoading: isLoadingSignIn }] = useSignInMutation();

  const handleSubmit = async (values: ISignInCredentials) => {
    try {
      const signInResponse: IAuthResponse = await signIn(values).unwrap();

      setAuth({
        accessToken: signInResponse.accessToken,
        refreshToken: signInResponse.refreshToken,
        role: signInResponse.role,
        username: signInResponse.username,
        userId: signInResponse.userId,
        email: signInResponse.email,
        expires: signInResponse.expires,
      });

      navigate(APP_PATHS.CARS_PAGE);
    } catch (error: any) {
      authErrorNotification(AUTH_ERROR_MESSAGES.SIGN_IN, error);
    }
  };

  return (
    <>
      <Metadata title={metaData.title} description={metaData.description} />

      <Form
        name='signInForm'
        requiredMark={false}
        layout='vertical'
        onFinish={handleSubmit}
        autoComplete='off'
      >
        <StyledFormItem
          name={FormFieldName.Email}
          label={<FormItemLabel>{FormFiledLabel.Email}</FormItemLabel>}
          rules={commonValidationRules.email}
        >
          <Input
            placeholder={FormPlaceholder.Email}
            size={formSettings.controlSize}
          />
        </StyledFormItem>

        <StyledFormItem
          label={<FormItemLabel>{FormFiledLabel.Password}</FormItemLabel>}
          name={FormFieldName.Password}
          rules={commonValidationRules.password}
        >
          <Input.Password
            placeholder={FormPlaceholder.Password}
            size={formSettings.controlSize}
          />
        </StyledFormItem>

        <StyledButtonWrapper>
          <StyledSubmitFormItem>
            <StyledResponsiveButton
              size={formSettings.controlSize}
              type='primary'
              htmlType='submit'
              loading={isLoadingSignIn}
            >
              Войти
            </StyledResponsiveButton>
          </StyledSubmitFormItem>

          <StyledLink to={NAVIGATE_PATHS.FORGOT_PASSWORD}>
            Забыли пароль?
          </StyledLink>
        </StyledButtonWrapper>
      </Form>
    </>
  );
};
