import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { NotificationFieldsType } from '../../models/notificationsCustom';

const initialState: NotificationFieldsType = {
  message: null,
};

export const appGeneralSlice = createSlice({
  name: 'appGeneral',
  initialState,
  reducers: {
    setNotification: (
      state,
      {
        payload: { message, status, description },
      }: PayloadAction<NotificationFieldsType>,
    ) => {
      state.message = message;
      if (status) state.status = status;
      if (description) state.description = description;
    },
    resetNotification: () => initialState,
  },
});

export const appGeneralActions = appGeneralSlice.actions;
export const appGeneralReducer = appGeneralSlice.reducer;
