import { FC } from 'react';
import { StyledFeatureTag } from './FeatureTag.styled';
import { CSSProperties } from 'styled-components';

type FeatureTagPropsType = {
  feature: string;
  style?: CSSProperties;
};

export const FeatureTag: FC<FeatureTagPropsType> = ({ feature, style }) => (
  <StyledFeatureTag style={style}>{feature}</StyledFeatureTag>
);
