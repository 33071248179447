export const AdditionalSvg = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='25'
    height='25'
    viewBox='0 0 25 25'
    fill='none'
  >
    <path
      d='M20.5 12.998V7.29805C20.5 5.61789 20.5 4.77781 20.173 4.13608C19.8854 3.57159 19.4265 3.11265 18.862 2.82503C18.2202 2.49805 17.3802 2.49805 15.7 2.49805H9.3C7.61984 2.49805 6.77976 2.49805 6.13803 2.82503C5.57354 3.11265 5.1146 3.57159 4.82698 4.13608C4.5 4.77781 4.5 5.61789 4.5 7.29805V17.698C4.5 19.3782 4.5 20.2183 4.82698 20.86C5.1146 21.4245 5.57354 21.8834 6.13803 22.1711C6.77976 22.498 7.61984 22.498 9.3 22.498H12.5M14.5 11.498H8.5M10.5 15.498H8.5M16.5 7.49805H8.5M15 19.498L17 21.498L21.5 16.998'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
