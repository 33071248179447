export const CloseSvg = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='25'
    viewBox='0 0 24 25'
    fill='none'
  >
    <path
      d='M6 6.15088L18 18.1509M18 6.15088L6 18.1509'
      stroke='currentColor'
      strokeLinecap='round'
    />
  </svg>
);
