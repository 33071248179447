import { useUpdateProfileInfoMutation } from '../../store/profile/profile.api';
import { UpdateAccountSettingsRequestType } from '../../models/profile';
import { useActions } from '../useActions';
import { useProfileNotification } from '../../pages/ProfilePage/hooks';
import {
  PROFILE_SETTINGS_SUCCESS_DESCRIPTIONS,
  PROFILE_SETTINGS_SUCCESS_MESSAGES,
  PROFILE_SETTINGS_ERROR_MESSAGES,
  PROFILE_SETTINGS_ERROR_DESCRIPTIONS,
} from '../../pages/ProfilePage/data/accountMessages';
import { getDescriptionFromError } from '../../pages/ProfilePage/utils';

type CurrencyUpdate = boolean | undefined;

export const useUpdateUserInfo = (currencyUpdate: CurrencyUpdate = false) => {
  const { contextHolder, openNotificationWithIcon } = useProfileNotification();
  const { updateUserName } = useActions();
  const [updateAccountInfo, { isLoading }] = useUpdateProfileInfoMutation();

  const updateUserHandler = async (
    info: UpdateAccountSettingsRequestType,
    callback?: () => void,
  ) => {
    try {
      await updateAccountInfo(info).unwrap();
      updateUserName(info.username);
      openNotificationWithIcon(
        'topRight',
        'success',
        currencyUpdate
          ? PROFILE_SETTINGS_SUCCESS_MESSAGES.UPDATE_CURRENCY
          : PROFILE_SETTINGS_SUCCESS_MESSAGES.UPDATE_INFO,
        currencyUpdate
          ? PROFILE_SETTINGS_SUCCESS_DESCRIPTIONS.UPDATE_CURRENCY
          : PROFILE_SETTINGS_SUCCESS_DESCRIPTIONS.UPDATE_INFO,
      );

      if (callback) {
        callback();
      }
    } catch (error) {
      openNotificationWithIcon(
        'topRight',
        'error',
        currencyUpdate
          ? PROFILE_SETTINGS_ERROR_MESSAGES.UPDATE_CURRENCY
          : PROFILE_SETTINGS_ERROR_MESSAGES.UPDATE_INFO,
        currencyUpdate
          ? PROFILE_SETTINGS_ERROR_DESCRIPTIONS.UPDATE_CURRENCY
          : getDescriptionFromError(error, 'updateUserData'),
      );
    }
  };

  return { updateUserHandler, contextHolder, isLoading };
};
