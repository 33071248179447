import { NON_IMPORTANT_TYPE_VALUE } from '../../../constants';
import { FormInstance } from 'antd';

// base on the idea - that first checked is always non important value

export const changeOnDefaultIncluded = (
  checkedValues: (number | string)[],
  checkedValuesBeforeUpdate: (number | string)[] | undefined,
  form: FormInstance,
  checkBoxGroupName: string,
) => {
  if (
    !Array.isArray(checkedValuesBeforeUpdate) ||
    !Array.isArray(checkedValues)
  ) {
    return;
  }

  if (
    !checkedValuesBeforeUpdate.includes(NON_IMPORTANT_TYPE_VALUE) &&
    checkedValues.includes(NON_IMPORTANT_TYPE_VALUE)
  ) {
    form.setFieldValue(checkBoxGroupName, [NON_IMPORTANT_TYPE_VALUE]);
  }

  if (
    !checkedValuesBeforeUpdate.includes(NON_IMPORTANT_TYPE_VALUE.toString()) &&
    checkedValues.includes(NON_IMPORTANT_TYPE_VALUE.toString())
  ) {
    form.setFieldValue(checkBoxGroupName, [
      NON_IMPORTANT_TYPE_VALUE.toString(),
    ]);
  }

  if (
    checkedValuesBeforeUpdate.includes(NON_IMPORTANT_TYPE_VALUE) &&
    checkedValues.includes(NON_IMPORTANT_TYPE_VALUE)
  ) {
    form.setFieldValue(checkBoxGroupName, checkedValues.slice(1));
  }

  if (
    checkedValuesBeforeUpdate.includes(NON_IMPORTANT_TYPE_VALUE.toString()) &&
    checkedValues.includes(NON_IMPORTANT_TYPE_VALUE.toString())
  ) {
    form.setFieldValue(checkBoxGroupName, checkedValues.slice(1));
  }
};
