import styled from 'styled-components';
import { Form } from 'antd';
import { SCREEN_WIDTH } from '../../../../../screenSettings';
import { EquipmentFormFieldsType } from '../types/form';

export const StyledEquipmentForm = styled(Form<EquipmentFormFieldsType>)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto 1fr;
  column-gap: 40px;
  row-gap: 32px;
  @media screen and (max-width: ${SCREEN_WIDTH.DESKTOP}px) {
    grid-template-columns: repeat(2, 1fr);
    max-width: 90%;
    margin-left: 4px;
  }
`;
