import styled from 'styled-components';
import { Drawer } from 'antd';
import { SCREEN_WIDTH } from '../../screenSettings';

export const StyledAntDrawer = styled(Drawer)`
  .ant-drawer-content {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    /* this doesn't worked - don't now why  */
  }

  .ant-drawer-header {
    padding: 16px;
    border-bottom: none;
  }

  .ant-drawer-body {
    padding: 10px 16px 16px 12px;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1;

    &::-webkit-scrollbar-button {
      display: none;
    }
    &::-webkit-scrollbar {
      width: 4px;
      height: 10px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
    }
  }

  .ant-drawer-header-title {
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  .ant-drawer-close {
    margin-right: 0;
  }
`;

export const StyledDrawerContent = styled.div`
  //
`;
