import styled from 'styled-components';
import { Button } from 'antd';
import { SCREEN_WIDTH } from '../../../../screenSettings';
import { CustomModal } from '../../../../components/CustomModal';

export const StyledContainerImageGallery = styled.div<{ imgHeight: number }>`
  margin-right: 8px;
  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET}px) {
    margin-right: 0;
  }

  .image-gallery {
    img {
      border-radius: 6px;
    }
  }

  .image-gallery-slides {
    border-radius: 8px;
    width: 100%;
    @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;
      border-radius: 4px;
    }

    .image-gallery-slide {
      @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
        width: calc(100% - 20px - 10%);
        padding-right: 8px;
      }

      img {
        width: 100%;
        height: ${(props) => props.imgHeight}px;
        object-fit: cover;
        overflow: hidden;
        object-position: center center;
        cursor: pointer;
        transition: transform 0.3s ease;
      }
    }
  }

  .fullscreen {
    .image-gallery-slide {
      img {
        height: 100vh;
        object-fit: contain;

        &:hover {
          transform: scale(1);
        }
      }
    }
  }

  .image-gallery-thumbnail-image {
    width: 100%;
    height: 45px;
    object-fit: cover;
    overflow: hidden;
    object-position: center center;
    cursor: pointer;
    @media only screen and (max-width: ${SCREEN_WIDTH.TABLET}px) {
      height: 45px;
    }
  }

  .image-gallery-thumbnails-wrapper {
    @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
      display: none;
    }
  }

  .image-gallery-thumbnails {
    margin-top: 16px;
    padding: 0;
    overflow: hidden;
    max-height: 50px;

    @media only screen and (max-width: ${SCREEN_WIDTH.TABLET}px) {
      max-height: 50px;
      border-radius: 8px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      margin-top: 10px;
    }
    @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
      max-height: 100px;
      margin-top: 8px;
    }
  }

  .image-gallery-thumbnails-container {
    display: flex;
    justify-content: flex-start;
    @media only screen and (max-width: ${SCREEN_WIDTH.TABLET}px) {
      max-height: 65px;
    }
  }

  .image-gallery-thumbnail {
    width: 70px;
    border: 2px solid transparent;
    border-color: transparent;
    border-radius: 8px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    & > span {
      width: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    @media only screen and (max-width: ${SCREEN_WIDTH.TABLET}px) {
      max-width: 100px;
      margin-right: 4px;
    }

    &:last-of-type {
      margin-right: 0;
    }

    &.active,
    &.focus,
    &.hover {
      border-color: #3f8bf3;
      border-radius: 8px;
    }
  }
`;

export const StyledGalleryNavButton = styled(Button)`
  width: 42px !important;
  height: 42px !important;
  position: absolute;
  top: 50%;
  border: none;
  background-color: transparent;
  z-index: 1;
`;

export const StyledLeftButton = styled(StyledGalleryNavButton)`
  left: 32px;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledRightButton = styled(StyledGalleryNavButton)`
  right: 32px;
  transform: translate(50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledModalFullScreen = styled(CustomModal)`
  max-width: 1200px;
  width: 100%;

  @media only screen and (max-width: 1350px) {
    max-width: 1000px;
  }

  @media only screen and (max-width: ${SCREEN_WIDTH.TO_DESKTOP_BREAK_1}px) {
    max-width: 800px;
  }

  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET}px) {
    max-width: 736px;
    border-radius: 10px;
  }

  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_2}px) {
    max-width: 500px;
  }

  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
    max-width: 100vw;
    border-radius: 0px;
  }
  @media only screen and (max-width: ${SCREEN_WIDTH.MOBILE}px) {
    max-width: 320px;
  }

  & .ant-modal-content {
    background-color: transparent;
    padding: 0;
    position: relative;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-shadow: none;
  }

  .ant-modal-body {
    background-color: transparent;
  }

  &.ant-modal .ant-modal-mask {
    background: rgba(0, 0, 0, 0.6);
  }

  .ant-modal-close {
    position: absolute;
    display: block;
    border: none;
    background: transparent;
    right: -55px;
    top: 15px;
    font-size: 30px;
    @media only screen and (max-width: ${SCREEN_WIDTH.TO_DESKTOP_BREAK_1}px) {
      right: -40px;
    }
    @media only screen and (max-width: ${SCREEN_WIDTH.DESKTOP}px) {
      right: -30px;
      font-size: 22px;
    }

    @media only screen and (max-width: ${SCREEN_WIDTH.TABLET}px) {
      top: 0px;
      right: 0px;
      font-size: 18px;
    }
    @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
      right: 15px;
      top: 20px;
      font-size: 16px;
    }
    @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_4}px) {
      top: 10px;
      right: 10px;
    }
  }
  .ant-modal-close:hover,
  .ant-modal-close:focus,
  .ant-modal-close:target {
    background: transparent;
  }

  .ant-modal-close-x {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    font-size: 24px;
    color: var(--White);
    background: transparent;
    &:hover,
    &:focus,
    &:target {
      transform: scale(1.03);
      color: #e1e7f0;
    }
    @media only screen and (max-width: ${SCREEN_WIDTH.TABLET}px) {
      font-size: 20px;
    }
    @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_4}px) {
      font-size: 18px;
    }
  }
`;
