import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { CalculateDeliveryForm } from '../../models/car/CalculateDelivery';

interface CarPageState {
  calculateDeliveryForm: CalculateDeliveryForm;
}

const initialState: CarPageState = {
  calculateDeliveryForm: {} as CalculateDeliveryForm,
};

export const carPageSlice = createSlice({
  name: 'carPage',
  initialState,
  reducers: {
    setSelectedDeliveryValues: (
      state,
      { payload }: PayloadAction<CalculateDeliveryForm>,
    ) => {
      state.calculateDeliveryForm = {
        ...state.calculateDeliveryForm,
        ...payload,
      };
    },
  },
});

export const carPageActions = carPageSlice.actions;
export const carPageReducer = carPageSlice.reducer;
