import {
  memo,
  useCallback,
  useState,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { ICarData } from '../../models';
import { useMediaQuery } from 'react-responsive';
import { SCREEN_WIDTH } from '../../screenSettings';
import { StyledSubscriptionIcon } from './SubscriptionIconBlock.styles';
import { IconWithTooltip } from '../IconWithTooltip';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { BellCustomIcon } from '../../img/icons/components';
import { useOfferSubscription } from '../../hooks/subscriptions';

type SubscriptionIconBlockProps = {
  item: ICarData;
  initialVisibility?: boolean;
};
export type ImperativeSubscriptionEffects = {
  hideSubscriptionsElement: () => void;
  showSubscriptionsElement: () => void;
};

export const SubscriptionIconBlock = memo(
  forwardRef<ImperativeSubscriptionEffects, SubscriptionIconBlockProps>(
    ({ item, initialVisibility }, ref) => {
      const isTabletWidth = useMediaQuery({
        query: `(max-width: ${SCREEN_WIDTH.TABLET}px`,
      });

      const [isSubscriptionVisible, setIsSubscriptionVisible] =
        useState(initialVisibility);

      const hideSubscriptionsElement = () => setIsSubscriptionVisible(false);

      const showSubscriptionsElement = () => setIsSubscriptionVisible(true);

      useImperativeHandle(ref, () => ({
        hideSubscriptionsElement,
        showSubscriptionsElement,
      }));

      const { handleSubscription, isLoading } = useOfferSubscription();

      const handleSubscriptionHandler = useCallback(() => {
        handleSubscription({
          adId: item.car.adId,
          currency: item.currency,
          sourceId: item.car.sourceId,
        });
      }, [handleSubscription, item.car.adId, item.car.sourceId, item.currency]);

      return isSubscriptionVisible || isTabletWidth ? (
        <StyledSubscriptionIcon onClick={handleSubscriptionHandler}>
          {isLoading ? (
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />}
            />
          ) : (
            <IconWithTooltip
              description={'Подписаться на объявление'}
              customPopoverWidth={230}
              icon={<BellCustomIcon style={{ color: '#3F8BF3' }} />}
              customTrigger={isTabletWidth ? [] : ['hover']}
            />
          )}
        </StyledSubscriptionIcon>
      ) : null;
    },
  ),
);
