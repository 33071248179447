import { useMediaQuery } from 'react-responsive';
import { CallToActionBlock } from '../../../components/CallToActionBlock';
import { StyledCallToActionSection } from './CallToTurnKey.styled';
import { APP_PATHS } from '../../../layout/settingsLayout';
import { SCREEN_WIDTH } from '../../../screenSettings';
import { LinkButton } from '../../../components/Buttons/LinkButton';

export const CallToTurnKey = () => {
  const isMobileBreakPointWidth = useMediaQuery({
    query: `(max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_2}px`,
  });

  return (
    <StyledCallToActionSection style={{ marginBottom: 40 }}>
      <CallToActionBlock
        title={`Не нашли подходящее авто?\nМы вам поможем!`}
        description='Заполните заявку, и мы найдем авто, соответствующее вашим требованиям, и рассчитаем стоимость доставки.'
      >
        <LinkButton
          to={`/${APP_PATHS.CAR_TURN_KEY}`}
          style={{
            maxWidth: isMobileBreakPointWidth ? '100%' : 190,
          }}
        >
          <span>Найти моё авто</span>
        </LinkButton>
      </CallToActionBlock>
    </StyledCallToActionSection>
  );
};
