import styled from 'styled-components';
import { Popover } from 'antd';
import type { PopoverProps } from 'antd';
import { SCREEN_WIDTH } from '../../../../../screenSettings';

export const StyledTrack = styled.div<{ ranges: number }>`
  position: relative;
  width: 100%;
  height: 16px;
  background: ${(props) =>
    props.ranges === 4
      ? 'linear-gradient(90deg,#22bf3c 25%,#95cf5b 25%,#95cf5b 50%, #ffc95f 50%, #ffc95f 75%, #ff2929 75%, #ff2929 100%)'
      : 'linear-gradient(90deg, #22bf3c 33%, #ffc95f 33%, #ffc95f 66%, #ff2929 66%, #ff2929 100%)'};

  border-radius: 24px;
  margin-bottom: 16px;
`;
export const StyledPopover = styled(Popover)<PopoverProps>`
  & div.ant-popover-arrow {
    left: 3px !important;
  }
  @media screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_2}px) {
    & div.ant-popover-content {
      color: var(--Gray-7);
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.048px;
    }
  }
`;

export const StyledDotContainer = styled.div<{ position: number }>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: ${(props) => {
    if (props.position >= 96) {
      return `calc(${props.position}% - 20px)`;
    } else if (props.position <= 2) {
      return '0%';
    } else return `calc(${props.position}% - 10px)`;
  }};
`;

export const StyledPopoverContent = styled.div<{
  titlecolor: string;
  subtitlecolor: string;
}>`
  text-align: center;
  padding: 0 12px;

  p {
    font-size: var(--main-font-size);
    font-style: var(--font-style-base);
    font-weight: var(--main-font-weight);
    line-height: var(--desktop-content-line-height);
    letter-spacing: 0.21px;
    margin-bottom: 3px;
    color: ${(props) => props.subtitlecolor || 'initial'};
    white-space: nowrap;
  }

  p:last-child {
    margin-bottom: 2px;
    color: ${(props) => props.titlecolor || 'initial'};
    font-weight: var(--bold-font-weight);
  }

  @media screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_2}px) {
    p {
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      letter-spacing: 0.048px;
      margin-bottom: 4;
    }
    p:last-child {
      font-size: 12px;
      line-height: 18px;
      font-weight: 600;
      margin-bottom: 0;
    }
  }
`;

export const StyledDot = styled.div`
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #ffffff;
  border: 1px solid #cad3e0;
`;
