export const APP_PATHS = {
  ROOT: '/',
  CARS_PAGE: `/cars`,
  CARS_MAKE_PAGE: `:make`,
  CARS_MAKE_MODEL_PAGE: ':model',
  //TODO fix this paths
  CAR_PAGE: '/cars/:make/:model/:adId/:sourceId/*',
  CAR_PAGE_CALCULATE_DELIVERY: 'calculate-delivery',
  CAR_PAGE_CALCULATE_CAR_INSPECTION: 'calculate-car-inspection',
  PROFILE_PAGE: '/profile',
  PROFILE_PAGE_ACCOUNT: '/profile/account',
  PROFILE_PAGE_SUBSCRIPTIONS_OFFER: '/profile/subscriptions/offer',
  PROFILE_PAGE_SUBSCRIPTIONS_FILTER: '/profile/subscriptions/filter',
  CAR_TURN_KEY: 'turnkey-car',
  API: 'api',
  AUTH: 'auth',
  ROOT_AUTH_PAGE: 'auth-page',
  AUTH_PAGE: 'auth-page/*',
  SIGN_IN: 'sign-in',
  SIGN_UP: 'sign-up',
  CONFIRM_EMAIL: 'confirm-email',
  FORGOT_PASSWORD: 'forgot-password',
  RESET_PASSWORD: 'reset-password',
  NOT_FOUND_PAGE: '*',
};

export const NAVIGATE_PATHS = {
  SIGN_IN: `/${APP_PATHS.API}/${APP_PATHS.AUTH}/${APP_PATHS.ROOT_AUTH_PAGE}/${APP_PATHS.SIGN_IN}`,
  SIGN_UP: `/${APP_PATHS.API}/${APP_PATHS.AUTH}/${APP_PATHS.ROOT_AUTH_PAGE}/${APP_PATHS.SIGN_UP}`,
  DEFAULT_AUTH: `/${APP_PATHS.API}/${APP_PATHS.AUTH}/${APP_PATHS.ROOT_AUTH_PAGE}`,
  FORGOT_PASSWORD: `/${APP_PATHS.API}/${APP_PATHS.AUTH}/${APP_PATHS.FORGOT_PASSWORD}`,
  CONFIRM_EMAIL: `/${APP_PATHS.API}/${APP_PATHS.AUTH}/${APP_PATHS.CONFIRM_EMAIL}`,
};
