import styled from 'styled-components';
import { SCREEN_WIDTH } from '../../../screenSettings';
import { Typography } from 'antd';

export const StyledExpandContainer = styled.span`
  color: var(--Blue-7);
  padding-bottom: 3px;
  border-bottom: 1px solid var(--Blue-7);
  width: fit-content;
  &:hover,
  &:active,
  &:target,
  &:focus {
    background-color: rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }

  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_2}px) {
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.21px;
  }
`;

export const StyledCarDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
`;

export const StyledCarDescription = styled(Typography.Paragraph)`
  font-size: 14px;
  margin-bottom: 0;
  width: 100%;
  color: var(--Dark);
  line-height: 140%;
  & span[aria-hidden] {
    display: none;
  }
`;
