import { FC, CSSProperties, useState, useRef } from 'react';
import {
  StyledUserOptionsPopover,
  overlayPopoverStyles,
  overlayPopoverInnerStyles,
} from './UserOptionsPopover.styled';
import { UserOptionsButton } from '../Buttons/UserOptionsButton';
import { DrawerPopoverContentWithMenu } from '../DrawerPopoverContentWithMenu';

type UserOptionsPopoverPropsType = {
  layoutStyles?: CSSProperties;
};

export const UserOptionsPopover: FC<UserOptionsPopoverPropsType> = ({
  layoutStyles,
}) => {
  const refContainerPopover = useRef<HTMLButtonElement>(null);
  const [isOpenedOptionBlock, setIsOpenedOptionBlock] = useState(false);

  const toggleOpenOptions = () => {
    setIsOpenedOptionBlock((prev) => !prev);
  };

  const hideOptionsAction = () => setIsOpenedOptionBlock(false);

  const hideOnMaskClick = (visible: boolean) => {
    if (!visible) {
      setTimeout(() => setIsOpenedOptionBlock((prev) => (prev ? !prev : prev)));
    }
  };

  return (
    <StyledUserOptionsPopover
      arrow={false}
      overlayStyle={overlayPopoverStyles}
      overlayInnerStyle={overlayPopoverInnerStyles}
      align={{ offset: [6, 2] }}
      trigger={['click']}
      getPopupContainer={() => refContainerPopover.current as HTMLElement}
      placement='bottomRight'
      open={isOpenedOptionBlock}
      destroyTooltipOnHide={true}
      onOpenChange={hideOnMaskClick}
      zIndex={15}
      content={
        <DrawerPopoverContentWithMenu
          additionalClickOptionAction={hideOptionsAction}
        />
      }
    >
      <UserOptionsButton
        ref={refContainerPopover}
        isArrowTransformed={isOpenedOptionBlock}
        onClick={toggleOpenOptions}
        style={layoutStyles}
      />
    </StyledUserOptionsPopover>
  );
};
