import { FC, PropsWithChildren } from 'react';
import { CleanLink } from '../Links/CleanLink';
import { DomainExtension } from '../../enums/domain';
import {
  useAuthSelector,
  useDomainSelector,
  useProfileSelector,
} from '../../store/selectors';
import { getTelegramLink } from '../../utils/getTelegramLink';

export const SubscriptionTelegramLink: FC<PropsWithChildren> = ({
  children,
}) => {
  const { userId } = useAuthSelector();
  const { domainExtension } = useDomainSelector();
  const { currency } = useProfileSelector();

  const TGLink = getTelegramLink(
    userId as string,
    domainExtension as DomainExtension,
    currency,
  );

  return (
    <CleanLink to={TGLink} target='_blank'>
      {children}
    </CleanLink>
  );
};
