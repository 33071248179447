import { FC, useState } from 'react';
import { StyledControlContainer } from './CalculateDeliverySection.styled';
import { StyledResponsiveButton } from '../../../../components/Buttons/StyledResponsiveButton';
import ReactGA from 'react-ga4';
import { GoogleAnanalyticsEvent } from '../../../../enums/googleAnalyticsAction';
import {
  SendRequestForm,
  SendRequestModalForm,
} from '../../../../components/SendRequestModalForm';
import { Form, notification } from 'antd';
import {
  useCarPageSelector,
  useProfileSelector,
} from '../../../../store/selectors';
import { CarPageOutletContext } from '../../CarPage.interface';
import { useOutletContext } from 'react-router-dom';
import {
  useBuyRequestMutation,
  useCarInspectionRequestMutation,
} from '../../../../store/car/car.api';
import {
  BuyRequestPayload,
  CarInspectionRequestPayload,
} from '../../../../models/car/CarRequests';
import { config } from '../../../../config';
import { removeLastRoute } from './utils';

interface CalculateDeliverySectionControlsProps {
  onlyCarInspection: boolean;
}

export const CalculateDeliverySectionControls: FC<
  CalculateDeliverySectionControlsProps
> = ({ onlyCarInspection }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [sendDeliveryRequest] = Form.useForm();
  const { currency } = useProfileSelector();
  const { calculateDeliveryForm } = useCarPageSelector();
  const { car } = useOutletContext<CarPageOutletContext>();
  const [notificationApi, contextHolder] = notification.useNotification();
  const [buyRequest, { isLoading: isBuyRequestLoading }] =
    useBuyRequestMutation();
  const [carInspectionRequest, { isLoading: isCarInspectionRequestLoading }] =
    useCarInspectionRequestMutation();

  const showModal = () => {
    if (process.env.NODE_ENV === 'production') {
      ReactGA.event({
        category: 'button',
        action: onlyCarInspection
          ? GoogleAnanalyticsEvent.OpenOfferCarInspectionForm
          : GoogleAnanalyticsEvent.OpenOfferDeliveryForm,
        label: window.location.href,
      });
    }

    setIsOpenModal(true);
  };

  const sendRequest = async (values: SendRequestForm) => {
    if (process.env.NODE_ENV === 'production') {
      ReactGA.event({
        category: 'button',
        action: onlyCarInspection
          ? GoogleAnanalyticsEvent.SubmitOfferCarInspectionForm
          : GoogleAnanalyticsEvent.SubmitOfferDeliveryForm,
        label: window.location.href,
      });
    }

    const { companyId, destinationRegionId } = calculateDeliveryForm;

    if (!companyId || !destinationRegionId) {
      notificationApi.error({
        message: 'Произошла ошибка при отправке заявки.',
        description: 'companyId или destinationRegionId неизвестны',
        duration: config.DEFAULT_NOTIFICATION_DURATION,
      });
      return;
    }

    const { personalDataProcessingConsent, ...formDataWithoutAgreement } =
      values;

    const commonParams: CarInspectionRequestPayload = {
      ...formDataWithoutAgreement,
      adId: car.adId,
      phone: formDataWithoutAgreement.phone.toString(),
      sourceId: car.sourceId,
      companyId,
      offerUrl: removeLastRoute(window.location.href),
      calculationBreakdownUrl: window.location.href,
    };

    try {
      if (onlyCarInspection) {
        await carInspectionRequest(commonParams).unwrap();
      } else {
        const buyRequestBody: BuyRequestPayload = {
          ...commonParams,
          currency,
          destinationRegionId,
        };

        await buyRequest(buyRequestBody).unwrap();
      }
      setIsOpenModal(false);
      sendDeliveryRequest.resetFields();
      notificationApi.success({
        message: 'Заявка успешно отправлена!',
        description: 'Ожидайте обратной связи.',
        duration: config.DEFAULT_NOTIFICATION_DURATION,
      });
    } catch (error: any) {
      console.error(error);
      notificationApi.error({
        message: 'Произошла ошибка при отправке заявки.',
        description: `${error?.status} ${error?.data?.title || ''}`,
        duration: config.DEFAULT_NOTIFICATION_DURATION,
      });
    }
  };

  return (
    <>
      {contextHolder}
      <StyledControlContainer>
        <StyledResponsiveButton
          onClick={showModal}
          size='large'
          type='primary'
          htmlType='submit'
        >
          отправить заявку
        </StyledResponsiveButton>
      </StyledControlContainer>

      <SendRequestModalForm
        title={onlyCarInspection ? 'Осмотр авто' : 'Рассчет стоимости'}
        isOpenModal={isOpenModal}
        setIsOpenModal={setIsOpenModal}
        sendRequest={sendRequest}
        form={sendDeliveryRequest}
        isLoading={isBuyRequestLoading || isCarInspectionRequestLoading}
      />
    </>
  );
};
