import {
  SearchParamsFormFieldsType,
  DeliveryFormFiledsType,
  EquipmentFormFieldsType,
} from '../types/form';
import { SendRequestForm } from '../../../../../components/SendRequestModalForm';
import { GetCustomCarRequestType } from '../../../../../models/response/cars';
import { TurnKeyDeliveryResponse } from '../../../../../models/car/CarTurnKey';
import { NON_IMPORTANT_TYPE_VALUE } from '../../../../../constants';
import { Company } from '../../../../../models/response/companies';
import { destinationRegionIdMapping } from '../../../../../data/mappings';
import { SelectOptions } from '../../../../../enums';

export const getCarTurnkeyDeliveryRequestBody = (
  searchParamsValues: SearchParamsFormFieldsType,
  equipmentValues: EquipmentFormFieldsType,
  deliveryValues: DeliveryFormFiledsType,
  contactValues: SendRequestForm,
  deliveryDetails?: TurnKeyDeliveryResponse,
): GetCustomCarRequestType => {
  const {
    currency,
    budget,
    make,
    model,
    bodyColors,
    bodyTypes,
    engineCapacityFrom,
    engineCapacityTo,
    engineTypes,
    toMileage,
    typesOfDrive,
    yearFrom,
    yearTo,
    hasDamage,
    sourceRegionId,
  } = searchParamsValues;
  const features = equipmentValues;
  const { companyId, destinationRegionId } = deliveryValues;

  const requestBody: GetCustomCarRequestType = {
    budget,
    budgetCurrency: currency,
    hasDamage: hasDamage === 0 ? false : hasDamage === 1 ? true : undefined,
    sourceRegionId,
    companyId,
    destinationRegionId,
    companyServicesCostWithDiscount: {
      amount:
        deliveryDetails?.companyServicesCostWithDiscountOriginal.amount ?? 0,
      currency:
        deliveryDetails?.companyServicesCostWithDiscountOriginal.currency ??
        'USD',
    },
    channel: contactValues.channel,
    name: contactValues.name,
    phone: contactValues.phone.toString(),
  };
  if (make) {
    requestBody.makeId = make;
  }
  if (model) {
    requestBody.modelId = model;
  }

  const featuresIds = Array.from(
    new Set(
      Object.values(features)
        .flat()
        .filter((elem) => !!elem),
    ),
  ) as number[];

  if (!!featuresIds.length) {
    requestBody.features = featuresIds;
  }

  if (
    Array.isArray(bodyColors) &&
    !bodyColors.includes(NON_IMPORTANT_TYPE_VALUE.toString())
  ) {
    requestBody.bodyColors = bodyColors.map((elem) => elem.toLowerCase());
  }

  if (bodyTypes && !bodyTypes.includes(NON_IMPORTANT_TYPE_VALUE)) {
    requestBody.bodyTypes = bodyTypes;
  }

  if (engineCapacityFrom && engineCapacityFrom !== SelectOptions.any) {
    requestBody.engineCapacityFrom = engineCapacityFrom;
  }

  if (engineCapacityTo && engineCapacityTo !== SelectOptions.any) {
    requestBody.engineCapacityTo = engineCapacityTo;
  }

  if (engineTypes && !engineTypes.includes(NON_IMPORTANT_TYPE_VALUE)) {
    requestBody.engineTypes = engineTypes;
  }

  if (toMileage) {
    requestBody.toMileage = +toMileage;
  }

  if (typesOfDrive && !typesOfDrive.includes(NON_IMPORTANT_TYPE_VALUE)) {
    requestBody.typesOfDrive = typesOfDrive;
  }

  if (yearFrom && yearFrom !== SelectOptions.any) {
    requestBody.fromYear = +yearFrom;
  }

  if (yearTo && yearTo !== SelectOptions.any) {
    requestBody.toYear = +yearTo;
  }

  return requestBody;
};

export const getInitialDestinationId = (
  companies: Company[],
  selectedDeliveryCompanyId: number,
) => {
  const routes = companies
    .filter((elem) => elem.id === selectedDeliveryCompanyId)
    .map((elem) => elem.supportedRoutes.map((item) => item.destinationRegionId))
    .flat();

  const uniqueRoutes = Array.from(new Set(routes));

  return uniqueRoutes.map((route) => ({
    label: destinationRegionIdMapping[route],
    value: route,
  }));
};
