export const initialSliderRangePositionProps = {
  dotPosition: 50,
  partsQuantity: 3,
  sector: 2,
};

export const priceAnalyticsData = [
  { title: 'Низкая цена' },
  { title: 'Средняя цена' },
  { title: 'Высокая цена' },
];

export const priceAnalyticsDataExtended = [
  { title: 'Низкая цена' },
  { title: 'Цена ниже среднего' },
  { title: 'Цена выше среднего' },
  { title: 'Высокая цена' },
];

export const mileageAnalyticsData = [
  { title: 'Небольшой пробег' },
  { title: 'Средний пробег' },
  { title: 'Большой пробег' },
];

export const mileageAnalyticsDataExtended = [
  { title: 'Небольшой пробег' },
  { title: 'Пробег ниже среднего' },
  { title: 'Пробег выше среднего' },
  { title: 'Большой пробег' },
];
