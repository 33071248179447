import { FC } from 'react';
import { Col, Row, Typography } from 'antd';
import { CarImageGallery } from '../CarImageGallery/CarImageGallery';
import { CarDetailsSection } from '../CarDetailsSection/CarDetailsSection';
import { StyledSectionTitle } from '../shared/styled';
import { useCarInfoContext } from '../../../../hooks/car';
import {
  StyledLinkContainer,
  StyledPriceContainer,
  StyledWrapper,
  StyledCarImageGalleryWrapper,
  StyledBaseAdvertDataWrapper,
  StyledBaseCarInformationWrapper,
  StyledLinkWithPriceSector,
} from './StyledCarMainSection';
import { CarPriceBlock, CarDescription } from '../../../../components/Car';
import { getDataForCarItemPrices } from '../../../../utils/getDataForCarItemPrices';
import { ICarData } from '../../../../models';
import { ICar } from '../../../../models/car';
import { useMediaQuery } from 'react-responsive';
import { SCREEN_WIDTH } from '../../../../screenSettings';
import { TitleWithActions } from '../../../../components/TitleWithActions';
import { CopyClipboard } from '../../../../components/CopyClipboard';
import { TranslateInGoogle } from '../../../../components/TranslateInGoogle';
import { SubscriptionIconBlock } from '../../../../components/SubscriptionIconBlock';
import { getPriceByCurrency } from '../../../../utils/getPriceByCurrency';
import { useProfileSelector } from '../../../../store/selectors';

export const CarMainSection: FC = () => {
  const { currency } = useProfileSelector();
  const { carDetails } = useCarInfoContext();
  const isMobileWidth = useMediaQuery({
    query: `(max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px`,
  });
  const isTabletWidth = useMediaQuery({
    query: `(max-width: ${SCREEN_WIDTH.TABLET}px`,
  });

  const dataForPricesBlock = getDataForCarItemPrices(
    carDetails?.baseCurrency ?? 'USD',
    carDetails?.prices ?? [],
    carDetails?.vatDeductible ?? false,
    carDetails?.vatPrices ?? [],
  );

  const carData: ICarData = {
    sourceId: carDetails?.sourceId || 0,
    adId: carDetails?.adId || '',
    title: carDetails?.fullName || '',
    imageUrl: carDetails?.imageUrl || '',
    price: getPriceByCurrency(carDetails?.prices, carDetails?.baseCurrency),
    currency: carDetails?.baseCurrency || currency,
    prices: carDetails?.prices || [],
    makeId: carDetails?.makeId || '',
    modelId: carDetails?.modelOriginalName || '',
    yearOfIssue: carDetails?.yearOfIssue || 0,
    car: carDetails as ICar,
    engineCapacity: carDetails?.engineCapacity || 0,
    tags: carDetails?.tags || [],
  };

  return (
    <>
      {!!carDetails && (
        <>
          <StyledBaseCarInformationWrapper>
            <StyledCarImageGalleryWrapper>
              <CarImageGallery car={carDetails} />
            </StyledCarImageGalleryWrapper>
            <StyledBaseAdvertDataWrapper>
              <StyledLinkWithPriceSector>
                <StyledLinkContainer>
                  <Typography.Link href={carDetails.sourceUrl} target='_blank'>
                    <h1>{`${carDetails.fullName}`}</h1>
                  </Typography.Link>
                  <SubscriptionIconBlock
                    item={carData}
                    initialVisibility={true}
                  />
                </StyledLinkContainer>
                <StyledWrapper>
                  <StyledPriceContainer>
                    {dataForPricesBlock.map((item, index) => (
                      <CarPriceBlock
                        {...item}
                        key={`${item.description}`}
                        showDivider={index !== dataForPricesBlock.length - 1}
                      />
                    ))}
                  </StyledPriceContainer>
                </StyledWrapper>
              </StyledLinkWithPriceSector>

              <CarDetailsSection car={carDetails} />
            </StyledBaseAdvertDataWrapper>
          </StyledBaseCarInformationWrapper>

          {process.env.REACT_APP_CAR_DESCRIPTION_FEATURE === 'true' &&
            !isTabletWidth &&
            carDetails.description && (
              <Row
                style={{ width: '100%', marginBottom: isMobileWidth ? 10 : 0 }}
              >
                <Col span={24}>
                  <TitleWithActions
                    title={
                      <StyledSectionTitle style={{ marginBottom: 0 }}>
                        Описание
                      </StyledSectionTitle>
                    }
                    actions={[
                      <CopyClipboard copiedText={carDetails.description} />,
                      <TranslateInGoogle text={carDetails.description} />,
                    ]}
                    actionsIds={['copyTextAction', 'translateGoogle']}
                  />
                  <CarDescription
                    carDescription={carDetails.description}
                    isExpanded={true}
                  />
                </Col>
              </Row>
            )}
        </>
      )}
    </>
  );
};
