import { FC, useEffect } from 'react';
import { Spin } from 'antd';
import {
  StyledProfileSettingsAccount,
  StyledTitleAccount,
} from './ProfileSettingsAccount.styled';
import { AccountInfoForm } from '../AccountInfoForm';
import { useGetProfileInfoQuery } from '../../../../store/profile/profile.api';
import { useProfileNotification } from '../../hooks';
import { PROFILE_SETTINGS_ERROR_MESSAGES } from '../../data/accountMessages';
import { getDescriptionFromError } from '../../utils';

export const ProfileSettingsAccount: FC = () => {
  const { contextHolder, openNotificationWithIcon } = useProfileNotification();
  const { isLoading, isError, error } = useGetProfileInfoQuery();

  useEffect(() => {
    if (isError && error) {
      const description = getDescriptionFromError(error, 'getUserData');
      openNotificationWithIcon(
        'topRight',
        'error',
        PROFILE_SETTINGS_ERROR_MESSAGES.GET_INFO,
        description,
      );
    }
  }, [error, isError, openNotificationWithIcon]);

  return (
    <Spin size='large' spinning={isLoading}>
      <StyledProfileSettingsAccount>
        {contextHolder}
        <StyledTitleAccount>Аккаунт</StyledTitleAccount>
        <AccountInfoForm />
      </StyledProfileSettingsAccount>
    </Spin>
  );
};
