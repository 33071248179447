import { useImperativeHandle, forwardRef, useEffect, useState } from 'react';
import { Form, Select, Spin } from 'antd';
import ReactGA from 'react-ga4';
import { CustomSelect } from '../../../../../components/FormElements';
import { DeliveryFormFiledsType } from '../types/form';
import {
  DeliveryFormFieldsNames,
  FormFieldName,
} from '../../../../../enums/form';
import { StyledFormDelivery } from './DeliveryForm.styled';
import { FormItemLabel } from '../../../../../components/FormItemLabel';
import { CaretDownOutlined } from '@ant-design/icons';
import { FormRef } from '../types/form';
import {
  useCompaniesSelector,
  useTurnKeyFormsSelector,
} from '../../../../../store/selectors';
import { SelectOptionStyle } from '../../../../../components/styled/SelectOption';
import { StyledFormWrapper } from '../TurnKeyForms.styled';
import { StyledTitleForm } from '../shared/styles';
import { commonValidationRules } from '../../../../../data/commonValidationRules';
import { TitleRow } from '../shared/styles';
import { FeatureTag } from '../../../../../components/Car';
import { StyledTitle } from '../../../../../components/styled/StyledTitle';
import { IconWithTooltip } from '../../../../../components/IconWithTooltip';
import { useMediaQuery } from 'react-responsive';
import { SCREEN_WIDTH } from '../../../../../screenSettings';
import { PriceRowWithDiscount } from '../../../../../components/PriceRowWithDiscount';
import { AlertInfo } from '../../../../../components/AlertInfo';
import {
  SendRequestModalForm,
  SendRequestForm,
} from '../../../../../components/SendRequestModalForm';
import { useCalculateCarTurnkeyDelivery } from '../../../../../hooks/car';
import { GoogleAnanalyticsEvent } from '../../../../../enums/googleAnalyticsAction';
import { FilterCellSingle } from '../../../../../components/MobileFormElements';

import { useActions } from '../../../../../hooks/useActions';
import { getCarTurnkeyDeliveryRequestBody } from './DeliveryForm.utils';
import {
  turnKeySourceIDMapping,
  turnKeySourceIDOptions,
} from '../../../../../data/sourceIdMapping';
import { StepsValues } from '../../../enums/stepsValues';
import { destinationRegionIdMapping } from '../../../../../data/mappings';
import { useSendCustomCarOfferMutation } from '../../../../../store/cars/cars.api';
import { OptionType } from '../../../../../data/types';
import { useQueryState } from '../../../../../hooks/useQueryState';

export const DeliveryForm = forwardRef<FormRef>((_, ref) => {
  const [deliveryOptions, setDeliveryOptions] = useState<OptionType[]>();
  const [form] = Form.useForm<DeliveryFormFiledsType>();
  const selectedCompanyId = Form.useWatch(
    DeliveryFormFieldsNames.CompanyId,
    form,
  );
  const destinationValue = Form.useWatch(
    DeliveryFormFieldsNames.DestinationRegionId,
    form,
  );
  const [queryParameters, setQueryParameters] = useQueryState();
  const [sendDeliveryRequestForm] = Form.useForm();
  const [sendCustomCarOffer, { isLoading: isSendingCarOffer }] =
    useSendCustomCarOfferMutation();
  const { searchParamsForm, deliveryForm, equipmentForm } =
    useTurnKeyFormsSelector();
  const { companies } = useCompaniesSelector();
  const { setDeliveryForm, resetTurnKeyFormsState, setActiveStep } =
    useActions();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { getDeliveryPrices, pricesDelivery, isFetchingPrices } =
    useCalculateCarTurnkeyDelivery();
  const isTabletWidth = useMediaQuery({
    query: `(max-width: ${SCREEN_WIDTH.TABLET}px)`,
  });
  const isMobileWidth = useMediaQuery({
    query: `(max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_1}px)`,
  });

  const selectedCompanyName = companies.find(
    (company) => company.id === selectedCompanyId,
  )?.name;

  const submitForm = () => form.submit();
  const resetForm = () => form.resetFields();

  const finishFormHandler = (values: DeliveryFormFiledsType) => {
    setDeliveryForm(values);
    setIsModalOpen(true);
  };

  useImperativeHandle(ref, () => ({ submitForm, resetForm }));

  const sendRequest = async (formValues: SendRequestForm) => {
    if (process.env.NODE_ENV === 'production') {
      ReactGA.event({
        category: 'button',
        action: GoogleAnanalyticsEvent.SubmitTurnkeyAutoDeliveryForm,
        label: window.location.href,
      });
    }

    const requestBody = getCarTurnkeyDeliveryRequestBody(
      searchParamsForm,
      equipmentForm,
      deliveryForm,
      formValues,
      pricesDelivery,
    );

    try {
      await sendCustomCarOffer(requestBody).unwrap();

      setIsModalOpen(false);
      setQueryParameters({
        [FormFieldName.SourceRegionId]: turnKeySourceIDOptions[0].value,
        [FormFieldName.Make]: undefined,
        [FormFieldName.Model]: undefined,
      });
      sendDeliveryRequestForm.resetFields();
      resetTurnKeyFormsState();
      setActiveStep(StepsValues.SearchParams);
    } catch (err: any) {
      console.error(err);
    }
  };

  const handleCompanyChange = async (value: number) => {
    const uniqueRoutes = Array.from(
      new Set(
        companies
          .find((company) => company.id === value)
          ?.supportedRoutes?.map((elem) => elem.destinationRegionId),
      ),
    );

    const selectDeliveryDestinations = uniqueRoutes.map((route) => ({
      label: destinationRegionIdMapping[route],
      value: route,
    }));

    setDeliveryOptions(selectDeliveryDestinations);

    if (isMobileWidth) {
      form.setFieldsValue({
        [DeliveryFormFieldsNames.DestinationRegionId]:
          selectDeliveryDestinations[0].value,
        [DeliveryFormFieldsNames.CompanyId]: value,
      });
    } else {
      form.setFieldsValue({
        [DeliveryFormFieldsNames.DestinationRegionId]:
          selectDeliveryDestinations[0].value,
      });
    }

    await getDeliveryPrices({
      companyId: value,
      currency: searchParamsForm.currency,
      destinationRegionId: selectDeliveryDestinations[0].value,
      sourceRegionId: searchParamsForm.sourceRegionId,
    });
  };

  const handleDeliveryChange = async (value: number) => {
    await getDeliveryPrices({
      companyId: selectedCompanyId,
      currency: searchParamsForm.currency,
      destinationRegionId: value,
      sourceRegionId: searchParamsForm.sourceRegionId,
    });
  };

  const deliveryCompaniesOptions = companies.map((elem) => ({
    label: elem.name,
    value: elem.id,
  }));

  useEffect(() => {
    handleCompanyChange(companies[0].id);
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <StyledFormWrapper>
        <StyledTitleForm style={{ marginLeft: 0 }}>
          Доставка авто
        </StyledTitleForm>

        <StyledFormDelivery
          scrollToFirstError
          form={form}
          requiredMark={false}
          name='desktopDeliveryForm'
          layout='vertical'
          autoComplete='off'
          onFinish={finishFormHandler}
        >
          {!isMobileWidth && (
            <>
              <CustomSelect
                onChange={handleCompanyChange}
                formItemProps={{
                  name: DeliveryFormFieldsNames.CompanyId,
                  label: <FormItemLabel>Компания</FormItemLabel>,
                  style: { width: '100%' },
                  initialValue: deliveryCompaniesOptions[0].value,
                }}
                disabled={companies.length <= 1}
                suffixIcon={<CaretDownOutlined rev='true' />}
              >
                {deliveryCompaniesOptions?.map((deliveryValue) => (
                  <Select.Option
                    key={deliveryValue.label}
                    style={SelectOptionStyle}
                    value={deliveryValue.value}
                  >
                    {deliveryValue.label}
                  </Select.Option>
                ))}
              </CustomSelect>

              <CustomSelect
                onChange={handleDeliveryChange}
                formItemProps={{
                  style: { width: '100%' },
                  name: DeliveryFormFieldsNames.DestinationRegionId,
                  label: <FormItemLabel>Доставка в</FormItemLabel>,
                  rules: commonValidationRules.deliveryRegion,
                }}
                suffixIcon={<CaretDownOutlined rev='true' />}
                placeholder={'Выберите регион доставки'}
                disabled={deliveryOptions && deliveryOptions.length <= 1}
              >
                {deliveryOptions?.map((region) => (
                  <Select.Option
                    key={region.label}
                    style={SelectOptionStyle}
                    value={region.value}
                  >
                    {region.label}
                  </Select.Option>
                ))}
              </CustomSelect>
            </>
          )}

          {isMobileWidth && (
            <>
              <FilterCellSingle
                style={{ marginBottom: 0, width: '100%' }}
                form={form}
                withSearch={true}
                options={deliveryCompaniesOptions}
                disabled={companies.length <= 1}
                label='Компания'
                name={DeliveryFormFieldsNames.CompanyId}
                placeholder='Выберите компанию'
                value={selectedCompanyId}
                callback={handleCompanyChange}
              />

              <FilterCellSingle
                style={{ marginBottom: 0, width: '100%' }}
                form={form}
                options={deliveryOptions}
                label='Модель'
                name={DeliveryFormFieldsNames.DestinationRegionId}
                placeholder='Выберите регион доставки'
                disabled={deliveryOptions && deliveryOptions.length <= 1}
                rules={[{ required: true, message: 'Обязательное поле' }]}
                value={destinationValue}
                callback={handleDeliveryChange}
              />
            </>
          )}
        </StyledFormDelivery>

        <TitleRow style={{ marginBottom: 24 }}>
          <StyledTitle level={3}>{`Услуги ${selectedCompanyName}`}</StyledTitle>
          <IconWithTooltip
            description={
              <>
                Комплекс услуг по подбору, осмотру и доставке автомобиля из
                региона{' '}
                {turnKeySourceIDMapping[searchParamsForm.sourceRegionId]} в
                выбранный город по специальной цене от AutoAggregator
              </>
            }
            customTrigger={isTabletWidth ? ['click'] : ['hover']}
            customPopoverWidth={230}
          />
        </TitleRow>

        {isMobileWidth && (
          <FeatureTag
            key='AutoAggregator price'
            style={{
              flexShrink: 0,
              flexGrow: 0,
              alignSelf: 'flex-start',
              display: 'inline-flex',
              marginBottom: 12,
            }}
            feature='Стоимость через AutoAggregator'
          />
        )}

        {isFetchingPrices && (
          <Spin style={{ margin: '0 auto', width: '100%' }} size='large' />
        )}

        {!isFetchingPrices && pricesDelivery && (
          <>
            <PriceRowWithDiscount
              title={
                <TitleRow style={{ gap: 8 }}>
                  <FeatureTag
                    key='find auto'
                    style={{
                      fontWeight: 400,
                      paddingLeft: 0,
                      flexShrink: 0,
                      flexGrow: 0,
                      alignSelf: 'flex-start',
                      backgroundColor: 'transparent',
                    }}
                    feature='Подбор авто'
                  />
                  {!isMobileWidth && (
                    <FeatureTag
                      key='with AutoAGG'
                      style={{
                        flexShrink: 0,
                        flexGrow: 0,
                        alignSelf: 'flex-start',
                      }}
                      feature='Стоимость через AutoAggregator'
                    />
                  )}
                </TitleRow>
              }
              priceOriginal={pricesDelivery.companyServicesCostOriginal.amount}
              currencyOriginal={
                pricesDelivery.companyServicesCostOriginal.currency
              }
              priceOriginalDiscount={
                pricesDelivery.companyServicesCostWithDiscountOriginal.amount
              }
              currencyOriginalDiscount={
                pricesDelivery.companyServicesCostWithDiscountOriginal.currency
              }
              priceServices={pricesDelivery.companyServicesCostConverted.amount}
              currencyConverted={
                pricesDelivery.companyServicesCostConverted.currency
              }
              style={{ marginBottom: 16 }}
            />

            <AlertInfo
              style={{ marginBottom: 40 }}
              content={
                <>
                  {'В расчет не включены следующие услуги:'}
                  <br />
                  {'\u00A0\u2022\u00A0Разовый осмотр авто'}
                  <br />
                  {'\u00A0\u2022\u00A0Доставка авто'}
                  <br />
                  {'\u00A0\u2022\u00A0Таможенное оформление и регистрация'}
                  <br />
                  {`Полная стоимость будет определена и предоставлена компанией ${selectedCompanyName}.`}
                </>
              }
            />
          </>
        )}
      </StyledFormWrapper>

      <SendRequestModalForm
        title='Авто “под ключ”'
        isLoading={isSendingCarOffer}
        isOpenModal={isModalOpen}
        setIsOpenModal={setIsModalOpen}
        sendRequest={sendRequest}
        form={sendDeliveryRequestForm}
      />
    </>
  );
});
