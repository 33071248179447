import { DomainExtension } from '../enums/domain';
import { APP_PATHS } from '../layout/settingsLayout';
import { MetaData } from './types';

export const metadataMapping: Record<
  DomainExtension,
  Record<string, MetaData>
> = Object.freeze({
  [DomainExtension.By]: {
    [APP_PATHS.ROOT]: {
      title: 'Авто из США и Европы в Беларусь',
      description:
        'Авто из США, Европы и Китая в Беларусь. Тысячи объявлений авто из Европы, авто под ключ, полный расчет стоимости, доставка и уведомления об авто в Telegram.',
    },
    [APP_PATHS.CARS_PAGE]: {
      title: 'Купить {0} {1} в Беларусь. Выгодные цены.',
      description:
        'Тысячи выгодных для покупки автомобилей {1} в Беларусь. Доставка бу машин и автомобилей {1} под заказ в Минск.',
    },
    [APP_PATHS.CAR_PAGE]: {
      title: `Купить {0} {1} {2} | AutoAggregator`,
      description: `Продажа легковых автомобилей из России, Германии и Европы 🚗 Свежие объявления частных лиц о продаже б/у авто с ценами и фото ✅ Купить подержанную машину в РБ`,
    },
    [APP_PATHS.CAR_TURN_KEY]: {
      title: `{0} в Беларусь`,
      description: `Закажите авто под ключ из {1} в Беларусь по вашим требованиям. Заполните форму с характеристиками, комплектацией и состоянием автомобиля. Быстрая и удобная доставка с гарантией качества.`,
    },
  },
  [DomainExtension.Ru]: {
    [APP_PATHS.ROOT]: {
      title: 'Авто из США и Европы в Беларусь',
      description:
        'Авто из США, Европы и Китая в Беларусь. Тысячи объявлений авто из Европы, авто под ключ, полный расчет стоимости, доставка и уведомления об авто в Telegram.',
    },
    [APP_PATHS.CARS_PAGE]: {
      title: 'Купить {0} {1} в Беларусь. Выгодные цены.',
      description:
        'Тысячи выгодных для покупки автомобилей {1} в Беларусь. Доставка бу машин и автомобилей {1} под заказ в Минск.',
    },
    [APP_PATHS.CAR_PAGE]: {
      title: `Купить {0} {1} {2} | AutoAggregator`,
      description: `Продажа легковых автомобилей из России, Германии и Европы 🚗 Свежие объявления частных лиц о продаже б/у авто с ценами и фото ✅ Купить подержанную машину в РБ`,
    },
    [APP_PATHS.CAR_TURN_KEY]: {
      title: `{0} в Беларусь`,
      description: `Закажите авто под ключ из {1} в Беларусь по вашим требованиям. Заполните форму с характеристиками, комплектацией и состоянием автомобиля. Быстрая и удобная доставка с гарантией качества.`,
    },
  },
  [DomainExtension.Net]: {
    [APP_PATHS.ROOT]: {
      title: 'Авто из США и Европы в Беларусь',
      description:
        'Авто из США, Европы и Китая в Беларусь. Тысячи объявлений авто из Европы, авто под ключ, полный расчет стоимости, доставка и уведомления об авто в Telegram.',
    },
    [APP_PATHS.CARS_PAGE]: {
      title: 'Купить {0} {1} в Беларусь. Выгодные цены.',
      description:
        'Тысячи выгодных для покупки автомобилей {1} в Беларусь. Доставка бу машин и автомобилей {1} под заказ в Минск.',
    },
    [APP_PATHS.CAR_PAGE]: {
      title: `Купить {0} {1} {2} | AutoAggregator`,
      description: `Продажа легковых автомобилей из России, Германии и Европы 🚗 Свежие объявления частных лиц о продаже б/у авто с ценами и фото ✅ Купить подержанную машину в Европе`,
    },
    [APP_PATHS.CAR_TURN_KEY]: {
      title: `{0} в Беларусь`,
      description: `Закажите авто под ключ из {1} в Беларусь по вашим требованиям. Заполните форму с характеристиками, комплектацией и состоянием автомобиля. Быстрая и удобная доставка с гарантией качества.`,
    },
  },
});

export const commonMetadata: Record<string, MetaData> = {
  [APP_PATHS.SIGN_UP]: {
    title: 'AutoAggregator',
    description: 'Регистрация',
  },
  [APP_PATHS.SIGN_IN]: {
    title: 'AutoAggregator',
    description: 'Вход в аккаунт',
  },
  [APP_PATHS.CONFIRM_EMAIL]: {
    title: 'AutoAggregator',
    description: 'Подтверждение',
  },
  [APP_PATHS.FORGOT_PASSWORD]: {
    title: 'AutoAggregator',
    description: 'Восстановление пароля',
  },
  [APP_PATHS.RESET_PASSWORD]: {
    title: 'AutoAggregator',
    description: 'Сброс пароля',
  },
  [APP_PATHS.PROFILE_PAGE]: {
    title: 'AutoAggregator',
    description: 'Кабинет',
  },
};
