import React, { FC, Fragment, useState, CSSProperties } from 'react';
import {
  StyledDateContainer,
  StyledImage,
  StyledImageContainer,
  StyledListContainer,
  StyledListItem,
  StyledMileageContainer,
  StyledPriceContainer,
  StyledPagination,
} from './StyledCarClonesList';
import { MyParagraph } from '../../../../../components/Paragraph/MyParagraph';
import { MyLink } from '../../../../../components/Links/MyLink';
import {
  Clone,
  ICarClonesResponse,
} from '../../../../../models/response/ICarClonesResponse';
import dayjs from 'dayjs';
import { formatNumber } from '../../../../../utils/formatNumber';
import {
  bodyTypes,
  currencySymbols,
  engineTypes,
  transmissions,
} from '../../../../../data';
import { ICar } from '../../../../../models/car';
import { findLabelByValue } from '../../../../../utils/findLabelByValue';
import { getPriceByCurrency } from '../../../../../utils/getPriceByCurrency';
import { useMediaQuery } from 'react-responsive';
import { SCREEN_WIDTH } from '../../../../../screenSettings';
import { Divider } from 'antd';
import { useProfileSelector } from '../../../../../store/selectors';
import { CLONES_CARS_PER_PAGE } from '../../../../../data/paginationConstants';

interface ICarClonesList {
  carClones: ICarClonesResponse;
  car: ICar | undefined;
}

export const CarClonesList: FC<ICarClonesList> = ({
  carClones: { clones },
  car,
}) => {
  const { currency } = useProfileSelector();

  const isDesktopWidth = useMediaQuery({
    query: `(max-width: ${SCREEN_WIDTH.DESKTOP}px`,
  });
  const [currentPage, setCurrentPage] = useState<number>(1);

  const selectedCarClones = clones?.filter(
    (_, index) =>
      index < currentPage * CLONES_CARS_PER_PAGE &&
      index >= (currentPage - 1) * CLONES_CARS_PER_PAGE,
  );

  const paragraphStyles: CSSProperties = {
    color: '#717C8C',
    marginBottom: isDesktopWidth ? 0 : 8,
  };

  return (
    <>
      <StyledListContainer>
        {selectedCarClones?.map((clone: Clone, index) => (
          <Fragment key={`car-clone-fragment-${clone.adId ?? index}`}>
            <StyledListItem key={`car-clone-${clone.adId ?? index}`}>
              <StyledImageContainer>
                <StyledImage src={clone.imageUrl} />
              </StyledImageContainer>
              <div className='clones__details'>
                <StyledDateContainer>
                  <MyParagraph type='secondary' style={paragraphStyles}>
                    Дата создания
                  </MyParagraph>
                  <MyParagraph>
                    {dayjs(clone.createAt).format('DD/MM/YYYY')}
                  </MyParagraph>
                </StyledDateContainer>
                <StyledMileageContainer>
                  <MyParagraph type='secondary' style={paragraphStyles}>
                    Пробег
                  </MyParagraph>
                  <MyParagraph>{formatNumber(clone.mileage)} км</MyParagraph>
                </StyledMileageContainer>
                <StyledPriceContainer>
                  <MyParagraph type='secondary' style={paragraphStyles}>
                    Цена
                  </MyParagraph>
                  <MyParagraph>
                    {/*@ts-ignore*/}
                    {formatNumber(
                      getPriceByCurrency(clone.prices, currency),
                    )}{' '}
                    {currencySymbols[currency]}
                  </MyParagraph>
                </StyledPriceContainer>
                <div className='clones__details_description'>
                  <MyParagraph type='secondary' style={paragraphStyles}>
                    Характеристики
                  </MyParagraph>
                  <MyParagraph>
                    {!car && '-'}

                    {car && (
                      <>
                        {car.yearOfIssue < 0
                          ? new Date().getFullYear()
                          : car.yearOfIssue}{' '}
                        г.,{' '}
                        <span>
                          {findLabelByValue(
                            transmissions,
                            car.transmissionType,
                          )?.toLowerCase()}
                          ,{' '}
                        </span>{' '}
                        <span>
                          {findLabelByValue(
                            engineTypes,
                            car.engineType,
                          )?.toLowerCase()}
                        </span>{' '}
                        <span>{car.engineCapacity} л,</span>{' '}
                        <span>
                          {findLabelByValue(
                            bodyTypes,
                            car.bodyType,
                          )?.toLowerCase()}
                          ,{' '}
                        </span>{' '}
                        <span>{formatNumber(car.mileage)} км</span>
                      </>
                    )}
                  </MyParagraph>
                </div>
                <div className='clones__details_link'>
                  <MyLink href={clone.sourceUrl} target='_blank'>
                    Перейти к деталям
                  </MyLink>
                </div>
              </div>
            </StyledListItem>
            {index !== selectedCarClones.length - 1 && (
              <Divider style={{ backgroundColor: '#E1E7F0', marginTop: 20 }} />
            )}
          </Fragment>
        ))}
      </StyledListContainer>
      {!!clones && (
        <StyledPagination
          current={currentPage}
          hideOnSinglePage={true}
          pageSize={CLONES_CARS_PER_PAGE}
          onChange={(page) => setCurrentPage(page)}
          total={clones.length}
          className='clones__list_pagination'
        />
      )}
    </>
  );
};
