import { useFeaturesDrawerContext } from '../../hooks/car';
import { CarFeatureList } from '../CarFeatureList';
import { StyledDrawerContent, StyledAntDrawer } from './FeaturesDrawer.styled';
import { CloseCustomIcon } from '../../img/icons/components';

export const FeaturesDrawer = () => {
  const { isOpenDrawer, closeDrawer, features, header } =
    useFeaturesDrawerContext();

  const handleCloseDrawer = () => closeDrawer();

  return (
    <StyledAntDrawer
      open={isOpenDrawer}
      style={{ borderTopLeftRadius: 10, borderTopRightRadius: 10 }}
      closeIcon={<CloseCustomIcon style={{ color: '#97A3B5' }} />}
      zIndex={20}
      height={'calc(100vh - 90px)'}
      onClose={handleCloseDrawer}
      title={header}
      closable={true}
      placement='bottom'
    >
      <CarFeatureList sortedByCategoryFeatures={features} />
    </StyledAntDrawer>
  );
};
