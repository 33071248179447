import { FC } from 'react';
import { StyledText } from './StylesSubscriptionModelWindow';
import { Divider } from 'antd';
import { CleanLink } from '../Links/CleanLink';
import { APP_PATHS } from '../../layout/settingsLayout';
import { SubscriptionType } from '../../enums/subscription';
import { getSubscriptionProfileUrl } from './utils';

interface ISubscriptionModelWindow {
  content?: string;
  subscribtionType?: SubscriptionType;
}

export const SubscriptionModelWindow: FC<ISubscriptionModelWindow> = ({
  content,
  subscribtionType,
}) => {
  return (
    <>
      {content && (
        <>
          <StyledText>{content}</StyledText>
          <Divider orientation='right' />
        </>
      )}
      <StyledText>
        Вы можете управлять своими подписками в{' '}
        <CleanLink to={getSubscriptionProfileUrl(subscribtionType)}>
          своем профиле
        </CleanLink>{' '}
        или через{' '}
        <CleanLink
          to={
            process.env.REACT_APP_TELEGRAM_URL || APP_PATHS.PROFILE_PAGE_ACCOUNT
          }
          target='_blank'
        >
          Telegram.
        </CleanLink>
      </StyledText>
    </>
  );
};
