import { useLazyCalculateTurnKeyDeliveryQuery } from '../../store/car/car.api';
import { notification } from 'antd';
import { config } from '../../config';
import { TurnKeyDeliveryRequest } from '../../models/car/CarTurnKey';

export const useCalculateCarTurnkeyDelivery = () => {
  const [getDeliveryCalculations, { isError, data, isFetching }] =
    useLazyCalculateTurnKeyDeliveryQuery();

  const [notificationApi, contextHolder] = notification.useNotification();

  const getDeliveryPrices = async (
    params: TurnKeyDeliveryRequest,
  ): Promise<void> => {
    try {
      await getDeliveryCalculations(params);
    } catch (err: any) {
      console.log(err);

      notificationApi.error({
        message: 'Ошибка при расчете доставки авто под ключ',
        description:
          err?.data?.message || 'Попробуйте сформировать запрос еще раз!',
        duration: config.DEFAULT_NOTIFICATION_DURATION,
      });
    }
  };

  return {
    getDeliveryPrices,
    pricesDelivery: data,
    isFetchingPrices: isFetching,
    errorFetchingPrices: isError,
    contextHolder,
  };
};
