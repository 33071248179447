import styled from 'styled-components';
import { SCREEN_WIDTH } from '../../../../screenSettings';

export const StyledWrapperDetailItem = styled.div`
  margin-bottom: 16px;
`;

export const StyledDetailsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  flex: 1;

  & .details__description {
    width: 100%;
    display: flex;
    margin-bottom: auto;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex-grow: 1;
    margin-bottom: auto;
    @media only screen and (max-width: ${SCREEN_WIDTH.TO_DESKTOP_BREAK_3}px) {
      margin-bottom: 20px;
    }
  }

  & .ant-row {
    width: 100%;
    margin-top: auto;
    align-items: center;
  }
`;

export const StyledButtonsWrapper = styled.div`
  width: 60%;
  margin-top: 24px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;

  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET}px) {
    margin-bottom: 0;
  }

  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_2}px) {
    margin: auto;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 0;
    gap: 16px;
  }
`;

export const StyledFixedToBottomBlock = styled.div`
  margin-top: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const StyledDealerWithData = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  flex: 1;
  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET}px) {
    align-items: baseline;
  }
`;

export const StyledDealerNameWrapper = styled.div`
  flex-shrink: 0;
  max-width: 55%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
