import { ConfigProvider, Form } from 'antd';
import styled from 'styled-components';
import { useCarsListPageContext } from '../../../../hooks/carsList';
import { FC } from 'react';
import { FilterCellSingle } from '../../../../components/MobileFormElements';
import { sortingOfDate, sortingOfPeriod } from '../../../../data';
import { useQueryState } from '../../../../hooks/useQueryState';
import { initialValuesSortForm } from '../../../../constants';
import { mobileFormTheme } from '../../../../themeSettings';
import { SortFormFieldNames } from '../../../../enums/form';

export const StyledMobileSortForm = styled(Form)`
  margin: 24px 0 14px 0;

  .ant-form-item {
    margin-bottom: 16px;
  }

  .ant-form-item:last-child {
    margin-bottom: 8px;
  }
`;

export const MobileSortForm: FC = () => {
  const {
    sortForm,
    getCountCars,
    getCars,
    setPage,
    setCurrentCount,
    handleSetMetadata,
  } = useCarsListPageContext();
  const [queryParameters, setQueryParameters] = useQueryState();

  const handleFromChange = async (from: string): Promise<void> => {
    setPage(1);
    setQueryParameters({
      [SortFormFieldNames.from]: from,
    });

    const [countCars] = await Promise.all([
      getCountCars({
        [SortFormFieldNames.from]: from,
        [SortFormFieldNames.sortBy]: queryParameters.sortBy,
      }),
      queryParameters.page === '1'
        ? getCars({
            [SortFormFieldNames.from]: from,
            [SortFormFieldNames.sortBy]: queryParameters.sortBy,
          })
        : null,
    ]);

    if (!!countCars) {
      handleSetMetadata(countCars);
      setCurrentCount(countCars);
    }
  };

  const handleSortByChange = async (sortBy: string): Promise<void> => {
    setPage(1);
    setQueryParameters({
      [SortFormFieldNames.sortBy]: sortBy,
    });

    if (queryParameters.page === '1') {
      await getCars({
        [SortFormFieldNames.from]: queryParameters.from,
        [SortFormFieldNames.sortBy]: sortBy,
      });
    }
  };

  return (
    <ConfigProvider theme={mobileFormTheme}>
      <StyledMobileSortForm
        name='sortFormMobile'
        layout='vertical'
        autoComplete='off'
        form={sortForm}
      >
        <FilterCellSingle
          form={sortForm}
          options={sortingOfPeriod}
          label='Период'
          name={SortFormFieldNames.from}
          placeholder='Выберите период'
          value={
            +queryParameters.from ||
            +initialValuesSortForm[SortFormFieldNames.from]
          }
          callback={handleFromChange}
        />
        <FilterCellSingle
          form={sortForm}
          options={sortingOfDate}
          label='Сортировка'
          name={SortFormFieldNames.sortBy}
          placeholder='Выберите сортировку'
          value={queryParameters.sortBy}
          callback={handleSortByChange}
        />
      </StyledMobileSortForm>
    </ConfigProvider>
  );
};
