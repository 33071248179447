import { ConfigProvider, Radio, RadioChangeEvent } from 'antd';
import { CSSProperties, FC, ReactNode } from 'react';

const StyleRadioGroup: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  marginTop: 24,
};

export const RadioGroup: FC<{
  children: ReactNode;
  value: string | number | undefined;
  onChange: (e: RadioChangeEvent) => void;
}> = ({ children, value, onChange }) => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Radio: {
            colorPrimary: '#F5FCFF',
            colorPrimaryActive: '#F5FCFF',
            colorPrimaryHover: '#F5FCFF',
            colorTextLightSolid: '#192A4D',
            fontSize: 16,
          },
        },
      }}
    >
      <Radio.Group
        buttonStyle='solid'
        optionType='button'
        value={value}
        onChange={onChange}
        style={StyleRadioGroup}
      >
        {children}
      </Radio.Group>
    </ConfigProvider>
  );
};
