export const SupportCustomSvg = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    viewBox='0 0 13 13'
    fill='none'
  >
    <path
      d='M6.58245 12.6161C5.57814 12.617 4.58833 12.3766 3.6964 11.9149C3.65326 11.8926 3.61494 11.8621 3.58362 11.825C3.5523 11.7879 3.5286 11.7449 3.51386 11.6987C3.49912 11.6524 3.49364 11.6037 3.49773 11.5553C3.50182 11.5069 3.5154 11.4598 3.5377 11.4167C3.55999 11.3736 3.59057 11.3352 3.62767 11.3039C3.66477 11.2726 3.70768 11.2489 3.75395 11.2342C3.80021 11.2194 3.84893 11.2139 3.89731 11.218C3.94569 11.2221 3.9928 11.2357 4.03593 11.258C5.23058 11.8658 6.6048 12.0207 7.90479 11.6942C9.20478 11.3678 10.3427 10.5819 11.1085 9.48183C11.8742 8.38174 12.216 7.04172 12.0708 5.70926C11.9255 4.37679 11.303 3.14189 10.3183 2.23261C9.3335 1.32334 8.05296 0.801116 6.71316 0.762391C5.37336 0.723666 4.0648 1.17105 3.02916 2.02193C1.99351 2.87281 1.30073 4.0697 1.07876 5.39155C0.856798 6.7134 1.12064 8.07092 1.82157 9.2134C1.87101 9.29794 1.88556 9.39842 1.86213 9.49351C1.83871 9.5886 1.77914 9.67082 1.69609 9.72271C1.65447 9.74815 1.60823 9.7651 1.56003 9.77256C1.51183 9.78003 1.46262 9.77786 1.41526 9.7662C1.3679 9.75453 1.32333 9.73359 1.28411 9.70458C1.24489 9.67558 1.21181 9.63909 1.18678 9.59723C0.376772 8.30011 0.0684034 6.7518 0.319634 5.24332C0.570866 3.73484 1.3644 2.37005 2.55108 1.40547C3.73777 0.440888 5.2359 -0.0570622 6.76389 0.0052072C8.29188 0.0674767 9.74452 0.685678 10.8488 1.74362C11.7016 2.53404 12.3179 3.54588 12.6291 4.66624C12.9402 5.78659 12.9339 6.97136 12.6108 8.08833C12.2877 9.2053 11.6606 10.2105 10.7995 10.9918C9.9383 11.7731 8.87697 12.2997 7.73392 12.5128C7.35421 12.5835 6.96867 12.6181 6.58245 12.6161Z'
      fill='currentColor'
    />
    <path
      d='M0.854547 13C0.711465 12.9973 0.571252 12.9594 0.446307 12.8896C0.321363 12.8198 0.215537 12.7204 0.138187 12.6C0.0608367 12.4796 0.0143465 12.3419 0.00282668 12.1993C-0.00869312 12.0567 0.0151127 11.9134 0.0721389 11.7821L1.19408 9.25774C1.23585 9.16828 1.31109 9.0988 1.40359 9.06428C1.4961 9.02976 1.59846 9.03297 1.68862 9.07321C1.77757 9.11349 1.84693 9.18737 1.88151 9.27868C1.9161 9.36999 1.91309 9.47128 1.87315 9.56037L0.743829 12.0847C0.729455 12.1039 0.721685 12.1272 0.721685 12.1512C0.721685 12.1751 0.729455 12.1984 0.743829 12.2176C0.759952 12.2361 0.781126 12.2494 0.804736 12.2559C0.828346 12.2625 0.85336 12.262 0.876691 12.2545L3.71846 11.2433C3.76393 11.2258 3.81248 11.2177 3.86117 11.2194C3.90987 11.2211 3.95771 11.2327 4.00183 11.2534C4.04595 11.274 4.08544 11.3034 4.11792 11.3397C4.1504 11.3761 4.17521 11.4186 4.19085 11.4647C4.22409 11.5567 4.21944 11.6582 4.17792 11.7468C4.1364 11.8353 4.0614 11.9038 3.96942 11.9371L1.1498 12.9484C1.05535 12.9834 0.95529 13.0009 0.854547 13Z'
      fill='currentColor'
    />
    <path
      d='M8.73919 5.42709H4.31047C4.21259 5.42709 4.11871 5.3882 4.0495 5.31899C3.98029 5.24978 3.94141 5.15591 3.94141 5.05803C3.94141 4.96014 3.98029 4.86627 4.0495 4.79706C4.11871 4.72785 4.21259 4.68896 4.31047 4.68896H8.73919C8.83707 4.68896 8.93095 4.72785 9.00016 4.79706C9.06937 4.86627 9.10825 4.96014 9.10825 5.05803C9.10825 5.15591 9.06937 5.24978 9.00016 5.31899C8.93095 5.3882 8.83707 5.42709 8.73919 5.42709Z'
      fill='currentColor'
    />
    <path
      d='M8.73919 8.37923H4.31047C4.21259 8.37923 4.11871 8.34035 4.0495 8.27114C3.98029 8.20193 3.94141 8.10805 3.94141 8.01017C3.94141 7.91229 3.98029 7.81842 4.0495 7.74921C4.11871 7.68 4.21259 7.64111 4.31047 7.64111H8.73919C8.83707 7.64111 8.93095 7.68 9.00016 7.74921C9.06937 7.81842 9.10825 7.91229 9.10825 8.01017C9.10825 8.10805 9.06937 8.20193 9.00016 8.27114C8.93095 8.34035 8.83707 8.37923 8.73919 8.37923Z'
      fill='currentColor'
    />
  </svg>
);
