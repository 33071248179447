import { useEffect } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import {
  CarInfoContext,
  FeaturesDrawerContextProvider,
} from '../../../contexts';
import { Result, Spin, notification } from 'antd';
import 'react-image-gallery/styles/css/image-gallery.css';
import { Helmet } from 'react-helmet';
import { StyledContent } from '../../../components/styled/StyledContent';
import { CostMileageAnalyticsSection } from '../Components/CostMileageAnalyticsSection/CostMileageAnalyticsSection';
import { SimilarAdsSection } from '../Components/SilimarAdsSection/SimilarAdsSection';
import { ClonesSection } from '../Components/ClonesSection/ClonesSection';
import { PopularFeatures } from '../Components/PopularFeatures';
import { CarFeaturesDescription } from '../Components/CarFeaturesDescription';
import { PriceHistorySection } from '../Components/PriceHistorySection/PriceHistorySection';
import { CarMainSection } from '../Components/CarMainSection/CarMainSection';
import { useMediaQuery } from 'react-responsive';
import { SCREEN_WIDTH } from '../../../screenSettings';
import {
  useGetCarClones,
  useGetCarsRecommendations,
  useGetCarModelMedianPricesByRegions,
} from '../../../hooks/car';
import { MobileCarFeatures } from '../../../components/MobileCarFeatures';
import { useProfileSelector } from '../../../store/selectors';
import { ICarUrlParams } from '../../../shared';
import { config } from '../../../config';
import { CarPageOutletContext } from '../CarPage.interface';
import { Price } from '../../../models/car';
import { CallToTurnKey } from '../CallToTurnKey';

export const CarInfo = () => {
  const {
    car,
    isErrorCar,
    isFetchingCar,
    contextHolderCar,
    currentRegion,
    similarAdsUrl,
  } = useOutletContext<CarPageOutletContext>();
  const params = useParams() as unknown as ICarUrlParams;
  const { currency } = useProfileSelector();
  const [notificationApi, contextHolder] = notification.useNotification();
  const isMobileWidth = useMediaQuery({
    query: `(max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px)`,
  });
  const isMobileBreakPointWidth = useMediaQuery({
    query: `(max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_2}px`,
  });

  const {
    carClones,
    contextHolder: contextHolderCarClones,
    getCarClones,
    isErrorCarClones,
    isFetchingCarClones,
  } = useGetCarClones();

  const {
    carModelMedianPricesByRegions,
    contextHolder: contextHolderMedianPrices,
    getCarModelMedianPricesByRegions,
    isErrorCarModelMedianPricesByRegions,
    isFetchingCarModelMedianPricesByRegions,
  } = useGetCarModelMedianPricesByRegions();

  const {
    carRecommendations,
    contextHolder: contextHolderCarRecommendations,
    getCarRecommendations,
    isErrorCarRecommendations,
    isFetchingCarRecommendations,
  } = useGetCarsRecommendations();

  const price: number | undefined =
    car &&
    car.prices.find((price: Price) => price.currency === car.baseCurrency)
      ?.amount;

  const groupedRecommendations =
    carRecommendations &&
    carRecommendations.recommendations.reduce((acc: any, recommendation) => {
      const sourceId = recommendation.sourceId;
      if (!acc[sourceId]) {
        acc[sourceId] = [];
      }
      acc[sourceId].push(recommendation);
      return acc;
    }, {});

  const resultRecommendations =
    carRecommendations && Object.values(groupedRecommendations);

  const init = async () => {
    const { adId, sourceId } = params;

    if (!adId || !sourceId) {
      notificationApi.error({
        message: 'Ошибка загрузки данных',
        description: 'adId или sourceId не найдены',
        duration: config.DEFAULT_NOTIFICATION_DURATION,
      });
      return;
    }

    const commonParams = {
      ...params,
      currency,
    };

    await Promise.all([
      getCarClones(commonParams),
      getCarRecommendations(commonParams),
    ]);

    if (process.env.REACT_APP_PRICES_DIAGRAM_FEATURE === 'true' && car) {
      await getCarModelMedianPricesByRegions({
        makeId: car.makeId,
        modelId: car.modelOriginalName,
        currency,
        yearOfIssue: car.yearOfIssue,
        engineCapacity: car.engineCapacity,
        sourceId: car.sourceId,
      });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    init();
  }, [params.adId, params.sourceId]);

  useEffect(() => {
    if (!car) return;

    if (process.env.REACT_APP_PRICES_DIAGRAM_FEATURE === 'true') {
      getCarModelMedianPricesByRegions({
        makeId: car.makeId,
        modelId: car.modelOriginalName,
        currency,
        yearOfIssue: car.yearOfIssue,
        engineCapacity: car.engineCapacity,
        sourceId: car.sourceId,
      });
    }
  }, [currency]);

  return (
    <CarInfoContext.Provider
      value={{
        carDetails: car,
        currentRegion,
        carModelMedianPricesByRegions,
        price,
        isFetchingCarRecommendations,
        isErrorCarRecommendations,
        carRecommendations,
        resultRecommendations,
        params,
        isErrorCarClones,
        carClones,
        similarAdsUrl,
      }}
    >
      {contextHolder}

      <StyledContent
        style={{ marginTop: 0, marginBottom: isMobileWidth ? 30 : 40 }}
      >
        {isErrorCar && (
          <Result status='404' subTitle='Извините, произошла ошибка' />
        )}
        <Spin size='large' spinning={isFetchingCar}>
          {contextHolderCar}
          <CarMainSection />
        </Spin>
      </StyledContent>

      <CallToTurnKey />

      {car && Array.isArray(car.features) && car.features.length > 0 && (
        <StyledContent
          style={{ marginTop: 0, marginBottom: isMobileWidth ? 30 : 40 }}
        >
          {isErrorCar && (
            <Result status='404' subTitle='Извините, произошла ошибка' />
          )}
          <Spin size='large' spinning={isFetchingCar}>
            <PopularFeatures />
          </Spin>
        </StyledContent>
      )}

      {isMobileBreakPointWidth && (
        <FeaturesDrawerContextProvider>
          {car && Array.isArray(car.features) && car.features.length > 0 && (
            <MobileCarFeatures features={car.features} />
          )}
        </FeaturesDrawerContextProvider>
      )}

      {!isMobileBreakPointWidth &&
        car &&
        Array.isArray(car.features) &&
        car.features.length > 0 && (
          <CarFeaturesDescription features={car.features} />
        )}

      <StyledContent style={{ marginBottom: isMobileWidth ? 30 : 40 }}>
        {isErrorCar && (
          <Result status='404' subTitle='Извините, произошла ошибка' />
        )}
        <Spin size='large' spinning={isFetchingCar}>
          <PriceHistorySection />
        </Spin>
      </StyledContent>
      <StyledContent style={{ marginBottom: isMobileWidth ? 30 : 40 }}>
        {contextHolderMedianPrices}
        {isErrorCarModelMedianPricesByRegions && (
          <Result status='404' subTitle='Извините, произошла ошибка' />
        )}
        <Spin size='large' spinning={isFetchingCarModelMedianPricesByRegions}>
          <CostMileageAnalyticsSection />
        </Spin>
      </StyledContent>
      <StyledContent style={{ marginBottom: isMobileWidth ? 30 : 40 }}>
        <Spin size='large' spinning={isFetchingCarClones}>
          <ClonesSection />
        </Spin>
        {contextHolderCarClones}
        {isErrorCarClones && (
          <Result status='404' subTitle='Извините, произошла ошибка' />
        )}
      </StyledContent>
      <StyledContent
        style={{
          marginBottom: isMobileWidth ? 30 : 40,
          paddingRight: isMobileWidth ? 0 : undefined,
        }}
      >
        {contextHolderCarRecommendations}
        {isErrorCarRecommendations && (
          <Result status='404' subTitle='Извините, произошла ошибка' />
        )}
        <Spin size='large' spinning={isFetchingCarRecommendations}>
          <SimilarAdsSection />
        </Spin>
      </StyledContent>
    </CarInfoContext.Provider>
  );
};
