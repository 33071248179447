import { CategoriesType } from '../models/turn-key/GetCarFeatures';
import { OptionType } from '../data/types';

type GrouperFeatures = {
  categoryId: number;
  options: OptionType[];
};

type GetGrouppedCategoriesType = (
  categories: CategoriesType[],
  ids: number[],
) => GrouperFeatures[];

export const getGrouppedCategories: GetGrouppedCategoriesType = (
  categories,
  ids,
) => {
  const res: GrouperFeatures[] = [];

  ids.forEach((id) => {
    const groupCategories = categories.find((elem) => elem.categoryId === id);
    if (
      groupCategories &&
      Array.isArray(groupCategories.features) &&
      !!groupCategories.features.length
    ) {
      const sortedFeatures = [...groupCategories.features];
      sortedFeatures.sort(
        (feature1, feature2) => feature1.featureOrder - feature2.featureOrder,
      );
      const tempArr: OptionType[] = sortedFeatures.map((elem) => ({
        label: elem.featureName,
        value: elem.featureId,
      }));
      res.push({ categoryId: id, options: tempArr });
    }
  });

  return res;
};
