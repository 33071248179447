import {
  Affix,
  ConfigProvider,
  Form,
  FormInstance,
  Input,
  RadioChangeEvent,
} from 'antd';
import React, { memo, useEffect, useState, CSSProperties } from 'react';
import { FormItemDrawer } from '../../pages/CarsPage/components/shared/FormItemDrawer';
import { ButtonFieldMobileForm } from './ButtonFieldMobileForm';
import { RadioGroup } from './RadioGroup';
import { RadioGroupItem } from './RadioGroupItem';
import { SelectOptions } from '../../enums';
import { FormItemLabel } from '../FormItemLabel';
import { StyledButton } from '../styled/StyledButton';
import { mobileFormItemTheme } from '../../themeSettings';

interface IPairFilterCell {
  form: FormInstance<any>;
  label: string;
  nameFrom: string;
  nameTo: string;
  placeholder: string;
  valueFrom: any;
  valueTo: any;
  options: { value: number | string; label: string }[];
  callback?: (value: any) => void;
  required?: boolean;
  style?: CSSProperties;
  disabled?: boolean;
}

export const PairFilterCell: React.FC<IPairFilterCell> = memo(
  ({
    label,
    nameFrom,
    nameTo,
    placeholder,
    valueFrom,
    valueTo,
    options,
    callback,
    required = false,
    form,
    style,
    disabled,
  }) => {
    const [open, setOpen] = useState<boolean>(false);
    const [valueFromSelected, setValueFromSelected] = useState(valueFrom);
    const [valueToSelected, setValueToSelected] = useState(valueTo);
    const [displayValue, setDisplayValue] = useState<string | undefined>(
      undefined,
    );

    const handleDrawer = (): void => {
      setOpen(!open);
    };

    const onChangeFrom = (e: RadioChangeEvent) => {
      setValueFromSelected(e.target.value);
    };

    const onChangeTo = (e: RadioChangeEvent) => {
      setValueToSelected(e.target.value);
    };

    const fromDisabledOptions = valueToSelected
      ? options.filter(
          (option) => Number(option.value) > Number(valueToSelected),
        )
      : [];

    const toDisabledOptions = valueFromSelected
      ? options.filter(
          (option) => Number(option.value) < Number(valueFromSelected),
        )
      : [];

    const getDisplayValue = (valueFrom: string, valueTo: string): string => {
      let from = valueFrom;
      let to = valueTo;

      if (!from || from === SelectOptions.any) {
        from = 'Любой';
      }

      if (!to || to === SelectOptions.any) {
        to = 'Любой';
      }

      return from !== 'Любой' || to !== 'Любой'
        ? `${from} - ${to}`
        : placeholder;
    };

    const handleSubmit = () => {
      setOpen(!open);

      form.setFieldsValue({
        [nameFrom]: valueFromSelected,
        [nameTo]: valueToSelected,
      });

      if (callback) {
        callback({
          [nameFrom]: valueFromSelected,
          [nameTo]: valueToSelected,
        });
      }

      setDisplayValue(getDisplayValue(valueFromSelected, valueToSelected));
    };

    useEffect(() => {
      if (!open) {
        setDisplayValue(getDisplayValue(valueFrom, valueTo));
      } else {
        setValueFromSelected(valueFrom);
        setValueToSelected(valueTo);
      }
    }, [open, valueFrom, valueTo]);

    return (
      <>
        <Form.Item
          style={style}
          name={nameFrom}
          label={<FormItemLabel>{label}</FormItemLabel>}
        >
          <ConfigProvider theme={mobileFormItemTheme}>
            <ButtonFieldMobileForm disabled={disabled} event={handleDrawer}>
              {displayValue}
            </ButtonFieldMobileForm>
          </ConfigProvider>
        </Form.Item>
        <Form.Item name={nameTo} style={{ display: 'none' }}>
          <Input type='hidden' />
        </Form.Item>
        <FormItemDrawer title={label} onClose={handleDrawer} open={open}>
          <div style={{ display: 'flex', gap: 15 }}>
            <div style={{ overflowY: 'scroll', height: '75vh', width: '100%' }}>
              <RadioGroup value={valueFromSelected} onChange={onChangeFrom}>
                {options.map(
                  (option: { value: number | string; label: string }) => (
                    <RadioGroupItem
                      key={option.value}
                      value={option.value}
                      disabled={fromDisabledOptions.some(
                        (item) => item.value === option.value,
                      )}
                    >
                      {option.label}
                    </RadioGroupItem>
                  ),
                )}
              </RadioGroup>
            </div>
            <div style={{ overflowY: 'scroll', height: '75vh', width: '100%' }}>
              <RadioGroup value={valueToSelected} onChange={onChangeTo}>
                {options.map(
                  (option: { value: number | string; label: string }) => (
                    <RadioGroupItem
                      key={option.value}
                      value={option.value}
                      disabled={toDisabledOptions.some(
                        (item) => item.value === option.value,
                      )}
                    >
                      {option.label}
                    </RadioGroupItem>
                  ),
                )}
              </RadioGroup>
            </div>
          </div>
          <Affix style={{ margin: '0 8px' }} offsetBottom={30}>
            <StyledButton
              type='primary'
              onClick={handleSubmit}
              size='large'
              block
            >
              Применить
            </StyledButton>
          </Affix>
        </FormItemDrawer>
      </>
    );
  },
);
