import {
  DataForRangePriceDistributionType,
  DataForRangeMileageDistributionType,
  DistributionsDescriptions,
  DistributionsDescriptionsThreeParts,
} from './CostMileageAnalyticsSection.types';
import { initialSliderRangePositionProps } from './CostMileageAnalyticsSection.data';
import { IPriceDistribution } from '../../../../models/response/IModelPriceDistributionResponse';
import { IMileageDistribution } from '../../../../models/response/IMileageDistributionResponse';

const getSectorFromAdditionalChecking = (sortedRanges: IPriceDistribution[], price: number) => {
  let sector: number = DistributionsDescriptionsThreeParts.MEDIUM_SECTOR;
  for (let i = 0; i < sortedRanges.length; i++) {
    if (price < sortedRanges[0].priceRange.fromAmount || price < sortedRanges[0].priceRange.toAmount) {
      sector = DistributionsDescriptionsThreeParts.LOW_SECTOR;
    } else if (
      price > sortedRanges[sortedRanges.length - 1].priceRange.fromAmount ||
      price > sortedRanges[sortedRanges.length - 1].priceRange.toAmount
    ) {
      sector = sortedRanges.length;
    } else if (
      sortedRanges[i + 1] &&
      price > sortedRanges[i].priceRange.toAmount &&
      price < sortedRanges[i + 1].priceRange.fromAmount
    ) {
      sector = i + 1;
    }
  }
  return { sector, dotPositionFixed: 0 };
};

const getSectorFromAdditionalCheckingMileage = (sortedRanges: IMileageDistribution[], currentMileage: number) => {
  let sector: number = DistributionsDescriptionsThreeParts.MEDIUM_SECTOR;
  for (let i = 0; i < sortedRanges.length; i++) {
    if (currentMileage < sortedRanges[0].mileageFrom || currentMileage < sortedRanges[0].mileageTo) {
      sector = DistributionsDescriptionsThreeParts.LOW_SECTOR;
    } else if (
      currentMileage > sortedRanges[sortedRanges.length - 1].mileageFrom ||
      currentMileage > sortedRanges[sortedRanges.length - 1].mileageTo
    ) {
      sector = sortedRanges.length;
    } else if (
      sortedRanges[i + 1] &&
      currentMileage > sortedRanges[i].mileageTo &&
      currentMileage < sortedRanges[i + 1].mileageFrom
    ) {
      sector = i + 1;
    }
  }
  return { sector, dotPositionFixed: 0 };
};

export const getSliderPriceDistributionData: DataForRangePriceDistributionType = (
  pricesDetails,
  price,
  currency,
  isCalculated
) => {
  if (isCalculated && pricesDetails && pricesDetails[0] && currency && price) {
    const sortedAscendFilteredByCurrencyPrices = pricesDetails
      .filter((priceItem) => priceItem.priceRange.currency === currency)
      .sort((first, second) => first.priceRange.fromAmount - second.priceRange.fromAmount);

    let sector: number | null = null;
    let dotPosition: number | null = null;
    const foundedRange = sortedAscendFilteredByCurrencyPrices.find((elem, index) => {
      if (elem.priceRange.fromAmount <= price && elem.priceRange.toAmount >= price) {
        sector = index + 1;
        return true;
      }
      return undefined;
    });

    if (sector && foundedRange) {
      dotPosition =
        Math.round(
          ((price - foundedRange.priceRange.fromAmount) /
            (foundedRange.priceRange.toAmount - foundedRange.priceRange.fromAmount)) *
            1000
        ) / 10;
    } else {
      const additionalChecking = getSectorFromAdditionalChecking(sortedAscendFilteredByCurrencyPrices, price);
      sector = additionalChecking.sector;
      dotPosition = additionalChecking.dotPositionFixed;
    }

    return {
      dotPosition,
      partsQuantity: sortedAscendFilteredByCurrencyPrices.length,
      sector,
    };
  }
  return initialSliderRangePositionProps;
};

export const getSliderMileageDistributionData: DataForRangeMileageDistributionType = (
  isCalculated,
  currentMileage,
  mileageRanges
) => {
  if (isCalculated && currentMileage && Array.isArray(mileageRanges) && mileageRanges[0]) {
    const sortedAscendMileage = [...mileageRanges].sort((a, b) => Number(a.mileageFrom) - Number(b.mileageFrom));

    let sector: number | null = null;
    let dotPosition: number | null = null;
    const foundedRange = sortedAscendMileage.find((elem, index) => {
      if (elem.mileageFrom <= currentMileage && elem.mileageTo >= currentMileage) {
        sector = index + 1;
        return true;
      }
      return undefined;
    });

    if (sector && foundedRange) {
      dotPosition =
        Math.round(
          ((currentMileage - foundedRange.mileageFrom) / (foundedRange.mileageTo - foundedRange.mileageFrom)) * 1000
        ) / 10;
    } else {
      const additionalChecking = getSectorFromAdditionalCheckingMileage(sortedAscendMileage, currentMileage);
      sector = additionalChecking.sector;
      dotPosition = additionalChecking.dotPositionFixed;
    }

    return {
      dotPosition,
      partsQuantity: mileageRanges.length,
      sector,
    };
  }
  return initialSliderRangePositionProps;
};

export const getTotalDotPosition = (parts: number, sector: number, relativePosition: number): number => {
  if (parts === 3) {
    switch (sector) {
      case DistributionsDescriptionsThreeParts.LOW_SECTOR: {
        return Math.round(relativePosition / 3);
      }
      case DistributionsDescriptionsThreeParts.MEDIUM_SECTOR: {
        return Math.round(100 / 3 + relativePosition / 3);
      }
      default: {
        return Math.round(200 / 3 + relativePosition / 3);
      }
    }
  }
  switch (sector) {
    case DistributionsDescriptions.LOW_SECTOR: {
      return Math.round(relativePosition / 4);
    }
    case DistributionsDescriptions.LESS_MEDIUM_SECTOR: {
      return Math.round(100 / 4 + relativePosition / 4);
    }
    case DistributionsDescriptions.MORE_MEDIUM_SECTOR: {
      return Math.round(200 / 4 + relativePosition / 4);
    }
    default: {
      return Math.round(300 / 4 + relativePosition / 4);
    }
  }
};
