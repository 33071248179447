import styled from 'styled-components';
import { SCREEN_WIDTH } from '../../../screenSettings';

export const StyledDetailsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 8px;
  @media screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
  }
`;
