export enum SendRequestFormFiled {
  Channel = 'channel',
  Name = 'name',
  Phone = 'phone',
  PersonalDataProcessingConsent = 'personalDataProcessingConsent',
  Message = 'message',
}

export enum SendRequestFormPlaceholder {
  Name = 'Как к вам обращаться?',
  Phone = 'Введите ваш номер телефона',
  Message = 'Введите ваше сообщение',
}

export enum SendRequestFormLabel {
  Channel = 'Мессенджер для связи',
  Name = 'Ваше имя',
  Phone = 'Номер телефона',
  PersonalDataProcessingConsent = 'Согласен на обработку персональных данных',
  Message = 'Сообщение',
}
