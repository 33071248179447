import { Radio } from "antd";
import { FC, ReactNode } from "react";
import styled from "styled-components";

const StyledRadioGroupItem = styled(Radio.Button)`
  display: flex;
  align-items: center;
  border: none;
  height: 40px;
  margin-bottom: 2px;
  border-inline-start: 0 !important;

  &:hover {
    color: #192A4D;
  }
`;

export const RadioGroupItem: FC<{
  children: ReactNode;
  value: any;
  disabled?: boolean;
}> = ({ children, value, disabled = false }) => {
  return (
    <StyledRadioGroupItem disabled={disabled} value={value}>
      {children}
    </StyledRadioGroupItem>
  );
};
