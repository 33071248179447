import React from 'react';
import { StepsProps, theme, Typography } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { ICarDetails } from '../../../../../models/car';
import { formatNumber } from '../../../../../utils/formatNumber';
import { currencySymbols } from '../../../../../data';
import { BaseType } from 'antd/es/typography/Base';
import {
  StyledActiveStep,
  StyledPriceHistoryContainer,
  StyledPriceHistorySubtitleContainer,
  StyledStep,
  StyledStepRowContainer,
  StyledStepsContainer,
  StyledTimeStampWithIcon,
} from './StyledPriceHistory';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useMediaQuery } from 'react-responsive';
import { SCREEN_WIDTH } from '../../../../../screenSettings';

interface IPriceHistoryProps {
  car: ICarDetails;
}

const { useToken } = theme;
const { Text, Paragraph } = Typography;

export const PriceHistory: React.FC<IPriceHistoryProps> = ({ car }) => {
  const isMobileWidth = useMediaQuery({
    query: `(max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px)`,
  });
  const { token } = useToken();

  const carPriceHistory = car.priceHistory ?? [];

  if (carPriceHistory.length === 1) {
    return (
      <Paragraph
        style={{
          fontSize: isMobileWidth ? 12 : 16,
          marginBottom: 0,
          display: 'inline-flex',
        }}
        type='secondary'
      >
        <ExclamationCircleOutlined
          rev='true'
          style={{
            fontSize: isMobileWidth ? 14 : 16,
            color: '#97A3B5',
            marginRight: 8,
          }}
        />
        <span>Цена не изменялась</span>
      </Paragraph>
    );
  }

  let totalChange: number = 0;

  const customDot: StepsProps['progressDot'] = (dot, { status, index }) =>
    index === 0 ? <StyledStep /> : <StyledActiveStep />;

  const historyItems = carPriceHistory
    .map((history, index) => {
      const currentPrice: number = history.price;
      const previousPrice: number | null =
        index > 0 ? carPriceHistory[index - 1].price : null;
      const difference: number =
        previousPrice !== null ? currentPrice - previousPrice : 0;

      totalChange += difference;

      const isPositiveChange: BaseType | undefined =
        difference === 0 ? undefined : difference < 0 ? 'success' : 'danger';

      return {
        title: (
          <StyledStepRowContainer
            bordercolor={token.colorBgContainerDisabled}
            isLastElement={carPriceHistory.length - 1 === index}
          >
            <Text
              style={{
                fontSize: isMobileWidth ? 12 : 14,
                lineHeight: isMobileWidth ? '14px' : '32px',
              }}
            >
              {dayjs(history.timeStamp).format('DD/MM/YYYY')}
            </Text>
            <Text
              style={{
                fontSize: isMobileWidth ? 12 : 16,
                fontWeight: 600,
                lineHeight: isMobileWidth ? '14px' : '32px',
              }}
              type={isPositiveChange}
            >
              {formatNumber(difference)} {currencySymbols[car.baseCurrency]}
            </Text>
            <Text
              style={{
                fontSize: isMobileWidth ? 12 : 14,
                fontWeight: 600,
                lineHeight: isMobileWidth ? '14px' : '32px',
              }}
            >
              {formatNumber(history.price)} {currencySymbols[car.baseCurrency]}
            </Text>
          </StyledStepRowContainer>
        ),
      };
    })
    .reverse();

  return (
    <StyledPriceHistoryContainer>
      <StyledPriceHistorySubtitleContainer>
        <Paragraph
          type='secondary'
          style={{ marginBottom: 0, fontSize: isMobileWidth ? 12 : 16 }}
        >
          Общее изменение цены:
          <Text
            className='price-history__general'
            style={{
              marginLeft: isMobileWidth ? 0 : 8,
              fontSize: isMobileWidth ? 12 : 16,
              fontWeight: 600,
            }}
            type={
              totalChange === 0
                ? undefined
                : totalChange < 0
                  ? 'success'
                  : 'danger'
            }
          >
            {totalChange === 0
              ? '   Не изменилась'
              : `${totalChange < 0 ? '   Уменьшилась' : '   Увеличилась'} на ${Math.abs(Math.round(totalChange))} ${
                  currencySymbols[car.baseCurrency]
                }`}
          </Text>
        </Paragraph>
        <StyledTimeStampWithIcon>
          <ClockCircleOutlined style={{ fontSize: '16px', color: '#97a3b5' }} />
          <Paragraph
            style={{
              fontSize: 14,
              fontWeight: 400,
              marginBottom: 0,
              fontFamily: 'Open Sans',
              lineHeight: '20px',
            }}
            type='secondary'
          >
            {`${dayjs(car.createdAt).fromNow(true)} на рынке`}
          </Paragraph>
        </StyledTimeStampWithIcon>
      </StyledPriceHistorySubtitleContainer>

      <StyledStepsContainer
        progressDot={customDot}
        current={5}
        direction='vertical'
        items={historyItems}
      />
    </StyledPriceHistoryContainer>
  );
};
