import { Company, GetCompaniesResponse } from '../../models/response/companies';
import { api } from '../api/api';
import { ApiEndpoints } from '../api/endpoints';
import { companiesActions } from './companies.slice';
import { ERROR_MESSAGES } from '../../data/errorMessages';

export const companiesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCompanies: build.query<Company[], void>({
      query: () => ({
        url: ApiEndpoints.Companies,
      }),
      transformResponse: (response: GetCompaniesResponse) => response.companies,
      transformErrorResponse: (response) => ({
        ...response,
        forCustomNotification: {
          status: ERROR_MESSAGES.GET_COMPANIES.status,
          message: ERROR_MESSAGES.GET_COMPANIES.message,
        },
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const response = await queryFulfilled;
          dispatch(companiesActions.setCompanies(response.data));
        } catch (err) {
          console.error(err);
        }
      },
    }),
  }),
});

export const { useGetCompaniesQuery } = companiesApi;
