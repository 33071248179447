import styled from 'styled-components';
import { UserOutlined } from '@ant-design/icons';

export const StyledAvatarBlock = styled.div`
  display: flex;
  align-items: start;
  justify-content: flex-start;
  gap: var(--gap-user-avatar-desktop);
  margin-bottom: 24px;
`;

export const StyledNameBlock = styled.div`
  p {
    margin-bottom: 0;
    font-style: var(--font-style-base);
    line-height: var(--desktop-content-line-height);
  }
`;

export const StyledUserName = styled.p`
  color: var(--Dark-13);
  font-size: var(--desktop-content-font-size);
  font-weight: var(--bold-font-weight);
`;

export const StyledUserEmail = styled.p`
  color: var(--Gray-7);
  font-size: var(--main-font-size);
  font-weight: var(--main-font-weight);
`;

export const StyledUserOutlined = styled(UserOutlined)`
  color: var(--Dark-13);
  background-color: transparent;
  font-size: 25px;
  align-self: center;
  padding: 8px;
`;
