import styled from 'styled-components';
import { SCREEN_WIDTH } from '../../../screenSettings';

export const StyledButtonWrapper = styled.div`
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
    gap: 16px;
    flex-direction: column;
  }
`;
