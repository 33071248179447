import { CSSProperties } from 'react';
import styled from 'styled-components';
import { Popover } from 'antd';

export const overlayPopoverStyles: CSSProperties = {
  boxShadow: `0px 0.4px 0.8px 0px rgba(80, 89, 102, 0.1),
	0px 3px 6px 0px rgba(80, 89, 102, 0.2)`,
  padding: 0,
  borderRadius: 8,
};

export const overlayPopoverInnerStyles: CSSProperties = {
  padding: 16,
};

export const StyledUserOptionsPopover = styled(Popover)`
  &.ant-popover-content {
    border-radius: 8px;
    background: var(--White);
    box-shadow: var(--popover-header-user-info-shadow);
  }
`;
