import { useCallback } from 'react';
import { useDeleteFilterSubscriptionMutation } from '../../store/subscription/subscription.api';
import { notification } from 'antd';
import { config } from '../../config';
import { useActions } from '../useActions';

export const useDeleteCarFromFilters = () => {
  const { deleteCarFromFilters } = useActions();

  const [
    deleteFromFilters,
    { isError: failedDeleteFromFilters, isLoading: isDeletingFromFilters },
  ] = useDeleteFilterSubscriptionMutation();

  const [notificationApi, contextHolder] = notification.useNotification();

  const deleteSubscriptionFromFilters = useCallback(
    async (hash: string) => {
      try {
        await deleteFromFilters(hash).unwrap();
        deleteCarFromFilters(hash);

        notificationApi.success({
          message: 'Успешно!',
          description: 'Авто удалено из подписок.',
          duration: config.SUBSCRIPTION_DELETE_DURATION,
        });
      } catch (err: unknown) {
        if (process.env.NODE_ENV === 'development') {
          console.log(err);
        }

        notificationApi.error({
          message: 'Ошибка удаления авто',
          description: 'Не удалось удалить подписку на авто',
          duration: config.SUBSCRIPTION_DELETE_DURATION,
        });
      }
    },
    [deleteCarFromFilters, deleteFromFilters, notificationApi],
  );

  return {
    failedDeleteFromFilters,
    isDeletingFromFilters,
    contextHolder,
    deleteSubscriptionFromFilters,
  };
};
