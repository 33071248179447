import { StyledStepsWrapper, StyledAntdSteps } from './TurnKeySteps.styled';
import { ConfigProvider } from 'antd';
import { stepsItems } from './TurnKeySteps.data';
import {
  useTurnKeyStepsErrorStatus,
  useTurnKeyStepsSelector,
} from '../../../../store/selectors';
import { useMediaQuery } from 'react-responsive';
import { SCREEN_WIDTH } from '../../../../screenSettings';

const STEP_PERCENT_DEFAULT = 60;

export const TurnKeySteps = () => {
  const isBeforeTabletWidth = useMediaQuery({
    query: `(max-width: ${SCREEN_WIDTH.DESKTOP}px)`,
  });

  const { activeStep } = useTurnKeyStepsSelector();
  const errorStatusSteps = useTurnKeyStepsErrorStatus();
  return (
    <ConfigProvider
      theme={{
        components: {
          Steps: {
            colorPrimary: '#5A9DFF',
          },
        },
      }}
    >
      <StyledStepsWrapper>
        <StyledAntdSteps
          responsive={false}
          status={errorStatusSteps}
          size={isBeforeTabletWidth ? 'small' : undefined}
          current={activeStep}
          percent={STEP_PERCENT_DEFAULT}
          items={stepsItems}
        />
      </StyledStepsWrapper>
    </ConfigProvider>
  );
};
