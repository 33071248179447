import styled from 'styled-components';
import { SCREEN_WIDTH } from '../../../screenSettings';

export const StyledPageLayout = styled.div`
  display: flex;
  gap: 24px;
  width: 100%;
  align-items: flex-start;
`;

export const CalculatedCarHeader = styled.span`
  @media screen and (max-width: ${SCREEN_WIDTH.TABLET}px) {
    display: none;
  }
`;
