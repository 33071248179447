import { APP_PATHS } from '../layout/settingsLayout';
import { ICarUrlParams } from '../shared';

export const getCarUrl = ({
  makeId,
  modelId,
  adId,
  sourceId,
}: ICarUrlParams): string =>
  `${APP_PATHS.CARS_PAGE}/${makeId?.toLowerCase()}/${modelId?.toLowerCase()}/${adId}/${sourceId}`;
