export const MileageSvg = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
  >
    <g clipPath='url(#clip0_4756_1401)'>
      <path
        d='M7.66683 3.3335H7.95643C9.98792 3.3335 11.0037 3.3335 11.3893 3.69836C11.7226 4.01374 11.8703 4.47835 11.7803 4.9283C11.6762 5.44884 10.8469 6.0354 9.18835 7.20852L6.47864 9.12514C4.8201 10.2983 3.99082 10.8848 3.88672 11.4054C3.79673 11.8553 3.94444 12.3199 4.27774 12.6353C4.66332 13.0002 5.67907 13.0002 7.71056 13.0002H8.3335M5.3335 3.3335C5.3335 4.43807 4.43807 5.3335 3.3335 5.3335C2.22893 5.3335 1.3335 4.43807 1.3335 3.3335C1.3335 2.22893 2.22893 1.3335 3.3335 1.3335C4.43807 1.3335 5.3335 2.22893 5.3335 3.3335ZM14.6668 12.6668C14.6668 13.7714 13.7714 14.6668 12.6668 14.6668C11.5623 14.6668 10.6668 13.7714 10.6668 12.6668C10.6668 11.5623 11.5623 10.6668 12.6668 10.6668C13.7714 10.6668 14.6668 11.5623 14.6668 12.6668Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_4756_1401'>
        <rect width='16' height='16' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
