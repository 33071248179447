import styled, { CSSProperties } from 'styled-components';
import { SCREEN_WIDTH } from './screenSettings';
import { APP_PATHS } from './layout/settingsLayout';

const baseWrapperStyles = {
  maxWidth: `${SCREEN_WIDTH.MAX_WIDTH_APP}px`,
  width: '100%',
  margin: '0 auto',
};

const getWrapperDynamicStyles = (props: any) => {
  if (props.pageLocation !== APP_PATHS.ROOT) return baseWrapperStyles;

  return {
    ...baseWrapperStyles,
    maxWidth: '100%',
    width: '100%',
    margin: 0,
  };
};

export const StyledWrapper = styled.div<{
  pageLocation?: string;
}>`
  ${(props) => getWrapperDynamicStyles(props)};

  @media screen and (max-width: ${SCREEN_WIDTH.TABLET}px) {
    ${(props) =>
      props.pageLocation?.includes(APP_PATHS.CAR_PAGE.replace('/', ''))
        ? 'padding: 0 16px;'
        : ''}
  }

  @media screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
    ${(props) =>
      props.pageLocation?.includes(APP_PATHS.CAR_PAGE.replace('/', ''))
        ? 'padding: 0;'
        : ''}
  }
`;

export const headerStyle: CSSProperties = {
  height: 80,
  backgroundColor: '#FFFFFF',
  display: 'flex',
  alignItems: 'center',
};

export const footerStyle: CSSProperties = {
  backgroundColor: '#0068CA',
  height: 320,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const footerMobileStyle: CSSProperties = {
  padding: '24px 16px',
};
