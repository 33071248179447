import { carsApi } from '../../store/cars/cars.api';
import { config } from '../../config';
import { NotificationInstance } from 'antd/es/notification/interface';
import { Params } from 'react-router-dom';
import { checkArrayValidity, checkValue, getDateFrom } from '../../utils/cars';
import { ISortingFormState } from '../../models/cars';
import { initialValuesSortForm } from '../../constants';
import { useCallback } from 'react';
import { SortFormFieldNames } from '../../enums/form';
import { useProfileSelector } from '../../store/selectors';

export const useGetCars = (
  notificationApi: NotificationInstance,
  queryParameters: any,
  params: Readonly<Params<string>>,
  setIsInvalidate?: (state: boolean) => void,
) => {
  const { currency } = useProfileSelector();
  const [
    fetchCars,
    { isError: isErrorCars, data: cars, isFetching: isFetchingCars },
  ] = carsApi.useLazyGetCarsQuery();

  const getCars = useCallback(
    (
      sortFormValues: ISortingFormState = {
        [SortFormFieldNames.from]: queryParameters.from,
        [SortFormFieldNames.sortBy]: queryParameters.sortBy,
      },
    ) => {
      if (setIsInvalidate) setIsInvalidate(false);
      const from =
        sortFormValues.from || initialValuesSortForm[SortFormFieldNames.from];

      fetchCars({
        currency,
        ...queryParameters,
        regionId: checkArrayValidity(queryParameters.regionId),
        engineType: checkArrayValidity(queryParameters.engineType),
        bodyType: checkArrayValidity(queryParameters.bodyType),
        typeOfDrive: checkArrayValidity(queryParameters.typeOfDrive),
        country: checkArrayValidity(queryParameters.country),
        engineCapacityFrom: checkValue(queryParameters.engineCapacityFrom),
        engineCapacityTo: checkValue(queryParameters.engineCapacityTo),
        toMileage: checkValue(queryParameters.toMileage),
        make: checkValue(params.make),
        model: checkValue(params.model),
        transmission: checkValue(queryParameters.transmission),
        yearFrom: checkValue(queryParameters.yearFrom),
        yearTo: checkValue(queryParameters.yearTo),
        vatDeductible: queryParameters.vatDeductible || undefined,
        from: getDateFrom(+from),
        sortBy: sortFormValues.sortBy,
        take: config.CARS_LIST_PAGE.ITEMS_PER_PAGE,
        skip:
          ((Number(queryParameters.page) || 1) - 1) *
          config.CARS_LIST_PAGE.ITEMS_PER_PAGE,
        page: undefined,
      })
        .unwrap()
        .catch((error: any) => {
          notificationApi.error({
            message:
              error?.data?.message ||
              error.message ||
              'Ошибка загрузки списка авто',
            duration: config.DEFAULT_NOTIFICATION_DURATION,
          });
        });
    },
    [queryParameters, params, currency],
  );

  return {
    getCars,
    cars,
    isFetchingCars,
    isErrorCars,
  };
};
