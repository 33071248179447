import { IPriceDistribution } from '../../../../models/response/IModelPriceDistributionResponse';
import { IMileageDistribution } from '../../../../models/response/IMileageDistributionResponse';

export type DataForRangePriceDistributionType = (
  priceDetails: IPriceDistribution[] | undefined,
  price: number | null,
  currency: string,
  isCalculated: boolean
) => DataForRangeDistributionType;

export type DataForRangeMileageDistributionType = (
  isCalculated: boolean,
  currentMileage: number | undefined,
  mileageRanges: IMileageDistribution[]
) => DataForRangeDistributionType;

export type DataForRangeDistributionType = {
  dotPosition: number;
  partsQuantity: number;
  sector: number;
};

export enum DistributionsDescriptions {
  LOW_SECTOR = 1,
  LESS_MEDIUM_SECTOR = 2,
  MORE_MEDIUM_SECTOR = 3,
  BIG_SECTOR = 4,
}
export enum DistributionsDescriptionsThreeParts {
  LOW_SECTOR = 1,
  MEDIUM_SECTOR = 2,
  BIG_SECTOR = 3,
}
