import { FC, useState, useMemo, useEffect } from 'react';
import {
  StyledCarsList,
  StyledPagination,
} from './CarSubscriptionsList.styles';
import { ICarData } from '../../../../models';
import { CARS_SUBSCRIPTION_PER_PAGE } from '../../../../data/paginationConstants';
import { CarListItem } from '../../../../components/CarListItem';
import { SubscriptionsListModal } from '../SubscriptionsListModal';
import { getPriceByCurrency } from '../../../../utils/getPriceByCurrency';
import { useMediaQuery } from 'react-responsive';
import { SCREEN_WIDTH } from '../../../../screenSettings';
import { useSubscriptionsOfferSelector } from '../../../../store/selectors';

export const CarSubscriptionsList: FC = () => {
  const { offerSubscriptions } = useSubscriptionsOfferSelector();

  const [currentPage, setCurrentPage] = useState(1);

  const isTabletToMobileBreak3 = useMediaQuery({
    query: `(max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px)`,
  });

  const carsData: ICarData[] = useMemo(
    () =>
      offerSubscriptions
        .map((car) => ({
          sourceId: car.sourceId,
          adId: car.adId,
          title: `${car.makeViewName} ${car.modelViewName}`,
          imageUrl: car.imageUrl,
          price: getPriceByCurrency(car.prices, car.baseCurrency),
          currency: car.baseCurrency,
          tag: car.priceEstimate?.tag,
          prices: car.prices,
          makeId: car.makeId,
          modelId: car.modelOriginalName,
          yearOfIssue: car.yearOfIssue,
          engineCapacity: car.engineCapacity,
          car: car,
          tags: car.tags,
        }))
        .filter(
          (_, index) =>
            index < currentPage * CARS_SUBSCRIPTION_PER_PAGE &&
            index >= (currentPage - 1) * CARS_SUBSCRIPTION_PER_PAGE,
        ),
    [currentPage, offerSubscriptions],
  );

  useEffect(() => {
    if (carsData.length === 0 && currentPage !== 1) {
      setCurrentPage((prev) => prev - 1);
    }
  }, [carsData.length, currentPage]);

  return (
    <>
      <StyledCarsList
        style={{
          marginBottom:
            offerSubscriptions.length <= CARS_SUBSCRIPTION_PER_PAGE ? 40 : 0,
        }}
      >
        {carsData.map((car) => (
          <CarListItem
            item={car}
            key={car.adId + car.sourceId}
            hasDeleteSubscriptionBlock={true}
          />
        ))}
      </StyledCarsList>
      <StyledPagination
        showLessItems={isTabletToMobileBreak3}
        current={currentPage}
        hideOnSinglePage={true}
        pageSize={CARS_SUBSCRIPTION_PER_PAGE}
        onChange={(page) => setCurrentPage(page)}
        total={offerSubscriptions.length}
      />

      <SubscriptionsListModal />
    </>
  );
};
