export const AUTH_SUCCESS_MESSAGES = {
  EMAIL_CONFIRMATION: 'Ваш email успешно подтвержден',
  NEW_PASSWORD_CREATED: 'Новый пароль установлен успешно',
};

export const AUTH_INFO_MESSAGES = {};

export const AUTH_WARNING_MESSAGES = {
  PASSWORD_RECOVERY_LINK_SENT:
    'Cсылка для восстановления пароля была отправлена на почту',
  EMAIL_CONFIRMATION_RESEND_LINK_SENT:
    'Cсылка для повторного подтверждения email была отправлена на почту',
  EMAIL_CONFIRMATION_LINK_SENT:
    'Cсылка для подтверждения email была отправлена на почту',
};

export const AUTH_ERROR_MESSAGES = {
  EMAIL_CONFIRMATION: 'Ошибка подтверждения email',
  SIGN_UP: 'Ошибка регистрации',
  SIGN_IN: 'Ошибка входа в аккаунт',
  PASSWORD_RESET: 'Произошла ошибка при сбросе пароля',
  PASSWORD_RECOVERY: 'Произошла ошибка при восстановлении пароля',
  SUPPORT: 'Что-то пошло не так, обратитесь в поддержку',
};
