import { FC } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { NAVIGATE_PATHS } from '../../../layout/settingsLayout';
import { StyledAuthSegmented } from './AuthPage.styled';
import { formRoutes } from './AuthPage.data';
import { AuthLayout } from '../components/AuthLayout/AuthLayout';

export const AuthPage: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath: string[] = location.pathname.split('/');
  const authRoute: string = currentPath[currentPath.length - 1];

  const handleFormChange = (route: unknown): void => {
    navigate(`${NAVIGATE_PATHS.DEFAULT_AUTH}/${route}`);
  };

  return (
    <AuthLayout>
      <StyledAuthSegmented
        block
        size='large'
        value={authRoute}
        options={formRoutes}
        onChange={handleFormChange}
      />
      <Outlet />
    </AuthLayout>
  );
};
