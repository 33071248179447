import { regionIdsMappingDeclinedFrom } from '../../../data/mappings';
import { CountryViewNameFrom } from '../../../enums';

interface GenerateMetadataParams {
  makeViewName?: string;
  modelViewName?: string;
}

interface GenerateHeaderListTitleParams extends GenerateMetadataParams {
  countryViewName?: string;
}

export const generateHeaderListTitle = (
  params: GenerateHeaderListTitleParams,
): string => {
  const { makeViewName, modelViewName, countryViewName } = params;

  if (makeViewName && modelViewName) {
    return `Купить ${makeViewName} ${modelViewName} ${countryViewName}`;
  }

  if (makeViewName) {
    return `Купить ${makeViewName} ${countryViewName}`;
  }

  return `Купить авто ${countryViewName}`;
};

export const getCountryViewName = (country?: string | string[]): string => {
  if (!country) return CountryViewNameFrom.Europe;

  if (Array.isArray(country) || !regionIdsMappingDeclinedFrom[country]) {
    return CountryViewNameFrom.Europe;
  }

  return regionIdsMappingDeclinedFrom[country];
};

export const generateMetadata = (params: GenerateMetadataParams): string => {
  const { makeViewName, modelViewName } = params;

  if (makeViewName && modelViewName) return `${makeViewName} ${modelViewName}`;
  if (makeViewName) return `${makeViewName}`;
  return `авто`;
};
