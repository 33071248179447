import { Rule } from 'antd/es/form';
import { FormFieldName } from '../enums/form';

const VALIDATION_MESSAGES = {
  USERNAME_REQUIRED: 'Пожалуйста введите имя пользователя',
  USERNAME_PATTERN:
    'Пожалуйста, используйте латинские буквы (a-z), до 20 символов',
  EMAIL_INVALID_FORMAT: 'Email неверного формата',
  EMAIL_REQUIRED: 'Пожалуйста, введите email',
  PASSWORD_REQUIRED: 'Пожалуйста введите пароль',
  REPEAT_PASSWORD_REQUIRED: 'Пожалуйста введите пароль',
  PASSWORD_MISMATCH: 'Пароль не совпадает',
  COUNTRY_CODE_REQUIRED: 'Пожалуйста, выберите страну',
  CURRENCY: 'Пожалуйста, выберите валюту',
  COMPANY_NAME: 'Пожалуйста, введите до 20 символов',
  URL: 'Пожалуйста, введите корректный веб-адрес',
  BUDGET: 'Выберите предел вашего бюджета',
};

const VALIDATION_REGEX = {
  USERNAME: /^[a-zA-Z]{1,20}$/,
  URL: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
  COMPANY_NAME: /^.{1,20}$/,
};

export const commonValidationRules: Record<string, Array<Rule>> = {
  username: [
    {
      required: true,
      message: VALIDATION_MESSAGES.USERNAME_REQUIRED,
    },
    {
      pattern: VALIDATION_REGEX.USERNAME,
      message: VALIDATION_MESSAGES.USERNAME_PATTERN,
    },
  ],
  email: [
    {
      type: 'email',
      message: VALIDATION_MESSAGES.EMAIL_INVALID_FORMAT,
    },
    {
      required: true,
      message: VALIDATION_MESSAGES.EMAIL_REQUIRED,
    },
  ],
  password: [
    {
      required: true,
      message: VALIDATION_MESSAGES.PASSWORD_REQUIRED,
    },
  ],
  repeatPassword: [
    {
      required: true,
      message: VALIDATION_MESSAGES.PASSWORD_REQUIRED,
    },
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (!value || getFieldValue(FormFieldName.Password) === value) {
          return Promise.resolve();
        }
        return Promise.reject(new Error(VALIDATION_MESSAGES.PASSWORD_MISMATCH));
      },
    }),
  ],
  countryCode: [
    { required: true, message: VALIDATION_MESSAGES.COUNTRY_CODE_REQUIRED },
  ],
  segmentedCurrency: [
    { required: true, message: VALIDATION_MESSAGES.CURRENCY },
  ],
  companyName: [
    {
      pattern: VALIDATION_REGEX.COMPANY_NAME,
      message: VALIDATION_MESSAGES.COMPANY_NAME,
    },
  ],
  companyUrl: [
    {
      pattern: VALIDATION_REGEX.URL,
      message: VALIDATION_MESSAGES.URL,
    },
  ],
  budget: [
    ({ getFieldValue }) => ({
      validator() {
        if (getFieldValue(FormFieldName.Budget) > 0) {
          return Promise.resolve();
        }
        return Promise.reject(new Error(VALIDATION_MESSAGES.BUDGET));
      },
    }),
  ],
  fromRegion: [{ required: true, message: 'Выбор региона обязателен!' }],
  deliveryRegion: [{ required: true, message: 'Выберите регион доставки!' }],
};
