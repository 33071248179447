import { useEffect } from 'react';
import { isFetchBaseQueryError } from '../../utils/isFetchBaseQueryError';
import { notification } from 'antd';
import { config } from '../../config';

import { useGetFiltersBySubscriptionQuery } from '../../store/subscription/subscription.api';

export const useGetSubscriptionsByFilters = () => {
  const [notificationApi, contextHolder] = notification.useNotification();
  const {
    data: subscriptionByFilters,
    isLoading,
    isError,
    error,
  } = useGetFiltersBySubscriptionQuery();

  useEffect(() => {
    if (!(isError && error)) return;

    if (isFetchBaseQueryError(error) && error.status !== 404) {
      notificationApi.error({
        message: 'Ошибка получения фильтров',
        description:
          'Не удалось загрузить список фильтров авто, на которые Вы подписаны',
        duration: config.MONITORING_SUBSCRIPTION_DURATION,
      });
    }
  }, [error, isError, notificationApi]);

  return { subscriptionByFilters, isLoading, contextHolder, error, isError };
};
