import React, { FC, useRef } from 'react';
import { CarItemHeader } from '../CarItemHeader';
import { CarFeaturesBlock } from '../CarFeaturesBlock';
import { CarsListImage } from '../../pages/CarsPage/components/CarsList/CarsListImage';
import { Typography } from 'antd';
import { Link } from 'react-router-dom';
import { getCarUrl } from '../../utils/getUrl';
import { ICarData } from '../../models';
import { CatItemPrices } from '../CarItemPrices';
import { Tags } from '../../enums';
import { CarTags, CarMetadata, CarDetailsWithIcon } from '../Car';
import { SubscriptionIconBlock } from '../SubscriptionIconBlock';
import { ImperativeSubscriptionEffects } from '../SubscriptionIconBlock';
import {
  StyledListItem,
  StyledCarTagsWrapper,
  StyledDealerNameWrapper,
  StyledDescriptionWrapper,
  StyledDetailsImageWrapper,
  StyledDivider,
  StyledDealerLocationWrapper,
  StyledCarMetaData,
} from './CarListItem.styled';
import { getDataForCarItemPrices } from '../../utils/getDataForCarItemPrices';
import { countiesMapping } from '../../data/countriesMapping';
import { useMediaQuery } from 'react-responsive';
import { SCREEN_WIDTH } from '../../screenSettings';
import { DeleteSubscriptionBlock } from '../DeleteSubscriptionBlock';
import { CarAdditionalTags } from '../CarAdditionalTags';

type CarListItemProps = {
  item: ICarData;
  hasDeleteSubscriptionBlock?: boolean;
  hasSubscriptionBlock?: boolean;
};

export const CarListItem: FC<CarListItemProps> = React.memo(
  ({
    item,
    hasDeleteSubscriptionBlock = false,
    hasSubscriptionBlock = false,
  }) => {
    const refIconSubscription = useRef<ImperativeSubscriptionEffects>(null);

    const isTabletWidth = useMediaQuery({
      query: `(max-width: ${SCREEN_WIDTH.TABLET}px`,
    });
    const isLessThanTabletWidth = useMediaQuery({
      query: `(max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_2}px`,
    });
    const isMobileWidth = useMediaQuery({
      query: `(max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px`,
    });

    const onHoverAction = () => {
      if (hasSubscriptionBlock) {
        refIconSubscription.current?.showSubscriptionsElement();
      }
    };

    const onLostHoverAction = () => {
      if (hasSubscriptionBlock) {
        refIconSubscription.current?.hideSubscriptionsElement();
      }
    };

    const carUrl: string = getCarUrl({
      adId: item.adId,
      sourceId: item.sourceId,
      makeId: item.makeId,
      modelId: item.modelId,
    });

    const dataForCarItemPrices = getDataForCarItemPrices(
      item.currency,
      item.prices,
      item.car.vatDeductible,
      item.car.vatPrices,
    );

    const isTagsBlockShowed =
      process.env.REACT_APP_TAG_TYPES_VARIATY === 'true' &&
      ((item.car.mileageEstimate?.tag &&
        item.car.mileageEstimate?.tag !== Tags.unknownMileageType) ||
        (item.car.priceEstimate?.tag &&
          item.car.priceEstimate?.tag !== Tags.unknownPriceType));

    return (
      <StyledListItem
        key={item.adId + item.sourceId}
        onMouseEnter={onHoverAction}
        onMouseLeave={onLostHoverAction}
      >
        <StyledDetailsImageWrapper>
          <StyledDescriptionWrapper>
            {hasDeleteSubscriptionBlock && (
              <DeleteSubscriptionBlock item={item} />
            )}
            {hasSubscriptionBlock && (
              <SubscriptionIconBlock item={item} ref={refIconSubscription} />
            )}
            <CarItemHeader isPriceShowed={false} item={item} carUrl={carUrl} />
            <CatItemPrices pricesProps={dataForCarItemPrices} />
            <CarDetailsWithIcon car={item.car} />

            {isTagsBlockShowed && (
              <StyledCarTagsWrapper>
                <CarTags car={item.car} />
              </StyledCarTagsWrapper>
            )}

            {Array.isArray(item.tags) && !!item.tags.length && (
              <StyledCarTagsWrapper>
                <CarAdditionalTags tags={item.tags} />
              </StyledCarTagsWrapper>
            )}

            {'features' in item.car &&
              Array.isArray(item.car.features) &&
              item.car.features.length > 0 && (
                <CarFeaturesBlock
                  features={item.car.features}
                  url={carUrl}
                  isCollapsed={true}
                />
              )}
          </StyledDescriptionWrapper>

          <Link
            style={{
              width:
                (isLessThanTabletWidth && '100%') ||
                (isTabletWidth && 220) ||
                246,
              alignSelf: isLessThanTabletWidth ? 'center' : undefined,
            }}
            to={carUrl}
            rel='noreferrer'
          >
            <CarsListImage
              item={item}
              width={
                (isLessThanTabletWidth && '100%') ||
                (isTabletWidth && 220) ||
                246
              }
              style={{
                height:
                  (isLessThanTabletWidth && 290) ||
                  (isTabletWidth && 260) ||
                  240,
              }}
            />
          </Link>
        </StyledDetailsImageWrapper>
        <StyledDealerLocationWrapper>
          {item.car.dealerName &&
            !!item.car.dealerName.length &&
            item.car.dealerUrl && (
              <>
                <StyledDealerNameWrapper>
                  <Typography.Link
                    target='_blank'
                    style={{
                      fontSize: isMobileWidth ? 14 : 16,
                      lineHeight: '24px',
                    }}
                    href={item.car.dealerUrl}
                  >
                    {item.car.dealerName}
                  </Typography.Link>
                </StyledDealerNameWrapper>
                <StyledDivider type='vertical' />
              </>
            )}

          <StyledCarMetaData>
            <CarMetadata
              car={item.car}
              location={`${countiesMapping[item.car.regionId]}, ${item.car.city}`}
            />
          </StyledCarMetaData>
        </StyledDealerLocationWrapper>
      </StyledListItem>
    );
  },
);
