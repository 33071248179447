import { FC, PropsWithChildren } from 'react';
import {
  StyledModalFooterControls,
  StyledModalFooterControlsContainer,
} from './ModalConfrim.styled';

interface ModalFooterControlsProps {}

export const ModalFooterControls: FC<
  PropsWithChildren<ModalFooterControlsProps>
> = ({ children }) => {
  return (
    <StyledModalFooterControlsContainer>
      <StyledModalFooterControls>{children}</StyledModalFooterControls>
    </StyledModalFooterControlsContainer>
  );
};
