import styled from 'styled-components';
import { Pagination } from 'antd';
import { SCREEN_WIDTH } from '../../../../screenSettings';

export const StyledCarsList = styled.section`
  flex-grow: 1;
  min-height: 100%;
  padding: 0 24px;
  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET}px) {
    padding: 0 16px;
  }
`;

export const StyledPagination = styled(Pagination)`
  margin-top: 40px;
  width: 100%;
  padding: 16px;
  background: var(--Gray-2);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  .ant-pagination-item-active {
    border-radius: 2px;
    background-color: transparent;
    border: 1px solid var(--Blue-7);
  }

  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
    justify-content: space-between;

    .ant-pagination-item,
    .ant-pagination-prev,
    .ant-pagination-next {
      background: var(--White);
      border-radius: 2px;
      border: 1px solid var(--Gray-4);
    }

    .ant-pagination-item-active {
      background: var(--White);
    }
    .ant-pagination-disabled {
      border-radius: 2px;
      border: 1px solid var(--Gray-4);
      background: var(--White);
      color: var(--Gray-4);
    }
  }
`;
