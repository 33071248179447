import { useDomainSelector } from '../../store/selectors';
import { metadataMapping } from '../../seo';
import { DomainExtension } from '../../enums/domain';
import { formatTemplateString } from '../../utils/formatTemplateString';

export const useMetadata = (
  path: string,
  ...params: Array<string | number>
) => {
  const { domainExtension } = useDomainSelector();
  const { title: titleTemplate, description: descriptionTemplate } =
    metadataMapping[domainExtension as DomainExtension][path];

  return {
    title: formatTemplateString(titleTemplate, ...params),
    description: formatTemplateString(descriptionTemplate, ...params),
  };
};
