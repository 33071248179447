import styled from 'styled-components';
import { Menu } from 'antd';

export const StyledHeaderAntMenu = styled(Menu)<{
  divedermargintop: number | undefined;
}>`
  width: 100%;
  background: transparent;
  .ant-menu-item {
    & > span:first-child {
      margin-left: 2px;
    }
  }

  .ant-menu-title-content {
    font-size: var(--desktop-content-font-size);
    font-style: var(--font-style-base);
    font-weight: var(--main-font-weight);
    line-height: var(--desktop-content-line-height);
    letter-spacing: var(--mobile-title-letter-spacing);
  }

  .ant-menu-item-selected .ant-menu-title-content {
    font-weight: var(--bold-font-weight);
  }

  .ant-menu-item-divider {
    background: var(--Gray-5);
    margin-top: ${({ divedermargintop }) =>
      divedermargintop ? `${divedermargintop}px` : '40px'};
    margin-bottom: 10px;
    border-top-width: 1px;
  }
`;
