import { FC } from 'react';
import { ConfigProvider, SliderSingleProps } from 'antd';
import { FormItemProps } from 'antd/es/form';
import { StyledFormItem } from '../../styled/StyledFormItem';
import { SliderRangeProps } from 'antd/es/slider';
import { StyledSlider } from './CustomSlider.styled';
import { PRICE_LIMIT_BUDGET } from '../../../constants';
import { useMediaQuery } from 'react-responsive';
import { SCREEN_WIDTH } from '../../../screenSettings';

type CustomSliderPropsType = (SliderRangeProps | SliderSingleProps) & {
  formItemProps: FormItemProps;
  refElement: HTMLDivElement | null;
};

export const CustomSlider: FC<CustomSliderPropsType> = ({
  formItemProps,
  refElement,
  tooltip,
  ...sliderProps
}) => {
  const isMobileWidth = useMediaQuery({
    query: `(max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_2}px)`,
  });

  return (
    <ConfigProvider
      theme={{
        components: {
          Slider: {
            controlHeight: isMobileWidth ? 16 : 12,
            dotSize: isMobileWidth ? 18 : 10,
            handleSize: isMobileWidth ? 16 : 10,
            handleSizeHover: isMobileWidth ? 17 : 12,
            railSize: isMobileWidth ? 7 : 4,
          },
        },
      }}
    >
      <StyledFormItem {...formItemProps}>
        <StyledSlider
          tooltip={{
            zIndex: 2,
            getPopupContainer: () => {
              return refElement ? refElement : document.body;
            },
            ...tooltip,
          }}
          min={0}
          max={PRICE_LIMIT_BUDGET}
          step={500}
          {...sliderProps}
        />
      </StyledFormItem>
    </ConfigProvider>
  );
};
