import styled from 'styled-components';
import { Steps } from 'antd';
import { SCREEN_WIDTH } from '../../../../screenSettings';

export const StyledStepsWrapper = styled.div`
  width: 100%;
  background: #f8fafc;
  box-shadow: 0px -1px 0px 0px #e1e7f0 inset;
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: ${SCREEN_WIDTH.DESKTOP}px) {
    padding: 22px;
  }

  @media screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_1}px) {
    padding: 16px;
  }
`;

export const StyledAntdSteps = styled(Steps)`
  max-width: calc(100% - 150px);

  @media screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_1}px) {
    & :not(.ant-steps-item-active).ant-steps-item {
      display: none;
    }
    & .ant-steps-item-active {
      padding-bottom: 4px;
    }
  }

  &.ant-steps-small .ant-steps-item-icon {
    @media screen and (max-width: ${SCREEN_WIDTH.DESKTOP}px) {
      margin-inline-end: 4px;
    }

    @media screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_1}px) {
      margin-inline-end: 8px;
    }
  }

  & .ant-steps-item-content {
    .ant-steps-item-title {
      margin-top: 8px;

      @media screen and (max-width: ${SCREEN_WIDTH.DESKTOP}px) {
        margin-top: 0;
      }
      &:after {
        transform: translateY(-5px);
        @media screen and (max-width: ${SCREEN_WIDTH.DESKTOP}px) {
          transform: translateY(0);
        }
      }
    }

    @media screen and (max-width: ${SCREEN_WIDTH.DESKTOP}px) {
      margin-top: 2px;
    }
    .ant-steps-item-title {
      color: var(--Dark-13);
      font-size: 14px;
      font-style: var(--font-style-base);
      font-weight: var(--main-font-weight);
      line-height: var(--desktop-content-line-height);
      letter-spacing: -0.07px;
    }
  }
  @media screen and (max-width: ${SCREEN_WIDTH.DESKTOP}px) {
    max-width: 100%;
  }
`;
