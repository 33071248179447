import { memo, FC } from 'react';
import { ButtonsBlock, GroupButtons } from './ActionButtons.styled';
import { StyledButton } from '../../../../components/styled/StyledButton';
import { useTurnKeyStepsSelector } from '../../../../store/selectors';
import { StepsValues } from '../../enums/stepsValues';
import { useActions } from '../../../../hooks/useActions';
import { useMediaQuery } from 'react-responsive';
import { SCREEN_WIDTH } from '../../../../screenSettings';
import { ResetButton } from '../../../../components/Buttons/ResetButton';

type ActionButtonsPropsType = {
  submitCallback: () => void;
  resetCallback: () => void;
  isLoading?: boolean;
};

export const ActionButtons: FC<ActionButtonsPropsType> = memo(
  ({ resetCallback, submitCallback, isLoading = false }) => {
    const isMobileWidth = useMediaQuery({
      query: `(max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_1}px)`,
    });
    const { activeStep } = useTurnKeyStepsSelector();
    const { setActiveStep, resetTurnKeyFormsState, setErrorStatus } =
      useActions();

    const handleClearForms = () => {
      resetCallback();
      setErrorStatus(false);
      resetTurnKeyFormsState();

      setActiveStep(StepsValues.SearchParams);
    };

    const handleContinueButtonClick = () => {
      submitCallback();
    };

    const handleBackClick = () => {
      switch (activeStep) {
        case StepsValues.SearchParams:
          break;
        case StepsValues.CarEquipment:
          setActiveStep(StepsValues.SearchParams);
          break;
        case StepsValues.CarDelivery:
          setActiveStep(StepsValues.CarEquipment);
          break;
        default:
          break;
      }
    };

    return (
      <ButtonsBlock>
        {activeStep !== StepsValues.CarDelivery && (
          <ResetButton
            onClick={handleClearForms}
            style={{
              width: isMobileWidth ? '100%' : 'max-content',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 4,
              color: '#3F8BF3',
            }}
          >
            Сбросить всё
          </ResetButton>
        )}
        <GroupButtons>
          {activeStep !== StepsValues.SearchParams && (
            <StyledButton
              type='primary'
              ghost
              size='large'
              block
              onClick={handleBackClick}
              style={isMobileWidth ? undefined : { width: '30%' }}
            >
              Назад
            </StyledButton>
          )}
          <StyledButton
            loading={isLoading}
            disabled={isLoading}
            style={isMobileWidth ? undefined : { width: '30%', marginLeft: 16 }}
            onClick={handleContinueButtonClick}
            type='primary'
            size='large'
            block
          >
            {activeStep === StepsValues.CarDelivery
              ? 'Отправить заявку'
              : 'Продолжить'}
          </StyledButton>
        </GroupButtons>
      </ButtonsBlock>
    );
  },
);
