export const CarBodyTypeSvg = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='14'
    viewBox='0 0 16 14'
    fill='none'
  >
    <path
      d='M3.54639 1H12.3352'
      stroke='currentColor'
      strokeWidth='0.8'
      strokeLinecap='round'
    />
    <path
      d='M0.555176 6.19922L15.4447 6.19922'
      stroke='currentColor'
      strokeWidth='0.8'
      strokeLinecap='round'
    />
    <path
      d='M4.22998 10.6978L11.6423 10.6978'
      stroke='currentColor'
      strokeWidth='0.8'
      strokeLinecap='round'
    />
    <path
      d='M14.3359 5.9248L14.3359 12.9217'
      stroke='currentColor'
      strokeWidth='0.8'
      strokeLinecap='round'
    />
    <path
      d='M1.5376 6.19922L1.5376 12.9998'
      stroke='currentColor'
      strokeWidth='0.8'
      strokeLinecap='round'
    />
    <path
      d='M4.29297 13H1.71576'
      stroke='currentColor'
      strokeWidth='0.8'
      strokeLinecap='round'
    />
    <path
      d='M14.3164 13H11.6427'
      stroke='currentColor'
      strokeWidth='0.8'
      strokeLinecap='round'
    />
    <path
      d='M4.22998 12.9219L4.22998 10.746'
      stroke='currentColor'
      strokeWidth='0.9'
      strokeLinecap='round'
    />
    <path
      d='M11.6421 12.9219L11.6421 10.746'
      stroke='currentColor'
      strokeWidth='0.8'
      strokeLinecap='round'
    />
    <path
      d='M12.3347 1.03403L14.527 6.37503'
      stroke='currentColor'
      strokeWidth='0.8'
    />
    <line
      y1='-0.4'
      x2='5.955'
      y2='-0.4'
      transform='matrix(-0.366735 0.930325 0.907412 0.420242 3.89893 1.20361)'
      stroke='currentColor'
      strokeWidth='0.8'
    />
  </svg>
);
