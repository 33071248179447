import {
  isFulfilled,
  isRejectedWithValue,
  Middleware,
  MiddlewareAPI,
} from '@reduxjs/toolkit';

import { appGeneralActions } from '../app-general/app.slice';
import { NotificationTransformedResponse } from '../../models/notificationsCustom';

export const appNotificationMiddleware: Middleware =
  ({ dispatch }: MiddlewareAPI) =>
  (next) =>
  (action) => {
    if (isRejectedWithValue(action)) {
      if (
        action.payload instanceof Object &&
        'forCustomNotification' in action.payload
      ) {
        const {
          forCustomNotification: { message, status, description },
        } = action.payload as NotificationTransformedResponse<any>;
        dispatch(
          appGeneralActions.setNotification({
            message,
            description,
            status,
          }),
        );
      }
    }

    if (isFulfilled(action)) {
      if (
        action.payload instanceof Object &&
        'forCustomNotification' in action.payload
      ) {
        const {
          forCustomNotification: { status, message, description },
        } = action.payload as NotificationTransformedResponse<any>;
        dispatch(
          appGeneralActions.setNotification({ status, message, description }),
        );
      }
    }

    return next(action);
  };
