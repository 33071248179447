import styled from 'styled-components';
import { SCREEN_WIDTH } from '../../screenSettings';

export const StyledSwitcherWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  padding: 24px 0;
  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;
    padding: 0;
  }
`;

export const StyledDescription = styled.div`
  width: 90%;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: 'none';
  overflow: hidden;
  hyphens: auto;
  text-align: left;
  text-overflow: ellipsis;
  color: var(--Dark-13);
  font-size: var(--desktop-content-font-size);
  font-style: var(--font-style-base);
  font-weight: var(--main-font-weight);
  line-height: var(--content-desktop-line-height);
  letter-spacing: 0.024px;

  @media only screen and (max-width: ${SCREEN_WIDTH.TO_DESKTOP_BREAK_1}px) {
    width: 85%;
  }
  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET}px) {
    width: 85%;
  }
  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_1}px) {
    width: 78%;
  }

  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_2}px) {
    -webkit-line-clamp: 2;
    font-size: var(--main-font-size);
    width: 87%;
  }
`;
