import styled from 'styled-components';

export const StyledFormItemLabel = styled.label`
  color: var(--Dark);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.21px;
`;
