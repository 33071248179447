import { useEffect } from 'react';
import { notification } from 'antd';
import { config } from '../../config';
import { isFetchBaseQueryError } from '../../utils/isFetchBaseQueryError';

import { useGetOffersBySubscriptionQuery } from '../../store/subscription/subscription.api';

export const useGetSubscriptionsOffers = () => {
  const [notificationApi, contextHolder] = notification.useNotification();
  const {
    data: offersBySubscription,
    isLoading,
    isError,
    error,
  } = useGetOffersBySubscriptionQuery();

  useEffect(() => {
    if (!(isError && error)) return;

    if (isFetchBaseQueryError(error) && error.status !== 404) {
      notificationApi.error({
        message: 'Ошибка получения авто',
        description: 'Не удалось загрузить авто, на которые Вы подписаны',
        duration: config.MONITORING_SUBSCRIPTION_DURATION,
      });
    }
  }, [error, isError, notificationApi]);

  return { offersBySubscription, isLoading, contextHolder, error, isError };
};
