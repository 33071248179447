import { FilterFormFieldNames } from '../../enums/form';
import { convertToArray } from '../../utils/convertToNumberArray';

export const carsFormDisabledHooks = {
  [FilterFormFieldNames.regionId]: (
    queryParametersCountry: number | string[] | number[] | string | undefined,
    russiaCountryValue: number,
  ) => {
    if (!queryParametersCountry) return true;

    return Array.isArray(queryParametersCountry)
      ? !convertToArray(queryParametersCountry).includes(russiaCountryValue)
      : +queryParametersCountry !== russiaCountryValue;
  },
};

export const carsFormActionsHooks = {
  [FilterFormFieldNames.country]: (
    countries: number[] | undefined,
    russiaCountryValue: number,
    setValues: (value: any) => void,
  ): void => {
    const shouldResetRegionId = Array.isArray(countries)
      ? !countries.includes(russiaCountryValue)
      : countries !== russiaCountryValue;

    if (shouldResetRegionId) {
      setValues({
        [FilterFormFieldNames.regionId]: undefined,
        [FilterFormFieldNames.country]: countries,
      });
    } else {
      setValues({ [FilterFormFieldNames.country]: countries });
    }
  },
};
