import { APP_PATHS } from '../../../layout/settingsLayout';

interface IFormRoute {
  value: string;
  label: string;
}

export const formRoutes: Array<IFormRoute> = [
  { value: APP_PATHS.SIGN_IN, label: 'Вход' },
  { value: APP_PATHS.SIGN_UP, label: 'Регистрация' },
];
