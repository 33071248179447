export enum FormFieldName {
  Email = 'email',
  Password = 'password',
  Username = 'username',
  RepeatPassword = 'repeatPassword',
  CountryCode = 'countryCode',
  Currency = 'currency',
  CompanyName = 'companyName',
  CompanyUrl = 'companyUrl',
  TransmissionType = 'transmissionType',
  Budget = 'budget',
  Make = 'make',
  Model = 'model',
  YearFrom = 'yearFrom',
  YearTo = 'yearTo',
  ToMileage = 'toMileage',
  EngineTypes = 'engineTypes',
  EngineCapacityFrom = 'engineCapacityFrom',
  EngineCapacityTo = 'engineCapacityTo',
  TypesOfDrive = 'typesOfDrive',
  BodyTypes = 'bodyTypes',
  BodyColors = 'bodyColors',
  HasDamage = 'hasDamage',
  SourceRegionId = 'sourceRegionId',
}

export enum FormFiledLabel {
  Email = 'Email',
  Password = 'Пароль',
  NewPassword = 'Введите новый пароль',
  Username = 'Имя пользователя',
  RepeatPassword = 'Подтверждение пароля',
  Country = 'Страна',
  Currency = 'Валюта',
  CompanyName = 'Название компании',
  CompanyUrl = 'Сайт компании',
  TransmissionType = 'Коробка передач',
}

export enum FormPlaceholder {
  Email = 'Введите ваш email',
  Password = 'Введите пароль',
  NewPassword = 'Введите новый пароль',
  Username = 'Введите имя пользователя',
  RepeatPassword = 'Подтвердите пароль',
  Country = 'Выберите страну',
  CompanyName = 'Введите название компании',
  CompanyUrl = 'Введите сайт',
}

export enum FilterFormFieldNames {
  country = 'country',
  make = 'make',
  model = 'model',
  regionId = 'regionId',
  yearFrom = 'yearFrom',
  yearTo = 'yearTo',
  currency = 'currency',
  fromPrice = 'fromPrice',
  toPrice = 'toPrice',
  vatDeductible = 'vatDeductible',
  engineType = 'engineType',
  engineCapacityFrom = 'engineCapacityFrom',
  engineCapacityTo = 'engineCapacityTo',
  transmission = 'transmission',
  bodyType = 'bodyType',
  typeOfDrive = 'typeOfDrive',
  toMileage = 'toMileage',
}

export enum FilterFormPlaceholder {
  Country = 'Выберите страну',
  Region = 'Выберите регион',
  Make = 'Выберите марку',
  Model = 'Выберите модель',
  YearFrom = 'от',
  YearTo = 'до',
  Year = 'Выберите год',
}

export enum FilterFormLabel {
  Country = 'Страна',
  Region = 'Регион',
  Make = 'Марка',
  Model = 'Модель',
  YearFrom = 'Год от',
  YearTo = 'Год до',
  Year = 'Год',
}

export enum SortFormFieldNames {
  sortBy = 'sortBy',
  from = 'from',
}

export enum EquipmentFormFieldNames {
  FeaturesComfortId = '2',
  FeaturesSafetyId = '1',
  FeaturesMultimediaId = '3',
  FeaturesInteriorId = '4',
  FeaturesExteriorId = '5',
  FeaturesAdditionalId = '6',
}

export enum DeliveryFormFieldsNames {
  CompanyId = 'companyId',
  DestinationRegionId = 'destinationRegionId',
}
