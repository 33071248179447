import { useEffect, forwardRef, useImperativeHandle, Ref } from 'react';
import { StyledFormWrapper } from '../TurnKeyForms.styled';
import { Form, notification, Spin, Select, Radio } from 'antd';
import { SelectOptionStyle } from '../../../../../components/styled/SelectOption';
import { SearchParamsFormFieldsType } from '../types/form';
import {
  years,
  engineSizes,
  mileages,
  turnkeyAutoCurrencies,
  transmissions,
} from '../../../../../data';
import { TwoColumnFormLayoutStyles, StyledTitleForm } from '../shared/styles';
import { Fragment } from 'react';
import { changeOnDefaultIncluded } from '../../../utils/changeOnDefault';
import { CheckIcon } from '../../../../../img/icons/components';
import {
  StyledColumn,
  StyledRadioGroup,
  StyledRowTwoColumn,
} from './SearchParamsForm.styled';
import {
  CustomCheckboxGroup,
  CustomSegmented,
} from '../../../../../components/FormElements';
import { FormFieldName } from '../../../../../enums/form';
import { FormItemLabel } from '../../../../../components/FormItemLabel';
import { FormFiledLabel } from '../../../../../enums/form';
import { commonValidationRules } from '../../../../../data/commonValidationRules';
import { useMediaQuery } from 'react-responsive';
import { SCREEN_WIDTH } from '../../../../../screenSettings';
import {
  useCarsSelector,
  useTurnKeySelector,
} from '../../../../../store/selectors';
import { MakesModelSelect } from '../../../../../components/FormElements/MakesModelSelect';
import { SelectOptions } from '../../../../../enums';
import { useGetModelsWhithoutParams } from '../../../../../hooks/cars';
import { CustomSelect } from '../../../../../components/FormElements';
import { CaretDownOutlined } from '@ant-design/icons';
import {
  getCarBodyColors,
  getCarBodyTypes,
  getCarEngineOptions,
  getCarTypesOfDrive,
} from '../../../utils/getSelectOptions';
import { useActions } from '../../../../../hooks/useActions';
import { useTurnKeyFormsSelector } from '../../../../../store/selectors';
import { StepsValues } from '../../../enums/stepsValues';
import { useFirstRender } from '../../../../../hooks/useFirstRender';
import { FormRef } from '../types/form';
import { useGetMakesQuery } from '../../../../../store/cars/cars.api';
import { CurrencySumSlider } from '../../../../../components/CurrencySumSlider';
import { NON_IMPORTANT_TYPE_VALUE } from '../../../../../constants';
import { changeGridOrderToBottomStyles } from '../utils/changeGridOrderToBottomStyles';
import { hasDamageOptions } from '../../../../../data/hasDamageOptions';
import { turnKeySourceIDOptions } from '../../../../../data/sourceIdMapping';
import { useQueryState } from '../../../../../hooks/useQueryState';
import { generateFormFields } from './SearchParamsForm.utils';
import { useTurnKeyCarContext } from '../../../../../hooks/turnKeyCar';

export const SearchParamsForm = forwardRef<FormRef>((_, ref: Ref<FormRef>) => {
  const { setSearchParamsForm, setActiveStep, setErrorStatus } = useActions();

  const isMount = useFirstRender();
  const { handleSetModels } = useTurnKeyCarContext();
  const { searchParamsForm } = useTurnKeyFormsSelector();
  const [notificationApi, contextHolder] = notification.useNotification();
  const [queryParameters, setQueryParameters] = useQueryState();
  const [form] = Form.useForm<SearchParamsFormFieldsType>();
  const makesFormValue = Form.useWatch(FormFieldName.Make, form);
  const yearFromCurrent = Form.useWatch(FormFieldName.YearFrom, form);
  const yearToCurrent = Form.useWatch(FormFieldName.YearTo, form);
  const prevCheckedBodyType = Form.useWatch(FormFieldName.BodyTypes, form);
  const prevTypesOfDrive = Form.useWatch(FormFieldName.TypesOfDrive, form);
  const prevBodyColors = Form.useWatch(FormFieldName.BodyColors, form);
  const prevEngineTypes = Form.useWatch(FormFieldName.EngineTypes, form);
  const currency = Form.useWatch(FormFieldName.Currency, form);
  const currentEngineCapacityFrom = Form.useWatch(
    FormFieldName.EngineCapacityFrom,
    form,
  );
  const currentEngineCapacityTo = Form.useWatch(
    FormFieldName.EngineCapacityTo,
    form,
  );

  const { isFetchingModels, models, getModels } =
    useGetModelsWhithoutParams(notificationApi);

  const { carMakes: makes } = useCarsSelector();

  const { isLoading: isFetchingMakes } = useGetMakesQuery();

  const { carOptions } = useTurnKeySelector();

  const isTabletWidth = useMediaQuery({
    query: `(max-width: ${SCREEN_WIDTH.TABLET}px)`,
  });

  const carEngineOptions = getCarEngineOptions(carOptions.engineTypes);
  const carTypesOfDrive = getCarTypesOfDrive(carOptions.typesOfDrive);
  const carBodyTypes = getCarBodyTypes(carOptions.bodyTypes);
  const carBodyColors = getCarBodyColors(carOptions.bodyColors);

  useEffect(() => {
    if (makesFormValue && makesFormValue !== SelectOptions.any) {
      getModels(makesFormValue);
    }
  }, [getModels, makesFormValue]);

  useEffect(() => {
    if (searchParamsForm.budget && isMount) {
      form.setFieldsValue(searchParamsForm);
    }
  }, [form, isMount, searchParamsForm]);

  useEffect(() => {
    handleSetModels(models);
  }, [models]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const finishFormHandler = (values: SearchParamsFormFieldsType) => {
    setSearchParamsForm(values);
    setActiveStep(StepsValues.CarEquipment);
  };

  const handleFieldsChanged = () => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);

    setErrorStatus(hasErrors);
  };

  const submitForm = () => form.submit();

  const resetForm = () => {
    form.resetFields();
    form.setFieldValue(FormFieldName.Currency, turnkeyAutoCurrencies[0].value);
    form.setFieldValue(FormFieldName.EngineTypes, [NON_IMPORTANT_TYPE_VALUE]);
    form.setFieldValue(FormFieldName.TypesOfDrive, [NON_IMPORTANT_TYPE_VALUE]);
    form.setFieldValue(FormFieldName.BodyTypes, [NON_IMPORTANT_TYPE_VALUE]);
    form.setFieldValue(FormFieldName.BodyColors, [
      NON_IMPORTANT_TYPE_VALUE.toString(),
    ]);
    form.setFieldValue(
      FormFieldName.SourceRegionId,
      turnKeySourceIDOptions[0].value,
    );
    form.setFieldValue(FormFieldName.Make, undefined);
    form.setFieldValue(FormFieldName.Model, undefined);
    setQueryParameters({
      [FormFieldName.SourceRegionId]: turnKeySourceIDOptions[0].value,
      [FormFieldName.Make]: undefined,
      [FormFieldName.Model]: undefined,
    });
  };

  useImperativeHandle(ref, () => ({ submitForm, resetForm }));

  return (
    <Fragment key='search-params-form'>
      {contextHolder}
      <StyledTitleForm>Параметры поиска</StyledTitleForm>
      <StyledFormWrapper>
        <Form
          scrollToFirstError
          onFieldsChange={handleFieldsChanged}
          requiredMark={false}
          layout='vertical'
          style={TwoColumnFormLayoutStyles}
          initialValues={searchParamsForm.make ? searchParamsForm : undefined}
          form={form}
          onFinish={finishFormHandler}
          name='searchParamsForm'
          fields={generateFormFields(queryParameters)}
        >
          <StyledColumn>
            <CustomSegmented
              formItemProps={{
                style: { width: '100%' },
                name: FormFieldName.SourceRegionId,
                label: <FormItemLabel>Авто из</FormItemLabel>,
                rules: commonValidationRules.fromRegion,
              }}
              options={turnKeySourceIDOptions}
              onChange={(value) => {
                setQueryParameters({ [FormFieldName.SourceRegionId]: value });
              }}
            />

            {!isTabletWidth && (
              <CustomSegmented
                formItemProps={{
                  style: { width: '100%' },
                  name: FormFieldName.HasDamage,
                  label: <FormItemLabel>Состояние авто</FormItemLabel>,
                }}
                options={hasDamageOptions}
              />
            )}

            {isTabletWidth && (
              <StyledRadioGroup
                name={FormFieldName.HasDamage}
                label={<FormItemLabel>Состояние авто</FormItemLabel>}
              >
                <Radio.Group size='large' options={hasDamageOptions} />
              </StyledRadioGroup>
            )}
            <CustomSegmented
              formItemProps={{
                name: FormFieldName.Currency,
                label: <FormItemLabel>{FormFiledLabel.Currency}</FormItemLabel>,
                rules: commonValidationRules.segmentedCurrency,
                initialValue: searchParamsForm.currency
                  ? undefined
                  : turnkeyAutoCurrencies[0].value,
              }}
              options={turnkeyAutoCurrencies}
            />

            <CurrencySumSlider form={form} currency={currency} />

            <MakesModelSelect
              modeProps={FormFieldName.Make}
              formItemProps={{
                rules: [],
                style: { marginBottom: 0 },
              }}
              disabled={!carOptions || isFetchingMakes}
              notFoundContent={isFetchingMakes ? <Spin size='large' /> : null}
              onChange={(value: any) => {
                form.setFieldValue(FormFieldName.Model, undefined);
                setQueryParameters({
                  [FormFieldName.Model]: undefined,
                  [FormFieldName.Make]: value.toLowerCase(),
                });
              }}
              placeholder={'Выберите марку'}
            >
              {makes &&
                makes.map((option, index: number) => (
                  <Select.Option
                    style={SelectOptionStyle}
                    key={`${index}-${option.viewName}`}
                    value={option.id}
                    label={option.viewName}
                    id={option.id}
                  >
                    {option.viewName}
                  </Select.Option>
                ))}
            </MakesModelSelect>
            <MakesModelSelect
              modeProps={FormFieldName.Model}
              formItemProps={{
                rules: [],
                style: { marginBottom: 0 },
              }}
              disabled={!models || isFetchingModels || !makesFormValue}
              notFoundContent={isFetchingModels ? <Spin size='large' /> : null}
              placeholder={'Выберите модель'}
              onChange={(value: any) => {
                setQueryParameters({
                  [FormFieldName.Model]: value.toLowerCase(),
                });
              }}
            >
              {models &&
                models.map((option, index: number) => (
                  <Select.Option
                    style={SelectOptionStyle}
                    key={`${index}-${option.originalName}`}
                    value={option.originalName || option.id}
                    label={option.viewName || option.originalName}
                    id={option.id}
                  >
                    {option.viewName}
                  </Select.Option>
                ))}
            </MakesModelSelect>
            <StyledRowTwoColumn>
              <CustomSelect
                formItemProps={{
                  style: { width: '100%' },
                  name: FormFieldName.YearFrom,
                  label: <FormItemLabel>Год</FormItemLabel>,
                }}
                style={{ width: '100%' }}
                showSearch
                placeholder={'от'}
                menuItemSelectedIcon={<CheckIcon />}
                suffixIcon={<CaretDownOutlined rev='true' />}
              >
                {years.map((option, index) => (
                  <Select.Option
                    style={SelectOptionStyle}
                    key={`${index}-${option.value}`}
                    value={option.value}
                    label={option.label}
                    id={option.value}
                    disabled={+option.value > +yearToCurrent}
                  >
                    {option.label}
                  </Select.Option>
                ))}
              </CustomSelect>
              <CustomSelect
                formItemProps={{
                  style: { width: '100%' },
                  name: FormFieldName.YearTo,
                  label: <FormItemLabel>до</FormItemLabel>,
                }}
                style={{ width: '100%' }}
                showSearch
                placeholder={'до'}
                menuItemSelectedIcon={<CheckIcon />}
                suffixIcon={<CaretDownOutlined rev='true' />}
              >
                {years.map((option, index) => (
                  <Select.Option
                    style={SelectOptionStyle}
                    key={`${index}-${option.value}`}
                    value={option.value}
                    label={option.label}
                    id={option.value}
                    disabled={+option.value < +yearFromCurrent}
                  >
                    {option.label}
                  </Select.Option>
                ))}
              </CustomSelect>
            </StyledRowTwoColumn>
            <CustomSelect
              formItemProps={{
                name: FormFieldName.ToMileage,
                style: { marginBottom: 0 },
                label: <FormItemLabel>Пробег</FormItemLabel>,
              }}
              showSearch
              placeholder='Выберите пробег'
              menuItemSelectedIcon={<CheckIcon />}
              suffixIcon={<CaretDownOutlined rev='true' />}
              listHeight={264}
            >
              {mileages.map((option, index: number) => (
                <Select.Option
                  style={SelectOptionStyle}
                  key={`${index}-${option.value}`}
                  value={option.value}
                  label={option.label}
                  id={option.value}
                >
                  {option.label}
                </Select.Option>
              ))}
            </CustomSelect>
            {isTabletWidth && (
              <CustomSegmented
                formItemProps={{
                  name: FormFieldName.TransmissionType,
                  label: (
                    <FormItemLabel>
                      {FormFiledLabel.TransmissionType}
                    </FormItemLabel>
                  ),
                  initialValue: searchParamsForm
                    ? undefined
                    : transmissions[0].value,
                }}
                options={transmissions}
              />
            )}
          </StyledColumn>
          <StyledColumn>
            {!isTabletWidth && (
              <CustomSegmented
                formItemProps={{
                  name: FormFieldName.TransmissionType,
                  label: (
                    <FormItemLabel>
                      {FormFiledLabel.TransmissionType}
                    </FormItemLabel>
                  ),
                  initialValue: searchParamsForm
                    ? undefined
                    : transmissions[0].value,
                }}
                options={transmissions}
              />
            )}
            <CustomCheckboxGroup
              formItemProps={{
                name: FormFieldName.EngineTypes,
                label: <FormItemLabel>Тип двигателя</FormItemLabel>,
                initialValue: searchParamsForm.engineTypes
                  ? undefined
                  : [NON_IMPORTANT_TYPE_VALUE],
              }}
              gridCheckboxes={true}
              style={
                isTabletWidth
                  ? changeGridOrderToBottomStyles(carEngineOptions.length)
                  : undefined
              }
              options={carEngineOptions}
              onChange={(checkedValues) => {
                changeOnDefaultIncluded(
                  checkedValues,
                  prevEngineTypes,
                  form,
                  FormFieldName.EngineTypes,
                );
              }}
            />
            <StyledRowTwoColumn style={{ alignItems: 'flex-end' }}>
              <CustomSelect
                formItemProps={{
                  style: { width: '100%' },
                  name: FormFieldName.EngineCapacityFrom,
                  label: (
                    <FormItemLabel style={{ whiteSpace: 'nowrap' }}>
                      Объем двигателя, л
                    </FormItemLabel>
                  ),
                }}
                style={{ width: '100%' }}
                showSearch
                placeholder='от'
                menuItemSelectedIcon={<CheckIcon />}
                suffixIcon={<CaretDownOutlined rev='true' />}
              >
                {engineSizes.map((option, index) => (
                  <Select.Option
                    style={SelectOptionStyle}
                    key={`${index}-${option.value}`}
                    value={option.value}
                    label={option.label}
                    id={option.value}
                    disabled={+option.value > +currentEngineCapacityTo}
                  >
                    {option.label}
                  </Select.Option>
                ))}
              </CustomSelect>
              <CustomSelect
                formItemProps={{
                  style: {
                    width: '100%',
                  },
                  name: FormFieldName.EngineCapacityTo,
                }}
                style={{ width: '100%' }}
                showSearch
                placeholder='до'
                menuItemSelectedIcon={<CheckIcon />}
                suffixIcon={<CaretDownOutlined rev='true' />}
              >
                {engineSizes.map((option, index) => (
                  <Select.Option
                    style={SelectOptionStyle}
                    key={`${index}-${option.value}`}
                    value={option.value}
                    label={option.label}
                    id={option.value}
                    disabled={+option.value < +currentEngineCapacityFrom}
                  >
                    {option.label}
                  </Select.Option>
                ))}
              </CustomSelect>
            </StyledRowTwoColumn>
            <CustomCheckboxGroup
              formItemProps={{
                name: FormFieldName.TypesOfDrive,
                label: <FormItemLabel>Тип привода</FormItemLabel>,
                initialValue: searchParamsForm.typesOfDrive
                  ? undefined
                  : [NON_IMPORTANT_TYPE_VALUE],
              }}
              gridCheckboxes={true}
              options={carTypesOfDrive}
              onChange={(checkedValues) => {
                changeOnDefaultIncluded(
                  checkedValues,
                  prevTypesOfDrive,
                  form,
                  FormFieldName.TypesOfDrive,
                );
              }}
            />
            <CustomCheckboxGroup
              formItemProps={{
                name: FormFieldName.BodyTypes,
                label: <FormItemLabel>Тип кузова</FormItemLabel>,
                initialValue: searchParamsForm.bodyTypes
                  ? undefined
                  : [NON_IMPORTANT_TYPE_VALUE],
              }}
              gridCheckboxes={true}
              style={
                isTabletWidth
                  ? changeGridOrderToBottomStyles(carBodyTypes.length)
                  : undefined
              }
              options={carBodyTypes}
              onChange={(checkedValues) => {
                changeOnDefaultIncluded(
                  checkedValues,
                  prevCheckedBodyType,
                  form,
                  FormFieldName.BodyTypes,
                );
              }}
            />
            <CustomCheckboxGroup
              formItemProps={{
                name: FormFieldName.BodyColors,
                label: <FormItemLabel>Цвет кузова</FormItemLabel>,
                initialValue: searchParamsForm.bodyColors
                  ? undefined
                  : [NON_IMPORTANT_TYPE_VALUE.toString()],
              }}
              gridCheckboxes={true}
              style={
                isTabletWidth
                  ? changeGridOrderToBottomStyles(carBodyColors.length)
                  : undefined
              }
              options={carBodyColors}
              onChange={(checkedValues) => {
                changeOnDefaultIncluded(
                  checkedValues,
                  prevBodyColors,
                  form,
                  FormFieldName.BodyColors,
                );
              }}
            />
          </StyledColumn>
        </Form>
      </StyledFormWrapper>
    </Fragment>
  );
});
