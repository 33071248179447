import { ConfigProvider, Form, notification } from 'antd';
import { FC, useEffect } from 'react';
import { mobileFormTheme } from '../../../../themeSettings';
import {
  FilterCellMulti,
  FilterCellSingle,
  PairFilterCell,
} from '../../../../components/MobileFormElements';
import { SelectOptions } from '../../../../enums';
import {
  countries,
  russiaCountryValue,
  russiaRegionId,
  years,
} from '../../../../data';
import {
  FilterFormProps,
  FormState,
  StyledDesktopFilterControlsWrapper,
  StyledFilterFormColumn,
  StyledFormElementsWrapper,
  StyledSubmitButton,
} from '../FilterForms';
import { useMediaQuery } from 'react-responsive';
import { SCREEN_WIDTH } from '../../../../screenSettings';
import {
  carsFormActionsHooks,
  carsFormDisabledHooks,
  useGetCountCars,
  useGetModels,
} from '../../../../hooks/cars';
import { getCountText } from '../../../../utils/cars';
import {
  useHandleChange,
  useMakeChange,
  useWatchFilterForm,
} from '../../hooks';
import {
  FilterFormFieldNames,
  FilterFormLabel,
  FilterFormPlaceholder,
} from '../../../../enums/form';
import { useGetMakesQuery } from '../../../../store/cars/cars.api';
import { useCarsSelector } from '../../../../store/selectors';

export const MobileFilterForm: FC<FilterFormProps> = ({ navigateToCars }) => {
  const [notificationApi, contextHolder] = notification.useNotification();
  const [mobileFilterForm] = Form.useForm<FormState>();
  const { country, regionId, make, model, yearFrom, yearTo } =
    useWatchFilterForm(mobileFilterForm);
  const { getCountCars, countCars, isFetchingCountCars } = useGetCountCars(
    notificationApi,
    {
      country,
      regionId,
      make,
      model,
      yearFrom,
      yearTo,
    },
  );
  const { isLoading: isFetchingMakes } = useGetMakesQuery();

  const { carMakes: makes } = useCarsSelector();

  const { getModels, models, isFetchingModels } = useGetModels(notificationApi);
  const isMobile = useMediaQuery({
    query: `(max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px)`,
  });
  const handleMakeChange = useMakeChange(mobileFilterForm, getModels);
  const handleModelChange = useHandleChange(
    mobileFilterForm,
    FilterFormFieldNames.model,
  );
  const handleRegionIdChange = useHandleChange(
    mobileFilterForm,
    FilterFormFieldNames.regionId,
  );
  const isModelDisabled =
    make === SelectOptions.any ||
    !models?.length ||
    !make ||
    isFetchingMakes ||
    isFetchingModels;

  useEffect(() => {
    getCountCars();
  }, [country, regionId, yearFrom, yearTo, make, model]);

  return (
    <>
      {contextHolder}
      <ConfigProvider theme={mobileFormTheme}>
        <Form
          requiredMark={false}
          name='filterFormMobile'
          layout='vertical'
          autoComplete='off'
          onFinish={navigateToCars}
          form={mobileFilterForm}
          scrollToFirstError={true}
          colon={false}
        >
          <StyledFormElementsWrapper>
            <StyledFilterFormColumn>
              <FilterCellMulti
                form={mobileFilterForm}
                label={FilterFormLabel.Country}
                name={FilterFormFieldNames.country}
                placeholder={FilterFormPlaceholder.Country}
                value={country}
                callback={(countries: number[] | undefined) =>
                  carsFormActionsHooks[FilterFormFieldNames.country](
                    countries,
                    russiaCountryValue,
                    mobileFilterForm.setFieldsValue,
                  )
                }
                options={countries}
              />

              {isMobile && (
                <FilterCellMulti
                  form={mobileFilterForm}
                  label={FilterFormLabel.Region}
                  name={FilterFormFieldNames.regionId}
                  placeholder={FilterFormPlaceholder.Region}
                  value={regionId}
                  options={russiaRegionId}
                  disabled={carsFormDisabledHooks[
                    FilterFormFieldNames.regionId
                  ](country, russiaCountryValue)}
                  callback={handleRegionIdChange}
                />
              )}

              <FilterCellSingle
                form={mobileFilterForm}
                withSearch={true}
                options={makes}
                loading={isFetchingMakes}
                disabled={isFetchingMakes}
                label={FilterFormLabel.Make}
                name={FilterFormFieldNames.make}
                placeholder={FilterFormPlaceholder.Make}
                value={make}
                callback={handleMakeChange}
              />

              {isMobile && (
                <FilterCellSingle
                  form={mobileFilterForm}
                  options={models}
                  disabled={isModelDisabled}
                  loading={isFetchingModels}
                  label={FilterFormLabel.Model}
                  name={FilterFormFieldNames.model}
                  placeholder={FilterFormPlaceholder.Model}
                  value={model}
                  callback={handleModelChange}
                />
              )}

              <PairFilterCell
                form={mobileFilterForm}
                label={FilterFormLabel.Year}
                nameFrom={FilterFormFieldNames.yearFrom}
                nameTo={FilterFormFieldNames.yearTo}
                placeholder={FilterFormPlaceholder.Year}
                valueFrom={yearFrom}
                valueTo={yearTo}
                options={years}
              />
            </StyledFilterFormColumn>
            <StyledFilterFormColumn>
              {!isMobile && (
                <FilterCellMulti
                  form={mobileFilterForm}
                  label={FilterFormLabel.Region}
                  name={FilterFormFieldNames.regionId}
                  placeholder={FilterFormPlaceholder.Region}
                  value={regionId}
                  options={russiaRegionId}
                  disabled={carsFormDisabledHooks[
                    FilterFormFieldNames.regionId
                  ](country, russiaCountryValue)}
                  callback={handleRegionIdChange}
                />
              )}

              {!isMobile && (
                <FilterCellSingle
                  form={mobileFilterForm}
                  options={models}
                  disabled={isModelDisabled}
                  loading={isFetchingModels}
                  label={FilterFormLabel.Model}
                  name={FilterFormFieldNames.model}
                  placeholder={FilterFormPlaceholder.Model}
                  value={model}
                  callback={handleModelChange}
                />
              )}
            </StyledFilterFormColumn>
          </StyledFormElementsWrapper>
          <StyledDesktopFilterControlsWrapper>
            <StyledSubmitButton
              size='large'
              disabled={!countCars}
              loading={isFetchingCountCars}
              block
              type='primary'
              htmlType='submit'
            >
              {getCountText(isFetchingCountCars, countCars)}
            </StyledSubmitButton>
          </StyledDesktopFilterControlsWrapper>
        </Form>
      </ConfigProvider>
    </>
  );
};
