import styled from 'styled-components';
import { SCREEN_WIDTH } from '../../screenSettings';
import { CustomModal } from '../CustomModal';

export const StyledAntModal = styled(CustomModal)<{
  withSeparateHeader?: boolean;
}>`
  .ant-modal-header {
    border-radius: 8px 8px 0 0;
    padding: 16px 24px;
    margin: 0;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    box-shadow: ${(props) =>
      props.withSeparateHeader ? '0px -1px 0px 0px #E1E7F0 inset' : ''};

    @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_1}px) {
      padding: 16px;
    }
  }

  & .ant-modal-content {
    padding: 0;
    border-radius: 8px;
    box-shadow:
      0px 3px 6px 0px rgba(80, 89, 102, 0.1),
      0px 24px 48px 0px rgba(80, 89, 102, 0.2);
  }

  .ant-modal-body {
    padding: 16px 24px;

    @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_1}px) {
      padding: 16px;
      gap: 10px;
    }
  }

  .ant-modal-footer {
    margin-top: 0;
    padding: 16px 24px;

    @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_1}px) {
      padding: 16px;
    }
  }

  .ant-modal-title {
    color: var(--Gray-100);
    font-size: 20px;
    font-style: var(--font-style-base);
    font-weight: var(--bold-font-weight);
    line-height: 120%;

    @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_1}px) {
      font-size: 16px;
    }
  }

  .ant-btn {
    margin-inline-start: 0 !important;
  }

  .ant-modal-close {
    .ant-modal-close-x {
      color: var(--Gray-7);
      font-size: 22px;

      @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_1}px) {
        font-size: 18px;
      }

      @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
        font-size: 16px;
      }
    }
  }
`;

export const StyledModalFooterControlsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
    justify-content: center;
  }
`;

export const StyledModalFooterControls = styled.div`
  display: flex;
  gap: 16px;

  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_1}px) {
    gap: 10px;
  }

  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
    flex-direction: column-reverse;
    width: 100%;
  }
`;
