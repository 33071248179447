import React, { FC } from 'react';
import { useCarInfoContext } from '../../../../hooks/car';
import { Col, Row, Typography } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';
import { StyledSectionTitle } from '../shared/styled';
import { PriceHistory } from './PriceHistory/PriceHistory';
import { StyledTimeStampWithIcon } from './PriceHistory/StyledPriceHistory';
import dayjs from 'dayjs';
import { useMediaQuery } from 'react-responsive';
import { SCREEN_WIDTH } from '../../../../screenSettings';

const { Paragraph } = Typography;

export const PriceHistorySection: FC = () => {
  const isMobileWidth = useMediaQuery({
    query: `(max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_4}px)`,
  });
  const { carDetails } = useCarInfoContext();

  return (
    <>
      {carDetails && (
        <Row style={{ width: '100%' }}>
          <Col span={24}>
            <StyledSectionTitle
              style={
                isMobileWidth
                  ? { marginBottom: 8, fontSize: 14 }
                  : { marginBottom: 12 }
              }
            >
              История цены
              <StyledTimeStampWithIcon className='price-history__subtitle_mobile'>
                <ClockCircleOutlined
                  style={{
                    fontSize: isMobileWidth ? 14 : 16,
                    color: '#97a3b5',
                  }}
                />
                <Paragraph
                  style={{
                    fontSize: isMobileWidth ? 12 : 14,
                    fontWeight: 400,
                    marginBottom: 0,
                    fontFamily: 'Open Sans',
                    lineHeight: isMobileWidth ? '14px' : '20px',
                  }}
                  type='secondary'
                >
                  {`${dayjs(carDetails.createdAt).fromNow(true)} на рынке`}
                </Paragraph>
              </StyledTimeStampWithIcon>
            </StyledSectionTitle>

            <PriceHistory car={carDetails} />
          </Col>
        </Row>
      )}
    </>
  );
};
