import styled from 'styled-components';
import { SCREEN_WIDTH } from '../../../screenSettings';

export const StyledCarDetailsText = styled.p`
  color: var(--Dark-13);
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: -0.432px;

  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_4}px) {
    font-size: 12px;
  }
`;
