import React, { FC, useRef, useState, useEffect } from 'react';
import { DataForRangeDistributionType } from '../CostMileageAnalyticsSection.types';
import { getTotalDotPosition } from '../CostMileageAnalyticsSection.utils';
import { theme } from 'antd';
import type { TooltipPlacement } from 'antd/es/tooltip';
import './RangeSliderChartStyles.scss';
import { CORRECT_POSITION_POPOVER } from './RangeSliderChart.data';

import {
  StyledPopoverContent,
  StyledDot,
  StyledDotContainer,
  StyledTrack,
  StyledPopover,
} from './StyledRangeSliderChart';

const { useToken } = theme;

interface IAnalyticSection {
  dataForSliderChart?: DataForRangeDistributionType;
  description?: JSX.Element;
  dialogContent: {
    titleText: string;
    subtitleText: string;
  };
}

export const RangeSliderChart: FC<IAnalyticSection> = ({
  dataForSliderChart,
  description,
  dialogContent,
}) => {
  const refContainerPopover = useRef<HTMLDivElement>(null);
  const [dotPosition, setDotPosition] = useState(50);
  const { token } = useToken();
  let placement: TooltipPlacement = 'top';
  let classNameOverlayPopup = 'slider-range__popup_overlay';

  useEffect(() => {
    const parts = dataForSliderChart?.partsQuantity ?? 3;
    const sector = dataForSliderChart?.sector ?? 2;
    let relativePosition = dataForSliderChart?.dotPosition ?? 0;
    if (relativePosition > 96) relativePosition = 96;

    const absoluteDotPosition = getTotalDotPosition(
      parts,
      sector,
      relativePosition,
    );
    setDotPosition(absoluteDotPosition);
  }, [
    dataForSliderChart?.dotPosition,
    dataForSliderChart?.partsQuantity,
    dataForSliderChart?.sector,
  ]);

  if (dotPosition < CORRECT_POSITION_POPOVER.LEFT) {
    placement = 'topLeft';
    classNameOverlayPopup = `${classNameOverlayPopup} left_correct`;
  } else if (dotPosition > CORRECT_POSITION_POPOVER.RIGHT) {
    placement = 'topRight';
    classNameOverlayPopup = `${classNameOverlayPopup} right_correct`;
  }

  return (
    <>
      {dotPosition !== null && dotPosition !== undefined && (
        <>
          <StyledTrack
            ref={refContainerPopover}
            ranges={dataForSliderChart?.partsQuantity || 3}
          >
            <StyledDotContainer position={dotPosition}>
              <StyledPopover
                zIndex={10}
                overlayClassName={classNameOverlayPopup}
                align={{
                  overflow: { adjustX: false, adjustY: false },
                  offset: [0, -20],
                }}
                open={true}
                placement={placement}
                getPopupContainer={() =>
                  refContainerPopover.current as HTMLElement
                }
                content={
                  <StyledPopoverContent
                    titlecolor={token.colorText}
                    subtitlecolor={token.colorTextSecondary}
                  >
                    <p>{dialogContent.titleText}</p>
                    <p>{dialogContent.subtitleText}</p>
                  </StyledPopoverContent>
                }
              >
                <StyledDot />
              </StyledPopover>
            </StyledDotContainer>
          </StyledTrack>
          {description}
        </>
      )}
    </>
  );
};
