import styled from 'styled-components';
import { SCREEN_WIDTH } from '../../../../screenSettings';

export const StyledSubscriptionsList = styled.div`
  padding: var(--padding-profile-content-desktop);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET}px) {
    padding: var(--padding-title-content-mobile);
  }
`;
