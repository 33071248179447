import styled from 'styled-components';
import { SCREEN_WIDTH } from '../../../../screenSettings';

export const StyledAuthPageContainer = styled.div`
  padding: 56px 0;
  display: flex;
  justify-content: center;
  align-content: center;
  width: 100%;

  @media screen and (max-width: ${SCREEN_WIDTH.TABLET}px) {
    padding: 0;
  }
`;

export const StyledAuthPageContent = styled.div`
  width: 100%;
  max-width: 360px;

  @media screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
    max-width: 100%;
  }
`;
