import { SelectOptions } from '../enums';
import { regionIdsMapping } from './mappings';
import { IRegion } from './types';
import { Currency } from '../enums/currency';
import { SourceId } from '../enums/sourceId';

//TODO: remove this variable after createion 'country' property on backend
export const russiaCountryValue: number = 7;

//TODO: remove array after createion 'counrty' property on backend
export const russiaRegionId = [
  {
    value: 2,
    label: 'Москва и Московская область',
  },
  {
    value: 3,
    label: 'Санкт-Петербург и Ленинградская область',
  },
  {
    value: 4,
    label: 'Краснодар и Краснодарский Край',
  },
  {
    value: 5,
    label: 'Екатеринбург и Свердловская Область',
  },
  {
    value: 6,
    label: 'Ростов-на-Дону и Ростовская область',
  },
];

export const countries = [
  {
    value: 1,
    label: 'Беларусь',
  },
  // {
  //   value: russiaCountryValue,
  //   label: 'Россия',
  // },
  {
    value: 49,
    label: 'Германия',
  },
  {
    value: 31,
    label: 'Нидерланды',
  },
  {
    value: 32,
    label: 'Бельгия',
  },
  {
    value: 33,
    label: 'Франция',
  },
  // {
  //   value: 34,
  //   label: 'Испания',
  // },
  // {
  //   value: 39,
  //   label: 'Италия',
  // },
];

// TODO: need refoctor this file. Create mappings and generate data like this
export const regions = Object.entries(regionIdsMapping).map(
  ([value, label]) => ({ value, label }),
);

export const sourceIds: IRegion[] = [
  {
    value: SourceId.Belarus,
    label: 'Беларусь',
  },
  {
    value: SourceId.Russia,
    label: 'Россия',
  },
  {
    value: SourceId.Europe,
    label: 'Европа',
  },
];

export const currencies = [
  {
    label: 'USD',
    value: 'USD',
  },
  {
    label: 'EUR',
    value: 'EUR',
  },
  {
    label: 'RUB',
    value: 'RUB',
  },
];

export const turnkeyAutoCurrencies = currencies.filter(
  (elem) => elem.value !== Currency.RUB,
);

// temporarily until all currencies are fixed on the backend for the form
export const currencyCarPage: Array<{ value: string; label: string }> = [
  { value: 'USD', label: 'USD' },
  { value: 'RUB', label: 'RUB' },
  { value: 'EUR', label: 'EUR' },
];

type CurrencySymbols = {
  [key: string]: string;
};
export const currencySymbols: CurrencySymbols = {
  USD: '$',
  EUR: '€',
  RUB: '₽',
  BYN: 'BYN',
};

export const transmissions = [
  {
    value: 0,
    label: 'Не важно',
  },
  {
    value: 2,
    label: 'Автомат',
  },
  {
    value: 1,
    label: 'Механика',
  },
];

export const engineTypes = [
  {
    value: 1,
    label: 'Бензин',
  },
  {
    value: 2,
    label: 'Дизель',
  },
  {
    value: 3,
    label: 'Газ',
  },
  {
    value: 4,
    label: 'Бензин(пропан-бутан)',
  },
  {
    value: 5,
    label: 'Дизель(гибрид)',
  },
  {
    value: 6,
    label: 'Электро',
  },
  {
    value: 7,
    label: 'Другое',
  },
];

export enum engineTypesValues {
  Electro = 6,
}

export const engineLabelElectro = engineTypes.find(
  (elem) => elem.value === engineTypesValues.Electro,
)?.label;

export const bodyTypes = [
  {
    value: 1,
    label: 'Седан',
  },
  {
    value: 2,
    label: 'Купе',
  },
  {
    value: 3,
    label: 'Универсал',
  },
  {
    value: 4,
    label: 'Хетчбэк',
  },
  {
    value: 5,
    label: 'Кабриолет',
  },
  {
    value: 6,
    label: 'Минивен',
  },
  {
    value: 7,
    label: 'Пикап',
  },
  {
    value: 8,
    label: 'Лимузин',
  },
  {
    value: 9,
    label: 'Родстер',
  },
  {
    value: 10,
    label: 'Внедорожник',
  },
  {
    value: 11,
    label: 'Фургон',
  },
  {
    value: 12,
    label: 'Микроавтобус',
  },
  {
    value: 13,
    label: 'Другие',
  },
  {
    value: 14,
    label: 'Лифтбек',
  },
];

export const typesOfDrive = [
  {
    value: 1,
    label: 'Передний',
  },
  {
    value: 2,
    label: 'Задний',
  },
  {
    value: 3,
    label: 'Полный',
  },
];

export const sortingOfDate = [
  {
    value: 'UpdatedAtDesc',
    label: 'Актуальные',
  },
  {
    value: 'CreatedAtDesc',
    label: 'Новые объявления',
  },
  {
    value: 'MileageAsc',
    label: 'С наименьшим пробегом',
  },
  {
    value: 'MileageDesc',
    label: 'С наибольшим пробегом',
  },
  {
    value: 'ConvertedPriceAsc',
    label: 'По возрастанию цены',
  },
  {
    value: 'ConvertedPriceDesc',
    label: 'По убыванию цены',
  },
  {
    value: 'YearOfIssueDesc',
    label: 'По году: новее',
  },
  {
    value: 'YearOfIssueAsc',
    label: 'По году: старше',
  },
];

export const sortingOfPeriod = [
  {
    value: 1,
    label: 'За сутки',
  },
  {
    value: 3,
    label: 'За 3 дня',
  },
  {
    value: 5,
    label: 'За 5 дней',
  },
  {
    value: 7,
    label: 'За неделю',
  },
  {
    value: 10,
    label: 'За 10 дней',
  },
  {
    value: 30,
    label: 'За 30 дней',
  },
  {
    value: 60,
    label: 'За 60 дней',
  },
  {
    value: 90,
    label: 'За 90 дней',
  },
];

interface IEngineSize {
  value: number | string;
  label: string;
}

export const createEngineSizes = (): Array<IEngineSize> => {
  const engineSizes: Array<IEngineSize> = [
    { value: SelectOptions.any, label: 'Любой' },
  ];

  for (let i = 1; i < 10; i += 0.1) {
    engineSizes.push({
      value: +i.toFixed(1),
      label: i.toFixed(1),
    });
  }

  return engineSizes;
};

export const engineSizes = createEngineSizes();

interface IOption {
  value: number | string;
  label: string;
}

export const createYears = (): Array<IOption> => {
  const currentYear: number = new Date().getFullYear();
  const years: IOption[] = [{ value: SelectOptions.any, label: 'Любой' }];

  for (let i = currentYear; i >= 1970; --i) {
    years.push({ value: `${i}`, label: `${i}` });
  }

  return years;
};

export const years = createYears();

interface IMileage {
  value: string;
  label: string;
}

export const createMileages = (): Array<IMileage> => {
  const mileage: Array<IMileage> = [
    { value: SelectOptions.any, label: 'Любой' },
  ];

  for (let i = 10; i <= 300; i += 10) {
    mileage.push({ value: `${i * 1000}`, label: `до ${i} тыс.` });
  }

  for (let i = 350; i <= 500; i += 50) {
    mileage.push({ value: `${i * 1000}`, label: `до ${i} тыс.` });
  }

  return mileage;
};

export const mileages = createMileages();
