import { FC } from 'react';
import { ICarFeature } from '../../models';
import { StyledFeatureList } from './CarFeatureList.styled';
import { CarFeatureListItem } from '../CarFeatureListItem';

type CarFeatureListProps = {
  sortedByCategoryFeatures: ICarFeature[];
};

export const CarFeatureList: FC<CarFeatureListProps> = ({
  sortedByCategoryFeatures,
}) => (
  <StyledFeatureList>
    {sortedByCategoryFeatures
      .sort((item1, item2) => item2.featureOrder - item1.featureOrder)
      .map((feature, index) => (
        <CarFeatureListItem
          key={feature.featureName + index}
          featureName={feature.featureName}
        />
      ))}
  </StyledFeatureList>
);
