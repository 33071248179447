import { SourceId } from '../../enums/sourceId';

export const regionIdsMapping: { [key: string]: string } = {
  '1': 'Беларусь',
  '49': 'Германия',
  '31': 'Нидерланды',
  '32': 'Бельгия',
  '2': 'Москва и Московская область',
  '3': 'Санкт-Петербург и Ленинградская область',
  '4': 'Краснодар и Краснодарский Край',
  '5': 'Екатеринбург и Свердловская Область',
  '6': 'Ростов-на-Дону и Ростовская область',
  '33': 'Франция',
  '34': 'Испания',
  '39': 'Италия',
};

export const regionIdsMappingDeclined: { [key: string]: string } = {
  '1': 'в Беларуси',
  '49': 'в Германии',
  '31': 'в Нидерландах',
  '32': 'в Бельгии',
  '2': 'в Москве и Московской области',
  '3': 'в Санкт-Петербурге и Ленинградской области',
  '4': 'в Краснодаре и Краснодарском Крае',
  '5': 'в Екатеринбурге и Свердловской Области',
  '6': 'в Ростове-на-Дону и Ростовской области',
  '33': 'во Франции',
  '34': 'в Испании',
  '39': 'в Италии',
};

export const regionIdsMappingDeclinedFrom: { [key: string]: string } = {
  '1': 'из Беларуси',
  '49': 'из Германии',
  '31': 'из Нидерландов',
  '32': 'из Бельгии',
  '2': 'из Москвы и Московской области',
  '3': 'из Санкт-Петербурга и Ленинградской области',
  '4': 'из Краснодара и Краснодарского Края',
  '5': 'из Екатеринбурга и Свердловской Области',
  '6': 'из Ростова-на-Дону и Ростовской области',
  '33': 'из Франции',
  '34': 'из Испании',
  '39': 'из Италии',
};

export const recommendationRegionIdsMappingDeclined: { [key: string]: string } =
  {
    [SourceId.Belarus]: 'Беларуси',
    [SourceId.Europe]: 'Европе',
    [SourceId.Russia]: 'России',
  };

export const destinationRegionIdMapping: { [key: number]: string } = {
  1: 'Беларусь, Минск',
};
