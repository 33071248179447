import styled from 'styled-components';
import { Button } from 'antd';

export const StyledCollapseButton = styled(Button)`
  padding: 8px;
  border: 1px solid var(--Blue-7);
  color: var(--Blue-7);
  font-size: var(--main-font-size);
  font-style: var(--font-style-base);
  font-weight: var(--bold-font-weight);
  line-height: var(--desktop-content-line-height);
  letter-spacing: var(--desktop-letter-spacing);
  display: flex;
  align-items: center;
`;
