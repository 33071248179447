import { Rule } from 'antd/es/form';

export const validationRules: Record<string, Array<Rule>> = {
  password: [
    {
      required: true,
      message: 'Пожалуйста введите пароль',
    },
    {
      min: 8,
      message: 'Пароль должен содержать не менее 8 символов',
    },
  ],
};
