import React, { FC } from 'react';
import { useCarInfoContext } from '../../../../hooks/car';
import { Col, Row, Typography } from 'antd';
import { StyledSectionTitle } from '../shared/styled';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { CarClonesList } from './DublicatesList/CarClonesList';
import { useMediaQuery } from 'react-responsive';
import { SCREEN_WIDTH } from '../../../../screenSettings';

const { Paragraph } = Typography;

export const ClonesSection: FC = () => {
  const { isErrorCarClones, carClones, carDetails } = useCarInfoContext();

  const isMobileWidth = useMediaQuery({
    query: `(max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px`,
  });

  return (
    <Row style={{ width: '100%' }}>
      <Col span={24}>
        <StyledSectionTitle style={{ marginBottom: isMobileWidth ? 8 : 24 }}>
          Похожие объявления продавца
        </StyledSectionTitle>

        {!isErrorCarClones && !carClones?.clones?.length ? (
          <Paragraph
            style={{
              fontSize: isMobileWidth ? 12 : 16,
              marginBottom: 0,
              display: 'inline-flex',
            }}
            type='secondary'
          >
            <ExclamationCircleOutlined
              rev='true'
              style={{ fontSize: isMobileWidth ? 14 : 16, marginRight: 8 }}
            />
            <span>Дубликатов объявления не найдено</span>
          </Paragraph>
        ) : null}

        {!!carClones?.clones?.length && (
          <CarClonesList carClones={carClones} car={carDetails} />
        )}
      </Col>
    </Row>
  );
};
