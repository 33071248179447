import styled from 'styled-components';
import { Steps } from 'antd';
import { SCREEN_WIDTH } from '../../../../../screenSettings';

export const StyledStepsContainer = styled(Steps)`
  .ant-steps-item-title {
    width: 100%;
    padding-inline-end: 0;
    @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_4}px) {
      margin-top: 8px;
    }
    @media only screen and (max-width: ${SCREEN_WIDTH.MOBILE}px) {
      margin-top: 0;
    }
  }
  .ant-steps-item-content {
    border-bottom: 1px solid var(--Gray-3);
    margin-bottom: 5px;
  }
  .ant-steps-item:last-child .ant-steps-item-content {
    border-bottom: none;
    margin-bottom: 0;
  }
  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
    & .ant-steps-item-icon {
      margin-top: 4px !important;
    }
    & .ant-steps-item-tail {
      padding-top: 0 !important;
    }
  }
`;

export const StyledPriceHistoryContainer = styled.div`
  width: 100%;
`;

export const StyledActiveStep = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 100px;
  border: 2px solid #3f8bf3;
  background: #88c3ff;
`;

export const StyledStep = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 100px;
  border: 2px solid #3f8bf3;
  background: #fff;
`;

export const StyledStepRowContainer = styled.div<{ bordercolor: string; isLastElement: boolean }>`
  display: flex;
  padding-right: 0;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 5px;

  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
    border-bottom: none;
    margin-top: 5px;
  }

  & > span {
    display: block;
    width: 30%;
    &:first-child {
      text-align: start;
    }
    &:last-child {
      text-align: end;
    }
    &:not(:first-child):not(:last-child) {
      text-align: center;
    }
  }
`;

export const StyledPriceHistorySubtitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 32px;

  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_2}px) {
    margin-bottom: 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    & .price-history__general {
      display: block;
      text-align: left;
    }
  }
`;

export const StyledTimeStampWithIcon = styled.div`
  display: flex;
  align-items: center;

  justify-content: center;
  gap: 6px;
  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_1}px) {
    display: none;
  }
  &.price-history__subtitle_mobile {
    display: none;
    @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_1}px) {
      display: flex;
      align-self: center;
      align-items: center;
      justify-content: center;
      gap: 6px;
    }
  }
`;
