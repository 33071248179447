import { FC, memo, PropsWithChildren } from 'react';
import { StyledButton } from '../styled/StyledButton';
import { DeleteBasketCustomIcon } from '../../img/icons/components';
import { ButtonProps } from 'antd';

export const ResetButton: FC<PropsWithChildren<ButtonProps>> = memo(
  ({ children, ...buttonProps }) => (
    <StyledButton
      size='large'
      type='text'
      block
      icon={
        <DeleteBasketCustomIcon
          viewBox='0 0 25 24'
          width='25'
          height='24'
          style={{ fontSize: 24 }}
        />
      }
      {...buttonProps}
    >
      {children}
    </StyledButton>
  ),
);
