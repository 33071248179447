import styled from 'styled-components';
import { Divider } from 'antd';
import { SCREEN_WIDTH } from '../../../screenSettings';

export const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const StyledPriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(4px);
`;

export const StyledPriceDescription = styled.div`
  color: var(--Gray-7);
  font-size: var(--price-description-desktop);
  font-style: var(--font-style-base);
  font-weight: var(--main-font-weight);
  line-height: 117%;
  letter-spacing: var(--desktop-price-title-letter-spacing);
`;

export const StyledAntdDivider = styled(Divider)`
  margin: 0 24px;
  height: 40px;
`;

export const StyledPrice = styled.div<{
  smallsize: boolean | undefined;
}>`
  color: var(--Dark-13);
  font-size: ${({ smallsize }) =>
    smallsize ? 'var(--price-small-desktop)' : 'var(--price-big-desktop)'};
  font-style: var(--font-style-base);
  font-weight: var(--bold-font-weight);
  line-height: 116%;

  @media screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
    font-size: ${({ smallsize }) =>
      smallsize ? 'var(--price-small-mobile)' : 'var(--price-big-mobile)'};
  }
`;
