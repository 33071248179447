import { useEffect } from 'react';
import { NotificationWrapper } from './AppNotification.styled';
import { useActions } from '../../hooks/useActions';
import { config } from '../../config';
import { useAppGeneralSelector } from '../../store/selectors';
import { notification } from 'antd';

export const AppNotification = () => {
  const [notificationApi, contextHolder] = notification.useNotification();
  const { message, description, status } = useAppGeneralSelector();
  const { resetNotification } = useActions();

  useEffect(() => {
    if (!message) return;

    switch (status) {
      case 'error': {
        notificationApi.error({
          message,
          description,
          placement: config.DEFAULT_NOTIFICATION_PLACEMENT,
          duration: config.APP_LEVEL_NOTIFICATION_DURATION,
        });
        break;
      }
      case 'warning': {
        notificationApi.warning({
          message,
          description,
          placement: config.DEFAULT_NOTIFICATION_PLACEMENT,
          duration: config.APP_LEVEL_NOTIFICATION_DURATION,
        });
        break;
      }
      case 'success': {
        notificationApi.success({
          message,
          description,
          placement: config.DEFAULT_NOTIFICATION_PLACEMENT,
          duration: config.APP_LEVEL_NOTIFICATION_DURATION,
        });
        break;
      }
      default:
        break;
    }

    const closeAfterDelay = () => resetNotification();

    const timerId = setTimeout(
      closeAfterDelay,
      config.DEFAULT_NOTIFICATION_DURATION,
    );

    return () => {
      clearTimeout(timerId);
    };
  }, [description, message, status, notificationApi, resetNotification]);

  return <NotificationWrapper>{contextHolder}</NotificationWrapper>;
};
