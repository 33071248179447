import styled from 'styled-components';
import { SCREEN_WIDTH } from '../../screenSettings';

type StyledTitleProps = {
  smallersize?: boolean;
};

export const StyledTitleWithIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
`;

export const StyledIcon = styled.span`
  display: inline-block;
`;

export const StyledTitle = styled.span<StyledTitleProps>`
  display: inline-block;
  color: var(--Dark-13);
  font-style: var(--font-style-base);
  font-weight: var(--bold-font-weight);
  font-size: var(--bold-font-size);
  line-height: var(--desktop-content-line-height);
  letter-spacing: 0.08px;

  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_2}px) {
    font-size: ${({ smallersize }) => (smallersize ? '14px' : '16px')};
    letter-spacing: 0.08px;
  }
`;
