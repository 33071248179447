import { Form, Select, notification } from 'antd';
import { FC, useEffect } from 'react';
import { CustomSelect } from '../../../../components/FormElements';
import {
  FilterFormFieldNames,
  FilterFormLabel,
  FilterFormPlaceholder,
} from '../../../../enums/form';
import { FormItemLabel } from '../../../../components/FormItemLabel';
import { CaretDownOutlined } from '@ant-design/icons';
import { SelectOptionStyle } from '../../../../components/styled/SelectOption';
import {
  countries,
  russiaCountryValue,
  russiaRegionId,
  years,
} from '../../../../data';
import {
  FilterFormProps,
  FormState,
  StyledDesktopFilterControlsWrapper,
  StyledFilterFormColumn,
  StyledFormElementsWrapper,
  StyledSubmitButton,
} from '../FilterForms';
import { SelectOptions } from '../../../../enums';
import {
  carsFormActionsHooks,
  carsFormDisabledHooks,
  useGetCountCars,
  useGetModels,
} from '../../../../hooks/cars';
import { getCountText } from '../../../../utils/cars';
import {
  useHandleChange,
  useMakeChange,
  useWatchFilterForm,
} from '../../hooks';
import { useGetMakesQuery } from '../../../../store/cars/cars.api';
import { useCarsSelector } from '../../../../store/selectors';

export const DesktopFilterForm: FC<FilterFormProps> = ({ navigateToCars }) => {
  const [notificationApi, contextHolder] = notification.useNotification();
  const [desktopFilterForm] = Form.useForm<FormState>();
  const { country, regionId, make, model, yearFrom, yearTo } =
    useWatchFilterForm(desktopFilterForm);
  const { getCountCars, countCars, isFetchingCountCars } = useGetCountCars(
    notificationApi,
    {
      country,
      regionId,
      make,
      model,
      yearFrom,
      yearTo,
    },
  );

  const { isLoading: isFetchingMakes } = useGetMakesQuery();

  const { carMakes: makes } = useCarsSelector();

  const { getModels, models, isFetchingModels } = useGetModels(notificationApi);
  const handleMakeChange = useMakeChange(desktopFilterForm, getModels);
  const handleModelChange = useHandleChange(
    desktopFilterForm,
    FilterFormFieldNames.model,
  );
  const handleRegionIdChange = useHandleChange(
    desktopFilterForm,
    FilterFormFieldNames.regionId,
  );
  const handleYearFromChange = useHandleChange(
    desktopFilterForm,
    FilterFormFieldNames.yearFrom,
  );
  const handleToFromChange = useHandleChange(
    desktopFilterForm,
    FilterFormFieldNames.yearTo,
  );

  const isModelDisabled =
    make === SelectOptions.any ||
    !models?.length ||
    !make ||
    isFetchingMakes ||
    isFetchingModels;

  useEffect(() => {
    getCountCars();
  }, [country, regionId, yearFrom, yearTo, make, model]);

  return (
    <>
      {contextHolder}
      <Form
        form={desktopFilterForm}
        requiredMark={false}
        onFinish={navigateToCars}
        name='desktopFilterForm'
        layout='vertical'
        autoComplete='off'
      >
        <StyledFormElementsWrapper>
          <StyledFilterFormColumn>
            <CustomSelect
              formItemProps={{
                name: FilterFormFieldNames.country,
                label: <FormItemLabel>{FilterFormLabel.Country}</FormItemLabel>,
              }}
              onChange={(countries: number[] | undefined) =>
                carsFormActionsHooks[FilterFormFieldNames.country](
                  countries,
                  russiaCountryValue,
                  desktopFilterForm.setFieldsValue,
                )
              }
              mode='multiple'
              maxTagCount='responsive'
              suffixIcon={<CaretDownOutlined rev='true' />}
              placeholder={FilterFormPlaceholder.Country}
            >
              {countries.map((country) => (
                <Select.Option
                  key={country.value}
                  style={SelectOptionStyle}
                  value={country.value}
                >
                  {country.label}
                </Select.Option>
              ))}
            </CustomSelect>

            <CustomSelect
              formItemProps={{
                name: FilterFormFieldNames.make,
                label: <FormItemLabel>{FilterFormLabel.Make}</FormItemLabel>,
              }}
              suffixIcon={<CaretDownOutlined rev='true' />}
              placeholder={FilterFormPlaceholder.Make}
              onChange={handleMakeChange}
              disabled={!makes || isFetchingMakes}
            >
              {makes &&
                makes.map((make) => (
                  <Select.Option
                    key={make.id}
                    style={SelectOptionStyle}
                    value={make.id}
                  >
                    {make.viewName}
                  </Select.Option>
                ))}
            </CustomSelect>

            <CustomSelect
              formItemProps={{
                name: FilterFormFieldNames.yearFrom,
                label: (
                  <FormItemLabel>{FilterFormLabel.YearFrom}</FormItemLabel>
                ),
              }}
              onChange={handleYearFromChange}
              suffixIcon={<CaretDownOutlined rev='true' />}
              placeholder={FilterFormPlaceholder.YearFrom}
            >
              {years.map((year) => (
                <Select.Option
                  key={year.value}
                  style={SelectOptionStyle}
                  value={year.value}
                  disabled={yearTo && +year.value > +yearTo}
                >
                  {year.label}
                </Select.Option>
              ))}
            </CustomSelect>
          </StyledFilterFormColumn>
          <StyledFilterFormColumn>
            <CustomSelect
              formItemProps={{
                name: FilterFormFieldNames.regionId,
                label: <FormItemLabel>{FilterFormLabel.Region}</FormItemLabel>,
              }}
              onChange={handleRegionIdChange}
              maxTagCount='responsive'
              mode='multiple'
              suffixIcon={<CaretDownOutlined rev='true' />}
              placeholder={FilterFormPlaceholder.Region}
              disabled={carsFormDisabledHooks[FilterFormFieldNames.regionId](
                country,
                russiaCountryValue,
              )}
            >
              {russiaRegionId.map((regionId) => (
                <Select.Option
                  key={regionId.value}
                  style={SelectOptionStyle}
                  value={regionId.value}
                >
                  {regionId.label}
                </Select.Option>
              ))}
            </CustomSelect>

            <CustomSelect
              formItemProps={{
                name: FilterFormFieldNames.model,
                label: <FormItemLabel>{FilterFormLabel.Model}</FormItemLabel>,
              }}
              disabled={isModelDisabled}
              suffixIcon={<CaretDownOutlined rev='true' />}
              onChange={handleModelChange}
              placeholder={FilterFormPlaceholder.Model}
            >
              {models &&
                models.map((model) => (
                  <Select.Option
                    key={model.id}
                    style={SelectOptionStyle}
                    value={model.originalName}
                  >
                    {model.viewName}
                  </Select.Option>
                ))}
            </CustomSelect>

            <CustomSelect
              formItemProps={{
                name: FilterFormFieldNames.yearTo,
                label: <FormItemLabel>{FilterFormLabel.YearTo}</FormItemLabel>,
              }}
              onChange={handleToFromChange}
              suffixIcon={<CaretDownOutlined rev='true' />}
              placeholder={FilterFormPlaceholder.YearTo}
            >
              {years.map((year) => (
                <Select.Option
                  key={year.value}
                  style={SelectOptionStyle}
                  value={year.value}
                  disabled={yearFrom && +year.value < +yearFrom}
                >
                  {year.label}
                </Select.Option>
              ))}
            </CustomSelect>
          </StyledFilterFormColumn>
        </StyledFormElementsWrapper>

        <StyledDesktopFilterControlsWrapper>
          <StyledSubmitButton
            size='large'
            disabled={!countCars}
            loading={isFetchingCountCars}
            block
            type='primary'
            htmlType='submit'
          >
            {getCountText(isFetchingCountCars, countCars)}
          </StyledSubmitButton>
        </StyledDesktopFilterControlsWrapper>
      </Form>
    </>
  );
};
