import styled from 'styled-components';
import { SCREEN_WIDTH } from '../../screenSettings';

export const StyledBreadcrumbsWrapper = styled.div`
  margin: 16px 0;
  min-height: 22px;

  @media screen and (max-width: ${SCREEN_WIDTH.TABLET}px) {
    margin: 16px 0;
  }

  @media screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
    margin: 16px;
  }

  & .ant-breadcrumb {
    @media screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_4}px) {
      font-size: 12px;
      font-weight: 400;
      line-height: 116%;
      letter-spacing: 0.048px;
    }
  }
`;
