import { Checkbox, Form } from 'antd';
import styled from 'styled-components';
import { SCREEN_WIDTH } from '../../screenSettings';

export const StyledTitle = styled.p`
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.24px;
`;

export const StyledSubTitle = styled.p`
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: -0.192px;
`;

export const StyledBlock = styled.div`
  margin-bottom: 16px;
`;

export const StyledFormElementsWrapper = styled.div`
  max-width: 341px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
    max-width: 100%;
    width: 100%;
  }
`;

export const StyledSingleCheckbox = styled(Checkbox)`
  span {
    padding-inline-end: 0px;
  }
`;

export const StyledControlsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 48px;
`;

export const StyledControls = styled.div`
  max-width: 341px;
  display: flex;
  gap: 16px;

  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_1}px) {
    gap: 10px;
  }

  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
    flex-direction: column-reverse;
    max-width: 100%;
    width: 100%;
  }
`;
