import { FC, memo, useState } from 'react';
import { ICarData } from '../../models';
import { useMediaQuery } from 'react-responsive';
import { SCREEN_WIDTH } from '../../screenSettings';
import { StyledSubscriptionIcon } from './DeleteSubscriptionBlock.styles';
import { IconWithTooltip } from '../IconWithTooltip';
import { DeleteBasketCustomIcon } from '../../img/icons/components';
import { useModalSubscriptionContext } from '../../hooks/subscriptions';
import { SelectedCarByFilters } from '../../contexts';

type DeleteSubscriptionBlockProps = {
  item?: ICarData;
  filtersItem?: SelectedCarByFilters;
  changeColorOnHover?: boolean;
  forSubscriptionsByFilter?: boolean;
};

export const DeleteSubscriptionBlock: FC<DeleteSubscriptionBlockProps> = memo(
  ({ item, changeColorOnHover, forSubscriptionsByFilter, filtersItem }) => {
    const [isHovered, setIsHovered] = useState(false);
    const { updateSelectedCar, openModal, updateSelectedCarByFilter } =
      useModalSubscriptionContext();

    const isTabletWidth = useMediaQuery({
      query: `(max-width: ${SCREEN_WIDTH.TABLET}px`,
    });

    const clickHandler = () => {
      if (forSubscriptionsByFilter && filtersItem) {
        updateSelectedCarByFilter(filtersItem);
      }
      if (item) {
        updateSelectedCar(item);
      }
      openModal();
    };

    const handleMouseEnter = () => {
      if (changeColorOnHover && !isTabletWidth) setIsHovered(true);
    };

    const handleMouseLeave = () => {
      if (changeColorOnHover && !isTabletWidth) setIsHovered(false);
    };

    return (
      <StyledSubscriptionIcon
        correctposition={!!forSubscriptionsByFilter}
        onClick={clickHandler}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <IconWithTooltip
          description={'Удалить объявление'}
          customPopoverWidth={200}
          icon={
            <DeleteBasketCustomIcon
              style={{
                color:
                  isHovered || !changeColorOnHover || isTabletWidth
                    ? '#FF2929'
                    : '#97A3B5',
                fontSize: 24,
              }}
            />
          }
          customTrigger={isTabletWidth ? [] : ['hover']}
        />
      </StyledSubscriptionIcon>
    );
  },
);
