import styled from 'styled-components';
import { Tag } from 'antd';

export const StyledTagWithIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledTag = styled(Tag)`
  &.ant-tag {
    margin-inline-end: 0;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  height: 22px;
  font-size: 12px;
  & > span {
    display: inline-block;
  }
`;
