import React, { FC, CSSProperties } from 'react';
import { IRecommendation } from '../../../models';
import { ICar } from '../../../models/car';
import { Row } from 'antd';
import dayjs from 'dayjs';
import { StyledTitle, StyledParagraph } from './CarMetadata.styled';

interface ICarMetadata {
  car: ICar | IRecommendation;
  location: string;
  style?: CSSProperties;
  lessFontSize?: boolean;
}

export const CarMetadata: FC<ICarMetadata> = ({
  car,
  style,
  location,
  lessFontSize,
}) => {
  return (
    <Row style={{ alignItems: 'baseline',...style }}>
      <StyledTitle
        smallersize={lessFontSize ? lessFontSize.toString() : undefined}
      >
        {location},
      </StyledTitle>
      <StyledParagraph
        smallersize={lessFontSize ? lessFontSize.toString() : undefined}
        type='secondary'
      >
        {dayjs(car.createdAt).fromNow()}
      </StyledParagraph>
    </Row>
  );
};
