import styled from "styled-components";
import {Typography} from "antd";

interface IStyledLinkProps {
  colorlink?: string;
  coloractive?: string;
  colorhover?: string;
}

export const StyledLink = styled(Typography.Link)<IStyledLinkProps>`
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: ${(props) => props.colorlink || 'transparent'};
  padding: 4px;
  white-space: nowrap;
  
  &:hover {
    border-color: ${(props) => props.colorhover || 'transparent'};
  }

  &:active {
    border-color: ${(props) => props.coloractive || 'transparent'};
  }
`;