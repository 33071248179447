import { CSSProperties } from 'react';
import styled from 'styled-components';
import { UserOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import { AccountSettingsFormFieldsType } from './AccountInfoForm.types';
import { SCREEN_WIDTH } from '../../../../screenSettings';

export const fullWidthInputStyles: CSSProperties = {
  flexGrow: 1,
  flexShrink: 1,
  width: '100%',
};

export const StyledAvatarNameWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  gap: 32px;
  margin-bottom: 16px;
  @media screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_4}px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
  }
`;

export const StyledAvatarIcon = styled(UserOutlined)`
  font-size: 50px;
  color: var(--Blue-13);
`;

export const StyledAccountSettingsForm = styled(
  Form<AccountSettingsFormFieldsType>,
)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-account-form-blocks-desktop);
  padding: var(--padding-profile-content-desktop);
  width: 100%;
  max-width: 480px;

  @media screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
    padding: var(--padding-profile-content-mobile);
  }
`;

export const StyledAccountSettingsContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-account-form-items-desktop);
`;

export const StyledActionButtons = styled.div`
  margin-top: 16px;
  align-self: flex-end;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: var(--gap-action-buttons-desktop);
  @media screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
    width: 100%;
    flex-direction: column-reverse;
    justify-content: flex-start;
    margin-bottom: 24px;
  }
`;

export const StyledFilterCellSingle = styled.div`
  @media screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
    width: 100%;
  }
`;
