import { FC, useEffect, useState } from 'react';
import {
  getSliderPriceDistributionData,
  getSliderMileageDistributionData,
} from './CostMileageAnalyticsSection.utils';
import { useCarInfoContext } from '../../../../hooks/car';
import { IconWithTooltip } from '../../../../components/IconWithTooltip';
import { DataForRangeDistributionType } from './CostMileageAnalyticsSection.types';
import { IMileageDistribution } from '../../../../models/response/IMileageDistributionResponse';
import {
  useLazyGetModelPriceDistributionQuery,
  useLazyGetMileageDistributionQuery,
} from '../../../../store/car/car.api';
import { Col, Row, notification, Result, Spin } from 'antd';
import { RangeSliderChart } from './RangeSliderChart/RangeSliderChart';
import { formatNumber } from '../../../../utils/formatNumber';
import { currencySymbols } from '../../../../data';
import { initialSliderRangePositionProps } from './CostMileageAnalyticsSection.data';
import { PriceChart } from './PriceChart/PriceChart';

import {
  mileageAnalyticsData,
  mileageAnalyticsDataExtended,
  priceAnalyticsData,
  priceAnalyticsDataExtended,
} from './CostMileageAnalyticsSection.data';
import {
  StyledAdRatingSliderDescriptionContainer,
  StyledAdRatingSliderDescriptionTitle,
  StyledAdRatingSliderTextContainer,
  StyledDescriptionRatingContainer,
  StyledDivider,
} from './StyledCostMileageAnalyticsSection';
import {
  StyledSectionTitle,
  StyledTextBold,
  StyledTitle,
} from '../shared/styled';
import { engineTypes } from '../../../../data';
import { getPriceByCurrency } from '../../../../utils/getPriceByCurrency';
import { useMediaQuery } from 'react-responsive';
import { SCREEN_WIDTH } from '../../../../screenSettings';
import { config } from '../../../../config';
import { useProfileSelector } from '../../../../store/selectors';
import { findLabelByValue } from '../../../../utils/findLabelByValue';
import { StyledTooltipDescription } from '../../../../components/styled/StyledTooltipDescription';

export const CostMileageAnalyticsSection: FC = () => {
  const { carDetails, currentRegion, price, carModelMedianPricesByRegions } =
    useCarInfoContext();
  const { currency } = useProfileSelector();
  const [dataForSliderPriceDistribution, setDataForSliderPriceDistribution] =
    useState<DataForRangeDistributionType>(initialSliderRangePositionProps);
  const [
    dataForSliderMileageDistribution,
    setDataForSliderMileageDistribution,
  ] = useState<DataForRangeDistributionType>(initialSliderRangePositionProps);
  const isMobileWidth = useMediaQuery({
    query: `(max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_4}px`,
  });

  const isPriceDiagramShowed =
    process.env.REACT_APP_PRICES_DIAGRAM_FEATURE === 'true' &&
    carModelMedianPricesByRegions &&
    Array.isArray(carModelMedianPricesByRegions?.medianPricesByRegions) &&
    carModelMedianPricesByRegions.isCalculated;

  const dataPriceDescriptions =
    dataForSliderPriceDistribution.partsQuantity === 3
      ? priceAnalyticsData
      : priceAnalyticsDataExtended;
  const dataMileageDescriptions =
    dataForSliderMileageDistribution.partsQuantity === 3
      ? mileageAnalyticsData
      : mileageAnalyticsDataExtended;

  const [notificationApi, contextHolder] = notification.useNotification();

  const [
    fetchModelPriceDistribution,
    { isError: isPriceDistributionError, isLoading: isPricesLoading },
  ] = useLazyGetModelPriceDistributionQuery();

  const [
    fetchModelMileage,
    { isError: isMileageDistributionError, isLoading: isMilesLoading },
  ] = useLazyGetMileageDistributionQuery();

  const getPriceDistribution = async () => {
    if (!carDetails) return;

    try {
      const result = await fetchModelPriceDistribution({
        makeId: carDetails.makeId,
        modelId: carDetails.modelOriginalName,
        sourceId: carDetails.sourceId,
        yearOfIssue: carDetails.yearOfIssue,
        engineCapacity: carDetails.engineCapacity || undefined,
      });
      return result;
    } catch (err: any) {
      notificationApi.error({
        message: 'Ошибка получения ценового диапазона авто в регионе.',
        description:
          err?.data?.errors ||
          err?.errors ||
          'Не удалось получить ценовые диапазоны авто',
        duration: config.DEFAULT_NOTIFICATION_DURATION,
      });
    }
  };

  const getMileageDistribution = async () => {
    if (!carDetails) return;

    try {
      const result = await fetchModelMileage({
        makeId: carDetails.makeId,
        modelId: carDetails.modelOriginalName,
        yearOfIssue: carDetails.yearOfIssue,
        engineType:
          carDetails?.engineType && carDetails.engineType > 0
            ? `${carDetails.engineType}`
            : '1',
      });
      return result;
    } catch (err: any) {
      notificationApi.error({
        message: 'Ошибка получения диапазона пробега авто в регионе.',
        description:
          err?.data?.errors ||
          err?.errors ||
          'Не удалось получить диапазоны пробега авто',
        duration: config.DEFAULT_NOTIFICATION_DURATION,
      });
    }
  };

  const isMileageSectionShowed =
    !!carDetails?.mileage &&
    !!carDetails?.engineType &&
    carDetails.engineType > 0;

  useEffect(() => {
    if (carDetails?.prices) {
      getPriceDistribution().then((result) => {
        const sliderRangePriceDistributionData = getSliderPriceDistributionData(
          result?.data?.priceDistributions,
          getPriceByCurrency(carDetails?.prices, currency) ?? null,
          currency,
          result?.data?.isCalculated ?? false,
        );
        setDataForSliderPriceDistribution(sliderRangePriceDistributionData);
      });
    }
  }, [carDetails?.prices, currency]);

  useEffect(() => {
    if (!carDetails || !isMileageSectionShowed) return;

    getMileageDistribution().then((result) => {
      if (
        result &&
        result.data &&
        result.data.isCalculated &&
        result.data.mileageDistributions
      ) {
        const sliderMileageDistributionData = getSliderMileageDistributionData(
          result.data.isCalculated,
          carDetails.mileage,
          result.data.mileageDistributions as IMileageDistribution[],
        );
        setDataForSliderMileageDistribution(sliderMileageDistributionData);
      }
    });
  }, [carDetails]);

  return (
    <>
      {carDetails && (
        <Row style={{ width: '100%' }}>
          <Col span={24}>
            <StyledSectionTitle style={{ marginBottom: 8 }}>
              Аналитика{' '}
              {`${carDetails.makeViewName} ${carDetails.modelViewName} ${carDetails.yearOfIssue}`}
            </StyledSectionTitle>

            {!!price && (
              <>
                <StyledDescriptionRatingContainer>
                  <StyledTitle
                    className='analytics__section_title'
                    style={isMobileWidth ? { order: 2 } : { order: 1 }}
                  >
                    Оценка стоимости {' '}
                    <StyledTextBold>{currentRegion}</StyledTextBold>
                  </StyledTitle>
                  <IconWithTooltip
                    styles={isMobileWidth ? { order: 1 } : { order: 2 }}
                    description={
                      <StyledTooltipDescription>
                        Оценка стоимости автомобиля{' '}
                        <b>{`${carDetails.fullName} ${carDetails.yearOfIssue}, ${findLabelByValue(engineTypes, carDetails.engineType)?.toLowerCase()}, ${carDetails.engineCapacity}л.`}</b>{' '}
                        на основе объявлений за последние 2 месяца {' '}
                        <b>{currentRegion}</b>
                      </StyledTooltipDescription>
                    }
                  />
                </StyledDescriptionRatingContainer>

                {contextHolder}

                {!!isPriceDistributionError && (
                  <Result status='404' subTitle='Извините, произошла ошибка' />
                )}

                <Spin size='large' spinning={isPricesLoading}>
                  <RangeSliderChart
                    key='price-slider'
                    dataForSliderChart={dataForSliderPriceDistribution}
                    dialogContent={{
                      titleText: 'Cтоимость данного авто',
                      // @ts-ignore
                      subtitleText: `${formatNumber(getPriceByCurrency(carDetails.prices, currency))} ${
                        currencySymbols[currency as string]
                      }`,
                    }}
                    description={
                      <StyledAdRatingSliderDescriptionContainer key='price-slider-description'>
                        {dataPriceDescriptions.map((priceData) => (
                          <StyledAdRatingSliderTextContainer
                            ranges={dataPriceDescriptions.length}
                            key={priceData.title}
                          >
                            <StyledAdRatingSliderDescriptionTitle>
                              {priceData.title}
                            </StyledAdRatingSliderDescriptionTitle>
                          </StyledAdRatingSliderTextContainer>
                        ))}
                      </StyledAdRatingSliderDescriptionContainer>
                    }
                  />
                </Spin>
              </>
            )}

            <StyledDivider />
          </Col>
          {isMileageSectionShowed && (
            <>
              <Col span={24}>
                <StyledDescriptionRatingContainer>
                  <StyledTitle
                    className='analytics__section_title'
                    style={isMobileWidth ? { order: 2 } : { order: 1 }}
                  >
                    Оценка пробега {' '}
                    <StyledTextBold>{currentRegion}</StyledTextBold>
                  </StyledTitle>{' '}
                  <IconWithTooltip
                    styles={isMobileWidth ? { order: 1 } : { order: 2 }}
                    description={
                      <>
                        <StyledTooltipDescription>
                          Оценка пробега автомобиля{' '}
                          <b>{`${carDetails.fullName} ${carDetails.yearOfIssue}, ${findLabelByValue(engineTypes, carDetails.engineType)?.toLowerCase()}, ${carDetails.engineCapacity}л.`}</b>{' '}
                          на основе объявлений за последние 2 месяца {' '}
                          <b>{currentRegion}</b>
                        </StyledTooltipDescription>
                      </>
                    }
                  />
                </StyledDescriptionRatingContainer>
                {isMileageDistributionError && (
                  <Result status='404' subTitle='Извините, произошла ошибка' />
                )}
                <Spin size='large' spinning={isMilesLoading}>
                  <RangeSliderChart
                    key='mileage-slider'
                    dataForSliderChart={dataForSliderMileageDistribution}
                    dialogContent={{
                      titleText: 'Пробег данного авто',
                      subtitleText: `${formatNumber(carDetails.mileage)} км`,
                    }}
                    description={
                      <StyledAdRatingSliderDescriptionContainer key='mileage-slider-description'>
                        {dataMileageDescriptions.map((mileageData) => (
                          <StyledAdRatingSliderTextContainer
                            ranges={dataMileageDescriptions.length}
                            key={mileageData.title}
                          >
                            <StyledAdRatingSliderDescriptionTitle>
                              {mileageData.title}
                            </StyledAdRatingSliderDescriptionTitle>
                          </StyledAdRatingSliderTextContainer>
                        ))}
                      </StyledAdRatingSliderDescriptionContainer>
                    }
                  />
                </Spin>
              </Col>
              <StyledDivider />
            </>
          )}
          {isPriceDiagramShowed && (
            <Col span={24}>
              <div style={{ marginBottom: isMobileWidth ? 16 : 40 }}>
                <StyledTitle>{`График средних цен по регионам (${currencySymbols[currency]})`}</StyledTitle>
              </div>

              <PriceChart
                carModelMedianPricesByRegions={carModelMedianPricesByRegions}
              />
            </Col>
          )}
        </Row>
      )}
    </>
  );
};
