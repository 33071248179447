import { FC, useState, PropsWithChildren, useMemo } from 'react';
import { TurnKeyCarContext } from './TurnKeyCarContext';
import { IModel } from '../../models/response/cars';
import { useQueryState } from '../../hooks/useQueryState';
import { useCarsSelector } from '../../store/selectors';
import {
  turnKeySourceIDMappingDeclined,
  turnKeySourceIDOptions,
} from '../../data/sourceIdMapping';

export const TurnKeyCarContextProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [models, setModels] = useState<IModel[]>();
  const [queryParams] = useQueryState();
  const { carMakes: makes } = useCarsSelector();
  const handleSetModels = (models?: IModel[]) => setModels(models);

  const selectedModelViewName = models?.find(
    (model) => model.originalName === queryParams.model?.toUpperCase(),
  )?.viewName;

  const selectedMakeViewName = makes?.find(
    (make) => make.id === queryParams.make?.toUpperCase(),
  )?.viewName;

  const selectedSourceIdViewName =
    turnKeySourceIDMappingDeclined[
      queryParams.sourceRegionId || turnKeySourceIDOptions[0].value
    ];

  const getTitleText = () => {
    if (selectedMakeViewName && selectedModelViewName) {
      return `${selectedMakeViewName} ${selectedModelViewName} под ключ из ${selectedSourceIdViewName}`;
    } else if (selectedMakeViewName) {
      return `${selectedMakeViewName} под ключ из ${selectedSourceIdViewName}`;
    } else {
      return `Авто под ключ из ${selectedSourceIdViewName}`;
    }
  };

  const value = useMemo(
    () => ({
      models,
      selectedSourceIdViewName:
        turnKeySourceIDMappingDeclined[
          queryParams.sourceRegionId || turnKeySourceIDOptions[0].value
        ],
      titleText: getTitleText(),
      handleSetModels,
    }),
    [models, queryParams],
  );

  return (
    <TurnKeyCarContext.Provider value={value}>
      {children}
    </TurnKeyCarContext.Provider>
  );
};
