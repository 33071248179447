import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICar } from '../../models/car';
import { FilterSubscriptionsType } from '../../models/profile';
import { DeleteCarFromSubscriptionsRequest } from '../../models/response/subscriptionsCars';
import { ToggleSubscriptionState } from '../../enums/carSubscriptions';

type InitialState = {
  offerSubscriptions: ICar[];
  filterSubscriptions: FilterSubscriptionsType[];
  notificationsEnabled: boolean;
};

const initialState: InitialState = {
  offerSubscriptions: [],
  filterSubscriptions: [],
  notificationsEnabled: false,
};

export const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    setCarsBySubscription: (
      state,
      {
        payload: { notificationsEnabled, offerSubscriptions },
      }: PayloadAction<Omit<InitialState, 'filterSubscriptions'>>,
    ) => {
      state.offerSubscriptions = offerSubscriptions;
      state.notificationsEnabled = notificationsEnabled;
    },
    setCarsByFilters: (
      state,
      {
        payload: { notificationsEnabled, filterSubscriptions },
      }: PayloadAction<Omit<InitialState, 'offerSubscriptions'>>,
    ) => {
      state.filterSubscriptions = filterSubscriptions;
      state.notificationsEnabled = notificationsEnabled;
    },
    deleteCarFromSubscriptions: (
      state,
      {
        payload: { adId, sourceId },
      }: PayloadAction<DeleteCarFromSubscriptionsRequest>,
    ) => {
      const filteredCars = state.offerSubscriptions.filter(
        (elem) => elem.adId + elem.sourceId !== adId + sourceId,
      );
      state.offerSubscriptions = filteredCars;
    },
    deleteCarFromFilters: (state, { payload }: PayloadAction<string>) => {
      const filteredCars = state.filterSubscriptions.filter(
        (elem) => elem.hash !== payload,
      );
      state.filterSubscriptions = filteredCars;
    },
    toggleNotification: (
      state,
      { payload }: PayloadAction<ToggleSubscriptionState>,
    ) => {
      if (payload === ToggleSubscriptionState.Enable) {
        state.notificationsEnabled = true;
      } else {
        state.notificationsEnabled = false;
      }
    },
    toggleACtiveSubscriptionByFilter: (
      state,
      {
        payload: { hash, isActive },
      }: PayloadAction<{ isActive: boolean; hash: string }>,
    ) => {
      const modifiedCars = state.filterSubscriptions.map((car) => {
        if (car.hash === hash) {
          car.isActive = isActive;
        }
        return car;
      });
      state.filterSubscriptions = modifiedCars;
    },
  },
});

export const subscriptionActions = subscriptionSlice.actions;
export const subscriptionReducer = subscriptionSlice.reducer;
