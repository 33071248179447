import styled from 'styled-components';
import { Popover } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { SCREEN_WIDTH } from '../../screenSettings';

export const StyledIconWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover,
  &:focus {
    cursor: pointer;
  }
`;

export const StyledPopoverOnIcon = styled(Popover)`
  &.ant-popover.popover__icon {
    border-radius: 2px;
    background: var(--White);

    .ant-popover-inner-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const StyledAntdInfoCircleOutlined = styled(InfoCircleOutlined)`
  font-size: 16px;
  color: var(--Gray-6);
  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_4}px) {
    font-size: 14px;
    margin-top: 2px;
  }
`;
