import { FC, useEffect } from 'react';
import qs from 'qs';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  useConfirmEmailMutation,
  useResendConfirmEmailMutation,
} from '../../../store/auth/auth.api';
import { useAppContext } from '../../../hooks/app';
import { IConfirmEmailParams } from '../../../models/auth';
import { APP_PATHS, NAVIGATE_PATHS } from '../../../layout/settingsLayout';
import { ErrorMachineName } from '../enums';
import { useAuthErrorNotification } from '../hooks';
import {
  AUTH_ERROR_MESSAGES,
  AUTH_SUCCESS_MESSAGES,
  AUTH_WARNING_MESSAGES,
} from '../data';
import { config } from '../../../config';
import { commonMetadata } from '../../../seo';
import { Metadata } from '../../../components/Metadata';

export const ConfirmEmail: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { apiNotification } = useAppContext();
  const metaData = commonMetadata[APP_PATHS.CONFIRM_EMAIL];

  const authErrorNotification = useAuthErrorNotification();
  const parsedQueryString = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const [confirmEmail] = useConfirmEmailMutation();
  const [resendConfirmEmail] = useResendConfirmEmailMutation();

  const handleSubmit = async (confirmEmailParams: IConfirmEmailParams) => {
    try {
      await confirmEmail(confirmEmailParams).unwrap();

      apiNotification.success({
        message: AUTH_SUCCESS_MESSAGES.EMAIL_CONFIRMATION,
        duration: config.DEFAULT_NOTIFICATION_DURATION,
      });

      navigate(NAVIGATE_PATHS.SIGN_IN);
    } catch (error: any) {
      if (error?.data?.message !== ErrorMachineName.ConfirmationCodeExpired) {
        apiNotification.error({
          message: AUTH_ERROR_MESSAGES.SUPPORT,
          duration: config.DEFAULT_NOTIFICATION_DURATION,
        });

        return;
      }

      try {
        await resendConfirmEmail(confirmEmailParams.userId).unwrap();

        apiNotification.warning({
          message: AUTH_WARNING_MESSAGES.EMAIL_CONFIRMATION_RESEND_LINK_SENT,
          duration: config.DEFAULT_NOTIFICATION_DURATION,
        });
      } catch (resendErr: any) {
        authErrorNotification(AUTH_ERROR_MESSAGES.EMAIL_CONFIRMATION, error);
      }
    }
  };

  useEffect(() => {
    const confirmEmail = async () => {
      const { userId, code } = parsedQueryString;

      if (!userId || !code) {
        apiNotification.error({
          message: AUTH_ERROR_MESSAGES.EMAIL_CONFIRMATION,
          description: 'Отсутствует userId или code',
          duration: config.DEFAULT_NOTIFICATION_DURATION,
        });

        return;
      }

      await handleSubmit({ userId, code });
    };

    confirmEmail();
  }, []);

  return (
    <Metadata title={metaData.title} description={metaData.description} />
  );
};
