import { Typography } from 'antd';
import styled from 'styled-components';
import { SCREEN_WIDTH } from '../../screenSettings';

export const StyledTitle = styled(Typography.Title)`
  margin: 0;
  line-height: 24px;
  font-size: 20px !important;

  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET}px) {
    font-size: 16px !important;
    line-height: 20px;
  }
`;
