import { useLazyGetCarModelMedianPricesByRegionsQuery } from '../../store/car/car.api';
import { notification } from 'antd';
import { config } from '../../config';
import { IMedianPricesByRegionRequest } from '../../models';

export const useGetCarModelMedianPricesByRegions = () => {
  const [notificationApi, contextHolder] = notification.useNotification();

  const [
    fetchCarModelMedianPricesByRegions,
    {
      isError: isErrorCarModelMedianPricesByRegions,
      data: carModelMedianPricesByRegions,
      isFetching: isFetchingCarModelMedianPricesByRegions,
    },
  ] = useLazyGetCarModelMedianPricesByRegionsQuery();

  const getCarModelMedianPricesByRegions = async (
    params: IMedianPricesByRegionRequest,
  ): Promise<void> => {
    try {
      await fetchCarModelMedianPricesByRegions(params);
    } catch (err: any) {
      if (process.env.NODE_ENV === 'development') {
        console.log(err);
      }
      notificationApi.error({
        message: 'Ошибка загрузки аналитических данных',
        description:
          err?.data?.message ||
          'Не удалось загрузить данные для построения графика',
        duration: config.DEFAULT_NOTIFICATION_DURATION,
      });
    }
  };

  return {
    getCarModelMedianPricesByRegions,
    carModelMedianPricesByRegions,
    isErrorCarModelMedianPricesByRegions,
    isFetchingCarModelMedianPricesByRegions,
    contextHolder,
  };
};
