import { IconWithTooltip } from '../../../../components/IconWithTooltip';
import { StyledTitleWrapper, StyledTitleText } from './TurnKeyTitle.styled';
import { useMediaQuery } from 'react-responsive';
import { SCREEN_WIDTH } from '../../../../screenSettings';
import { useTurnKeyCarContext } from '../../../../hooks/turnKeyCar';

export const TurnKeyTitle = () => {
  const { titleText } = useTurnKeyCarContext();
  const isTabletWidth = useMediaQuery({
    query: `(max-width: ${SCREEN_WIDTH.TABLET}px)`,
  });

  return (
    <StyledTitleWrapper>
      <StyledTitleText>{titleText}</StyledTitleText>
      <IconWithTooltip
        description='Заполните форму для доставки авто "под ключ"!'
        customPopoverWidth={230}
        customTrigger={isTabletWidth ? ['click'] : ['hover']}
        placement='topLeft'
      />
    </StyledTitleWrapper>
  );
};
