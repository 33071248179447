import styled from 'styled-components';
import { SCREEN_WIDTH } from '../../../../screenSettings';

export const StyledFeaturesDescription = styled.section`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 24px;
  align-items: start;
  @media only screen and (max-width: ${SCREEN_WIDTH.DESKTOP}px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;
