import styled from 'styled-components';
import { Drawer } from 'antd';
import { SCREEN_WIDTH } from '../../screenSettings';

export const StyledOptionsDrawer = styled(Drawer)`
  &.ant-drawer-content {
    height: 700px;
    border-bottom-left-radius: 8px;
    box-shadow: var(--user-options-drawer-shadow);
    @media screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
      border-bottom-left-radius: 0;
      height: 100vh;
    }
  }

  .ant-drawer-wrapper-body {
    padding: 24px 16px;
    .ant-drawer-header {
      border-bottom: none;
      padding: 0;
      padding-bottom: 24px;
    }
    .ant-drawer-header-title {
      justify-content: flex-end;
    }
    .ant-drawer-close {
      display: inline-block;
      border-radius: 50%;
      color: var(--Gray-7);
      font-size: 22px;
      padding: 4px;
      margin-right: 0;
      transition: all ease-in 0.2s;
      &:hover,
      &:active,
      &:target {
        transform: scale(1.02);
        background-color: var(--Gray-3);
      }
    }
    .ant-drawer-body {
      padding: 0;
    }
  }
`;
