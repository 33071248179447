import { SliderSingleProps } from 'antd';
import { getCurrencySymbol } from '../../../../../utils/getCurrencySymbol';
import { PRICE_LIMIT_BUDGET } from '../../../../../constants';
import { FormFieldName } from '../../../../../enums/form';
import { turnKeySourceIDOptions } from '../../../../../data/sourceIdMapping';

export const getMarksSlider: (
  currency: string,
) => SliderSingleProps['marks'] = (currency) => {
  return {
    0: (
      <span
        style={{ color: '#192A4D' }}
      >{`0\u00A0${getCurrencySymbol(currency)}`}</span>
    ),
    [PRICE_LIMIT_BUDGET]: `${PRICE_LIMIT_BUDGET.toLocaleString('en-US')}\u00A0${getCurrencySymbol(currency)}`,
  };
};

export const generateFormFields = (queryParams: any) => {
  return [
    {
      name: [FormFieldName.SourceRegionId],
      value: queryParams?.sourceRegionId || turnKeySourceIDOptions[0].value,
    },
    {
      name: [FormFieldName.Make],
      value: queryParams?.make?.toUpperCase(),
    },
    {
      name: [FormFieldName.Model],
      value: queryParams?.model?.toUpperCase(),
    },
  ];
};
