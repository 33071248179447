import { FilterFormFieldNames, SortFormFieldNames } from '../enums/form';
import {
  IInitialValuesFilterForm,
  IInitialValuesSortForm,
} from '../pages/CarsPage/components/CarsList/CarList.interface';

export const initialValuesFilterForm: IInitialValuesFilterForm = {
  [FilterFormFieldNames.currency]: 'USD',
};

export const initialValuesSortForm: IInitialValuesSortForm = {
  [SortFormFieldNames.from]:
    process.env.REACT_APP_SORT_FORM_DEFAULT_FROM_DAYS || '10',
};

export const PRICE_LIMIT_BUDGET = 200000;

export const NON_IMPORTANT_TYPE_VALUE = 999;
