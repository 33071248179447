import { FC, ReactElement } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useIsUserAuth } from '../../hooks/auth/useIsUserAuth';
import { APP_PATHS } from '../../layout/settingsLayout';

type UserAlreadyAuthPropsType = {
  destination: ReactElement<any, any> | null;
};

export const UserAlreadyAuth: FC<UserAlreadyAuthPropsType> = ({
  destination,
}) => {
  const isUserAuth = useIsUserAuth();
  const location = useLocation();

  return !isUserAuth ? (
    destination
  ) : (
    <Navigate
      to={APP_PATHS.CARS_PAGE}
      state={{ from: location }}
      replace={true}
    />
  );
};
