import { ReactNode, FC, PropsWithChildren } from 'react';
import {
  StyledBlockWrapper,
  StyledDescription,
  StyledTitle,
  StyledInsideContent,
} from './CallToActionBlock.styled';

type CallToActionSectionPropsType = {
  title: ReactNode;
  description?: ReactNode;
};

export const CallToActionBlock: FC<
  PropsWithChildren<CallToActionSectionPropsType>
> = ({ title, description, children }) => {
  return (
    <StyledBlockWrapper>
      <StyledInsideContent>
        <StyledTitle>{title}</StyledTitle>
        {description && <StyledDescription>{description}</StyledDescription>}
        {children}
      </StyledInsideContent>
    </StyledBlockWrapper>
  );
};
