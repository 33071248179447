import { CSSProperties, ReactElement } from 'react';
import { ICar } from '../../../models/car';
import { CarDetailsKeys } from '../../../enums/carDetails';
import { IconWithTooltip } from '../../IconWithTooltip';
import { engineLabelElectro } from '../../../data';
import {
  CarBodyColorIcon,
  CarBodyTypeIcon,
  HorsePowerIcon,
  MileageIcon,
  YearOfIssueIcon,
  TransmissionSwitcherIcon,
  GasolineIcon,
  OwnersIcon,
} from '../../../img/icons/components';
import { findLabelByValue } from '../../../utils/findLabelByValue';
import { formatNumber } from '../../../utils/formatNumber';
import { bodyTypes, transmissions, engineTypes } from '../../../data';

const lightColorIcon: CSSProperties = {
  color: '#717C8C',
};

export type DetailWithIconType = {
  description: string | number;
  icon: ReactElement;
  id: string;
};

export const getDetailsWithIconForRender = (
  car: ICar,
): DetailWithIconType[] => {
  const result: DetailWithIconType[] = [];
  const engineDescription = { gasoline: '', capacity: '' };
  let engineDescriptionString = '';

  for (let key in car) {
    switch (key) {
      case CarDetailsKeys.bodyColor:
        result.push({
          description: car[key]?.toLowerCase() ?? '',
          icon: <CarBodyColorIcon style={lightColorIcon} />,
          id: CarDetailsKeys.bodyColor,
        });
        break;

      case CarDetailsKeys.bodyType:
        result.push({
          description:
            findLabelByValue(bodyTypes, car[key])?.toLowerCase() ?? '',
          icon: <CarBodyTypeIcon style={lightColorIcon} />,
          id: CarDetailsKeys.bodyType,
        });
        break;

      case CarDetailsKeys.enginePowerInHp:
        result.push({
          description:
            car[key] && typeof car[key] === 'number' && car[key]! > 0
              ? `${car[key]}\u00A0л.с.`
              : '',
          icon: <HorsePowerIcon style={lightColorIcon} />,
          id: CarDetailsKeys.enginePowerInHp,
        });
        break;

      case CarDetailsKeys.mileage:
        result.push({
          description: car[key] ? `${formatNumber(car[key])}\u00A0км` : '',
          icon: <MileageIcon style={lightColorIcon} />,
          id: CarDetailsKeys.mileage,
        });
        break;

      case CarDetailsKeys.yearOfIssue:
        result.push({
          description:
            car[key] < 0
              ? new Date().getFullYear() + '\u00A0г.'
              : car[key] + '\u00A0г.',
          icon: <YearOfIssueIcon style={lightColorIcon} />,
          id: CarDetailsKeys.yearOfIssue,
        });
        break;

      case CarDetailsKeys.transmissionType:
        result.push({
          description:
            findLabelByValue(transmissions, car[key])?.toLowerCase() ?? '',
          icon: <TransmissionSwitcherIcon style={lightColorIcon} />,
          id: CarDetailsKeys.transmissionType,
        });
        break;

      case CarDetailsKeys.previousOwnersNumber: {
        result.push({
          description: car[key] ?? '',
          icon: (
            <IconWithTooltip
              description='Количество предыдущих владельцев'
              icon={<OwnersIcon style={lightColorIcon} />}
              customPopoverWidth={300}
            />
          ),
          id: CarDetailsKeys.previousOwnersNumber,
        });
        break;
      }

      case CarDetailsKeys.engineCapacity:
        engineDescription.capacity = car[key] > 0 ? `${car[key]}\u00A0л.` : '';
        break;

      case CarDetailsKeys.engineType:
        engineDescription.gasoline = car[key]
          ? `${findLabelByValue(engineTypes, car[key])}`
          : '';
        break;

      default:
        break;
    }
  }

  if (engineDescription.gasoline) {
    engineDescriptionString =
      engineDescription.gasoline === engineLabelElectro
        ? `${engineDescription.gasoline.toLowerCase()}`
        : `${engineDescription.gasoline.toLowerCase()},`;
  }
  if (engineDescription.capacity) {
    engineDescriptionString += `\u00A0${engineDescription.capacity}`;
  }

  if (engineDescriptionString) {
    result.push({
      description: engineDescriptionString,
      icon: <GasolineIcon style={lightColorIcon} />,
      id: CarDetailsKeys.engineType,
    });
  }

  return result;
};
