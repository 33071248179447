import { Drawer, Space, Typography } from 'antd';
import React, { ReactNode } from 'react';
import { useMediaQuery } from 'react-responsive';
import { SCREEN_WIDTH } from '../../../../screenSettings';
import { StyledButton } from '../../../../components/styled/StyledButton';

interface IDrawer {
  open: boolean;
  onClose: () => void;
  children: ReactNode;
  title: string;
}

export const FormItemDrawer: React.FC<IDrawer> = ({
  open,
  onClose,
  children,
  title,
}) => {
  const isMobileWidth = useMediaQuery({
    query: `(max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px`,
  });

  return (
    <Drawer
      style={{ borderTopLeftRadius: 15, borderTopRightRadius: 15 }}
      height='95%'
      styles={{
        header: {
          padding: isMobileWidth ? '16px' : '8px 16px',
        },
        body: {
          padding: 0,
        },
      }}
      title={
        <Space style={{ justifyContent: 'space-between', width: '100%' }}>
          <Typography.Title level={2} style={{ marginBottom: 0 }}>
            {title}
          </Typography.Title>
          <StyledButton type='primary' onClick={onClose} size='large'>
            Закрыть
          </StyledButton>
        </Space>
      }
      placement='bottom'
      onClose={onClose}
      open={open}
      closable={false}
    >
      {children}
    </Drawer>
  );
};
