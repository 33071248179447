import { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import { SCREEN_WIDTH } from '../../../screenSettings';
import { StyledPageLayout } from './CalculateDelivery.styled';
import { Section } from '../../../components/Section';
import { StyledTitle } from '../../../components/styled/StyledTitle';
import { CalculateDelivery } from '../Components/CalculateDelivery';
import { DesktopCalculateDeliveryForm } from '../Components/DesktopCalculateDeliveryForm';

interface CalculateDeliveryPageProps {
  onlyCarInspection: boolean;
}

export const CalculateDeliveryPage: FC<CalculateDeliveryPageProps> = ({
  onlyCarInspection,
}) => {
  const isTablet = useMediaQuery({
    query: `(max-width: ${SCREEN_WIDTH.TABLET}px)`,
  });

  return (
    <StyledPageLayout>
      {!isTablet && (
        <Section
          style={{ width: 340 }}
          headerStyle={{ padding: '24px 16px' }}
          bodyStyle={{ padding: '24px 16px' }}
          headerContent={
            <StyledTitle level={2}>Параметры рассчета</StyledTitle>
          }
        >
          <DesktopCalculateDeliveryForm onlyCarInspection={onlyCarInspection} />
        </Section>
      )}

      <CalculateDelivery onlyCarInspection={onlyCarInspection} />
    </StyledPageLayout>
  );
};
