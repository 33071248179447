type ReplaceCharactersToStar = (string: string, count: number) => string;

export const replaceCharactersToStar: ReplaceCharactersToStar = (
  str,
  count,
) => {
  if (str.length <= count) {
    return str;
  } else if (count === 0) {
    return str;
  } else {
    return str.slice(0, -count) + '*'.repeat(count);
  }
};
