import { FC, memo } from 'react';
import { useCarPageSelector } from '../../../../store/selectors';
import {
  DeliveryBlock,
  StyledKeyBlock,
  StyledPricesBlockRow,
  StyledVerticalDivider,
  TextKey,
  TextKeyBold,
  TextValueBold,
  TitleRow,
} from './CalculateDeliverySection.styled';
import { StyledTitle } from '../../../../components/styled/StyledTitle';
import { IconWithTooltip } from '../../../../components/IconWithTooltip';
import { FeatureTag } from '../../../../components/Car';
import { formatNumber } from '../../../../utils/formatNumber';
import { getCurrencySymbol } from '../../../../utils/getCurrencySymbol';
import {
  destinationRegionIdMapping,
  regionIdsMapping,
} from '../../../../data/mappings';
import { CompanyCostDetails } from '../../../../models/car/CalculateDelivery';
import { Company } from '../../../../models/response/companies';
import { CarPageOutletContext } from '../../CarPage.interface';
import { useOutletContext } from 'react-router-dom';
import { StyledHighlitedText } from '../../../../components/styled/StyledHighlitedText';
import { CarCalculationReportRow } from '../CarCalculationReportRow';

interface CompanyServicesBlockProps {
  shipmentPathDescription: string | null;
  companyDetails: CompanyCostDetails;
  selectedCompany: Company | undefined;
  onlyCarInspection: boolean;
}

export const CompanyServicesBlock: FC<CompanyServicesBlockProps> = memo(
  ({
    companyDetails,
    selectedCompany,
    shipmentPathDescription,
    onlyCarInspection,
  }) => {
    const { calculateDeliveryForm } = useCarPageSelector();
    const { car } = useOutletContext<CarPageOutletContext>();

    return (
      <DeliveryBlock>
        <TitleRow>
          <StyledTitle level={3}>Услуги {selectedCompany?.name}</StyledTitle>
          <IconWithTooltip
            description={
              <>
                Комплекс услуг по подбору, осмотру и доставке автомобиля из
                Европы в выбранный город по специальной цене от AutoAggregator
              </>
            }
          />
        </TitleRow>

        {!onlyCarInspection && (
          <>
            <FeatureTag
              style={{ flexShrink: 0, flexGrow: 0, alignSelf: 'flex-start' }}
              feature='Стоимость через AutoAggregator'
            />
            <StyledPricesBlockRow>
              <StyledKeyBlock>
                <TextKey>Подбор авто</TextKey>
              </StyledKeyBlock>
              <TextValueBold>
                <span
                  style={{
                    display: 'inline-block',
                    margin: '0 8px 0 0',
                    textDecoration:
                      companyDetails.companyServicesCostWithDiscountOriginal
                        ? 'line-through'
                        : undefined,
                  }}
                >
                  {formatNumber(
                    companyDetails.companyServicesCostOriginal.amount,
                  )}{' '}
                  {getCurrencySymbol(
                    companyDetails.companyServicesCostOriginal.currency,
                  )}
                </span>

                {companyDetails.companyServicesCostWithDiscountOriginal && (
                  <>
                    <StyledHighlitedText>
                      {formatNumber(
                        companyDetails.companyServicesCostWithDiscountOriginal
                          .amount,
                      )}{' '}
                      {getCurrencySymbol(
                        companyDetails.companyServicesCostWithDiscountOriginal
                          .currency,
                      )}
                    </StyledHighlitedText>
                    <StyledVerticalDivider type='vertical' />
                  </>
                )}

                <span>
                  ~{' '}
                  {formatNumber(
                    companyDetails.companyServicesCostConverted.amount,
                  )}{' '}
                  {getCurrencySymbol(
                    companyDetails.companyServicesCostConverted.currency,
                  )}
                </span>
              </TextValueBold>
            </StyledPricesBlockRow>
          </>
        )}

        {!onlyCarInspection && calculateDeliveryForm.carInspectionRequired && (
          <CarCalculationReportRow
            title='Разовый осмотр авто'
            costOriginal={companyDetails.carInspectionCostOriginal}
            costConverted={companyDetails.carInspectionCostConverted}
          />
        )}

        {onlyCarInspection && (
          <CarCalculationReportRow
            title='Разовый осмотр авто'
            costOriginal={companyDetails.carInspectionCostOriginal}
            costConverted={companyDetails.carInspectionCostConverted}
          />
        )}

        {!onlyCarInspection && (
          <>
            <StyledPricesBlockRow>
              <TextKey>
                {shipmentPathDescription ? (
                  shipmentPathDescription
                ) : (
                  <>
                    Доставка из {regionIdsMapping[car.regionId]} в{' '}
                    {calculateDeliveryForm.destinationRegionId &&
                      destinationRegionIdMapping[
                        calculateDeliveryForm.destinationRegionId
                      ]}
                  </>
                )}
              </TextKey>
              <TextValueBold>
                {formatNumber(
                  companyDetails.deliveryServiceCostConverted.amount,
                )}{' '}
                {getCurrencySymbol(
                  companyDetails.deliveryServiceCostConverted.currency,
                )}
              </TextValueBold>
            </StyledPricesBlockRow>

            {companyDetails.euPartnerCommissionFeeConverted && (
              <StyledPricesBlockRow>
                <TextKey>Комиссия Европейскому партнеру за выкуп авто</TextKey>
                <TextValueBold>
                  {formatNumber(
                    companyDetails.euPartnerCommissionFeeConverted.amount,
                  )}{' '}
                  {getCurrencySymbol(
                    companyDetails.euPartnerCommissionFeeConverted.currency,
                  )}
                </TextValueBold>
              </StyledPricesBlockRow>
            )}

            {companyDetails.bankCommissionFeeConverted && (
              <StyledPricesBlockRow>
                <TextKey>Комиссия Банку за перевод средств</TextKey>
                <TextValueBold>
                  {formatNumber(
                    companyDetails.bankCommissionFeeConverted.amount,
                  )}{' '}
                  {getCurrencySymbol(
                    companyDetails.bankCommissionFeeConverted.currency,
                  )}
                </TextValueBold>
              </StyledPricesBlockRow>
            )}

            <StyledPricesBlockRow>
              <TextKeyBold>ИТОГО</TextKeyBold>
              {calculateDeliveryForm.carInspectionRequired ? (
                <TextValueBold>
                  ~{' '}
                  {formatNumber(
                    companyDetails.totalCostConvertedWithCarInspection.amount,
                  )}{' '}
                  {getCurrencySymbol(
                    companyDetails.totalCostConvertedWithCarInspection.currency,
                  )}
                </TextValueBold>
              ) : (
                <TextValueBold>
                  ~ {formatNumber(companyDetails.totalCostConverted.amount)}{' '}
                  {getCurrencySymbol(
                    companyDetails.totalCostConverted.currency,
                  )}
                </TextValueBold>
              )}
            </StyledPricesBlockRow>
          </>
        )}
      </DeliveryBlock>
    );
  },
);
