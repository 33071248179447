import styled from 'styled-components';
import { SCREEN_WIDTH } from '../../../../screenSettings';

export const StyledBaseCarInformationWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  gap: 30px;

  @media only screen and (max-width: ${SCREEN_WIDTH.TO_DESKTOP_BREAK_3}px) {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
  }
`;

export const StyledCarImageGalleryWrapper = styled.div`
  width: calc(50% - 20px);
  flex-shrink: 1;
  @media only screen and (max-width: ${SCREEN_WIDTH.TO_DESKTOP_BREAK_3}px) {
    width: 100%;
  }
  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET}px) {
    width: 100%;
  }
`;

export const StyledBaseAdvertDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: start;
  flex-grow: 1;
  width: calc(50% - 15px);
  gap: 16px;
  @media only screen and (max-width: ${SCREEN_WIDTH.TO_DESKTOP_BREAK_3}px) {
    width: 100%;
  }
  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET}px) {
    width: 100%;
    justify-content: flex-start;
  }
`;

export const StyledWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const StyledLinkWithPriceSector = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 16px;
`;

export const StyledPriceContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  & > .ant-typography {
    display: block;
  }
`;

export const StyledLinkContainer = styled.div`
  position: relative;
  width: 100%;
  text-align: left;
  & a,
  h1 {
    color: var(--Dark-13);
    font-size: 22px;
    font-style: normal;
    font-weight: var(--bold-font-weight);
    line-height: 26px;
    letter-spacing: 0.033px;
    padding: 4px 0px;

    @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_2}px) {
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0.027px;
    }
  }
`;
