import { FC, useState, useEffect, useMemo } from 'react';
import { Form, Select } from 'antd';
import { PrimaryButton } from '../../../../components/Buttons/PrimaryButton';
import { AccountSettingsFormFieldsType } from './AccountInfoForm.types';
import { CaretDownOutlined } from '@ant-design/icons';
import { StyledButton } from '../../../../components/styled/StyledButton';
import { FormItemLabel } from '../../../../components/FormItemLabel';
import {
  StyledAvatarNameWrapper,
  StyledAvatarIcon,
  StyledAccountSettingsForm,
  StyledActionButtons,
  fullWidthInputStyles,
  StyledAccountSettingsContent,
  StyledFilterCellSingle,
} from './AccountInfoForm.styled';
import { FilterCellSingle } from '../../../../components/MobileFormElements';
import {
  CustomInputText,
  CustomSegmented,
  CustomSelect,
} from '../../../../components/FormElements';
import { currencies } from '../../../../data';
import {
  FormFiledLabel,
  FormFieldName,
  FormPlaceholder,
} from '../../../../enums/form';
import { commonValidationRules } from '../../../../data/commonValidationRules';
import { countries } from '../../../../data/countries';
import { SelectOptionStyle } from '../../../../components/styled/SelectOption';
import {
  useAuthSelector,
  useProfileSelector,
} from '../../../../store/selectors';
import { UpdateAccountSettingsRequestType } from '../../../../models/profile';
import { useMediaQuery } from 'react-responsive';
import { SCREEN_WIDTH } from '../../../../screenSettings';
import { useUpdateUserInfo } from '../../../../hooks/profile';

export const AccountInfoForm: FC = () => {
  const isMobileWidth = useMediaQuery({
    query: `(max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px)`,
  });
  const { updateUserHandler, contextHolder, isLoading } =
    useUpdateUserInfo(false);
  const [form] = Form.useForm<AccountSettingsFormFieldsType>();
  const country = Form.useWatch(FormFieldName.CountryCode, form);
  const [isShowedButtons, setIsShowedButtons] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const { email } = useAuthSelector();
  const { countryCode, currency, username, companyName, companyUrl } =
    useProfileSelector();

  const initialFormValues = useMemo<AccountSettingsFormFieldsType>(
    () => ({
      email: email ?? '',
      currency,
      username,
      countryCode,
      companyUrl: companyUrl ?? '',
      companyName: companyName ?? '',
    }),
    [companyName, companyUrl, countryCode, currency, email, username],
  );

  useEffect(() => {
    form.setFieldsValue(initialFormValues);
  }, [form, initialFormValues]);

  const updateCountryChangeMobile = (value: string) => {
    form.setFieldsValue({ [FormFieldName.CountryCode]: value });
    setIsShowedButtons(true);
  };

  const formSubmitHandler = (values: AccountSettingsFormFieldsType) => {
    const userData: UpdateAccountSettingsRequestType = {
      username: values.username,
      currency: values.currency,
      countryCode: values.countryCode,
      companyName: values.companyName ?? undefined,
      companyUrl: values.companyUrl ?? undefined,
    };

    updateUserHandler(userData, () => setIsShowedButtons(false));
  };

  const cancelActionHandler = () => {
    form.resetFields();
    setIsShowedButtons(false);
  };

  const handleFieldsChange = () => {
    setIsShowedButtons(true);
    const formHasErrors = form
      .getFieldsError()
      .some(({ errors }) => errors.length);

    setSubmitDisabled(formHasErrors);
  };

  return (
    <StyledAccountSettingsForm
      requiredMark={false}
      form={form}
      autoComplete='off'
      layout='vertical'
      onFinish={formSubmitHandler}
      onFieldsChange={handleFieldsChange}
      name='userAccountInfoForm'
      scrollToFirstError={true}
      initialValues={initialFormValues}
    >
      {contextHolder}
      <StyledAvatarNameWrapper>
        <StyledAvatarIcon />
        <CustomInputText
          formItemProps={{
            style: fullWidthInputStyles,
            label: <FormItemLabel>{FormFiledLabel.Username}</FormItemLabel>,
            name: FormFieldName.Username,
            rules: commonValidationRules.username,
          }}
          inputProps={{ placeholder: FormPlaceholder.Username }}
        />
      </StyledAvatarNameWrapper>
      <StyledAccountSettingsContent>
        <CustomInputText
          formItemProps={{
            style: fullWidthInputStyles,
            label: <FormItemLabel>{FormFiledLabel.Email}</FormItemLabel>,
            name: FormFieldName.Email,
          }}
          inputProps={{ placeholder: FormPlaceholder.Email, disabled: true }}
        />
        {!isMobileWidth && (
          <CustomSelect
            formItemProps={{
              style: fullWidthInputStyles,
              name: FormFieldName.CountryCode,
              label: <FormItemLabel>{FormFiledLabel.Country}</FormItemLabel>,
            }}
            suffixIcon={<CaretDownOutlined rev='true' />}
            placeholder={FormPlaceholder.Country}
          >
            {countries.map((country) => (
              <Select.Option
                key={country.code}
                style={SelectOptionStyle}
                value={country.code}
              >
                {country.name}
              </Select.Option>
            ))}
          </CustomSelect>
        )}
        {isMobileWidth && (
          <StyledFilterCellSingle>
            <FilterCellSingle
              style={{ marginBottom: 0 }}
              form={form}
              name={FormFieldName.CountryCode}
              placeholder={FormPlaceholder.Country}
              value={country}
              options={countries.map((elem) => ({
                value: elem.code,
                label: elem.name,
              }))}
              label={FormFiledLabel.Country}
              callback={updateCountryChangeMobile}
            />
          </StyledFilterCellSingle>
        )}
        <CustomSegmented
          formItemProps={{
            style: fullWidthInputStyles,
            name: FormFieldName.Currency,
            label: <FormItemLabel>{FormFiledLabel.Currency}</FormItemLabel>,
            rules: commonValidationRules.segmentedCurrency,
          }}
          options={currencies}
        />
        <CustomInputText
          formItemProps={{
            style: fullWidthInputStyles,
            label: <FormItemLabel>{FormFiledLabel.CompanyName}</FormItemLabel>,
            name: FormFieldName.CompanyName,
            rules: commonValidationRules.companyName,
          }}
          inputProps={{ placeholder: FormPlaceholder.CompanyName }}
        />
        <CustomInputText
          formItemProps={{
            style: fullWidthInputStyles,
            label: <FormItemLabel>{FormFiledLabel.CompanyUrl}</FormItemLabel>,
            name: FormFieldName.CompanyUrl,
            rules: commonValidationRules.companyUrl,
          }}
          inputProps={{ placeholder: FormPlaceholder.CompanyUrl }}
        />
      </StyledAccountSettingsContent>
      {isShowedButtons && (
        <StyledActionButtons>
          <StyledButton
            size='large'
            htmlType='button'
            disabled={isLoading}
            style={{ width: isMobileWidth ? '100%' : undefined }}
            ghost
            type='primary'
            onClick={cancelActionHandler}
          >
            Отменить
          </StyledButton>
          <PrimaryButton
            style={{
              width: isMobileWidth ? '100%' : undefined,
              borderRadius: 4,
            }}
            backgroundColorDefault='#3F8BF3'
            colorDefault='#fff'
            htmlType='submit'
            disabled={isLoading || submitDisabled}
            loading={isLoading}
          >
            <span style={{ marginTop: 2 }}>Сохранить изменения</span>
          </PrimaryButton>
        </StyledActionButtons>
      )}
    </StyledAccountSettingsForm>
  );
};
