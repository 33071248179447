import { Breadcrumb, theme } from 'antd';
import { FC, memo, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { APP_PATHS } from '../../../../layout/settingsLayout';
import { ICarDetails } from '../../../../models/car';

interface CarBreadcumbProps {
  similarAdsUrl: string;
  currentRegion: string | undefined;
  car: ICarDetails;
}

export const CarBreadcrumb: FC<CarBreadcumbProps> = memo(
  ({ similarAdsUrl, currentRegion, car }) => {
    const { token } = theme.useToken();
    const location = useLocation();
    const isOnCalculateDeliveryPage = location.pathname.includes(
      APP_PATHS.CAR_PAGE_CALCULATE_DELIVERY,
    );

    const isOnCarInspectionPage = location.pathname.includes(
      APP_PATHS.CAR_PAGE_CALCULATE_CAR_INSPECTION,
    );

    const isActive = (path: string) => location.pathname === path;

    const breadcrumbItems = [
      {
        path: APP_PATHS.CARS_PAGE,
        label: 'Автомобили',
      },
      {
        path: `${APP_PATHS.CARS_PAGE}/${car.makeId.toLowerCase()}`,
        label: car.makeViewName,
      },
      {
        path: `${APP_PATHS.CARS_PAGE}/${car.makeId.toLowerCase()}/${car.modelOriginalName.toLowerCase()}`,
        label: car.modelViewName,
      },
      {
        path: similarAdsUrl,
        label: `${car.makeViewName} ${car.modelViewName} ${currentRegion}`,
      },
      {
        path: `${APP_PATHS.CARS_PAGE}/${car.makeId.toLowerCase()}/${car.modelOriginalName.toLowerCase()}/${car.adId}/${car.sourceId}`,
        label: `${car.makeViewName} ${car.modelViewName} ${car.yearOfIssue}`,
      },
    ];

    if (isOnCalculateDeliveryPage) {
      breadcrumbItems.push({
        path: location.pathname,
        label: 'Рассчет стоимости',
      });
    }

    if (isOnCarInspectionPage) {
      breadcrumbItems.push({
        path: location.pathname,
        label: 'Осмотр авто',
      });
    }

    const items = breadcrumbItems.map((item) => ({
      key: item.path,
      title: (
        <Link
          to={item.path}
          style={{
            color: isActive(item.path) ? token.colorTextHeading : '',
          }}
        >
          {item.label}
        </Link>
      ),
    }));

    return <Breadcrumb items={items} />;
  },
);
