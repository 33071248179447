import { FC } from 'react';
import {
  StyledGalleryFullscreenContent,
  StyledAntImage,
  StyledFullScreenLeftButton,
  StyledFullScreenRightButton,
} from './StyledGalleryFullscreenContent';
import NoCar from '../../../../../img/NoVehicle.png';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import ImageGallery from 'react-image-gallery';
import { SCREEN_WIDTH } from '../../../../../screenSettings';
import { useMediaQuery } from 'react-responsive';

type FullScreenGalleryContentPropsType = {
  images: string[];
  currentImageIndex: number;
};

export const FullScreenGalleryModalContent: FC<
  FullScreenGalleryContentPropsType
> = ({ images, currentImageIndex }) => {
  const isTabletWidth = useMediaQuery({
    query: `(max-width: ${SCREEN_WIDTH.TABLET}px)`,
  });

  const isMobileWidth = useMediaQuery({
    query: `(max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px)`,
  });

  const imagesForGallery =
    Array.isArray(images) && images.length > 0
      ? images.map((img) => ({
          original: img,
          thumbnail: '',
        }))
      : [
          {
            original: '',
            thumbnail: '',
          },
        ];

  return (
    <StyledGalleryFullscreenContent>
      {Array.isArray(images) && images.length > 0 && (
        <ImageGallery
          startIndex={currentImageIndex}
          additionalClass='fullscreen-car__gallery'
          renderLeftNav={(onClick, disabled) => (
            <StyledFullScreenLeftButton
              type='text'
              icon={
                <LeftOutlined style={{ fontSize: isTabletWidth ? 30 : 40 }} />
              }
              onClick={onClick}
              disabled={disabled}
            />
          )}
          renderRightNav={(onClick, disabled) => (
            <StyledFullScreenRightButton
              type='text'
              icon={
                <RightOutlined style={{ fontSize: isTabletWidth ? 30 : 40 }} />
              }
              onClick={onClick}
              disabled={disabled}
            />
          )}
          showNav={!isMobileWidth}
          showBullets={true}
          showThumbnails={false}
          items={imagesForGallery}
          showPlayButton={false}
          showFullscreenButton={false}
          slideDuration={200}
          infinite={false}
        />
      )}
      {(!Array.isArray(images) || images.length === 0) && (
        <StyledAntImage preview={false} key='empty' src={NoCar} />
      )}
    </StyledGalleryFullscreenContent>
  );
};
