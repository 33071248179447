import { Result, Spin, theme } from 'antd';
import React, { FC, useEffect, useMemo, useRef } from 'react';
import { ICarData } from '../../../../models';
import { ICar } from '../../../../models/car';
import { config } from '../../../../config';
import { FullHeightContainer } from '../../../../components/FullHeightContainer';
import { getPriceByCurrency } from '../../../../utils/getPriceByCurrency';
import { CarListItem } from '../../../../components/CarListItem';
import { CarsListHeaderWrapper } from './CarsListItemHeaderWrapper';
import { StyledCarList } from './CarsList.styled';
import { ICarsList } from './CarList.interface';
import { useMediaQuery } from 'react-responsive';
import { SCREEN_WIDTH } from '../../../../screenSettings';

export const CarsList: FC<ICarsList> = React.memo((props) => {
  const {
    headerListTitle,
    currentCount,
    page,
    cars,
    isFetchingCars,
    isErrorCars,
    setIsInvalidate,
    setPage,
  } = props;
  const { token } = theme.useToken();
  const firstRenderRef = useRef(true);

  const handlePagination = (page: number): void => {
    window.scrollTo(0, 0);
    setPage(page);
  };

  useEffect(() => {
    if (firstRenderRef.current) {
      firstRenderRef.current = false;
      return;
    }

    setIsInvalidate(true);
  }, [page]);

  const isMobileWidth = useMediaQuery({
    query: `(max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px)`,
  });

  const isTabletWidth = useMediaQuery({
    query: `(max-width: ${SCREEN_WIDTH.TABLET - 1}px)`,
  });

  const data: ICarData[] | undefined = useMemo(() => {
    return cars?.map((car: ICar) => ({
      sourceId: car.sourceId,
      adId: car.adId,
      title: car.fullName,
      imageUrl: car.imageUrl,
      price: getPriceByCurrency(car.prices, car.baseCurrency),
      currency: car.baseCurrency,
      tag: car.priceEstimate?.tag,
      prices: car.prices,
      makeId: car.makeId,
      modelId: car.modelOriginalName,
      yearOfIssue: car.yearOfIssue,
      engineCapacity: car.engineCapacity,
      car: car,
      tags: car.tags,
    }));
  }, [cars]);

  if (data === undefined && !isErrorCars) {
    return (
      <FullHeightContainer>
        <Spin size='large' />
      </FullHeightContainer>
    );
  }

  if (process.env.NODE_ENV === 'development') {
    console.log('[CAR LIST] Render', cars, isFetchingCars, isErrorCars);
  }

  return (
    <Spin
      size='large'
      spinning={isFetchingCars}
      style={{ borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px' }}
    >
      <StyledCarList.StyledList
        backgroundcolor={token.colorBgLayout}
        paginationitemswidth={isTabletWidth ? 40 : 32}
        locale={{
          emptyText: (
            <Result status='404' subTitle='Извините, нет объявлений' />
          ),
        }}
        itemLayout='vertical'
        size='large'
        header={
          <CarsListHeaderWrapper
            currentCount={currentCount}
            headerListTitle={headerListTitle}
          />
        }
        pagination={{
          current: page || 1,
          size: 'small',
          align: isTabletWidth ? 'center' : 'end',
          position: 'bottom',
          onChange: (page: number) => handlePagination(page),
          pageSize: config.CARS_LIST_PAGE.ITEMS_PER_PAGE,
          total: currentCount,
          showSizeChanger: false,
          responsive: true,
          showLessItems: isMobileWidth,
        }}
        dataSource={data}
        renderItem={(item: unknown) => (
          <CarListItem item={item as ICarData} hasSubscriptionBlock={true} />
        )}
      />
    </Spin>
  );
});
