import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetCarOptionsResponse } from '../../models/turn-key/GetOptionsResponse';
import { CategoriesType } from '../../models/turn-key/GetCarFeatures';

type InitialState = {
  carOptions: GetCarOptionsResponse;
  categories: CategoriesType[];
};

const initialState: InitialState = {
  carOptions: {} as GetCarOptionsResponse,
  categories: [],
};

export const turnKeySlice = createSlice({
  name: 'turnKey',
  initialState,
  reducers: {
    setCarOptions: (
      state,
      { payload }: PayloadAction<GetCarOptionsResponse>,
    ) => {
      state.carOptions = payload;
    },
    setCategories: (state, { payload }: PayloadAction<CategoriesType[]>) => {
      state.categories = payload;
    },
  },
});

export const turnKeyActions = turnKeySlice.actions;
export const turnKeyReducer = turnKeySlice.reducer;
