import styled from 'styled-components';
import { Tag } from 'antd';

export const StyledCarTags = styled.div``;

export const StyledTagWrapper = styled.div`
  .ant-tag-blue {
    color: #1890ff;
    background: #e6f7ff;
  }

  .ant-tag-default {
    color: #000000d9;
    background: #fafafa;
  }
`;

export const StyledTag = styled(Tag)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 22px;
  font-size: 12px;
  & > span {
    display: inline-block;
  }

  .anticon + span {
    margin-inline-start: 8px;
    margin-bottom: 2px;
  }
`;
