import { useCallback } from 'react';
import { useDeleteSubscriptionMutation } from '../../store/subscription/subscription.api';
import { notification } from 'antd';
import { config } from '../../config';
import { DeleteCarFromSubscriptionsRequest } from '../../models/response/subscriptionsCars';
import { useActions } from '../useActions';

export const useDeleteCarFromSubscriptions = () => {
  const { deleteCarFromSubscriptions } = useActions();
  const [
    deleteCarFromSubscription,
    { isError: failedDelete, isLoading: isDeleting },
  ] = useDeleteSubscriptionMutation();

  const [notificationApi, contextHolder] = notification.useNotification();

  const deleteSubscription = useCallback(
    async (data: DeleteCarFromSubscriptionsRequest) => {
      try {
        await deleteCarFromSubscription(data).unwrap();
        deleteCarFromSubscriptions(data);
        notificationApi.success({
          message: 'Успешно!',
          description: 'Авто удалено из подписок.',
          duration: config.SUBSCRIPTION_DELETE_DURATION,
        });
      } catch (err: unknown) {
        if (process.env.NODE_ENV === 'development') {
          console.log(err);
        }
        notificationApi.error({
          message: 'Ошибка удаления авто',
          description: 'Не удалось удалить подписку на авто',
          duration: config.SUBSCRIPTION_DELETE_DURATION,
        });
      }
    },
    [deleteCarFromSubscription, deleteCarFromSubscriptions, notificationApi],
  );

  return {
    failedDelete,
    isDeleting,
    contextHolder,
    deleteSubscription,
  };
};
