import { CarPriceBlockPropsType } from '../components/Car';
import { Price } from '../models/car';
import { getPriceByCurrency } from './getPriceByCurrency';

import { PRICE_DESCRIPTIONS } from '../data/carPriceDescriptions';

type GetDataForCarItemPrices = (
  currency: string,
  prices: Price[],
  vatDeductible: boolean,
  vatPrices: Price[],
) => CarPriceBlockPropsType[];

export const getDataForCarItemPrices: GetDataForCarItemPrices = (
  currency,
  prices,
  vatDeductible,
  vatPrices,
): CarPriceBlockPropsType[] => {
  const result: CarPriceBlockPropsType[] = [];
  const hasVatPrices =
    vatDeductible && Array.isArray(vatPrices) && vatPrices.length > 0;

  result.push({
    currency: currency,
    description: PRICE_DESCRIPTIONS.ORIGIN,
    isSmallSize: false,
    price: getPriceByCurrency(prices, currency),
  });

  if (hasVatPrices) {
    result.push({
      currency: currency,
      description: PRICE_DESCRIPTIONS.VAT,
      isSmallSize: true,
      price: getPriceByCurrency(vatPrices, currency),
    });
  }
  return result;
};
