import { FC, useState } from 'react';
import { StyledVinWrapper } from './CarVin.styled';
import { replaceCharactersToStar } from '../../../utils/replaceCharactersToStar';

type CarVinType = {
  vin: string;
};
const VIN_REPLACED_SYMBOLS = 4;

export const CarVin: FC<CarVinType> = ({ vin }) => {
  const [countReplaced, setCountReplaced] = useState(VIN_REPLACED_SYMBOLS);

  const preparedVin = replaceCharactersToStar(vin, countReplaced);

  const handleVinClick = () => {
    setCountReplaced((prev) =>
      prev === VIN_REPLACED_SYMBOLS ? 0 : VIN_REPLACED_SYMBOLS,
    );
  };
  return (
    <StyledVinWrapper onClick={handleVinClick}>{preparedVin}</StyledVinWrapper>
  );
};
