import { FC } from 'react';
import { Helmet } from 'react-helmet';
import { MetaData } from '../../seo';

export const Metadata: FC<MetaData> = ({ title, description, imageUrl }) => {
  return (
    <Helmet>
      <title>{title}</title>
      {process.env.NODE_ENV === 'production' && (
        <link rel='canonical' href={window.location.href} />
      )}
      <meta name='description' content={description} data-rh='true' />
      <meta property='og:title' content={title} />
      <meta property='og:description' content={description} />
      <meta name='twitter:title' content={title} />
      <meta name='twitter:description' content={description} />
      <meta name='twitter:card' content={title} />
      <meta property='og:image' content={imageUrl || undefined} />
      <meta property='og:image:width' content='1200' />
      <meta property='og:image:height' content='630' />
      <meta property='twitter:image' content={imageUrl || undefined} />
    </Helmet>
  );
};
