export const enum FeatureCategoriesIds {
  Safety = 1,
  Comfort = 2,
  Multimedia = 3,
  Interior = 4,
  Exterior = 5,
  Additional = 6,
  Popular = 100,
}

