import styled from 'styled-components';
import wrapperBgImg from '../../img/callToActionBg.png';
import { SCREEN_WIDTH } from '../../screenSettings';

export const StyledBlockWrapper = styled.div`
  width: 100%;
  border-radius: 8px;

  background:
    linear-gradient(
      270deg,
      rgba(25, 42, 77, 0.1) 18%,
      rgba(25, 42, 77, 0.8) 85%
    ),
    url(${wrapperBgImg}) lightgray;
  background-position:
    center,
    0 -205px;
  background-size:
    cover,
    100% 180%;

  background-repeat: no-repeat no-repeat;
  border-radius: 8px;

  @media only screen and (max-width: ${SCREEN_WIDTH.TO_DESKTOP_BREAK_2}px) {
    background-size:
      cover,
      110% 150%;
    background-position:
      center,
      -70px -100px;

    @media only screen and (max-width: ${SCREEN_WIDTH.TABLET}px) {
      background-size:
        cover,
        105% 135%;
      background-position:
        center,
        -5px -10px;
    }
    @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_2}px) {
      background:
        linear-gradient(0deg, #192a4d 30%, rgba(25, 42, 77, 0.1) 60%),
        linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0) 55%,
          rgba(255, 255, 255, 1) 100%
        ),
        url(${wrapperBgImg}) #35496b;

      background-repeat: no-repeat, no-repeat, no-repeat;

      background-position:
        center,
        center bottom,
        100% 0;

      background-size:
        cover,
        cover,
        100% 65%;
    }

    @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
      border-radius: 0;
      background-size:
        cover,
        cover,
        130% 65%;
    }
  }
`;

export const StyledInsideContent = styled.div`
  padding: 64px 0 64px 80px;
  max-width: 55%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;

  @media only screen and (max-width: ${SCREEN_WIDTH.TO_DESKTOP_BREAK_1}px) {
    max-width: 70%;
  }

  @media only screen and (max-width: ${SCREEN_WIDTH.TO_DESKTOP_BREAK_3}px) {
    max-width: 85%;
    padding: 40px 0 40px 67px;
  }
  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_2}px) {
    padding: 24px 16px;
    max-width: 100%;
    gap: 16px;
  }
`;

export const StyledTitle = styled.h2`
  color: var(--White);
  font-size: 34px;
  font-weight: 700;
  line-height: 120%;
  font-style: normal;

  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET}px) {
    font-size: 24px;
    line-height: 100%;
  }

  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_2}px) {
    margin-top: 170px;
    font-size: 18px;
    max-width: 50%;
  }
  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
    max-width: 70%;
  }
  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_4}px) {
    max-width: 100%;
  }
`;

export const StyledDescription = styled.p`
  margin-bottom: 0;
  max-width: 85%;
  color: var(--Gray-2);
  font-size: var(--desktop-content-font-size);
  font-weight: 400;
  line-height: 125%;
  margin-bottom: 16px;
  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_2}px) {
    font-size: 12px;
    line-height: 140%;
    max-width: 70%;
  }

  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_4}px) {
    max-width: 90%;
  }
`;
