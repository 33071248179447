export const PopularSvg = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='25'
    height='25'
    viewBox='0 0 25 25'
    fill='none'
  >
    <path
      d='M11.7062 4.36856C11.9498 3.87493 12.0716 3.62812 12.237 3.54926C12.3809 3.48065 12.5481 3.48065 12.692 3.54926C12.8574 3.62812 12.9792 3.87493 13.2229 4.36856L15.5346 9.05172C15.6065 9.19745 15.6425 9.27031 15.695 9.32689C15.7416 9.37698 15.7974 9.41756 15.8594 9.4464C15.9294 9.47896 16.0098 9.49071 16.1706 9.51421L21.3414 10.27C21.8859 10.3496 22.1582 10.3894 22.2842 10.5224C22.3938 10.6381 22.4454 10.7971 22.4245 10.9551C22.4005 11.1367 22.2034 11.3287 21.8092 11.7127L18.069 15.3557C17.9524 15.4692 17.8941 15.526 17.8565 15.5936C17.8231 15.6534 17.8018 15.7191 17.7935 15.7871C17.7842 15.8639 17.798 15.9441 17.8255 16.1045L18.708 21.2501C18.8011 21.7928 18.8476 22.0641 18.7602 22.2252C18.6841 22.3653 18.5488 22.4635 18.392 22.4926C18.2119 22.526 17.9682 22.3978 17.4808 22.1415L12.8582 19.7105C12.7141 19.6348 12.6421 19.5969 12.5663 19.582C12.4991 19.5689 12.43 19.5689 12.3628 19.582C12.2869 19.5969 12.2149 19.6348 12.0709 19.7105L7.44823 22.1415C6.96087 22.3978 6.71719 22.526 6.53701 22.4926C6.38025 22.4635 6.24497 22.3653 6.16888 22.2252C6.08141 22.0641 6.12795 21.7928 6.22103 21.2501L7.10356 16.1045C7.13107 15.9441 7.14483 15.8639 7.13552 15.7871C7.12728 15.7191 7.10591 15.6534 7.0726 15.5936C7.03499 15.526 6.97669 15.4692 6.86009 15.3557L3.11985 11.7127C2.72564 11.3287 2.52854 11.1367 2.50455 10.9551C2.48369 10.7971 2.53524 10.6381 2.64486 10.5224C2.77086 10.3894 3.04312 10.3496 3.58763 10.27L8.75845 9.51421C8.91926 9.49071 8.99966 9.47896 9.06969 9.4464C9.13169 9.41756 9.1875 9.37698 9.23404 9.32689C9.2866 9.27031 9.32257 9.19745 9.3945 9.05172L11.7062 4.36856Z'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
