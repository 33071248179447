import { FC } from 'react';
import { FormItemProps } from 'antd/es/form';
import { StyledSegmented } from '../../styled/StyledSegmented';
import { StyledFormItem } from '../../styled/StyledFormItem';
import { formSettings } from '../../../config';
import { SegmentedValue, SegmentedLabeledOption } from 'antd/es/segmented';

type SegmentedOptionsType = Array<SegmentedValue | SegmentedLabeledOption>;

// can't extends SegmentedProps due to library error
interface CustomSegmentedProps {
  formItemProps: FormItemProps;
  options: SegmentedOptionsType;
  onChange?: (value: unknown) => void;
}

export const CustomSegmented: FC<CustomSegmentedProps> = ({
  formItemProps,
  options,
  onChange,
}) => (
  <StyledFormItem {...formItemProps}>
    <StyledSegmented
      block
      required={true}
      size={formSettings.controlSize}
      options={options}
      onChange={onChange}
    />
  </StyledFormItem>
);
