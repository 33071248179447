export const MultiMediaSvg = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='25'
    height='25'
    viewBox='0 0 25 25'
    fill='none'
  >
    <path
      d='M15 18.4978V6.08669C15 5.22947 15 4.80086 15.1805 4.54272C15.3382 4.31732 15.5817 4.16681 15.8538 4.1266C16.1655 4.08056 16.5488 4.27224 17.3155 4.6556L21 6.49783M15 18.4978C15 20.1547 13.6569 21.4978 12 21.4978C10.3431 21.4978 9 20.1547 9 18.4978C9 16.841 10.3431 15.4978 12 15.4978C13.6569 15.4978 15 16.841 15 18.4978ZM7 10.4978V4.49783M4 7.49783H10'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
