import { SendRequestFormFiled } from './enum';

export const radioOptions = [
  { label: 'Telegram', value: 'telegram' },
  { label: 'WhatsApp', value: 'whatsapp' },
  { label: 'Viber', value: 'viber' },
];

export const formInitialValues = {
  [SendRequestFormFiled.Channel]: radioOptions[0].value,
  [SendRequestFormFiled.PersonalDataProcessingConsent]: true,
};

export const validationRules = {
  [SendRequestFormFiled.Name]: [
    {
      required: true,
      message: 'Пожалуйста, введите ваше имя',
    },
    {
      min: 2,
      max: 50,
      message: 'Имя должно быть от 2 до 50 символов',
    },
  ],
  [SendRequestFormFiled.Channel]: [
    {
      required: true,
      message: 'Пожалуйста, выберите канал',
    },
  ],
  [SendRequestFormFiled.PersonalDataProcessingConsent]: [
    {
      required: true,
      message: 'Пожалуйста, дайте согласие на обработку персональных данных',
    },
    {
      validator: (_: any, value: boolean) => {
        return value
          ? Promise.resolve()
          : Promise.reject('Согласие обязательно');
      },
    },
  ],
  [SendRequestFormFiled.Phone]: [
    {
      required: true,
      message: 'Пожалуйста, введите ваш телефон',
    },
    {
      pattern: /^\d{10,15}$/,
      message: 'Телефон должен содержать от 10 до 15 цифр',
    },
  ],
};
