export enum ApiEndpoints {
  RefreshToken = '/auth/refresh-token/use',
  SignIn = '/auth/sign-in',
  SignUp = '/auth/sign-up',
  ConfirmEmail = '/auth/confirm-email',
  ResendConfirmEmail = '/auth/resend-email-confirmation',
  ForgotPassword = '/auth/forgot-password',
  ResetPassword = '/auth/reset-password',
  Cars = '/cars/offer',
  CarClones = '/cars/clones',
  CarRecommendations = '/cars/recommendations',
  CarMedianPricesByRegion = '/cars/analytics/model-median-prices-by-regions',
  CarModelPriceDistributions = 'cars/analytics/model-price-distribution',
  CarMileageDistributions = 'cars/analytics/model-mileage-distribution',
  CheckAvailability = '/cars/offer/check-availability',
  CalculateDelivery = '/cars/offer/services/delivery',
  CalculateTurnKeyDelivery = '/cars/services/delivery',
  ByuCarRequest = '/cars/requests/buy',
  CarInspection = '/cars/requests/inspection',
  AskQuestionsCars = '/cars/requests/ask-question',
  CarsMakes = '/cars/makes',
  CustomCar = '/cars/requests/custom',
  Companies = 'reference-data/companies',
  AccountSettings = '/me/account-settings',
  CarOptions = '/reference-data/car-options',
  CarFeaturesAvailable = '/reference-data/features',
}
