import { forwardRef } from 'react';
import { ButtonProps } from 'antd';

import { useAuthSelector } from '../../../store/selectors';
import {
  StyledUserNameWithOptionsButton,
  StyledUserOutlinedIcon,
  StyledPopoverToggleIcon,
  StyledUserName,
} from './UserOptionsButton.styled';
import { useMediaQuery } from 'react-responsive';
import { SCREEN_WIDTH } from '../../../screenSettings';

interface IUserOptionsButtonProps extends ButtonProps {
  isArrowTransformed: boolean;
}

export const UserOptionsButton = forwardRef<
  HTMLButtonElement,
  IUserOptionsButtonProps
>(({ isArrowTransformed, ...antStandardProps }, ref) => {
  const { username } = useAuthSelector();
  const isMobileWidth = useMediaQuery({
    query: `(max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px)`,
  });

  return (
    <StyledUserNameWithOptionsButton
      ref={ref}
      size='large'
      icon={<StyledUserOutlinedIcon />}
      type='text'
      {...antStandardProps}
    >
      {!isMobileWidth && <StyledUserName>{username}</StyledUserName>}
      <StyledPopoverToggleIcon
        style={isArrowTransformed ? { transform: 'rotate(180deg)' } : undefined}
      />
    </StyledUserNameWithOptionsButton>
  );
});
