import { FC, useEffect } from 'react';
import {
  StyledCalcilateDelivery,
  StyledMainDivider,
  StyledHighlitedTextContainer,
  StyledMainRow,
} from './CalculateDeliverySection.styled';
import { StyledTitle } from '../../../../components/styled/StyledTitle';
import { StyledHighlitedText } from '../../../../components/styled/StyledHighlitedText';
import { MobileCalculateDeliveryForm } from '../MobileCalculateDeliveryForm';
import { useMediaQuery } from 'react-responsive';
import { SCREEN_WIDTH } from '../../../../screenSettings';
import {
  useCarPageSelector,
  useCompaniesSelector,
  useProfileSelector,
} from '../../../../store/selectors';
import { useLazyCalculateDeliveryQuery } from '../../../../store/car/car.api';
import { useOutletContext } from 'react-router-dom';
import { CarPageOutletContext } from '../../CarPage.interface';
import { Section } from '../../../../components/Section';
import { CalculatedCarHeader } from '../../CalculateDelivery';
import { destinationRegionIdMapping } from '../../../../data/mappings';
import { CompanyServicesBlock } from './CompanyServicesBlock';
import { PriceAfterTaxConvertedBlock } from './PriceAfterTaxConverted';
import { Clearence } from './Сlearance';
import { PriceBlock } from './PriceBlock';
import { CalculateDeliverySectionControls } from './CalculateDeliverySectionControls';
import { Company } from '../../../../models/response/companies';
import { AlertInfo } from '../../../../components/AlertInfo';
import { generateTotalPrices } from './utils';
import { useActions } from '../../../../hooks/useActions';

interface CalculateDeliverySectionProps {
  onlyCarInspection: boolean;
}

export const CalculateDelivery: FC<CalculateDeliverySectionProps> = ({
  onlyCarInspection,
}) => {
  const { setSelectedDeliveryValues } = useActions();
  const { calculateDeliveryForm } = useCarPageSelector();
  const { companies } = useCompaniesSelector();
  const { currency } = useProfileSelector();
  const { car } = useOutletContext<CarPageOutletContext>();
  const [
    fetchCalculatedDelivery,
    {
      isError: isErrorCalculatedDelivery,
      data: calculatedDelivery,
      isFetching: isFetchingCalculatedDelivery,
    },
  ] = useLazyCalculateDeliveryQuery();
  const isTablet = useMediaQuery({
    query: `(max-width: ${SCREEN_WIDTH.TABLET}px)`,
  });
  const selectedCompany = companies?.find(
    (company: Company) => company.id === calculateDeliveryForm.companyId,
  );
  const headerMainText = `${car.makeViewName} ${car.modelViewName} ${car.yearOfIssue} от ${selectedCompany?.name}`;
  const headerCalculatedCarText = calculateDeliveryForm.destinationRegionId
    ? `${headerMainText} в ${destinationRegionIdMapping[calculateDeliveryForm.destinationRegionId]}`
    : '';

  useEffect(() => {
    if (
      !companies.length ||
      !calculateDeliveryForm.companyId ||
      !calculateDeliveryForm.destinationRegionId
    ) {
      return;
    }

    fetchCalculatedDelivery({
      companyId: calculateDeliveryForm.companyId,
      destinationRegionId: calculateDeliveryForm.destinationRegionId,
      currency,
      adId: car.adId,
      sourceId: car.sourceId,
    });

    return () => {
      setSelectedDeliveryValues({
        carInspectionRequired: false,
        hasCustomsClearanceBenefit: false,
      });
    };
  }, [
    calculateDeliveryForm.companyId,
    calculateDeliveryForm.destinationRegionId,
    currency,
  ]);

  return (
    <Section
      isError={isErrorCalculatedDelivery}
      isFetching={isFetchingCalculatedDelivery}
      headerContent={
        <StyledTitle level={1}>
          {onlyCarInspection ? 'Осмотр авто' : 'Рассчет стоимости'}{' '}
          <CalculatedCarHeader>
            <StyledHighlitedText>
              {calculatedDelivery &&
                (onlyCarInspection ? headerMainText : headerCalculatedCarText)}
            </StyledHighlitedText>
          </CalculatedCarHeader>
        </StyledTitle>
      }
    >
      <StyledCalcilateDelivery>
        <StyledMainRow>
          <StyledHighlitedTextContainer>
            <StyledHighlitedText>{headerCalculatedCarText}</StyledHighlitedText>
          </StyledHighlitedTextContainer>

          {isTablet && (
            <MobileCalculateDeliveryForm
              onlyCarInspection={onlyCarInspection}
            />
          )}

          {calculatedDelivery && (
            <>
              {!onlyCarInspection && (
                <PriceBlock
                  title='Итоговая цена'
                  prices={generateTotalPrices({
                    calculatedDelivery,
                    customsClearance: calculateDeliveryForm.hasCustomsClearanceBenefit,
                    carInspectionRequired:
                      calculateDeliveryForm.carInspectionRequired,
                  })}
                />
              )}

              <CompanyServicesBlock
                onlyCarInspection={onlyCarInspection}
                shipmentPathDescription={
                  calculatedDelivery.shipmentPathDescription
                }
                companyDetails={calculatedDelivery.companyCostDetails}
                selectedCompany={selectedCompany}
              />
            </>
          )}
        </StyledMainRow>

        {calculatedDelivery && (
          <>
            {!onlyCarInspection && (
              <>
                <StyledMainDivider />

                <PriceAfterTaxConvertedBlock
                  carPriceOriginal={calculatedDelivery.carPriceOriginal}
                  carPriceConverted={calculatedDelivery.carPriceConverted}
                  taxRate={calculatedDelivery.taxRate}
                  carPriceAfterTaxConverted={
                    calculatedDelivery.carPriceAfterTaxConverted
                  }
                />

                <StyledMainDivider />

                <Clearence calculatedDelivery={calculatedDelivery} />
              </>
            )}

            <CalculateDeliverySectionControls
              onlyCarInspection={onlyCarInspection}
            />

            <AlertInfo
              content={
                <>
                  Итоговая цена зависит от курса валюты, выбора компании и
                  необходимости осмотра авто. <br /> Мы предоставляем
                  максимально точный расчет, но финальная стоимость будет
                  определена компанией.
                </>
              }
            />
          </>
        )}
      </StyledCalcilateDelivery>
    </Section>
  );
};
