export enum GoogleAnanalyticsEvent {
  OpenAskQuestionForm = 'open-ask-question-form',
  SubmitAskQuestionForm = 'submit-ask-question-from',
  OpenOfferCarInspectionForm = 'open-offer-car-inspection-form',
  SubmitOfferCarInspectionForm = 'submit-offer-car-inspection-form',
  OfferIsActual = 'offer-is-actual',
  OpenOfferDeliveryForm = 'open-offer-delivery-form',
  SubmitOfferDeliveryForm = 'submit-offer-delivery-form',
  AddFilterSubscription = 'add-filter-subscription',
  AddOfferSubscription = 'add-offer-subscription',
  SubmitTurnkeyAutoDeliveryForm = 'submit-turnkey-auto-delivery-form',
}
