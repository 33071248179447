export const SCREEN_WIDTH = {
  MAX_WIDTH_APP: 1312,
  MOBILE: 320,
  TABLET: 768,
  DESKTOP: 992,
  TO_DESKTOP_BREAK_1: 1200,
  TO_DESKTOP_BREAK_2: 1100,
  TO_DESKTOP_BREAK_3: 1000,
  TABLET_TO_MOBILE_BREAK_1: 750,
  TABLET_TO_MOBILE_BREAK_2: 650,
  TABLET_TO_MOBILE_BREAK_3: 500,
  TABLET_TO_MOBILE_BREAK_4: 380,
};
