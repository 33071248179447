import { Spin } from 'antd';
import { StyledFilterSubscriptionWrapper } from './ProfileFilterSubscription.styles';
import { useSubscriptionsOfferSelector } from '../../../../store/selectors';
import { useGetSubscriptionsByFilters } from '../../../../hooks/subscriptions';
import { ModalSubscriptionsContextProvider } from '../../../../contexts';
import { BlockTitleWithIcon } from '../BlockTitleWithIcon';
import { CarsNotFound } from '../CarsNotFound';
import { CarSubscriptionsByFilterList } from '../CarSubscriptionsByFilterList';
import { isFetchBaseQueryError } from '../../../../utils/isFetchBaseQueryError';

export const ProfileFilterSubscription = () => {
  const { filterSubscriptions } = useSubscriptionsOfferSelector();
  const { contextHolder, isLoading, isError, error } =
    useGetSubscriptionsByFilters();

  const has404Error =
    isError && isFetchBaseQueryError(error) && error.status === 404;

  return (
    <ModalSubscriptionsContextProvider>
      {contextHolder}
      <Spin size='large' spinning={isLoading}>
        <StyledFilterSubscriptionWrapper>
          <BlockTitleWithIcon
            tooltipDescription={
              <>
                <b>"Подписки по фильтру"</b> позволяют вам получать уведомления
                в Telegram о новых объявлениях по заданному вами фильтру: марка,
                модель, год, цена и т.д.
              </>
            }
            loading={isLoading}
            title='Подписки по фильтру'
            count={
              Array.isArray(filterSubscriptions)
                ? filterSubscriptions.length
                : 0
            }
            withSwitcher={true}
          />
          {Array.isArray(filterSubscriptions) &&
            !!filterSubscriptions.length && (
              <CarSubscriptionsByFilterList loading={isLoading} />
            )}
          {Array.isArray(filterSubscriptions) &&
            !filterSubscriptions.length &&
            !isLoading && <CarsNotFound needLinkMessenger={has404Error} />}
        </StyledFilterSubscriptionWrapper>
      </Spin>
    </ModalSubscriptionsContextProvider>
  );
};
