import { FormInstance } from 'antd';
import { FormState } from '../components/FilterForms';
import { FilterFormFieldNames } from '../../../enums/form';

export const useHandleChange = (
  form: FormInstance<FormState>,
  fieldName: FilterFormFieldNames,
) => {
  const handleChange = (value: string | number[] | undefined) => {
    form.setFieldsValue({
      [fieldName]: value,
    });
  };

  return handleChange;
};
