import styled from 'styled-components';

export const StyledDetailWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
  margin-right: 8px;
  &:last-child {
    margin-right: 0;
  }
`;

export const StyledIconBlock = styled.span`
  display: inline-block;
`;

export const StyledDescriptionBlock = styled.span`
  display: inline-block;
  color: var(--Dark-13);
  font-size: var(--main-font-size);
  font-style: var(--font-style-base);
  font-weight: var(--main-font-weight);
  line-height: 140%;
  letter-spacing: var(--desktop-price-title-letter-spacing);
`;
