import { CSSProperties, FC, ReactNode } from 'react';
import styled from "styled-components";

interface ICustomButton {
  children?: ReactNode;
  onClick?: () => void;
  styles?: CSSProperties;
}

const StyledCustomButton = styled.button`
  background-color: #FFFFFF;
  border: 0;
  cursor: pointer;
  padding: 0;
`;

export const CustomButton: FC<ICustomButton> = ({
                                                  children,
                                                  onClick,
                                                  styles
                                                }) => {
  return (
    <StyledCustomButton onClick={onClick} style={styles}>
      {children}
    </StyledCustomButton>
  );
};
