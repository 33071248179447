import { FormInstance } from 'antd';
import { useEffect } from 'react';
import { PaymentSettingsFormNames } from '../CarPage.enum';
import { Company } from '../../../models/response/companies';

export const useSetDefaultCompany = (
  defaultDeliveryCompany: Company | undefined,
  desktopCalculateDeliveryForm: FormInstance,
) => {
  useEffect(() => {
    if (defaultDeliveryCompany) {
      desktopCalculateDeliveryForm.setFieldsValue({
        [PaymentSettingsFormNames.Company]: defaultDeliveryCompany.id,
      });
    }
  }, [defaultDeliveryCompany]);
};
