export const mobileFormItemTheme = {
  components: {
    Button: {
      controlHeightLG: 36,
      fontSizeLG: 14,
    },
  },
};

export const mobileFormTheme = {
  components: {
    Button: {
      controlHeightLG: 38,
      fontSizeLG: 12,
    },
  },
};
