import { FC } from 'react';
import { Link } from 'react-router-dom';
import { StyledCollapseButton } from './FeaturesCollapseButton.styled';
import { featuresByDefault } from '../../../enums/featuresByDefault';
import { SCREEN_WIDTH } from '../../../screenSettings';
import { useMediaQuery } from 'react-responsive';

type FeaturesCollapseButtonPropsType = {
  isCollapsed: boolean;
  featuresLength: number;
  url?: string;
  action?: () => void;
};

export const FeaturesCollapseButton: FC<FeaturesCollapseButtonPropsType> = ({
  isCollapsed,
  url,
  featuresLength,
  action,
}) => {
  const handleClick = () => {
    if (action) action();
  };
  const isTabletWidth = useMediaQuery({
    query: `(max-width: ${SCREEN_WIDTH.TABLET}px)`,
  });

  const isMobileWidth = useMediaQuery({
    query: `(max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px)`,
  });

  const featuresQuantity =
    (isTabletWidth && featuresByDefault.TABLET) || featuresByDefault.DESKTOP;

  const buttonText = isCollapsed
    ? `Скрыть (${featuresLength - featuresQuantity})`
    : `+${featuresLength - featuresQuantity}...`;

  return isMobileWidth && url ? (
    <Link to={url} target='blank'>
      <StyledCollapseButton type='text' onClick={handleClick}>
        {buttonText}
      </StyledCollapseButton>
    </Link>
  ) : (
    <StyledCollapseButton type='text' onClick={handleClick}>
      {buttonText}
    </StyledCollapseButton>
  );
};
