import styled from 'styled-components';
import { SCREEN_WIDTH } from '../../../../screenSettings';

export const StyledSingleColForm = styled.div`
  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET}px) {
    width: calc(50% - 16px);
  }
  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
    width: 100%;
  }
`;

export const StyledDoubleColForm = styled.div`
  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET}px) {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
  }
  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
    display: block;
  }
`;
