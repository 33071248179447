import { FC, PropsWithChildren, useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';

const sendPageView = (url: string) => {
  ReactGA.send({
    hitType: 'pageview',
    location: url,
    title: document.title,
  });
};

export const TrackPageViews: FC<PropsWithChildren> = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      sendPageView(window.location.href);
    }
  }, [location.pathname]);

  return <>{children}</>;
};
