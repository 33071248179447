import type { MenuProps } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { getAntMenuItem } from '../utils/getAntMenuItem';
import { KeysMenuItem, LabelsMenuItem } from '../enums/menu';
import {
  SubscriptionsFilterCustomIcon,
  BellCustomIcon,
  LogoutCustomIcon,
  SupportCustomIcon,
} from '../img/icons/components';

import { defaultStylesIcon } from './defaultMenuIconStyles';

export const menuItems: MenuProps['items'] = [
  getAntMenuItem(
    LabelsMenuItem.account,
    KeysMenuItem.account,
    <UserOutlined style={defaultStylesIcon} />,
  ),
  getAntMenuItem(
    LabelsMenuItem.subscriptionsFilter,
    KeysMenuItem.subscriptionsFilter,
    <SubscriptionsFilterCustomIcon style={defaultStylesIcon} />,
  ),
  getAntMenuItem(
    LabelsMenuItem.subscriptionsMonitoring,
    KeysMenuItem.subscriptionsMonitoring,
    <BellCustomIcon
      style={{ transform: 'scale(0.8)', ...defaultStylesIcon }}
    />,
  ),

  { type: 'divider' },

  getAntMenuItem(
    LabelsMenuItem.support,
    KeysMenuItem.support,
    <SupportCustomIcon style={{ ...defaultStylesIcon, fontSize: 13 }} />,
  ),
  getAntMenuItem(
    LabelsMenuItem.logout,
    KeysMenuItem.logout,
    <LogoutCustomIcon style={{ ...defaultStylesIcon, fontSize: 16 }} />,
  ),
];
