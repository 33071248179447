import { FC, MouseEvent, KeyboardEvent } from 'react';
import { StyledOptionsDrawer } from './UserOptionsDrawer.styled';
import { DrawerPopoverContentWithMenu } from '../DrawerPopoverContentWithMenu';
import { useMediaQuery } from 'react-responsive';
import { SCREEN_WIDTH } from '../../screenSettings';

type UserOptionsDrawerPropsType = {
  isDrawerOpened: boolean;
  onCloseAction?: () => void;
};

export const UserOptionsDrawer: FC<UserOptionsDrawerPropsType> = ({
  onCloseAction,
  isDrawerOpened,
}) => {
  const isMobileWidth = useMediaQuery({
    query: `(max-width: ${SCREEN_WIDTH.MOBILE}px)`,
  });
  const closeDrawerHandler = (event: MouseEvent | KeyboardEvent) => {
    if (onCloseAction) onCloseAction();
  };

  return (
    <StyledOptionsDrawer
      width={320}
      title={null}
      placement={isMobileWidth ? 'top' : 'right'}
      open={isDrawerOpened}
      maskClosable={true}
      onClose={closeDrawerHandler}
      destroyOnClose={true}
    >
      <DrawerPopoverContentWithMenu
        additionalClickOptionAction={onCloseAction}
        additionalMarginTopDivider={64}
      />
    </StyledOptionsDrawer>
  );
};
