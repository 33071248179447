interface CountiesMapping {
  [key: number]: string;
}

export const countiesMapping: CountiesMapping = {
  1: 'Беларусь',
  2: 'Россия',
  3: 'Россия',
  4: 'Россия',
  5: 'Россия',
  6: 'Россия',
  49: 'Германия',
  31: 'Нидерланды',
  32: 'Бельгия',
  33: 'Франция',
  34: 'Испания',
  39: 'Италия',
};
