import { FC, Fragment, memo } from 'react';
import {
  DeliveryBlock,
  StyledHighlightKey,
  StyledHighlightPricesBlock,
  StyledHighlightValue,
  StyledMediumDivider,
  StyledPricesBlockRow,
  TitleRow,
} from './CalculateDeliverySection.styled';
import { StyledTitle } from '../../../../components/styled/StyledTitle';
import { IconWithTooltip } from '../../../../components/IconWithTooltip';
import { formatNumber } from '../../../../utils/formatNumber';
import { getCurrencySymbol } from '../../../../utils/getCurrencySymbol';
import { AmountCurrency } from '../../../../models/car/CalculateDelivery';

interface PriceBlockProps {
  title: string;
  prices: Array<{
    key: string;
    value: AmountCurrency;
  }>;
}

export const PriceBlock: FC<PriceBlockProps> = memo(({ title, prices }) => (
  <DeliveryBlock>
    <TitleRow>
      <StyledTitle level={3}>{title}</StyledTitle>
    </TitleRow>
    <StyledHighlightPricesBlock>
      {prices.map((price, index) => (
        <Fragment key={index}>
          <StyledPricesBlockRow>
            <StyledHighlightKey>{price.key}</StyledHighlightKey>
            <StyledHighlightValue>
              ~ {formatNumber(price.value.amount)}{' '}
              {getCurrencySymbol(price.value.currency)}
            </StyledHighlightValue>
          </StyledPricesBlockRow>
          {index < prices.length - 1 && <StyledMediumDivider />}
        </Fragment>
      ))}
    </StyledHighlightPricesBlock>
  </DeliveryBlock>
));
