import { FC, ReactNode } from 'react';
import {
  StyledBlock,
  StyledControls,
  StyledControlsWrapper,
  StyledFormElementsWrapper,
  StyledSingleCheckbox,
  StyledSubTitle,
  StyledTitle,
} from './StyledSendRequestModalForm.styled';
import { StyledButton } from '../styled/StyledButton';
import { ModalConfirm } from '../ModalConfirm';
import { CustomInputText } from '../FormElements';
import { FormItemLabel } from '../FormItemLabel';
import { Form, FormInstance, Input, InputNumber, Radio } from 'antd';
import { StyledFormItem } from '../styled/StyledFormItem';
import {
  SendRequestFormFiled,
  SendRequestFormLabel,
  SendRequestFormPlaceholder,
} from './enum';
import { formInitialValues, radioOptions, validationRules } from './data';
import { SendRequestForm } from './interface';
import { formSettings } from '../../config';

interface SendRequestFormProps<T = SendRequestForm> {
  title: ReactNode;
  isOpenModal: boolean;
  setIsOpenModal: (state: boolean) => void;
  sendRequest: (formValues: T) => Promise<void>;
  form: FormInstance;
  isLoading: boolean;
  withMessageField?: boolean;
}

export const SendRequestModalForm: FC<SendRequestFormProps> = ({
  title,
  isOpenModal,
  setIsOpenModal,
  sendRequest,
  form,
  isLoading,
  withMessageField,
}) => {
  const closeModal = () => {
    setIsOpenModal(false);
    form.resetFields();
  };

  return (
    <>
      <ModalConfirm
        withSeparateHeader={true}
        width={500}
        open={isOpenModal}
        zIndex={40}
        title={title}
        maskClosable={true}
        centered
        onCancel={closeModal}
        footer={null}
      >
        <StyledBlock>
          <StyledTitle>Пожалуйста, введите ваши контактные данные</StyledTitle>
          <StyledSubTitle>
            Мы придерживаемся политики конфиденциальности и никому не разглашаем
            вашу информацию.
          </StyledSubTitle>
        </StyledBlock>

        <Form
          form={form}
          initialValues={formInitialValues}
          layout='vertical'
          requiredMark={false}
          onFinish={sendRequest}
        >
          <StyledFormElementsWrapper>
            <CustomInputText
              formItemProps={{
                label: (
                  <FormItemLabel>{SendRequestFormLabel.Name}</FormItemLabel>
                ),
                name: SendRequestFormFiled.Name,
                rules: validationRules.name,
              }}
              inputProps={{ placeholder: SendRequestFormPlaceholder.Name }}
            />

            <StyledFormItem
              name={SendRequestFormFiled.Channel}
              label={
                <FormItemLabel>{SendRequestFormLabel.Channel}</FormItemLabel>
              }
              rules={validationRules.channel}
            >
              <Radio.Group>
                {radioOptions.map((option) => (
                  <Radio key={option.value} value={option.value}>
                    {option.label}
                  </Radio>
                ))}
              </Radio.Group>
            </StyledFormItem>

            <StyledFormItem
              label={
                <FormItemLabel>{SendRequestFormLabel.Phone}</FormItemLabel>
              }
              rules={validationRules.phone}
              name={SendRequestFormFiled.Phone}
            >
              <InputNumber
                size={formSettings.controlSize}
                style={{ width: '100%' }}
                controls={false}
                placeholder={SendRequestFormPlaceholder.Phone}
              />
            </StyledFormItem>

            {withMessageField && (
              <StyledFormItem
                label={
                  <FormItemLabel>{SendRequestFormLabel.Message}</FormItemLabel>
                }
                name={SendRequestFormFiled.Message}
              >
                <Input.TextArea
                  size={formSettings.controlSize}
                  style={{ width: '100%' }}
                  placeholder={SendRequestFormPlaceholder.Message}
                />
              </StyledFormItem>
            )}

            <StyledFormItem
              valuePropName='checked'
              name={SendRequestFormFiled.PersonalDataProcessingConsent}
              rules={validationRules.personalDataProcessingConsent}
            >
              <StyledSingleCheckbox>
                {SendRequestFormLabel.PersonalDataProcessingConsent}
              </StyledSingleCheckbox>
            </StyledFormItem>
          </StyledFormElementsWrapper>

          <StyledControlsWrapper>
            <StyledControls>
              <StyledButton
                size={formSettings.controlSize}
                ghost
                block
                type='primary'
                onClick={closeModal}
              >
                Отменить
              </StyledButton>
              <StyledButton
                size={formSettings.controlSize}
                type='primary'
                block
                htmlType='submit'
                loading={isLoading}
              >
                Отправить заявку
              </StyledButton>
            </StyledControls>
          </StyledControlsWrapper>
        </Form>
      </ModalConfirm>
    </>
  );
};
