import styled from 'styled-components';
import { SCREEN_WIDTH } from '../../../screenSettings';

export const StyledCallToActionSection = styled.section`
  display: block;
  width: 100%;
  max-width: calc(100% - 2 * 35px);
  margin: auto;

  @media only screen and (max-width: ${SCREEN_WIDTH.TO_DESKTOP_BREAK_2}px) {
    max-width: 100%;
  }
`;
