import { FC } from 'react';
import {
  StyledAvatarBlock,
  StyledNameBlock,
  StyledUserOutlined,
  StyledUserName,
  StyledUserEmail,
} from './UserAvatarBlock.styled';
import { useAuthSelector } from '../../store/selectors';

export const UserAvatarBlock: FC = () => {
  const { email, username } = useAuthSelector();

  return (
    <StyledAvatarBlock>
      <StyledUserOutlined />
      <StyledNameBlock>
        <StyledUserName>{email}</StyledUserName>
        <StyledUserEmail>{username}</StyledUserEmail>
      </StyledNameBlock>
    </StyledAvatarBlock>
  );
};
