import { Divider } from 'antd';
import styled from 'styled-components';
import { SCREEN_WIDTH } from '../../../../screenSettings';

export const StyledCalcilateDelivery = styled.div`
  color: var(--Dark-13);
  letter-spacing: 0.027px;
`;

export const StyledHighlitedTextContainer = styled.div`
  display: none;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.03px;

  @media screen and (max-width: ${SCREEN_WIDTH.TABLET}px) {
    display: block;
  }

  @media screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.21px;
  }
`;

export const StyledMainRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;

  @media screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
    gap: 32px;
  }
`;

export const StyledBr = styled.br`
  display: none;

  @media screen and (max-width: ${SCREEN_WIDTH.DESKTOP}px) {
    display: inline-block;
  }
`;

export const DeliveryBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const TitleRow = styled.div`
  display: flex;
  height: 32px;
  align-items: center;
  gap: 8px;
`;

export const StyledMainDivider = styled(Divider)`
  margin: 40px 0;

  @media screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
    margin: 24px 0;
  }
`;

export const StyledMediumDivider = styled(Divider)`
  margin: 12px 0;

  @media screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
    margin: 8px 0;
  }
`;

export const StyledSmallDivider = styled(Divider)`
  margin: 0;
`;

export const StyledHighlightPricesBlock = styled.div`
  border-radius: 8px;
  background: #edf7ef;
  padding: 24px;

  @media screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
    padding: 16px 8px;
  }
`;

export const StyledHighlightKey = styled.div`
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.027px;

  @media screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.18px;
  }
`;

export const StyledHighlightValue = styled.div`
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;

  @media screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.18px;
  }
`;

export const StyledPricesBlockRow = styled.div`
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledControlContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 40px 0 40px 0;
`;

export const TextKeyBold = styled.span`
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-transform: uppercase;

  @media screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.18px;
  }
`;

export const TextKey = styled.span`
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.08px;

  @media screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.18px;
  }
`;

export const TextValueBold = styled.div`
  display: inline-block;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.333px;

  @media screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.18px;
  }
`;

export const StyledKeyBlock = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;

  @media screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
    gap: 12px;
  }
`;

export const StyledVerticalDivider = styled(Divider)`
  height: 24px;
  margin: 0 16px;
`;
