import { SubscriptionType } from '../../enums/subscription';
import { APP_PATHS } from '../../layout/settingsLayout';

export const getSubscriptionProfileUrl = (
  subscriptionType?: SubscriptionType,
) => {
  switch (subscriptionType) {
    case SubscriptionType.Offer:
      return APP_PATHS.PROFILE_PAGE_SUBSCRIPTIONS_OFFER;
    case SubscriptionType.Filter:
      return APP_PATHS.PROFILE_PAGE_SUBSCRIPTIONS_FILTER;
    default:
      return APP_PATHS.PROFILE_PAGE_ACCOUNT;
  }
};
