import { CalculateDeliveryResponse } from '../../../../models/car/CalculateDelivery';

const TOTAL_IMPORT_PRICE = 'Полная растаможка';
const TOTAL_IMPORT_PRICE_WITH_BENEFIT = 'Льготная растаможка';

export const removeLastRoute = (url: string): string => {
  const urlParts = url.split('/');
  urlParts.pop();
  return urlParts.join('/');
};

export const generateTotalPrices = ({
  calculatedDelivery,
  customsClearance,
  carInspectionRequired,
}: {
  calculatedDelivery: CalculateDeliveryResponse;
  customsClearance?: boolean;
  carInspectionRequired?: boolean;
}) => {
  const prices = [];

  if (!customsClearance && !carInspectionRequired) {
    prices.push({
      key: TOTAL_IMPORT_PRICE,
      value: calculatedDelivery.totalImportPriceConverted,
    });
  }

  if (!customsClearance && carInspectionRequired) {
    prices.push({
      key: TOTAL_IMPORT_PRICE,
      value: calculatedDelivery.totalImportPriceWithCarInspectionConverted,
    });
  }

  if (customsClearance && !carInspectionRequired) {
    prices.push({
      key: TOTAL_IMPORT_PRICE_WITH_BENEFIT,
      value: calculatedDelivery.totalImportPriceWithBenefitConverted,
    });
  }

  if (customsClearance && carInspectionRequired) {
    prices.push({
      key: TOTAL_IMPORT_PRICE_WITH_BENEFIT,
      value:
        calculatedDelivery.totalImportPriceWithBenefitAndCarInspectionConverted,
    });
  }

  return prices;
};
