import styled from 'styled-components';
import { SCREEN_WIDTH } from '../../../../screenSettings';

export const StyledFormButtonsWrapper = styled.div`
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 4px;
  background-color: #ffffff;

  &.tablet__wrapper {
    @media only screen and (max-width: ${SCREEN_WIDTH.TABLET}px) {
      padding: 16px 0;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;
      & button {
        width: calc(50% - 12px);
      }
    }

    @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_2}px) {
      padding: 16px 0;
      display: flex;
      flex-direction: column;
      gap: 16px;
      & button {
        width: 100%;
      }
    }
  }

  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET}px) {
    padding: 16px 0;
  }
`;
