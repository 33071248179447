import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useActions } from '../../../hooks/useActions';
import { PaymentSettingsFormNames } from '../CarPage.enum';

export const useControlledFormControls = () => {
  const { setSelectedDeliveryValues } = useActions();

  const handleCarInspectionRequiredChange = (event: CheckboxChangeEvent) => {
    setSelectedDeliveryValues({
      [PaymentSettingsFormNames.CarInspectionRequired]: event.target.checked,
    });
  };

  const handleCustomsClearanceChange = (event: CheckboxChangeEvent) => {
    setSelectedDeliveryValues({
      [PaymentSettingsFormNames.hasCustomsClearanceBenefit]: event.target.checked,
    });
  };

  return {
    handleCarInspectionRequiredChange,
    handleCustomsClearanceChange,
  };
};
