import { SourceId } from '../enums/sourceId';
import { SourceRegionIdTurnKey } from '../enums/sourceRegionId';

interface CountiesMapping {
  [key: number]: string;
}

export const sourceIdMapping: CountiesMapping = {
  [SourceId.Belarus]: 'Беларусь',
  [SourceId.Russia]: 'Россия',
  [SourceId.Europe]: 'Европа',
};

export const turnKeySourceIDMapping: CountiesMapping = {
  [SourceRegionIdTurnKey.Europe]: 'Европа',
  [SourceRegionIdTurnKey.USA]: 'США',
  [SourceRegionIdTurnKey.China]: 'Китай',
};

export const turnKeySourceIDMappingDeclined: CountiesMapping = {
  [SourceRegionIdTurnKey.Europe]: 'Европы',
  [SourceRegionIdTurnKey.USA]: 'США',
  [SourceRegionIdTurnKey.China]: 'Китая',
};

export const turnKeySourceIDOptions = Object.entries(
  turnKeySourceIDMapping,
).map(([value, label]) => ({ label, value }));
