import styled from 'styled-components';
import { Menu, Drawer, Segmented } from 'antd';
import { SCREEN_WIDTH } from '../../screenSettings';
import { Link } from 'react-router-dom';

export const StyledWrapperNavigation = styled.div`
  width: 100%;
  @media only screen and (max-width: ${SCREEN_WIDTH.TO_DESKTOP_BREAK_2}px) {
    width: 22px;
    justify-self: flex-start;
    text-align: left;
  }
`;

export const StyledNavBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--gap-header-nav-desktop);
  width: 100%;
  @media only screen and (max-width: ${SCREEN_WIDTH.DESKTOP}px) {
    gap: var(--gap-header-nav-before-tablet);
  }
`;

export const StyledAntMenu = styled(Menu)`
  background: transparent;
  border: none;
  box-shadow: none;
  width: 100%;
  justify-content: center;

  &.header__menu_vertical.ant-menu {
    border: none;
    border-inline-end-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-header-nav-vertical-desktop);
    & > .ant-menu-item {
      padding-left: 0;
    }
  }

  &.header__menu_horizontal {
    .ant-menu-item {
      display: flex;
      align-items: center;
      justify-content: center;

      //reset menu items styles
      &:hover::after {
        border-bottom-color: transparent;
      }

      &:focus::after {
        border-bottom-color: transparent;
      }

      &:active::after {
        border-bottom-color: transparent;
      }
    }
  }
`;

export const StyledNavLink = styled(Link)`
  color: var(--Gray-7);
  font-size: var(--main-font-size);
  font-style: var(--font-style-base);
  font-weight: var(--main-font-weight);
  line-height: var(--desktop-content-line-height);
  padding: 4px;
  border: 1px solid transparent;
  border-radius: 0;
  letter-spacing: 0.21px;
  transition: all 0.2s;

  &:hover {
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    border-bottom: 1px solid var(--Blue-7);
  }

  &:active {
    border-radius: 4px;
    border: 1px solid var(--Blue-8);
  }

  @media only screen and (max-width: ${SCREEN_WIDTH.DESKTOP}px) {
    padding: 2px;
  }

  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET}px) {
    display: inline-block;
    width: 100%;
  }
`;

export const StyledAntDrawer = styled(Drawer)`
  .ant-drawer-header-title {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
  }

  .ant-drawer-body {
    padding-top: 0;
  }

  .ant-drawer-title {
    color: var(--Blue-13);
    font-size: var(--desktop-content-font-size);
    font-style: var(--font-style-base);
    font-weight: var(--bold-font-weight);
    line-height: var(--desktop-content-line-height);
  }
  .ant-drawer-close {
    margin-right: 0;
    font-size: 20px;
    color: var(--Gray-6);
  }
`;

export const StyledSegmentedContent = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
`;

export const StyledSegmented = styled(Segmented)`
  width: 100%;
  font-weight: 600;
  .ant-segmented-group {
    width: 100%;
    flex: 1;
  }
  .ant-segmented-item {
    width: calc(100% / 3);
  }
`;
