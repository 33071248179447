import styled from 'styled-components';
import { StyledButton } from '../styled/StyledButton';
import { SCREEN_WIDTH } from '../../screenSettings';

export const StyledResponsiveButton = styled(StyledButton)`
  min-width: 189px;

  @media screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
    width: 100%;
  }
`;
