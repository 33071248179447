export const ComfortSvg = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='25'
    height='25'
    viewBox='0 0 25 25'
    fill='none'
  >
    <path
      d='M8.5 14.4978C8.5 14.4978 10 16.4978 12.5 16.4978C15 16.4978 16.5 14.4978 16.5 14.4978M17.5 9.7378C17.105 10.2228 16.565 10.4978 16 10.4978C15.435 10.4978 14.91 10.2228 14.5 9.7378M10.5 9.7378C10.105 10.2228 9.565 10.4978 9 10.4978C8.435 10.4978 7.91 10.2228 7.5 9.7378M22.5 12.4978C22.5 18.0207 18.0228 22.4978 12.5 22.4978C6.97715 22.4978 2.5 18.0207 2.5 12.4978C2.5 6.97496 6.97715 2.4978 12.5 2.4978C18.0228 2.4978 22.5 6.97496 22.5 12.4978Z'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
