export enum SelectOptions {
  any = 'any',
}

export enum Tags {
  lowMileage = 'low_mileage',
  averageMileage = 'average_mileage',
  highMileage = 'high_mileage',
  belowAverageMileage = 'below_average_mileage',
  aboveAverageMileage = 'above_average_mileage',
  lowPrice = 'low_price',
  averagePrice = 'average_price',
  highPrice = 'high_price',
  unknownPriceType = 'unknown_price_type',
  unknownMileageType = 'unknown_mileage_type',
}

export enum CountryViewNameFrom {
  Europe = 'из Европы',
}
