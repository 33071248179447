import styled from 'styled-components';
import { SCREEN_WIDTH } from '../../screenSettings';

export const StyledContent = styled.section`
  margin-top: 32px;
  background-color: #ffffff;
  border-radius: 8px;
  flex: initial !important;
  padding: 24px;

  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
    margin-top: 16px;
    padding: 16px;
    border-radius: 0;
  }
`;
