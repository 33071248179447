import { createContext } from 'react';
import { ICar } from '../../models/car';
import { ISortingFormState } from '../../models/cars';
import { IModel } from '../../models/response/cars';

interface IMainPageContext {
  isErrorCars: boolean;
  isFetchingModels: boolean;
  models: IModel[] | undefined;
  isFetchingCountCars: boolean;
  countCars: number | undefined;
  isLoadingSubscription: boolean;
  cars: ICar[] | undefined;
  isFetchingCars: boolean;
  getCountCars: (sortFormValues?: ISortingFormState) => Promise<number | void>;
  getCars: (sortFormValues?: ISortingFormState) => void;
  filterForm: any;
  filterFormMobile: any;
  sortForm: any;
  onSubscription: (params: any) => Promise<void>;
  isValidPrice: boolean;
  handleCollapseChange: () => void;
  setPage: (page: number) => void;
  setCurrentCount: (countCars: number) => void;
  handleSetMetadata: (currentCount?: number) => void;
  handleMakeChange: (make: string) => Promise<void>;
  handleModelChange: (model: string) => void;
}

export const CarsListPageContext = createContext<IMainPageContext | undefined>(
  undefined,
);
