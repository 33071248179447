import { FC, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { Col, Collapse, Form, Layout, notification } from 'antd';
import { config } from '../../config';
import { SelectOptions } from '../../enums';
import { ReactComponent as AccordionArrowSvg } from '../../img/icons/AccordionArrow.svg';
import { useQueryState } from '../../hooks/useQueryState';
import {
  StyledCarsListWrapper,
  StyledCarsListContent,
  StyledCollapse,
  StyledSider,
  StyledSiderHeader,
} from './StyledCarsPage';
import { CarsListPageContext } from '../../contexts';
import { CarsList, DesktopFilterForm, MobileFilterForm } from './components';
import { useNavigate, useParams } from 'react-router-dom';
import qs from 'qs';
import { useAppSelector } from '../../hooks/redux';
import { APP_PATHS, NAVIGATE_PATHS } from '../../layout/settingsLayout';
import { subscriptionApi } from '../../store/subscription/subscription.api';
import { SubscriptionFilterResponse } from '../../models/response/ISubscription';
import { SubscriptionModelWindow } from '../../components/SubscriptionModelWindow/SubscriptionModelWindow';
import { useSubscriptionError } from '../../hooks/subscriptions/useSubscriptionError';
import { NotificationInstance } from 'antd/es/notification/interface';
import { useIsUserAuth } from '../../hooks/auth/useIsUserAuth';
import { IFilterFormState } from '../../models';
import { useGetCars, useGetCountCars, useGetModels } from '../../hooks/cars';
import { useMediaQuery } from 'react-responsive';
import { SCREEN_WIDTH } from '../../screenSettings';
import { useCarsSelector, useProfileSelector } from '../../store/selectors';
import { StyledTitle } from '../../components/styled/StyledTitle';
import { SubscriptionType } from '../../enums/subscription';
import { GoogleAnanalyticsEvent } from '../../enums/googleAnalyticsAction';
import {
  generateHeaderListTitle,
  generateMetadata,
  getCountryViewName,
} from './utils';
import { Metadata } from '../../components/Metadata';
import { useMetadata } from '../../hooks/seo';

export const CarsPage: FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { currency } = useProfileSelector();
  const [notificationApi, contextHolder] = notification.useNotification();
  const [isInvalidate, setIsInvalidate] = useState<boolean>(false);
  const [isOpenCollapse, setIsOpenCollapse] = useState<boolean>(false);
  // TODO: test all scenario and move to store if needed
  const [currentCount, setCurrentCount] = useState<number | undefined>(
    undefined,
  );
  const isDesktopWidth = useMediaQuery({
    query: `(max-width: ${SCREEN_WIDTH.DESKTOP}px)`,
  });
  const [filterForm] = Form.useForm<any>();
  const [sortForm] = Form.useForm<any>();
  const [filterFormMobile] = Form.useForm<any>();
  const [queryParameters, setQueryParameters] = useQueryState();
  const [headerListTitle, setHeaderListTitle] = useState<string>('');
  const [selectedCountryViewName, setSelectedCountryViewName] =
    useState<string>('');
  const [carSeoText, setCarSeoText] = useState<string>('');
  const metadata = useMetadata(
    APP_PATHS.CARS_PAGE,
    carSeoText,
    selectedCountryViewName,
  );
  const [page, setPage] = useState<number>(+queryParameters.page || 1);
  const { userId } = useAppSelector((state) => state.auth);
  const isAuth = useIsUserAuth();
  const handleSubscriptionError = useSubscriptionError(userId);
  const { carMakes } = useCarsSelector();
  const { getModels, models, isFetchingModels } = useGetModels(notificationApi);
  const { getCountCars, countCars, isFetchingCountCars } = useGetCountCars(
    notificationApi,
    queryParameters,
    params,
  );
  const { getCars, cars, isFetchingCars, isErrorCars } = useGetCars(
    notificationApi,
    queryParameters,
    params,
    setIsInvalidate,
  );

  const [subscriptionFilter, { isLoading: isLoadingSubscription }] =
    subscriptionApi.useSubscriptionFilterMutation();

  const checkIfPriceValid = (): boolean => {
    if (!queryParameters.fromPrice || !queryParameters.toPrice) return true;

    return +queryParameters.fromPrice < +queryParameters.toPrice;
  };

  const handleCollapseChange = (): void => {
    setIsOpenCollapse((prevState: any) => !prevState);
  };

  const handleMakeChange = async (make: string): Promise<void> => {
    const queryString = qs.stringify(queryParameters, {
      addQueryPrefix: true,
      encode: false,
    });

    navigate(
      `${APP_PATHS.CARS_PAGE}/${make === SelectOptions.any ? '' : make.toLowerCase()}${queryString}`,
    );

    if (make !== SelectOptions.any) await getModels(make);
  };

  const handleModelChange = (model: string) => {
    const queryString = qs.stringify(queryParameters, {
      addQueryPrefix: true,
      encode: false,
    });

    navigate(
      `${APP_PATHS.CARS_PAGE}/${params.make?.toLowerCase()}/${model === SelectOptions.any ? '' : model.toLowerCase()}${queryString}`,
    );
  };

  const onSubscription = async (params: IFilterFormState) => {
    if (process.env.NODE_ENV === 'production') {
      ReactGA.event({
        category: 'button',
        action: GoogleAnanalyticsEvent.AddFilterSubscription,
        label: window.location.href,
      });
    }

    if (!isAuth) {
      navigate(NAVIGATE_PATHS.SIGN_IN);
      return;
    }

    if (params.yearFrom === SelectOptions.any) delete params.yearFrom;
    if (params.yearTo === SelectOptions.any) delete params.yearTo;
    if (params.transmission === 0) delete params.transmission;
    delete params.vatDeductible;

    try {
      const response: SubscriptionFilterResponse =
        await subscriptionFilter(params).unwrap();
      const subscriptionViewName = response.subscriptionViewName;

      notificationApi.success({
        message: 'Подписка успешно добавлена!',
        description: (
          <SubscriptionModelWindow
            subscribtionType={SubscriptionType.Filter}
            content={subscriptionViewName}
          />
        ),
        duration: config.DEFAULT_NOTIFICATION_DURATION,
      });
    } catch (error: any) {
      const props = handleSubscriptionError(error, SubscriptionType.Filter);
      notificationApi[props.type as keyof NotificationInstance]({
        ...props,
      } as any);
    }
  };

  const handleSetMetadata = (currentCount?: number) => {
    const makeViewName = carMakes?.find(
      (make) => make.id === params.make?.toUpperCase(),
    )?.viewName;

    const modelViewName = models?.find(
      (model) => model.originalName === params.model?.toUpperCase(),
    )?.viewName;

    const countryViewName = getCountryViewName(queryParameters.country);
    setSelectedCountryViewName(countryViewName);

    const headerListTitle = generateHeaderListTitle({
      makeViewName,
      modelViewName,
      countryViewName,
    });

    setHeaderListTitle(headerListTitle);

    const metadataText = generateMetadata({
      makeViewName,
      modelViewName,
    });

    setCarSeoText(metadataText);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getCars();
  }, [currency]);

  useEffect(() => {
    if (isDesktopWidth) {
      getCountCars();
    }
  }, [isDesktopWidth]);

  useEffect(() => {
    if (currentCount === undefined && !!countCars) {
      handleSetMetadata(countCars);
      setCurrentCount(countCars);
    }
  }, [countCars]);

  useEffect(() => {
    isInvalidate && getCars();
  }, [isInvalidate]);

  useEffect(() => {
    setQueryParameters({ page });
  }, [page]);

  return (
    <CarsListPageContext.Provider
      value={{
        isErrorCars,
        isFetchingModels,
        models,
        isFetchingCountCars,
        countCars,
        isLoadingSubscription,
        cars,
        isFetchingCars,
        filterForm,
        filterFormMobile,
        sortForm,
        isValidPrice: checkIfPriceValid(),
        getCountCars,
        onSubscription,
        getCars,
        handleCollapseChange,
        setPage,
        setCurrentCount,
        handleSetMetadata,
        handleMakeChange,
        handleModelChange,
      }}
    >
      <Metadata title={metadata.title} description={metadata.description} />

      {contextHolder}

      <Layout hasSider style={{ marginTop: 16 }}>
        {!isDesktopWidth && (
          <StyledSider width={340}>
            <StyledSiderHeader>
              <StyledTitle level={2}>Параметры поиска</StyledTitle>
            </StyledSiderHeader>
            <DesktopFilterForm />
          </StyledSider>
        )}
        <div
          style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
        >
          {isDesktopWidth && (
            <Col lg={0} style={{ width: '100%' }}>
              <StyledCollapse
                onChange={handleCollapseChange}
                activeKey={isOpenCollapse ? ['1'] : []}
                expandIconPosition='end'
                size='large'
                expandIcon={({ isActive }) => (
                  <AccordionArrowSvg
                    style={{
                      transform: `rotate(${isActive ? 0 : 180}deg)`,
                      transition: 'transform 0.2s ease-in-out',
                    }}
                  />
                )}
              >
                <Collapse.Panel
                  header={<StyledTitle level={2}>Параметры поиска</StyledTitle>}
                  key='1'
                >
                  <MobileFilterForm />
                </Collapse.Panel>
              </StyledCollapse>
            </Col>
          )}

          <StyledCarsListWrapper>
            <StyledCarsListContent>
              <CarsList
                headerListTitle={headerListTitle}
                currentCount={currentCount}
                page={page}
                setPage={setPage}
                cars={cars}
                isFetchingCars={isFetchingCars}
                isErrorCars={isErrorCars}
                setIsInvalidate={setIsInvalidate}
                sortForm={sortForm}
              />
            </StyledCarsListContent>
          </StyledCarsListWrapper>
        </div>
      </Layout>
    </CarsListPageContext.Provider>
  );
};
