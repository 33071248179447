import { FC, useState, ReactNode, useMemo } from 'react';
import { FeaturesDrawerContext } from './featuresDrawerContext';
import { ICarFeature } from '../../models';

interface FeaturesDrawerProviderProps {
  children: ReactNode;
}

export const FeaturesDrawerContextProvider: FC<FeaturesDrawerProviderProps> = ({
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [header, setHeader] = useState('');
  const [features, setFeatures] = useState<ICarFeature[]>([]);

  const openDrawer = () => setIsOpen(true);

  const closeDrawer = () => setIsOpen(false);

  const updateHeader = (str: string) => setHeader(str);

  const updateFeatures = (newFeatures: ICarFeature[]) =>
    setFeatures(newFeatures);

  const value = useMemo(
    () => ({
      isOpenDrawer: isOpen,
      header,
      setHeader: updateHeader,
      features,
      setFeatures: updateFeatures,
      openDrawer,
      closeDrawer,
    }),
    [features, header, isOpen],
  );

  return (
    <FeaturesDrawerContext.Provider value={value}>
      {children}
    </FeaturesDrawerContext.Provider>
  );
};
