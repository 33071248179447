import styled from 'styled-components';
import { Collapse, Layout, Typography } from 'antd';
import { SCREEN_WIDTH } from '../../screenSettings';

const { Content, Sider } = Layout;

export const StyledSider = styled(Sider)`
  background: #ffffff !important;
  margin-right: 32px;
  border-radius: 8px;
  height: 100%;
`;

export const StyledSiderHeader = styled.div`
  padding: 24px 16px;
  box-shadow: 0px -1px 0px 0px #e1e7f0 inset;
`;

export const StyledCarsListContent = styled(Content)`
  .ant-list-header {
    padding-block: 0;
    box-shadow: 0px -1px 0px 0px #e1e7f0 inset;
    border-block-end: none;
  }
`;

export const StyledCollapse = styled(Collapse)`
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px -1px 0px 0px #e1e7f0 inset;
  border: none;
  margin-bottom: 16px;

  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
    border-radius: 0;
    margin-bottom: 0;
  }

  .ant-collapse-header {
    padding: 24px 16px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .ant-collapse-content-box {
    padding: 24px 16px !important;
  }
`;

export const StyledCarsListWrapper = styled(Content)`
  background-color: #ffffff;
  border-radius: 8px;
  flex: initial !important;
  padding: 0px;

  @media screen and (max-width: ${SCREEN_WIDTH.TABLET}px) {
    padding: 24px 0 0;
  }
  @media screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
    border-radius: 0;
  }
`;
