import { useImperativeHandle, forwardRef, useEffect } from 'react';
import { StyledFormWrapper } from '../TurnKeyForms.styled';
import { Form } from 'antd';
import { useActions } from '../../../../../hooks/useActions';
import { EquipmentFormFieldsType } from '../types/form';
import { StyledEquipmentForm } from './EquipmentForm.styled';
import { StyledTitleForm } from '../shared/styles';
import { EquipmentFormFieldNames } from '../../../../../enums/form';
import { prepareOptionCheckboxGroup } from '../utils/prepareOptionCheckboxGroup';
import {
  useTurnKeyFormsSelector,
  useTurnKeySelector,
} from '../../../../../store/selectors';
import { FormItemLabel } from '../../../../../components/FormItemLabel';
import { CustomCheckboxGroup } from '../../../../../components/FormElements';
import { FormRef } from '../types/form';
import { StepsValues } from '../../../enums/stepsValues';
import { MobileFeaturesCheckboxes } from '../../../../../components/MobileFeaturesCheckboxes';
import { useMediaQuery } from 'react-responsive';
import { SCREEN_WIDTH } from '../../../../../screenSettings';

const formFieldNames = [
  EquipmentFormFieldNames.FeaturesSafetyId,
  EquipmentFormFieldNames.FeaturesComfortId,
  EquipmentFormFieldNames.FeaturesMultimediaId,
  EquipmentFormFieldNames.FeaturesInteriorId,
  EquipmentFormFieldNames.FeaturesExteriorId,
  EquipmentFormFieldNames.FeaturesAdditionalId,
];

export const EquipmentForm = forwardRef<FormRef>((_, ref) => {
  const isMobileWidth = useMediaQuery({
    query: `(max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_1}px)`,
  });

  const { equipmentForm } = useTurnKeyFormsSelector();
  const { categories } = useTurnKeySelector();
  const [form] = Form.useForm<EquipmentFormFieldsType>();

  const { setEquipmentForm, setActiveStep, setMobileInitialCategories } =
    useActions();

  const finishFormHandler = (values: EquipmentFormFieldsType) => {
    if (isMobileWidth) {
      setActiveStep(StepsValues.CarDelivery);
      return;
    }

    setEquipmentForm(values);
    setActiveStep(StepsValues.CarDelivery);
    setMobileInitialCategories(values);
  };

  const submitForm = () => form.submit();

  const resetForm = () => form.resetFields();

  useImperativeHandle(ref, () => ({ submitForm, resetForm }));

  const [
    safetyOptions,
    comfortOptions,
    multimediaOptions,
    interiorOptions,
    exteriorOptions,
    additionalOptions,
  ] = !!categories.length
    ? prepareOptionCheckboxGroup(
        categories,
        formFieldNames.map((elem) => +elem),
      )
    : [];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <StyledFormWrapper style={{ marginBottom: 40 }}>
      <StyledTitleForm style={{ marginLeft: 0 }}>Комплектация</StyledTitleForm>
      {!isMobileWidth && (
        <StyledEquipmentForm
          initialValues={
            equipmentForm[EquipmentFormFieldNames.FeaturesSafetyId]
              ? equipmentForm
              : undefined
          }
          requiredMark={false}
          layout='vertical'
          form={form}
          onFinish={finishFormHandler}
          name='equipmentForm'
        >
          {!!safetyOptions.length && (
            <CustomCheckboxGroup
              formItemProps={{
                name: EquipmentFormFieldNames.FeaturesSafetyId,
                label: <FormItemLabel>Безопасность</FormItemLabel>,
              }}
              options={safetyOptions}
            />
          )}

          {!!comfortOptions.length && (
            <CustomCheckboxGroup
              formItemProps={{
                name: EquipmentFormFieldNames.FeaturesComfortId,
                label: <FormItemLabel>Комфорт</FormItemLabel>,
              }}
              options={comfortOptions}
            />
          )}

          {!!multimediaOptions.length && (
            <CustomCheckboxGroup
              formItemProps={{
                name: EquipmentFormFieldNames.FeaturesMultimediaId,
                label: <FormItemLabel>Мультимедиа</FormItemLabel>,
              }}
              options={multimediaOptions}
            />
          )}

          {!!interiorOptions.length && (
            <CustomCheckboxGroup
              formItemProps={{
                name: EquipmentFormFieldNames.FeaturesInteriorId,
                label: <FormItemLabel>Интерьер</FormItemLabel>,
              }}
              options={interiorOptions}
            />
          )}

          {!!exteriorOptions.length && (
            <CustomCheckboxGroup
              formItemProps={{
                name: EquipmentFormFieldNames.FeaturesExteriorId,
                label: <FormItemLabel>Экстерьер</FormItemLabel>,
              }}
              options={exteriorOptions}
            />
          )}

          {!!additionalOptions.length && (
            <CustomCheckboxGroup
              formItemProps={{
                name: EquipmentFormFieldNames.FeaturesAdditionalId,
                label: <FormItemLabel>Дополнительно</FormItemLabel>,
              }}
              options={additionalOptions}
            />
          )}
        </StyledEquipmentForm>
      )}

      {isMobileWidth && (
        <Form
          initialValues={
            equipmentForm[EquipmentFormFieldNames.FeaturesSafetyId]
              ? equipmentForm
              : undefined
          }
          layout='vertical'
          form={form}
          onFinish={finishFormHandler}
          name='equipmentForm'
        >
          <MobileFeaturesCheckboxes
            categories={categories}
            form={form}
            fieldNames={formFieldNames}
          />
        </Form>
      )}
    </StyledFormWrapper>
  );
});
