export const CarBodyColorSvg = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
  >
    <path
      d='M5.99389 1.50621L0.962109 6.538C0.695297 6.80489 0.54541 7.16683 0.54541 7.54422C0.54541 7.9216 0.695297 8.28354 0.962109 8.55043L4.98697 12.576C5.11915 12.7083 5.27611 12.8133 5.44889 12.8849C5.62166 12.9565 5.80686 12.9934 5.99389 12.9934C6.18093 12.9934 6.36613 12.9565 6.5389 12.8849C6.71168 12.8133 6.86864 12.7083 7.00082 12.576L12.0319 7.54422L5.99389 1.50621ZM5.99389 1.50621L4.98768 0.5'
      stroke='currentColor'
      strokeWidth='0.8'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M13.3189 9.8877C13.3189 9.8877 15.4537 12.0175 15.4537 13.3653C15.4537 14.543 14.4966 15.5002 13.3189 15.5002C12.1412 15.5002 11.1926 14.543 11.1841 13.3653C11.1912 12.0168 13.3189 9.8877 13.3189 9.8877Z'
      stroke='currentColor'
      strokeWidth='0.8'
      strokeMiterlimit='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
