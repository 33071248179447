import styled from 'styled-components';
import { Button } from 'antd';
import { SCREEN_WIDTH } from '../../screenSettings';

export const StyledButton = styled(Button)`
  &,
  &.ant-btn {
    text-transform: uppercase;
    font-weight: var(--bold-font-weight);
    line-height: 20px;
    border-radius: 4px;
  }

  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_2}px) {
    &.ant-btn {
      line-height: 20px;
      letter-spacing: 0.18px;
      width: 100%;
    }
  }
`;
