import { FC } from 'react';
import { FormItemProps } from 'antd';
import { CheckboxGroupProps } from 'antd/es/checkbox';
import {
  StyledFormItem,
  StyledCheckboxGroup,
} from './CustomCheckboxGroup.styled';

interface CustomCheckboxGroupPropsType extends CheckboxGroupProps {
  formItemProps: FormItemProps;
  gridCheckboxes?: boolean;
}

export const CustomCheckboxGroup: FC<CustomCheckboxGroupPropsType> = ({
  formItemProps,
  gridCheckboxes,
  ...checkboxProps
}) => {
  return (
    <StyledFormItem {...formItemProps}>
      <StyledCheckboxGroup
        needgrid={gridCheckboxes ? 1 : 0}
        {...checkboxProps}
      />
    </StyledFormItem>
  );
};
