import { FC } from 'react';
import { Input, InputProps } from 'antd';
import { FormItemProps } from 'antd/es/form';
import { formSettings } from '../../../config';
import { StyledFormItem } from '../../styled/StyledFormItem';

type CustomInputTextPropsType = {
  formItemProps: FormItemProps;
  inputProps: InputProps;
};

export const CustomInputText: FC<CustomInputTextPropsType> = ({
  formItemProps,
  inputProps,
}) => {
  return (
    <StyledFormItem {...formItemProps}>
      <Input size={formSettings.controlSize} {...inputProps} />
    </StyledFormItem>
  );
};
