import styled from 'styled-components';
import { Image, Button } from 'antd';
import { SCREEN_WIDTH } from '../../../../../screenSettings';

export const StyledGalleryFullscreenContent = styled.div`
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .fullscreen-car__gallery {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .image-gallery-content {
      width: 100%;
    }

    .image-gallery-slides {
      border-radius: 8px;

      @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
        border-radius: 0px;
      }
    }

    .image-gallery-image {
      width: 100%;
      object-fit: cover;
      overflow: hidden;
      object-position: center center;
      height: 800px;

      @media only screen and (max-width: ${SCREEN_WIDTH.TO_DESKTOP_BREAK_1}px) {
        height: 700px;
      }
      @media only screen and (max-width: ${SCREEN_WIDTH.TO_DESKTOP_BREAK_3}px) {
        height: 650px;
      }
      @media only screen and (max-width: ${SCREEN_WIDTH.DESKTOP}px) {
        height: 600px;
      }

      @media only screen and (max-width: ${SCREEN_WIDTH.TABLET}px) {
        height: 470px;
      }

      @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_1}px) {
        height: 280px;
      }
    }
  }

  & .image-gallery-bullets {
    bottom: -40px;
    @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_1}px) {
      bottom: -100px;
    }
  }

  .image-gallery-bullets {
    bottom: -20px;

    @media only screen and (max-width: ${SCREEN_WIDTH.TABLET}px) {
      bottom: -30px;
    }
    @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_2}px) {
      bottom: -90px;
    }
    .image-gallery-bullet {
      box-shadow: none;
      @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
        padding: 2px;
      }
    }
    .image-gallery-bullet:not(.active) {
      background-color: #afbbcc;
      border-color: #afbbcc;
    }
  }
`;

export const StyledAntImage = styled(Image)`
  &.ant-image-img {
    width: 100%;
    border-radius: 8px;
  }
`;

export const StyledGalleryFullScreenNavButton = styled(Button)`
  width: 64px !important;
  height: 64px !important;
  position: absolute;
  top: 50%;
  box-shadow: none;
  border: none;
  background-color: transparent;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_1}px) {
    width: 40px !important;
    height: 40px !important;
  }

  &:hover,
  &:active,
  &:target {
    border: none;
    background-color: transparent;
  }
  &:disabled .ant-btn-icon {
    color: gray;
  }
  & .ant-btn-icon {
    color: white;
  }
  & .ant-btn-icon:hover,
  & .ant-btn-icon:target,
  & .ant-btn-icon:focus {
    color: var(--Gray-6);
  }
`;

export const StyledFullScreenLeftButton = styled(
  StyledGalleryFullScreenNavButton,
)`
  left: -50px;
  transform: translate(-50%, -50%);
  @media only screen and (max-width: ${SCREEN_WIDTH.DESKTOP}px) {
    left: -20px;
  }
  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET}px) {
    left: 20px;
  }
`;

export const StyledFullScreenRightButton = styled(
  StyledGalleryFullScreenNavButton,
)`
  right: -50px;
  transform: translate(50%, -50%);
  @media only screen and (max-width: ${SCREEN_WIDTH.DESKTOP}px) {
    right: -20px;
  }
  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET}px) {
    right: 20px;
  }
`;
