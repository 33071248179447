export const SubscriptionsFilterSvg = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    viewBox='0 0 16 16'
    fill='none'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5.33203 12C5.33203 11.7239 5.55589 11.5 5.83203 11.5H10.1654C10.4415 11.5 10.6654 11.7239 10.6654 12C10.6654 12.2761 10.4415 12.5 10.1654 12.5H5.83203C5.55589 12.5 5.33203 12.2761 5.33203 12ZM3.33203 8C3.33203 7.72386 3.55589 7.5 3.83203 7.5H12.1654C12.4415 7.5 12.6654 7.72386 12.6654 8C12.6654 8.27614 12.4415 8.5 12.1654 8.5H3.83203C3.55589 8.5 3.33203 8.27614 3.33203 8ZM2 4C2 3.72386 2.22386 3.5 2.5 3.5H13.5C13.7761 3.5 14 3.72386 14 4C14 4.27614 13.7761 4.5 13.5 4.5H2.5C2.22386 4.5 2 4.27614 2 4Z'
      fill='currentColor'
    />
  </svg>
);
