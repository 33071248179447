import styled from 'styled-components';
import { Button } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { SCREEN_WIDTH } from '../../../screenSettings';
import { ArrowDownIcon } from '../../../img/icons/components';

export const StyledUserNameWithOptionsButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
  &.ant-btn {
    padding: 6px 0;
    .ant-btn-icon.ant-btn-icon {
      margin-right: 0;
      margin-inline-end: 0;
    }
  }

  &:hover,
  &:active {
    background: transparent;
  }
`;

export const StyledUserOutlinedIcon = styled(UserOutlined)`
  font-size: 25px;
  color: var(--Blue-13);
`;

export const StyledPopoverToggleIcon = styled(ArrowDownIcon)`
  font-size: 14px;
  color: var(--Blue-13);
  margin-left: 0;

  &&& {
    //to increase a specificity of selectors is used triple parent sign
    margin-inline-start: 0;
  }
`;

export const StyledUserName = styled.span`
  color: var(--Blue-13);
  font-size: var(--main-font-size);
  font-weight: var(--main-font-weight);
  line-height: var(--desktop-content-line-height);
  letter-spacing: var(--desktop-letter-spacing);
  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_2}px) {
    display: none;
  }
`;
