import styled from 'styled-components';
import { SCREEN_WIDTH } from '../../../../screenSettings';

export const StyledTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  width: 100%;
  padding: 24px;
  box-shadow: 0px -1px 0px 0px #e1e7f0 inset;

  @media screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_1}px) {
    padding: 20px 16px;
  }
`;

export const StyledTitleText = styled.h1`
  margin-bottom: 0;
  color: var(--Dark-13);
  font-size: var(--big-title-size);
  font-style: var(--font-style-base);
  font-weight: var(--bold-font-weight);
  line-height: var(--title-account-line-height);

  @media screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
    font-size: var(--mobile-title-size);
  }
`;
