import { initialValuesFilterForm } from '../../constants';
import { FilterFormFieldNames } from '../../enums/form';
import { convertToArray } from '../../utils/convertToNumberArray';

const generateFormFields = (
  queryParameters: any,
  routeParams: { make: string | undefined; model: string | undefined },
) => {
  return [
    {
      name: [FilterFormFieldNames.country],
      value: convertToArray(queryParameters.country),
    },
    {
      name: [FilterFormFieldNames.regionId],
      value: convertToArray(queryParameters.regionId),
    },
    { name: [FilterFormFieldNames.model], value: routeParams.model },
    { name: [FilterFormFieldNames.make], value: routeParams.make },
    { name: [FilterFormFieldNames.yearFrom], value: queryParameters.yearFrom },
    { name: [FilterFormFieldNames.yearTo], value: queryParameters.yearTo },
    {
      name: [FilterFormFieldNames.currency],
      value:
        queryParameters.currency ||
        initialValuesFilterForm[FilterFormFieldNames.currency],
    },
    {
      name: [FilterFormFieldNames.fromPrice],
      value: queryParameters.fromPrice,
    },
    { name: [FilterFormFieldNames.toPrice], value: queryParameters.toPrice },
    {
      name: [FilterFormFieldNames.vatDeductible],
      value: queryParameters.vatDeductible,
    },
    {
      name: [FilterFormFieldNames.transmission],
      value: +queryParameters.transmission || 0,
    },
    {
      name: [FilterFormFieldNames.engineType],
      value: convertToArray(queryParameters.engineType),
    },
    {
      name: [FilterFormFieldNames.engineCapacityFrom],
      value: queryParameters.engineCapacityFrom,
    },
    {
      name: [FilterFormFieldNames.engineCapacityTo],
      value: queryParameters.engineCapacityTo,
    },
    {
      name: [FilterFormFieldNames.typeOfDrive],
      value: convertToArray(queryParameters.typeOfDrive),
    },
    {
      name: [FilterFormFieldNames.bodyType],
      value: convertToArray(queryParameters.bodyType),
    },
    {
      name: [FilterFormFieldNames.toMileage],
      value: queryParameters.toMileage,
    },
  ];
};

export const FormUtils = {
  generateFormFields,
};
