import styled from 'styled-components';
import { SCREEN_WIDTH } from '../../../screenSettings';

export const StyledFeatureTag = styled.div`
  color: var(--Dark-13);
  font-size: var(--main-font-size);
  letter-spacing: var(--desktop-letter-spacing);
  font-style: var(--font-style-base);
  font-weight: var(--bold-font-weight);
  line-height: var(--desktop-content-line-height);
  padding: var(--padding-feature-tag-desktop);
  border-radius: 8px;
  background: var(--Gray-2);
  @media screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
    font-size: var(--content-mobile-size);
  }
`;
