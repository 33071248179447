import styled from 'styled-components';

export const StyledSwitcherWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
`;

export const StyledDescription = styled.div`
  color: var(--Dark-13);
  font-size: var(--desktop-content-font-size);
  font-style: var(--font-style-base);
  font-weight: var(--main-font-weight);
  line-height: var(--content-desktop-line-height);
  letter-spacing: 0.024px;
`;
