import { ReactNode } from 'react';
import {
  CheckCircleOutlined,
  SyncOutlined,
  CloseCircleOutlined,
  ClockCircleOutlined,
} from '@ant-design/icons';
import { Tags } from '../../../enums';

export const tags: Record<
  string,
  { label: string; color: string; icon?: ReactNode }
> = {
  [Tags.lowMileage]: {
    label: 'небольшой пробег',
    color: 'success',
    icon: <CheckCircleOutlined rev='true' />,
  },
  [Tags.averageMileage]: {
    label: 'средний пробег',
    color: 'blue',
    icon: <SyncOutlined rev='true' />,
  },
  [Tags.highMileage]: {
    label: 'большой пробег',
    color: 'error',
    icon: <CloseCircleOutlined rev='true' />,
  },
  [Tags.belowAverageMileage]: {
    label: 'пробег ниже среднего',
    color: 'green',
    icon: <CloseCircleOutlined rev='true' />,
  },
  [Tags.aboveAverageMileage]: {
    label: 'пробег выше среднего',
    color: 'orange',
    icon: <ClockCircleOutlined rev='true' />,
  },
  [Tags.lowPrice]: {
    label: 'низкая цена',
    color: 'success',
    icon: <CheckCircleOutlined rev='true' />,
  },
  [Tags.averagePrice]: {
    label: 'средняя цена',
    color: 'warning',
    icon: <SyncOutlined rev='true' />,
  },
  [Tags.highPrice]: {
    label: 'высокая цена',
    color: 'error',
    icon: <CloseCircleOutlined rev='true' />,
  },
  [Tags.unknownPriceType]: {
    label: '',
    color: '',
  },
  [Tags.unknownMileageType]: {
    label: '',
    color: '',
  },
};
