import styled from 'styled-components';
import { SCREEN_WIDTH } from '../../screenSettings';

export const StyledSubscriptionIcon = styled.div`
  position: absolute;
  z-index: 10;
  top: 0;
  right: 2%;

  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET}px) {
    right: 1%;
  }
  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_1}px) {
    right: 3%;
    top: 1%;
  }
  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
    right: 4%;
    top: 0;
  }
`;
