import { createContext } from 'react';
import { IModel } from '../../models/response/cars';

export interface TurnKeyCarContext {
  models?: IModel[];
  selectedSourceIdViewName?: string;
  titleText?: string;
  handleSetModels: (models?: IModel[]) => void;
}

const initialContext: TurnKeyCarContext = {
  handleSetModels: () => {},
};

export const TurnKeyCarContext =
  createContext<TurnKeyCarContext>(initialContext);
