import styled from 'styled-components';
import { Typography } from 'antd';
import { SCREEN_WIDTH } from '../../../../screenSettings';

const { Text, Paragraph } = Typography;

export const StyledSectionTitle = styled(Paragraph)<{ pageLocation?: string }>`
  color: var(--Dark-13);
  font-size: var(--section-title-desktop-font-size);
  font-style: var(--font-style-base);
  font-weight: var(--bold-font-weight);
  line-height: var(--content-desktop-line-height);
  letter-spacing: var(--desktop-title-letter-spacing);
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  &.ant-typography {
    margin-bottom: 16px;
  }

  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET}px) {
    &.ant-typography {
      margin-bottom: 8px;
    }
  }

  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
    font-size: var(--mobile-title-size);
    letter-spacing: var(--mobile-title-letter-spacing);
  }
`;

export const StyledTextBold = styled.span`
  font-weight: 600;
`;

export const StyledTitle = styled(Text)`
  color: var(--Dark-13);
  font-size: var(--section-title-desktop-font-size);
  font-weight: var(--main-font-weight);
  line-height: var(--content-desktop-line-height);
  letter-spacing: var(--desktop-title-letter-spacing);

  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_2}px) {
    font-size: 16px;
    line-height: 20px;
    width: calc(100% - 16px);
  }
  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
    font-size: 14px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;

    & .location__region {
      display: inline-block;
      margin-left: 4px;
    }
  }
`;
