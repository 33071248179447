import { FC, PropsWithChildren } from 'react';
import { StyledContent } from '../../../../components/styled/StyledContent';
import {
  StyledAuthPageContainer,
  StyledAuthPageContent,
} from './AuthLayout.styled';

export const AuthLayout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <StyledContent>
      <StyledAuthPageContainer>
        <StyledAuthPageContent>{children}</StyledAuthPageContent>
      </StyledAuthPageContainer>
    </StyledContent>
  );
};
