import { FC } from 'react';
import { useCarInfoContext } from '../../../../hooks/car';
import { Col, Image, Row, Typography } from 'antd';
import {
  StyledSectionTitle,
  StyledTextBold,
  StyledTitle,
} from '../shared/styled';
import { IRecommendation } from '../../../../models';
import { SwiperSlide, useSwiper } from 'swiper/react';
import 'swiper/css';
import { CarCard } from '../../../../components/CarCard/CarCard';
import { CustomButton } from '../../../../components/Buttons/CustomButton';
import ArrowRightBlack from '../../../../img/icons/ArrowBlack.svg';
import { useMediaQuery } from 'react-responsive';
import {
  StyledSimilarAdsContainer,
  StyledSwiper,
  StyledSwiperControlsContainer,
  StyledTitleSimilarAdsContainer,
} from './StyledSimilarAdsSection';
import { SCREEN_WIDTH } from '../../../../screenSettings';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { useProfileSelector } from '../../../../store/selectors';
import { recommendationRegionIdsMappingDeclined } from '../../../../data/mappings';

const { Paragraph } = Typography;

const SwiperNavigation: FC = () => {
  const swiper = useSwiper();
  const isMobileWidth = useMediaQuery({
    query: `(max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_4}px)`,
  });

  return !isMobileWidth ? (
    <StyledSwiperControlsContainer>
      <CustomButton onClick={() => swiper.slidePrev()}>
        <Image
          width={24}
          height={24}
          src={ArrowRightBlack}
          alt='prev button'
          preview={false}
        />
      </CustomButton>

      <CustomButton onClick={() => swiper.slideNext()}>
        <Image
          src={ArrowRightBlack}
          width={24}
          height={24}
          alt='prev button'
          preview={false}
        />
      </CustomButton>
    </StyledSwiperControlsContainer>
  ) : (
    <></>
  );
};

export const SimilarAdsSection: FC = () => {
  const params = useParams();
  const { carRecommendations, resultRecommendations } = useCarInfoContext();
  const { currency } = useProfileSelector();
  const isTabletWidth = useMediaQuery({
    query: `(max-width: ${SCREEN_WIDTH.TABLET}px)`,
  });
  const isDesktopWidth = useMediaQuery({
    query: `(max-width: ${SCREEN_WIDTH.DESKTOP}px)`,
  });
  const isMobileWidth = useMediaQuery({
    query: `(max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px)`,
  });

  return (
    <Row style={{ width: '100%' }}>
      <Col span={24}>
        <StyledSectionTitle style={{ marginBottom: isTabletWidth ? 8 : 16 }}>
          Рекомендации
        </StyledSectionTitle>

        {carRecommendations &&
          carRecommendations.recommendations?.length === 0 && (
            <Paragraph
              style={{ fontSize: 16, marginBottom: 0 }}
              type='secondary'
            >
              <ExclamationCircleOutlined
                rev='true'
                style={{ marginRight: 8 }}
              />
              <span>Рекомендаций не найдено</span>
            </Paragraph>
          )}

        {carRecommendations &&
          !!carRecommendations.recommendations?.length &&
          resultRecommendations &&
          resultRecommendations.map(
            (recommendationRegion: any, index: number) => (
              <StyledSimilarAdsContainer
                key={`${index}-${recommendationRegion[index]}`}
              >
                <StyledTitleSimilarAdsContainer>
                  <StyledTitle>
                    Похожие объявления в{' '}
                    {recommendationRegion[index] && (
                      <StyledTextBold className='location__region'>
                        {
                          recommendationRegionIdsMappingDeclined[
                            recommendationRegion[index]?.sourceId
                          ]
                        }
                      </StyledTextBold>
                    )}
                  </StyledTitle>
                </StyledTitleSimilarAdsContainer>

                <StyledSwiper
                  spaceBetween={
                    (isMobileWidth && 6) || (isTabletWidth && 8) || 10
                  }
                  slidesPerView={
                    (isMobileWidth && 1.1) ||
                    (isTabletWidth && 2.2) ||
                    (isDesktopWidth && 3) ||
                    4
                  }
                  loop={false}
                >
                  {recommendationRegion.map(
                    (recommendation: IRecommendation, index: number) => (
                      <SwiperSlide
                        key={`${recommendation.adId}-${index}similar-advert`}
                      >
                        <CarCard
                          car={recommendation}
                          showSmallerSize={true}
                          currency={currency}
                          makeId={params.make as string}
                          modelId={params.model as string}
                          // tagType={tagType from server response directly}
                          //TODO when at BE create a tag with recommendations - add it to the card
                        />
                      </SwiperSlide>
                    ),
                  )}
                  {!isTabletWidth && recommendationRegion.length > 4 && (
                    <SwiperNavigation />
                  )}
                </StyledSwiper>
              </StyledSimilarAdsContainer>
            ),
          )}
      </Col>
    </Row>
  );
};
