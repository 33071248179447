import { FC, LabelHTMLAttributes, PropsWithChildren } from 'react';
import { StyledFormItemLabel } from './FormItemLabel.styled';

interface FormItemLabelProps extends LabelHTMLAttributes<HTMLLabelElement> {}

export const FormItemLabel: FC<PropsWithChildren<FormItemLabelProps>> = ({
  children,
  ...props
}) => {
  return <StyledFormItemLabel {...props}>{children}</StyledFormItemLabel>;
};
