export const InstagramSVG = () => <svg className="InstagramSVG" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                       xmlns="http://www.w3.org/2000/svg">
  <path
    d="M18.437 21H5.56299C4.89995 21 4.26406 20.7366 3.79522 20.2677C3.32638 19.7989 3.06299 19.163 3.06299 18.5V5.62598C3.06299 4.96294 3.32638 4.32705 3.79522 3.85821C4.26406 3.38937 4.89995 3.12598 5.56299 3.12598H18.437C19.1 3.12598 19.7359 3.38937 20.2048 3.85821C20.6736 4.32705 20.937 4.96294 20.937 5.62598V18.5C20.937 19.163 20.6736 19.7989 20.2048 20.2677C19.7359 20.7366 19.1 21 18.437 21ZM5.56299 4.12598C5.16516 4.12598 4.78363 4.28401 4.50233 4.56532C4.22102 4.84662 4.06299 5.22815 4.06299 5.62598V18.5C4.06299 18.8978 4.22102 19.2793 4.50233 19.5606C4.78363 19.8419 5.16516 20 5.56299 20H18.437C18.8348 20 19.2163 19.8419 19.4976 19.5606C19.779 19.2793 19.937 18.8978 19.937 18.5V5.62598C19.937 5.22815 19.779 4.84662 19.4976 4.56532C19.2163 4.28401 18.8348 4.12598 18.437 4.12598H5.56299Z"
    fill="white"/>
  <path
    d="M12 16.633C11.0961 16.632 10.2128 16.363 9.4618 15.8601C8.71075 15.3572 8.12567 14.643 7.7805 13.8076C7.43533 12.9722 7.34558 12.0533 7.52259 11.1669C7.6996 10.2806 8.13543 9.46659 8.77497 8.82788C9.41452 8.18917 10.2291 7.75441 11.1157 7.57856C12.0023 7.4027 12.9211 7.49365 13.756 7.83991C14.5909 8.18617 15.3044 8.77219 15.8063 9.52389C16.3082 10.2756 16.576 11.1592 16.5758 12.0631C16.5743 13.2756 16.0915 14.438 15.2335 15.2948C14.3755 16.1517 13.2125 16.633 12 16.633ZM12 8.48198C11.2931 8.48296 10.6024 8.69341 10.0151 9.08674C9.42774 9.48007 8.97015 10.0386 8.7001 10.6919C8.43005 11.3451 8.35965 12.0638 8.49779 12.757C8.63594 13.4502 8.97643 14.087 9.47626 14.5868C9.97609 15.0866 10.6128 15.4271 11.3061 15.5653C11.9993 15.7034 12.7179 15.633 13.3712 15.363C14.0244 15.0929 14.583 14.6353 14.9763 14.048C15.3697 13.4607 15.5801 12.7699 15.5811 12.0631C15.5811 11.1133 15.2038 10.2024 14.5322 9.53086C13.8606 8.85927 12.9498 8.48198 12 8.48198Z"
    fill="white"/>
  <path
    d="M17.1729 7.98954C17.7635 7.98954 18.2422 7.51078 18.2422 6.92019C18.2422 6.3296 17.7635 5.85083 17.1729 5.85083C16.5823 5.85083 16.1035 6.3296 16.1035 6.92019C16.1035 7.51078 16.5823 7.98954 17.1729 7.98954Z"
    fill="white"/>
</svg>
