import styled from 'styled-components';
import { Menu } from 'antd';

export const StyledAntMenu = styled(Menu)`
  .ant-menu-item {
    padding-left: 8px;
    & > span:first-child {
      margin-left: 2px;
    }
  }

  .ant-menu-submenu-title {
    margin-left: 2px;
  }

  .ant-menu-title-content {
    font-size: var(--desktop-content-font-size);
    font-style: var(--font-style-base);
    font-weight: var(--main-font-weight);
    line-height: var(--desktop-content-line-height);
    letter-spacing: var(--mobile-title-letter-spacing);
  }

  .ant-menu-item-selected .ant-menu-title-content {
    font-weight: var(--bold-font-weight);
  }

  .ant-menu-item-divider {
    background: var(--Gray-5);
    margin-top: 64px;
    margin-bottom: 10px;
    border-top-width: 0.5px;
  }

  .ant-menu.ant-menu-sub .ant-menu-item {
    justify-content: flex-start;
    & > span:first-child {
      margin-left: 20px;
    }
  }
`;
