import {
  Affix,
  Checkbox,
  ConfigProvider,
  Form,
  InputNumber,
  Segmented,
  Typography,
} from 'antd';
import React, { useEffect, useState } from 'react';
import {
  bodyTypes,
  countries,
  currencies,
  engineSizes,
  engineTypes,
  mileages,
  russiaCountryValue,
  russiaRegionId,
  transmissions,
  typesOfDrive,
  years,
} from '../../../../data';
import {
  StyledSingleColForm,
  StyledDoubleColForm,
} from './MobileFilterForm.styled';
import {
  FilterCellSingle,
  PairFilterCell,
  FilterCellMulti,
} from '../../../../components/MobileFormElements';
import { SelectOptions } from '../../../../enums';
import { useCarsListPageContext } from '../../../../hooks/carsList';
import { useQueryState } from '../../../../hooks/useQueryState';
import { SegmentedValue } from 'antd/es/segmented';
import { StyledButton } from '../../../../components/styled/StyledButton';
import { convertToArray } from '../../../../utils/convertToNumberArray';
import qs from 'qs';
import { useNavigate, useParams } from 'react-router-dom';
import { FormUtils } from '../../FormUtils';
import { config, formSettings } from '../../../../config';
import { StyledFormButtonsWrapper } from '../shared/StyledFormButtonsWrapper';
import { APP_PATHS } from '../../../../layout/settingsLayout';
import { IFilterFormState } from '../../../../models';
import { SCREEN_WIDTH } from '../../../../screenSettings';
import { useMediaQuery } from 'react-responsive';
import { FormItemLabel } from '../../../../components/FormItemLabel';
import {
  initialValuesFilterForm,
  initialValuesSortForm,
} from '../../../../constants';
import { mobileFormTheme } from '../../../../themeSettings';
import {
  carsFormActionsHooks,
  carsFormDisabledHooks,
} from '../../../../hooks/cars';
import { getCountText } from '../../../../utils/cars';
import { FilterFormFieldNames } from '../../../../enums/form';
import { useCarsSelector } from '../../../../store/selectors';
import { useGetMakesQuery } from '../../../../store/cars/cars.api';
import { ResetButton } from '../../../../components/Buttons/ResetButton';
import { useDebounceCb } from '../../../../hooks/useDebounceCb';

export const MobileFilterForm: React.FC = () => {
  const { carMakes: makes } = useCarsSelector();
  const {
    isFetchingModels,
    models,
    isFetchingCountCars,
    countCars,
    isLoadingSubscription,
    filterFormMobile,
    getCars,
    getCountCars,
    isValidPrice,
    handleCollapseChange,
    onSubscription,
    setCurrentCount,
    handleSetMetadata,
    setPage,
    handleMakeChange,
    handleModelChange,
  } = useCarsListPageContext();
  const { isLoading: isFetchingMakes } = useGetMakesQuery();
  const params = useParams();
  const navigate = useNavigate();
  const [bottom] = useState(0);
  const [queryParameters, setQueryParameters] = useQueryState();

  const handleFetchCars = async (): Promise<void> => {
    window.scrollTo(0, 0);
    setPage(1);
    handleCollapseChange();

    if (!!countCars) {
      handleSetMetadata(countCars);
      setCurrentCount(countCars);
    }
    if (!queryParameters.page || queryParameters.page === '1') getCars();
  };

  const onClear = async (): Promise<void> => {
    const queryString = qs.stringify(
      {
        ...initialValuesFilterForm,
        ...initialValuesSortForm,
        page: '1',
      },
      { addQueryPrefix: true, encode: false, arrayFormat: 'repeat' },
    );

    navigate(`${APP_PATHS.CARS_PAGE}${queryString}`);
  };

  const handleFromPriceChange = (fromPrice: number | null) => {
    setQueryParameters({ [FilterFormFieldNames.fromPrice]: fromPrice });
  };

  const handleToPriceChange = (toPrice: number | null) => {
    setQueryParameters({ [FilterFormFieldNames.toPrice]: toPrice });
  };

  const debouncedHandleFromPriceChange = useDebounceCb(
    handleFromPriceChange,
    config.FORM.DEBOUNCE,
  );

  const debouncedHandleToPriceChange = useDebounceCb(
    handleToPriceChange,
    config.FORM.DEBOUNCE,
  );

  useEffect(() => {
    if (isValidPrice) getCountCars();
  }, [
    queryParameters.country,
    queryParameters.regionId,
    params.model,
    params.make,
    queryParameters.yearFrom,
    queryParameters.yearTo,
    queryParameters.currency,
    queryParameters.transmission,
    queryParameters.engineType,
    queryParameters.engineCapacityFrom,
    queryParameters.engineCapacityTo,
    queryParameters.typeOfDrive,
    queryParameters.bodyType,
    queryParameters.toMileage,
    queryParameters.fromPrice,
    queryParameters.toPrice,
    queryParameters.vatDeductible,
  ]);

  if (process.env.NODE_ENV === 'development') {
    console.log('[MOBILE FILTER FORM RENDER]');
  }

  const handleValidation = (params: IFilterFormState): boolean => {
    let errors = [];

    if (params.make === SelectOptions.any) {
      errors.push({
        name: FilterFormFieldNames.make,
        errors: ['Выберите марку авто'],
      });
    }

    if (params.model === SelectOptions.any) {
      errors.push({
        name: FilterFormFieldNames.model,
        errors: ['Выберите модель авто'],
      });
    }

    if (errors.length > 0) {
      filterFormMobile.setFields(errors);
      return false;
    }

    return true;
  };

  const handleSubscription = (params: IFilterFormState) => {
    const isValid = handleValidation(params);

    if (!isValid) return;

    onSubscription(params);
  };

  const isMobileWidth = useMediaQuery({
    query: `(max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px)`,
  });

  const formatParams = (param: string | undefined) => {
    return param === SelectOptions.any ? param : param?.toUpperCase();
  };

  return (
    <ConfigProvider theme={mobileFormTheme}>
      <Form
        requiredMark={false}
        name='filterFormMobile'
        layout='vertical'
        autoComplete='off'
        onFinish={handleSubscription}
        form={filterFormMobile}
        scrollToFirstError={true}
        colon={false}
        fields={FormUtils.generateFormFields(queryParameters, {
          make: params.make || SelectOptions.any,
          model: params.model || SelectOptions.any,
        })}
      >
        <StyledDoubleColForm>
          <StyledSingleColForm>
            <FilterCellMulti
              form={filterFormMobile}
              label='Страна'
              name={FilterFormFieldNames.country}
              placeholder='Выберите страну'
              value={convertToArray(queryParameters.country)}
              callback={(countries: number[] | undefined) =>
                carsFormActionsHooks[FilterFormFieldNames.country](
                  countries,
                  russiaCountryValue,
                  setQueryParameters,
                )
              }
              options={countries}
            />
            <FilterCellMulti
              form={filterFormMobile}
              label='Регионы'
              name={FilterFormFieldNames.regionId}
              placeholder='Выберите регионы'
              value={convertToArray(queryParameters.regionId)}
              callback={(regionIds: number[] | undefined) =>
                setQueryParameters({
                  [FilterFormFieldNames.regionId]: regionIds,
                })
              }
              options={russiaRegionId}
              disabled={carsFormDisabledHooks[FilterFormFieldNames.regionId](
                queryParameters.country,
                russiaCountryValue,
              )}
            />
            <FilterCellSingle
              form={filterFormMobile}
              withSearch={true}
              options={makes}
              loading={isFetchingMakes}
              disabled={isFetchingMakes}
              label='Марка'
              name={FilterFormFieldNames.make}
              placeholder='Выберите марку'
              value={formatParams(params.make)}
              callback={handleMakeChange}
              required={true}
            />
            <FilterCellSingle
              form={filterFormMobile}
              options={models}
              disabled={
                params.make === SelectOptions.any ||
                !models?.length ||
                !params.make ||
                isFetchingMakes ||
                isFetchingModels
              }
              loading={isFetchingModels}
              label='Mодель'
              name={FilterFormFieldNames.model}
              placeholder='Выберите модель'
              value={formatParams(params.model)}
              callback={handleModelChange}
              required={true}
            />
            <PairFilterCell
              form={filterFormMobile}
              label='Год'
              nameFrom={FilterFormFieldNames.yearFrom}
              nameTo={FilterFormFieldNames.yearTo}
              placeholder='Выберите год'
              valueFrom={queryParameters.yearFrom}
              valueTo={queryParameters.yearTo}
              options={years}
              callback={setQueryParameters}
            />

            {process.env.REACT_APP_CURRENCY_SEGMENTED_FEATURE === 'true' && (
              <Form.Item
                name={FilterFormFieldNames.currency}
                label={<FormItemLabel>Валюта</FormItemLabel>}
                rules={[{ required: true, message: 'Обязательное поле' }]}
              >
                <Segmented
                  required={true}
                  style={{ fontWeight: 600 }}
                  block
                  size='large'
                  onChange={(currency: SegmentedValue) =>
                    setQueryParameters({
                      [FilterFormFieldNames.currency]: currency,
                    })
                  }
                  options={currencies}
                />
              </Form.Item>
            )}

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: 8,
              }}
            >
              <Form.Item
                style={{ width: '100%' }}
                label={<FormItemLabel>Cтоимость</FormItemLabel>}
                name={FilterFormFieldNames.fromPrice}
                rules={[
                  {
                    pattern: /^[0-9]*$/,
                    message: 'Введите целое число!',
                  },
                ]}
              >
                <InputNumber
                  onChange={debouncedHandleFromPriceChange}
                  style={{ width: '100%' }}
                  size={formSettings.controlSize}
                  min={0}
                  step={1}
                  max={99999999999}
                  controls={false}
                  placeholder='от'
                  maxLength={15}
                />
              </Form.Item>

              <Form.Item
                name={FilterFormFieldNames.toPrice}
                style={{ width: '100%' }}
                label={
                  <Typography.Title level={4} style={{ margin: 0, opacity: 0 }}>
                    Cтоимость
                  </Typography.Title>
                }
                rules={[
                  {
                    pattern: /^[0-9]*$/,
                    message: 'Введите целое число!',
                  },
                ]}
              >
                <InputNumber
                  onChange={debouncedHandleToPriceChange}
                  style={{ width: '100%' }}
                  size={formSettings.controlSize}
                  min={0}
                  step={1}
                  max={99999999999}
                  controls={false}
                  placeholder='до'
                  maxLength={15}
                />
              </Form.Item>
            </div>
          </StyledSingleColForm>

          <StyledSingleColForm>
            {isMobileWidth && (
              <Form.Item name={FilterFormFieldNames.vatDeductible}>
                <FormItemLabel>
                  <Checkbox
                    checked={queryParameters.vatDeductible}
                    onChange={(e) =>
                      setQueryParameters({
                        [FilterFormFieldNames.vatDeductible]: e.target.checked,
                      })
                    }
                  >
                    Только с вычетом НДС
                  </Checkbox>
                </FormItemLabel>
              </Form.Item>
            )}

            <FilterCellMulti
              form={filterFormMobile}
              label='Тип двигателя'
              name={FilterFormFieldNames.engineType}
              placeholder='Выберите тип двигателя'
              value={convertToArray(queryParameters.engineType)}
              callback={(engineType: number[] | undefined) =>
                setQueryParameters({
                  [FilterFormFieldNames.engineType]: engineType,
                })
              }
              options={engineTypes}
            />
            <PairFilterCell
              form={filterFormMobile}
              label='Объем двигателя'
              nameFrom={FilterFormFieldNames.engineCapacityFrom}
              nameTo={FilterFormFieldNames.engineCapacityTo}
              placeholder='Выберите объем двигателя'
              valueFrom={queryParameters.engineCapacityFrom}
              valueTo={queryParameters.engineCapacityTo}
              options={engineSizes}
              callback={setQueryParameters}
            />
            <Form.Item
              name={FilterFormFieldNames.transmission}
              label={<FormItemLabel>Коробка передач</FormItemLabel>}
            >
              <Segmented
                style={{ fontWeight: 600 }}
                onChange={(transmission: SegmentedValue) =>
                  setQueryParameters({
                    [FilterFormFieldNames.transmission]: transmission,
                  })
                }
                block
                size='large'
                options={transmissions}
              />
            </Form.Item>
            <FilterCellMulti
              form={filterFormMobile}
              label='Тип кузова'
              name={FilterFormFieldNames.bodyType}
              placeholder='Выберите тип кузова'
              value={convertToArray(queryParameters.bodyType)}
              callback={(bodyType: number[] | undefined) =>
                setQueryParameters({
                  [FilterFormFieldNames.bodyType]: bodyType,
                })
              }
              options={bodyTypes}
            />
            <FilterCellMulti
              form={filterFormMobile}
              label='Тип привода'
              name={FilterFormFieldNames.typeOfDrive}
              placeholder='Выберите тип привода'
              value={convertToArray(queryParameters.typeOfDrive)}
              callback={(typeOfDrive: number[] | undefined) =>
                setQueryParameters({
                  [FilterFormFieldNames.typeOfDrive]: typeOfDrive,
                })
              }
              options={typesOfDrive}
            />
            <FilterCellSingle
              form={filterFormMobile}
              options={mileages}
              label='Пробег'
              name={FilterFormFieldNames.toMileage}
              placeholder='Выберите пробег'
              value={queryParameters.toMileage}
              callback={(toMileage: any) =>
                setQueryParameters({
                  [FilterFormFieldNames.toMileage]: toMileage,
                })
              }
            />
          </StyledSingleColForm>
        </StyledDoubleColForm>
        <Affix
          offsetBottom={bottom}
          style={
            !isMobileWidth
              ? { display: 'flex', flexWrap: 'wrap', width: '100%' }
              : {}
          }
        >
          <StyledFormButtonsWrapper className='tablet__wrapper'>
            <StyledButton
              size='large'
              disabled={!countCars || !isValidPrice}
              loading={isFetchingCountCars}
              block
              type='primary'
              htmlType='button'
              onClick={handleFetchCars}
            >
              {getCountText(isFetchingCountCars, countCars)}
            </StyledButton>
            <StyledButton
              size='large'
              htmlType='submit'
              ghost
              type='primary'
              loading={isLoadingSubscription || isFetchingCountCars}
              block
              disabled={!isValidPrice}
            >
              Подписаться на уведомления
            </StyledButton>
            <ResetButton
              danger
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 4,
              }}
              onClick={onClear}
            >
              Сбросить всё
            </ResetButton>
          </StyledFormButtonsWrapper>
        </Affix>
      </Form>
    </ConfigProvider>
  );
};
