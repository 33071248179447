import styled from 'styled-components';
import { Select } from 'antd';

export const StyledCurrencyWrapper = styled.div`
  //
`;

export const StyledAntSelect = styled(Select)`
  & {
    display: flex;
    width: 100%;
    align-items: baseline;
    justify-content: space-between;

    && .ant-select-arrow {
      left: 32px;
    }
    && .ant-select-selector {
      border: none;
      height: fit-content;
      padding: 0;
      padding-right: 15px;
    }
    &.ant-select:hover .ant-select-selector {
      border: none;
    }
    .ant-select-selection-item {
      color: var(--Blue-13);
      font-size: var(--main-font-size);
      font-style: var(--font-style-base);
      font-weight: var(--main-font-weight);
      line-height: var(--desktop-content-line-height);
      letter-spacing: var(--desktop-letter-spacing);
    }
  }
`;
