import { FC, CSSProperties } from 'react';
import {
  StyledTitleWithIcon,
  StyledIcon,
  StyledTitle,
} from './TitleWithIcon.styled';
import { getTitleWithIconByCategory } from '../../utils/getTitleWithIconByCategory';

type TitleWithIconProps = {
  categoryID: number;
	smallerDescription?:boolean;
  styles?: CSSProperties;
};

export const TitleWithIcon: FC<TitleWithIconProps> = ({
  categoryID,
  styles,
	smallerDescription
}) => {
  const { icon, description } = getTitleWithIconByCategory(categoryID);

  return (
    <StyledTitleWithIcon style={{ ...styles }}>
      <StyledIcon>{icon}</StyledIcon>
      <StyledTitle smallersize={smallerDescription}>{description}</StyledTitle>
    </StyledTitleWithIcon>
  );
};
