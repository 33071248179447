import { CategoriesType } from '../../../../../models/turn-key/GetCarFeatures';
import { getGrouppedCategories } from '../../../../../utils/getGrouppedCategories';

const DESKTOP_TABLET_CHECKBOXES_QUANTITY = 15;

export const prepareOptionCheckboxGroup = (
  categories: CategoriesType[],
  ids: number[],
) => {
  const res = getGrouppedCategories(categories, ids);
  return res.map((elem) =>
    elem.options.slice(0, DESKTOP_TABLET_CHECKBOXES_QUANTITY),
  );
};
