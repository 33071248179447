import { Button } from 'antd';
import { FC, PropsWithChildren } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import styled from 'styled-components';

const StyledCleanLink = styled(Button)`
  padding: 0;
  font-size: 14px;
`;

export const CleanLink: FC<PropsWithChildren<LinkProps>> = ({
  children,
  ...props
}) => {
  return (
    <Link {...props}>
      <StyledCleanLink type='link'>{children}</StyledCleanLink>
    </Link>
  );
};
