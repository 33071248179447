import styled from 'styled-components';
import { SCREEN_WIDTH } from '../../../../screenSettings';

export const StyledBody = styled.div`
  color: var(--Gray-9);
  display: inline-block;
  font-size: var(--main-font-size);
  font-style: var(--font-style-base);
  font-weight: var(--main-font-weight);
  line-height: var(--desktop-content-line-height);
  letter-spacing: var(--desktop-letter-spacing);

  @media only screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_1}px) {
    font-size: 14px;
  }

  span.modal__highlight {
    display: inline-block;
    padding: 0 2px;
    font-weight: var(--extra-bold-font-weight);
  }
`;
