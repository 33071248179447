import { FC, CSSProperties } from 'react';
import { IRecommendation } from '../../../models';
import { ICar } from '../../../models/car';
import { bodyTypes, engineTypes, transmissions } from '../../../data';
import { findLabelByValue } from '../../../utils/findLabelByValue';
import { formatNumber } from '../../../utils/formatNumber';
import { StyledCarDetailsText } from './CarDetails.styled';

interface ICarDetailsProps {
  car: ICar | IRecommendation;
  style?: CSSProperties;
}

export const CarDetails: FC<ICarDetailsProps> = ({ car, style }) => {
  const detailsToDisplay = [];

  detailsToDisplay.push(
    car.yearOfIssue < 0
      ? new Date().getFullYear() + '\u00A0г.'
      : car.yearOfIssue + '\u00A0г.',
  );

  if (car.transmissionType) {
    detailsToDisplay.push(
      findLabelByValue(transmissions, car.transmissionType)?.toLowerCase(),
    );
  }

  if (car.engineType) {
    detailsToDisplay.push(
      findLabelByValue(engineTypes, car.engineType)?.toLowerCase(),
    );
  }

  if (car.engineCapacity > 0) {
    detailsToDisplay.push(car.engineCapacity + '\u00A0л');
  }

  if (car.bodyType) {
    detailsToDisplay.push(
      findLabelByValue(bodyTypes, car.bodyType)?.toLowerCase(),
    );
  }

  if (car.mileage > 0) {
    detailsToDisplay.push(formatNumber(car.mileage) + '\u00A0км');
  }

  return (
    <StyledCarDetailsText style={style}>
      {detailsToDisplay.join(', ')}
    </StyledCarDetailsText>
  );
};
