import styled from 'styled-components';
import { SCREEN_WIDTH } from '../../../../screenSettings';

export const StyledProfileSettingsAccount = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
`;

export const StyledTitleAccount = styled.h1`
  width: 100%;
  color: var(--Dark-13);
  font-size: var(--big-title-size);
  font-style: var(--font-style-base);
  font-weight: var(--bold-font-weight);
  line-height: var(--title-account-line-height);
  padding: var(--padding-title-content-desktop);
  margin-bottom: 0;
  box-shadow: var(--account-content-shadow);
  @media screen and (max-width: ${SCREEN_WIDTH.TABLET_TO_MOBILE_BREAK_3}px) {
    padding: var(--padding-title-content-mobile);
    font-size: var(--mobile-title-size);
  }
`;
