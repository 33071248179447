import { CaretDownOutlined } from '@ant-design/icons';
import {
  Affix,
  Checkbox,
  ConfigProvider,
  Form,
  InputNumber,
  Segmented,
  Select,
  Space,
  Spin,
} from 'antd';
import { CSSProperties, FC, useEffect } from 'react';
import {
  bodyTypes,
  countries,
  currencies,
  engineSizes,
  engineTypes,
  mileages,
  russiaCountryValue,
  russiaRegionId,
  transmissions,
  typesOfDrive,
  years,
} from '../../../../data';
import { SelectOptions } from '../../../../enums';
import { useCarsListPageContext } from '../../../../hooks/carsList';
import { StyledButton } from '../../../../components/styled/StyledButton';
import { useNavigate, useParams } from 'react-router-dom';
import { useQueryState } from '../../../../hooks/useQueryState';
import { SegmentedValue } from 'antd/es/segmented';
import { useDebounceCb } from '../../../../hooks/useDebounceCb';
import qs from 'qs';
import { config, formSettings } from '../../../../config';
import { FormUtils } from '../../FormUtils';
import { StyledFormButtonsWrapper } from '../shared/StyledFormButtonsWrapper';
import { CheckIcon } from '../../../../img/icons/components';
import { StyledDesktopFilterForm } from './DesktopFilterForm.styled';
import { APP_PATHS } from '../../../../layout/settingsLayout';
import { FormItemLabel } from '../../../../components/FormItemLabel';
import {
  initialValuesFilterForm,
  initialValuesSortForm,
} from '../../../../constants';
import {
  carsFormActionsHooks,
  carsFormDisabledHooks,
} from '../../../../hooks/cars';
import { getCountText } from '../../../../utils/cars';
import { FilterFormFieldNames } from '../../../../enums/form';
import { MakesModelSelect } from '../../../../components/FormElements/MakesModelSelect';
import { useCarsSelector } from '../../../../store/selectors';
import { useGetMakesQuery } from '../../../../store/cars/cars.api';
import { FormFieldName } from '../../../../enums/form';
import { ResetButton } from '../../../../components/Buttons/ResetButton';

const { Option } = Select;

const OptionStyle: CSSProperties = {
  height: 36,
  fontWeight: 100,
  display: 'flex',
  alignItems: 'center',
  marginBottom: 2,
};

export const DesktopFilterForm: FC = () => {
  const {
    isFetchingModels,
    models,
    isFetchingCountCars,
    countCars,
    isLoadingSubscription,
    filterForm,
    isValidPrice,
    getCountCars,
    onSubscription,
    getCars,
    setCurrentCount,
    handleSetMetadata,
    setPage,
    handleMakeChange,
    handleModelChange,
  } = useCarsListPageContext();

  const { isLoading: isFetchingMakes } = useGetMakesQuery();

  const params = useParams();
  const { carMakes: makes } = useCarsSelector();
  const navigate = useNavigate();
  const [queryParameters, setQueryParameters] = useQueryState();

  const handleFetchCars = async (): Promise<void> => {
    window.scrollTo(0, 0);
    setPage(1);

    if (!!countCars) {
      handleSetMetadata(countCars);
      setCurrentCount(countCars);
    }
    if (!queryParameters.page || queryParameters.page === '1') getCars();
  };

  const onClear = async (): Promise<void> => {
    const queryString = qs.stringify(
      {
        ...initialValuesFilterForm,
        ...initialValuesSortForm,
        page: '1',
      },
      { addQueryPrefix: true, encode: false, arrayFormat: 'repeat' },
    );

    navigate(`${APP_PATHS.CARS_PAGE}${queryString}`);
  };

  useEffect(() => {
    if (isValidPrice) getCountCars();
  }, [
    queryParameters.country,
    queryParameters.regionId,
    params.model,
    params.make,
    queryParameters.yearFrom,
    queryParameters.yearTo,
    queryParameters.currency,
    queryParameters.transmission,
    queryParameters.engineType,
    queryParameters.engineCapacityFrom,
    queryParameters.engineCapacityTo,
    queryParameters.typeOfDrive,
    queryParameters.bodyType,
    queryParameters.toMileage,
    queryParameters.fromPrice,
    queryParameters.toPrice,
    queryParameters.vatDeductible,
  ]);

  if (process.env.NODE_ENV === 'development') {
    console.log('[FITER FORM RENDER]');
  }

  const handleFromPriceChange = (fromPrice: number | null) => {
    setQueryParameters({ [FilterFormFieldNames.fromPrice]: fromPrice });
  };

  const debouncedHandleFromPriceChange = useDebounceCb(
    handleFromPriceChange,
    config.FORM.DEBOUNCE,
  );

  const handleToPriceChange = (toPrice: number | null) => {
    setQueryParameters({ [FilterFormFieldNames.toPrice]: toPrice });
  };

  const debouncedHandleToPriceChange = useDebounceCb(
    handleToPriceChange,
    config.FORM.DEBOUNCE,
  );

  return (
    <StyledDesktopFilterForm.StyledFormWrapper>
      <Form
        requiredMark={false}
        name='filterFormDesktop'
        layout='vertical'
        autoComplete='off'
        onFinish={onSubscription}
        form={filterForm}
        scrollToFirstError={true}
        fields={FormUtils.generateFormFields(queryParameters, {
          make:
            !makes || params.make === SelectOptions.any
              ? undefined
              : params.make?.toUpperCase(),
          model:
            !models || params.model === SelectOptions.any
              ? undefined
              : params.model?.toUpperCase(),
        })}
      >
        <Form.Item
          name={FilterFormFieldNames.country}
          label={<FormItemLabel>Страна</FormItemLabel>}
        >
          <Select
            style={{ color: '#FFFFFF' }}
            showSearch={false}
            mode='multiple'
            onChange={(countries: number[] | undefined) =>
              carsFormActionsHooks[FilterFormFieldNames.country](
                countries,
                russiaCountryValue,
                setQueryParameters,
              )
            }
            notFoundContent={null}
            placeholder='Выберите страну'
            maxTagCount='responsive'
            suffixIcon={<CaretDownOutlined rev='true' />}
            menuItemSelectedIcon={<CheckIcon />}
            listHeight={264}
            optionLabelProp='label'
          >
            {countries.map((option) => (
              <Option
                style={OptionStyle}
                key={option.value}
                value={option.value}
                label={option.label}
              >
                <Space>{option.label}</Space>
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name={FilterFormFieldNames.regionId}
          label={<FormItemLabel>Регионы</FormItemLabel>}
        >
          <Select
            style={{ color: '#FFFFFF' }}
            showSearch={false}
            mode='multiple'
            onChange={(regionIds) =>
              setQueryParameters({ [FilterFormFieldNames.regionId]: regionIds })
            }
            notFoundContent={null}
            placeholder='Выберите регионы'
            maxTagCount='responsive'
            suffixIcon={<CaretDownOutlined rev='true' />}
            menuItemSelectedIcon={<CheckIcon />}
            listHeight={264}
            optionLabelProp='label'
            disabled={carsFormDisabledHooks[FilterFormFieldNames.regionId](
              queryParameters.country,
              russiaCountryValue,
            )}
          >
            {russiaRegionId.map((option, index) => (
              <Option
                style={OptionStyle}
                key={`${index}-${option.value}`}
                value={option.value}
                label={option.label}
                id={option.value}
              >
                <Space>{option.label}</Space>
              </Option>
            ))}
          </Select>
        </Form.Item>

        <MakesModelSelect
          key='makes'
          modeProps={FormFieldName.Make}
          onChange={handleMakeChange}
          disabled={!makes || isFetchingMakes}
          notFoundContent={isFetchingMakes ? <Spin size='large' /> : null}
          placeholder={'Выберите марку'}
          listHeight={264}
        >
          {makes &&
            makes.map((option, index: number) => (
              <Option
                style={OptionStyle}
                key={`${index}-${option.viewName}`}
                value={option.id}
                label={option.viewName}
                id={option.id}
              >
                {option.viewName}
              </Option>
            ))}
        </MakesModelSelect>

        <MakesModelSelect
          key='model'
          modeProps={FormFieldName.Model}
          disabled={
            !models ||
            isFetchingModels ||
            params[FilterFormFieldNames.make] === SelectOptions.any
          }
          onChange={handleModelChange}
          notFoundContent={isFetchingModels ? <Spin size='large' /> : null}
          placeholder={'Выберите модель'}
          listHeight={264}
        >
          {models &&
            models.map((option, index: number) => (
              <Option
                style={OptionStyle}
                key={`${index}-${option.originalName}`}
                //todo: after connect endpoint check interface IModel
                value={option.originalName || option.id}
                label={option.viewName || option.originalName}
                id={option.id}
              >
                {option.viewName}
              </Option>
            ))}
        </MakesModelSelect>

        <div style={{ width: '100%', display: 'flex', gap: 8 }}>
          <Form.Item
            style={{ width: '100%' }}
            name={FilterFormFieldNames.yearFrom}
            label={<FormItemLabel>Год</FormItemLabel>}
          >
            <Select
              onChange={(yearFrom) =>
                setQueryParameters({
                  [FilterFormFieldNames.yearFrom]: yearFrom,
                })
              }
              style={{ width: '100%' }}
              showSearch
              size='large'
              placeholder='от'
              menuItemSelectedIcon={<CheckIcon />}
              suffixIcon={<CaretDownOutlined rev='true' />}
              listHeight={264}
            >
              {years.map((option, index) => (
                <Option
                  style={OptionStyle}
                  key={`${index}-${option.value}`}
                  value={option.value}
                  label={option.label}
                  id={option.value}
                  disabled={+option.value > +queryParameters.yearTo}
                >
                  {option.label}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            style={{ width: '100%' }}
            name={FilterFormFieldNames.yearTo}
            label={<FormItemLabel>до</FormItemLabel>}
          >
            <Select
              onChange={(yearTo) =>
                setQueryParameters({ [FilterFormFieldNames.yearTo]: yearTo })
              }
              style={{ width: '100%' }}
              showSearch
              size='large'
              placeholder='до'
              menuItemSelectedIcon={<CheckIcon />}
              suffixIcon={<CaretDownOutlined rev='true' />}
              listHeight={264}
            >
              {years.map((option, index) => (
                <Option
                  style={OptionStyle}
                  key={`${index}-${option.value}`}
                  value={option.value}
                  label={option.label}
                  id={option.value}
                  disabled={+option.value < +queryParameters.yearFrom}
                >
                  {option.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>

        {process.env.REACT_APP_CURRENCY_SEGMENTED_FEATURE === 'true' && (
          <Form.Item
            name={FilterFormFieldNames.currency}
            label={<FormItemLabel>Валюта</FormItemLabel>}
          >
            <Segmented
              required={true}
              style={{ fontWeight: 600 }}
              block
              size='large'
              onChange={(currency: SegmentedValue) =>
                setQueryParameters({
                  [FilterFormFieldNames.currency]: currency,
                })
              }
              options={currencies}
            />
          </Form.Item>
        )}

        <div
          style={{ display: 'flex', justifyContent: 'space-between', gap: 8 }}
        >
          <Form.Item
            style={{ width: '100%' }}
            label={<FormItemLabel>Cтоимость</FormItemLabel>}
            name={FilterFormFieldNames.fromPrice}
            rules={[
              {
                pattern: /^[0-9]*$/,
                message: 'Введите целое число!',
              },
            ]}
          >
            <InputNumber
              onChange={debouncedHandleFromPriceChange}
              style={{ width: '100%' }}
              size={formSettings.controlSize}
              min={0}
              step={1}
              max={99999999999}
              controls={false}
              placeholder='от'
              maxLength={15}
            />
          </Form.Item>

          <Form.Item
            name={FilterFormFieldNames.toPrice}
            style={{ width: '100%' }}
            label={
              <FormItemLabel style={{ margin: 0, opacity: 0 }}>
                Cтоимость
              </FormItemLabel>
            }
            rules={[
              {
                pattern: /^[0-9]*$/,
                message: 'Введите целое число!',
              },
            ]}
          >
            <InputNumber
              onChange={debouncedHandleToPriceChange}
              style={{ width: '100%' }}
              size={formSettings.controlSize}
              min={0}
              step={1}
              max={99999999999}
              controls={false}
              placeholder='до'
              maxLength={15}
            />
          </Form.Item>
        </div>

        <Form.Item name={FilterFormFieldNames.vatDeductible}>
          <FormItemLabel>
            <Checkbox
              checked={queryParameters.vatDeductible}
              onChange={(e) =>
                setQueryParameters({
                  [FilterFormFieldNames.vatDeductible]: e.target.checked,
                })
              }
            >
              Только с вычетом НДС
            </Checkbox>
          </FormItemLabel>
        </Form.Item>

        <Form.Item
          name={FilterFormFieldNames.engineType}
          label={<FormItemLabel>Тип двигателя</FormItemLabel>}
        >
          <Select
            onChange={(engineType) =>
              setQueryParameters({
                [FilterFormFieldNames.engineType]: engineType,
              })
            }
            style={{ color: '#FFFFFF' }}
            showSearch={false}
            notFoundContent={null}
            mode='multiple'
            placeholder='Выберите тип двигателя'
            maxTagCount='responsive'
            menuItemSelectedIcon={<CheckIcon />}
            suffixIcon={<CaretDownOutlined rev='true' />}
            listHeight={264}
            optionLabelProp='label'
          >
            {engineTypes.map((option, index) => (
              <Option
                style={OptionStyle}
                key={`${index}-${option.value}`}
                value={option.value}
                label={option.label}
              >
                <Space>{option.label}</Space>
              </Option>
            ))}
          </Select>
        </Form.Item>

        <div style={{ width: '100%', display: 'flex', gap: 8 }}>
          <Form.Item
            style={{ width: '100%' }}
            name={FilterFormFieldNames.engineCapacityFrom}
            label={<FormItemLabel>Объем двигателя</FormItemLabel>}
          >
            <Select
              onChange={(engineCapacityFrom) =>
                setQueryParameters({
                  [FilterFormFieldNames.engineCapacityFrom]: engineCapacityFrom,
                })
              }
              style={{ width: '100%' }}
              showSearch
              size='large'
              placeholder='от'
              menuItemSelectedIcon={<CheckIcon />}
              suffixIcon={<CaretDownOutlined rev='true' />}
              listHeight={264}
            >
              {engineSizes.map((option, index) => (
                <Option
                  style={OptionStyle}
                  key={`${index}-${option.value}`}
                  value={option.value}
                  label={option.label}
                  id={option.value}
                  disabled={
                    +option.value >
                    +filterForm.getFieldValue(
                      FilterFormFieldNames.engineCapacityTo,
                    )
                  }
                >
                  {option.label}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            style={{ width: '100%' }}
            name={FilterFormFieldNames.engineCapacityTo}
            label={
              <FormItemLabel style={{ opacity: 0, margin: 0 }}>
                до
              </FormItemLabel>
            }
          >
            <Select
              onChange={(engineCapacityTo) =>
                setQueryParameters({
                  [FilterFormFieldNames.engineCapacityTo]: engineCapacityTo,
                })
              }
              style={{ width: '100%' }}
              showSearch
              size='large'
              placeholder='до'
              menuItemSelectedIcon={<CheckIcon />}
              suffixIcon={<CaretDownOutlined rev='true' />}
              listHeight={264}
            >
              {engineSizes.map((option, index) => (
                <Select.Option
                  style={OptionStyle}
                  key={`${index}-${option.value}`}
                  value={option.value}
                  label={option.label}
                  id={option.value}
                  disabled={
                    +option.value <
                    +filterForm.getFieldValue(
                      FilterFormFieldNames.engineCapacityFrom,
                    )
                  }
                >
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>

        <Form.Item
          name={FilterFormFieldNames.transmission}
          label={<FormItemLabel>Коробка передач</FormItemLabel>}
        >
          <Segmented
            required={true}
            style={{ fontWeight: 600 }}
            onChange={(transmission: SegmentedValue) =>
              setQueryParameters({
                [FilterFormFieldNames.transmission]: transmission,
              })
            }
            block
            size='large'
            options={transmissions}
          />
        </Form.Item>

        <Form.Item
          name={FilterFormFieldNames.bodyType}
          label={<FormItemLabel>Тип кузова</FormItemLabel>}
        >
          <Select
            onChange={(bodyType) =>
              setQueryParameters({ [FilterFormFieldNames.bodyType]: bodyType })
            }
            style={{ color: '#FFFFFF' }}
            showSearch={false}
            notFoundContent={null}
            mode='multiple'
            placeholder='Выберите тип кузова'
            maxTagCount='responsive'
            menuItemSelectedIcon={<CheckIcon />}
            suffixIcon={<CaretDownOutlined rev='true' />}
            listHeight={264}
            optionLabelProp='label'
          >
            {bodyTypes.map((option, index) => (
              <Option
                style={OptionStyle}
                key={`${index}-${option.value}`}
                value={option.value}
                label={option.label}
                id={option.value}
              >
                <Space>{option.label}</Space>
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name={FilterFormFieldNames.typeOfDrive}
          label={<FormItemLabel>Тип привода</FormItemLabel>}
        >
          <Select
            mode='multiple'
            onChange={(typeOfDrive) =>
              setQueryParameters({
                [FilterFormFieldNames.typeOfDrive]: typeOfDrive,
              })
            }
            style={{ color: '#FFFFFF' }}
            showSearch={false}
            options={typesOfDrive}
            maxTagCount='responsive'
            notFoundContent={null}
            placeholder='Выберите тип привода'
            menuItemSelectedIcon={<CheckIcon />}
            suffixIcon={<CaretDownOutlined rev='true' />}
            listHeight={264}
            optionLabelProp='label'
          >
            {typesOfDrive.map((option, index) => (
              <Option
                style={OptionStyle}
                key={`${index}-${option.value}`}
                value={option.value}
                label={option.label}
                id={option.value}
              >
                <Space>{option.label}</Space>
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name={FilterFormFieldNames.toMileage}
          label={<FormItemLabel>Пробег</FormItemLabel>}
        >
          <Select
            onChange={(toMileage) =>
              setQueryParameters({
                [FilterFormFieldNames.toMileage]: toMileage,
              })
            }
            showSearch={true}
            size='large'
            placeholder='Выберите пробег'
            menuItemSelectedIcon={<CheckIcon />}
            suffixIcon={<CaretDownOutlined rev='true' />}
            listHeight={264}
          >
            {mileages.map((option, index: number) => (
              <Option
                style={OptionStyle}
                key={`${index}-${option.value}`}
                value={option.value}
                label={option.label}
                id={option.value}
              >
                {option.label}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Affix offsetBottom={0}>
          <StyledFormButtonsWrapper>
            <ConfigProvider
              theme={{
                token: {
                  fontSizeLG: 12,
                },
                components: {
                  Button: {
                    borderRadius: 8,
                  },
                },
              }}
            >
              <StyledButton
                size='large'
                disabled={!countCars || !isValidPrice}
                loading={isFetchingCountCars}
                block
                type='primary'
                htmlType='button'
                onClick={handleFetchCars}
              >
                {getCountText(isFetchingCountCars, countCars)}
              </StyledButton>

              <StyledButton
                size='large'
                htmlType='submit'
                loading={isLoadingSubscription || isFetchingCountCars}
                block
                disabled={!isValidPrice}
                ghost
                type='primary'
              >
                Подписаться на уведомления
              </StyledButton>

              <ResetButton
                danger
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: 4,
                }}
                onClick={onClear}
              >
                Сбросить всё
              </ResetButton>
            </ConfigProvider>
          </StyledFormButtonsWrapper>
        </Affix>
      </Form>
    </StyledDesktopFilterForm.StyledFormWrapper>
  );
};
